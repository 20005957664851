import { SERVER_URL } from "../../../../../utils/constants";
import HttpRequest from "../../../../../utils/HttpRequest";

export const sendOtpToCustomer = async (
  data: any,
  token: string,
  itemId: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/otp/create/${itemId}`,
    data,
    token
  );
};

export const VerifyCustomerOtpService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/otp`, data, token);
};

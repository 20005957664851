import React from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getShopStockCartAction } from "../../../store/wareHouse/actions";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const CartItemsTable = (props: any) => {
  const { auth, wareHouse, company,pos } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const saleType = wareHouse?.createdCart?.data?.isSubscription;
  let images: any = [];
  const { cartId } = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    auth?.token && getShopStockCartAction(auth?.token, cartId)(dispatch);
  }, [auth?.token, cartId, dispatch]);

  const normalColumns: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text, record) => (
        <div className="h-24 w-24 rounded-lg xbg-blue-600">
          <img src={images && images[0]} alt="" className="h-24 w-24" />{" "}
        </div>
      ),
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "Extended Warranty",
      dataIndex: "extendedWarranty",
      key: "extendedWarranty",
      render: (text: any) => {
        return <p>{text + " %"}</p>;
      },
    },
    {
      title: "Price/Unit",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (text: any, _record) => <b>{`${text} ${getDefaultCurrencyCode(company)}`}</b>,
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (text: any) => {
        return <p>{text + " %"}</p>;
      },
    },
  ];
  const subscriptionColumns: ColumnsType<DataType> = [
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   render: (text, record) => (
    //     <div className="h-24 w-24 rounded-lg xbg-blue-600">
    //       <img src={images && images[0]} alt="" className="h-24 w-24" />{" "}
    //     </div>
    //   ),
    // },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "Price/Unit",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (text: any, record) => (
        <p className="font-medium">{`${text} ${getDefaultCurrencyCode(
          company
        )}`}</p>
      ),
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (text: any) => {
        return <p>{text + " %"}</p>;
      },
    },
  ];
  const columnsDesplayed =
    saleType === "subscription" ? subscriptionColumns : normalColumns;
  const value = wareHouse?.createdCart?.data?.list;
  React.useEffect(() => {
    value &&
      value?.forEach((el: any) => {
        dataMapper.push({
          id: el?._id,
          product: el?.shopStockItem?.requestedItem?.product?.product?.model,
          description: `${el?.shopStockItem?.requestedItem?.product?.specification
            ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
            ?.join(", ")}`,
          imei: el?.warehouseProduct?.serialNumber,
          extendedWarranty:
            el?.shopStockItem?.requestedItem?.product?.extendedWarranty,
          unitPrice: el?.payment?.amount,
          tax: "0",
          data: el,
        });
      });

    setformData(dataMapper);
  }, [wareHouse.createdCart]);

  try {
    images =
      formData[0]?.data?.shopStockItem?.requestedItem?.product?.product?.images;
  } catch (err) {}
  return (
    <Table
      columns={columnsDesplayed}
      dataSource={formData}
      loading={wareHouse?.isReFetchingCart ||pos?.isFetching}
      pagination={false}
    />
  );
};

export default CartItemsTable;

import React from 'react'
import { BiDollarCircle } from "react-icons/bi";
interface cardProps{
    icon:any,
    text:any,
    amount:any,
    yesterdayAmount:any
}
const OverviewCard = ({icon,text,amount,yesterdayAmount}:cardProps) => {
  return (
    <div className='bg-white rounded-md p-3 shadow-md'>
        <div>
       {icon}
        </div>
        <p className='text-[#030229B2] text-sm pt-2'>{text}</p>
        <p className='text-[#030229] text-base pt-2'>{amount}</p>
        <p className='text-gray-500 text-base pt-2'>{yesterdayAmount}</p>
    </div>
  )
}

export default OverviewCard
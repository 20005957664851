import React from "react";
import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";


export default function InventoryChart() {
  const { inventory } = useSelector((state: any) => state);

  const dates = inventory?.shopStats?.data?.StockTransfer?.results?.map((el: any) => el?.date) || [];
  const requests = inventory?.shopStats?.data?.stockreq?.results?.map((el: any) => el?.StockRequested) || [];
  const transfers = inventory?.shopStats?.data?.StockTransfer?.results?.map((el: any) => el?.StockTransfered) || [];

  const current = [2400,2210,2290,2000,2181,2500,2100] 
  const amount = [2400,2210,2290,2000,2181,2500,2100] 

  const data = dates.map((date:any, index:any) => ({
    name: date,
    current:current[index] ||0,
    amount:amount[index] ||0,
    request: requests[index] || 0, 
    transfer: transfers[index] || 0, 
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="0.2 0.2"/>
        <XAxis dataKey="name" axisLine={false} />
        <YAxis axisLine={false} />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="request"
          stroke="#FA365952"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="current" stroke="#0FA91E80" />
        <Line type="monotone" dataKey="transfer" stroke="#605BFF80" />
        {/* <Legend /> */}
      </LineChart>
    </ResponsiveContainer>
  );
}



import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "Vault-Management",
  initialState: {
    isFetching: false,
    isSendingFetching: false,
    new: null,
    updated: null,
    deviceInfo:null,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setIsSendingFetching(state, action) {
      state.isSendingFetching = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setDeviceInfo(state, action) {
      state.deviceInfo = action.payload;
    },
  },
});

export const vaultManagementActions = mySlice.actions;

export default mySlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "sub-customer",
  initialState: {
    isFetching: false,
    isSavingFeedback:false,
    customFormIsFetching: false,
    new: null,
    updated: null,
    customerSignature: null,
    customerImage: null,
    customerNationaID: null,
    customForm: null,
    userValidation:null,
    invalidUserData:null,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setCustomerSignature(state, action) {
      state.customerSignature = action.payload;
    },

    setCustomerImage(state, action) {
      state.customerImage = action.payload;
    },

    setCustomerNationaID(state, action) {
      state.customerNationaID = action.payload;
    },
    setCustomForm(state, action) {
      state.customForm = action.payload;
    },
    setCustomFormIsFetching(state, action) {
      state.customFormIsFetching = action.payload;
    },
    setIsSavingFeedback(state, action) {
      state.isSavingFeedback = action.payload;
    },
    setUserValidation(state, action) {
      state.userValidation = action.payload;
    },
    setInvalidUserData(state, action) {
      state.invalidUserData = action.payload;
    },
  },
});

export const subCustomerActions = mySlice.actions;

export default mySlice.reducer;

import { useDispatch } from "react-redux";
import { handleDisableMoMoIntegration } from "../../store/layout/actions";
import "../../styles/switches.css";

const Switches = () => {
  const dispatch = useDispatch();
  const handleCheckboxChange = async (event: any) => {
    const isChecked = event.target.checked;
    await handleDisableMoMoIntegration(isChecked)(dispatch);
  };
  return (
    <div className="toggle-button-cover">
      <div id="button-3" className="button r">
        <input
          className="checkbox"
          type="checkbox"
          onChange={handleCheckboxChange}
        />
        <div className="knobs"></div>
        <div className="layer"></div>
      </div>
    </div>
  );
};

export default Switches;

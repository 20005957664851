import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "kpi",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    allEarning: null,
    updated: null,
    query: null,
    options: null,
    manageItem: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setAllEarning(state, action) {
      state.allEarning = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const myKPIActions = mySlice.actions;

export default mySlice.reducer;

import { notification } from "antd";
import { myPurchaseOrderActions } from ".";
import {
  createPurchaseOrderService,
  getAllPurchaseOrdersService,
  getOnePurchaseOrderService,
  getRecentPurchaseOrdersService,
  sendPurchaseOrderEmailService,
  updatePurchaseOrderItemService,
  updatePurchaseOrderService
} from "./services";

export const createPurchaseOrderAction = (token: string, poId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPurchaseOrderActions.setIsFetching(true));
      const res = await createPurchaseOrderService(token, poId);
      const resAll = await getAllPurchaseOrdersService(token);

      if (res?.status === 201) {
        dispatch(myPurchaseOrderActions.setNew(res));
        dispatch(myPurchaseOrderActions.setAll(resAll));
        dispatch(myPurchaseOrderActions.setIsFetching(false));
        notification.success({message:"P.O created"})
      }
      dispatch(myPurchaseOrderActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllPurchaseOrdersAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPurchaseOrderActions.setIsFetching(true));
      const res = await getAllPurchaseOrdersService(token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPurchaseOrderActions.setAll(res));
        dispatch(myPurchaseOrderActions.setIsFetching(false));
      }
      dispatch(myPurchaseOrderActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSinglePurchaseOrderAction = (token: string, poId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPurchaseOrderActions.setIsFetching(true));
      const res = await getOnePurchaseOrderService(poId, token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPurchaseOrderActions.setSelected(res));
        dispatch(myPurchaseOrderActions.setIsFetching(false));
      }
      dispatch(myPurchaseOrderActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};


export const updatePurchaseOrderItemAction = (token: string, poIdItem: string, poId: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPurchaseOrderActions.setIsFetching(true));
      const res = await updatePurchaseOrderItemService(token, poIdItem, data);
      const resAll = await getOnePurchaseOrderService(poId, token);
      if (res?.status === 200) {
        // dispatch(myPurchaseOrderActions.setNew(res));
        dispatch(myPurchaseOrderActions.setSelected(resAll));
        dispatch(myPurchaseOrderActions.setIsFetching(false));
        notification.success({message:"P.O updated"})
      }
      dispatch(myPurchaseOrderActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updatePurchaseOrderAction = (token: string, poId: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPurchaseOrderActions.setIsFetching(true));
      const res = await updatePurchaseOrderService(token, poId, data);
      const resAll = await getOnePurchaseOrderService(poId, token);

      if (res?.status === 200) {
        // dispatch(myPurchaseOrderActions.setNew(res));
        dispatch(myPurchaseOrderActions.setSelected(resAll));
        dispatch(myPurchaseOrderActions.setIsFetching(false));
        notification.success({message:"P.O updated"})
        return true
      }
      dispatch(myPurchaseOrderActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const sendPurchaseOrderEmailAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPurchaseOrderActions.setIsFetching(true));
      const res = await sendPurchaseOrderEmailService(token, data);
      const resAll = await getOnePurchaseOrderService(data?.purchaseOrderID, token);

      if (res?.status === 200) {
        // dispatch(myPurchaseOrderActions.setNew(res));
        dispatch(myPurchaseOrderActions.setSelected(resAll));
        dispatch(myPurchaseOrderActions.setIsFetching(false));
        notification.success({message:"P.O Email sent."})
        return true
      }
      dispatch(myPurchaseOrderActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getRecentPurchaseOrdersAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPurchaseOrderActions.setIsFetching(true));
      const res = await getRecentPurchaseOrdersService(token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPurchaseOrderActions.setRecent(res));
        dispatch(myPurchaseOrderActions.setIsFetching(false));
      }
      dispatch(myPurchaseOrderActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({message: "something went wrong whle fetching recent activities"})
    }
  };
};
import Profile from "../../../assets/images/profile.jpeg";
import { ReactComponent as Increase } from "../../../icons/increaseIcon.svg";
import { ReactComponent as Earnings } from "../../../icons/earning.svg";
import { ReactComponent as Tquantity } from "../../../icons/quantity.svg";
import { ReactComponent as Tcustomer } from "../../../icons/customers.svg";
import EarningChart from "./EarningChart";
import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getAllAgentRevenueAction } from "../../../store/kpi/actions";
import {
  calculatePercentageChange,
  getActiveShop,
} from "../../../utils/converter";
import React, { useEffect } from "react";
import moment from "moment";
import EarningsDateRange from "./EarnDateRange";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";

const OverView = () => {
  const { auth, kpi, appSettings,company } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [enteredDate, setEnteredDate] = React.useState<any>(formattedDate);
  const [compareDate, setCompareDate] = React.useState<any>(
    formattedPreviousDate
  );

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEnteredDate(dateString);
  };

  const onChangePreviousDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setCompareDate(dateString);
  };

  const todayRevenue = kpi?.all?.data[0]?.currentDateRevenue;
  const comparedRevenue = kpi?.all?.data[0]?.comparedDateRevenue;

  const todayQuantity = kpi?.all?.data[0]?.currentDateQuantity;
  const comparedQuantity = kpi?.all?.data[0]?.comparedDateQuantity;

  const todayCustomer = kpi?.all?.data[0]?.currentDateCustomer;
  const comparedCustomer = kpi?.all?.data[0]?.comparedDateCustomer;

  const revenuePercentage = calculatePercentageChange(
    todayRevenue,
    comparedRevenue
  );

  const quantityPercentage = calculatePercentageChange(
    todayQuantity,
    comparedQuantity
  );

  const customerPercentage = calculatePercentageChange(
    todayCustomer,
    comparedCustomer
  );

  const userId = auth?.user?._id;
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  const cardData = [
    {
      title: "Total sales",
      amount: `${getDefaultCurrencyCode(company)} ${todayRevenue ?? 0}`,
      yesterdayAmount: `${getDefaultCurrencyCode(company)} ${comparedRevenue ?? 0}`,
      icon: <Earnings />,
      percent: (
        <div
          className={`flex px-1 ${revenuePercentage?.type === "increase"
            ? "bg-[#d4f8e56b]"
            : revenuePercentage?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-300"
            } rounded-[5px] py-[2px] absolute right-4 `}
        >
          <div className="mt-[2.5px] mr-1">
            {revenuePercentage?.type === "increase" ? (
              <Increase fill="#0FA958" />
            ) : revenuePercentage?.type === "decrease" ? (
              <Increase className="rotate-180 fill-red-500" />
            ) : (
              ""
            )}
          </div>
          <p
            className={`font-medium ${revenuePercentage?.type === "increase"
              ? "text-[#0FA958]"
              : revenuePercentage?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
              } text-[blue-900] text-sm`}
          >
            {`${revenuePercentage?.percentage}%`}
          </p>
        </div>
      ),
    },
    {
      title: "Total Quantity",
      amount: `${todayQuantity ?? 0} Devices`,
      yesterdayAmount: `${comparedQuantity ?? 0} Devices`,
      icon: <Tquantity />,
      percent: (
        <div
          className={`flex px-1 ${quantityPercentage?.type === "increase"
            ? "bg-[#d4f8e56b]"
            : quantityPercentage?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-300"
            } rounded-[5px] py-[2px] absolute right-4 `}
        >
          <div className="mt-[2.5px] mr-1">
            {quantityPercentage?.type === "increase" ? (
              <Increase fill="#0FA958" />
            ) : quantityPercentage?.type === "decrease" ? (
              <Increase className="rotate-180 fill-red-500" />
            ) : (
              ""
            )}
          </div>
          <p
            className={`font-medium ${quantityPercentage?.type === "increase"
              ? "text-[#0FA958]"
              : quantityPercentage?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
              } text-[blue-900] text-sm`}
          >
            {`${quantityPercentage?.percentage}%`}
          </p>
        </div>
      ),
    },
    {
      title: "Total Customers",
      amount: `${todayCustomer ?? 0} People`,
      yesterdayAmount: `${comparedCustomer ?? 0} People`,
      icon: <Tcustomer />,
      percent: (
        <div
          className={`flex px-1 ${customerPercentage?.type === "increase"
            ? "bg-[#d4f8e56b]"
            : customerPercentage?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-300"
            } rounded-[5px] py-[2px] absolute right-4 `}
        >
          <div className="mt-[2.5px] mr-1">
            {customerPercentage?.type === "increase" ? (
              <Increase fill="#0FA958" />
            ) : customerPercentage?.type === "decrease" ? (
              <Increase className="rotate-180 fill-red-500" />
            ) : (
              ""
            )}
          </div>
          <p
            className={`font-medium ${customerPercentage?.type === "increase"
              ? "text-[#0FA958]"
              : customerPercentage?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
              } text-[blue-900] text-sm`}
          >
            {`${customerPercentage?.percentage}%`}
          </p>
        </div>
      ),
    },
  ];
  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (auth?.token && enteredDate && compareDate) {
      getAllAgentRevenueAction(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&shop=${shopId}&agentId=${userId}`
      )(dispatch);
    } else {
      auth?.token && getAllAgentRevenueAction(auth?.token, "?")(dispatch);
    }
  }, [auth?.token, compareDate, dispatch, enteredDate, shopId, userId]);

  return (
    <div className="w-full min-h-screen text-[#030229] font-poppins mb-6">
      <div className="flex flex-wrap-reverse justify-between  ">
        <h1 className="font-semibold text-base md:text-[18px] opacity-90 mt-4 md:mt-0">
          Agent Statistics
        </h1>
        <div className="flex space-x-4">
          <DatePicker
            onChange={onChangeDate}
            defaultValue={dayjs()}
            style={{ width: 120 }}
            disabledDate={disabledDate}
          />
          <p className="text-[.8rem] md:text-sm mt-[.3rem]">Compared To</p>
          <DatePicker
            onChange={onChangePreviousDate}
            defaultValue={dayjs().subtract(1, "day")}
            style={{ width: 120 }}
            disabledDate={disabledDate}
          />
        </div>
      </div>
      <div className="flex flex-wrap justify-between mt-6 gap-6 lg:gap-0">
        <div className="bg-white shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)]  rounded-[12px]  py-6 p-6 w-[40rem] lg:w-[47%] ">
          <h1 className="font-medium text-base md:text-[18px] opacity-90">
            Account Profile
          </h1>
          <Space
            wrap
            style={{
              gap: "2rem",
              marginLeft: "2rem",
              marginTop: "3rem",
              marginBottom: "2rem",
              fontFamily: "poppins",
              color: "#030229",
            }}
          >
            <div className="w-[100px] md:w-[200px] h-[100px] md:h-[200px] xl:w-[230px] xl:h-[230px] rounded-full xl:mr-14">
            <img
              className="w-full h-full rounded-full xl:mr-14"
              src={auth?.user?.picture || Profile}
              alt="Profile pic"
            />
            </div>
            <div className="flex flex-col gap-4">
              <span>
                <p className="opacity-50 text-sm md:text-base  font-medium">Name</p>
                <p className="opacity-80 text-sm md:text-[18px] font-medium">
                  {auth?.user?.names}
                </p>
              </span>
              <span>
                <p className="opacity-50 text-sm md:text-base font-medium">Email</p>
                <p className="opacity-80 text-sm md:text-[18px] font-medium">
                  {auth?.user?.email}
                </p>
              </span>
              <span>
                <p className="opacity-50 text-sm md:text-base font-medium">Phone</p>
                <p className="opacity-80 text-sm md:text-[18px] font-medium">
                  {auth?.user?.phone}
                </p>
              </span>
              <span>
                <p className="opacity-50 text-sm md:text-base font-medium">Role</p>
                <p className="opacity-80 text-sm md:text-[18px] font-medium">
                  {auth?.user?.role}
                </p>
              </span>
            </div>
          </Space>
        </div>
        <div className="bg-white shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)] font-medium rounded-[12px] py-6 p-6  w-[40rem] lg:w-[49%]">
          <h1 className="font-medium text-base md:text-[18px] opacity-90 mb-8">
            Agent Sales KPI
          </h1>
          <div className="grid xl:grid-cols-2 gap-5">
            {cardData?.map((data: any) => {
              return (
                <div className=" bg-white py-5 px-8 rounded-[8px] w-full sm:w-[23rem] h-[10rem] shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)]  relative">
                  {data?.percent}
                  <div className="flex gap-6 mt-5">
                    <div className="mt-2"></div> {data?.icon}
                    <span className="flex flex-col gap-[6px]">
                      <p className="font-normal text-[16px] opacity-90 ">
                        {data?.title}
                      </p>
                      <p className="font-semibold text-[18px] opacity-90">
                        {data?.amount?.toLocaleString()}
                      </p>
                      <p className="font-medium text-[18px] opacity-50 ">
                        {data?.yesterdayAmount?.toLocaleString()}
                      </p>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="xl:flex flex-row-reverse justify-between mt-7">
        <div className="shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)] w-full pt-2 xl:w-[37%] bg-white rounded-[12px] overflow-x-auto">
          <EarningsDateRange />
        </div>
        <div className="shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)] w-full  xl:w-[60%] bg-white rounded-[12px] px-10 py-1 mt-5 xl:mt-0">
          <h1 className="font-medium text-[18px] opacity-90 mt-8 ml-4">
            Earnings Analytic
          </h1>
          <EarningChart />
        </div>
      </div>
    </div>
  );
};

export default OverView;

import React, { ReactInstance, useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { updateShopStockCartDetailsAction } from "../../../store/wareHouse/actions";
import { LoadingButton } from "@mui/lab";
import { Button, notification } from "antd";
import { useReactToPrint } from "react-to-print";
// import { handleNextStep } from "../../../store/layout/actions";
import { FaBackward } from "react-icons/fa6";
import { handleNextStepAction } from "../../device_financing/Subscription/redux/layout/actions";
import { customerSignatureAction } from "../../../store/pos/actions";
import { updateShopStockCartDetailsAction } from "../../../store/wareHouse/actions";
import { customerDocumentsAction } from "../../device_financing/Subscription/redux/customer/actions";

const Signature = (props: any, handleClose: any) => {
  const { auth, pos, wareHouse,customer } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const [sign, setSign] = useState<SignatureCanvas | null>(null);
  const templateRef = useRef<ReactInstance>(null);

  const handlePrint = useReactToPrint({
    content: () => templateRef.current,
    // pageStyle: `
    // @media print {
    //   @page {
    //     size: 58mm auto;
    //     margin: 0;
    //   }
    // }
    // `,
  });

  const handleClear = () => {
    sign?.clear();
    // props?.setCustomerSignature("");
  };

  const handleSignature = async () => {
    const accept = props.from === "receiptSignature" ? false : true;
    const trimmedCanvas = sign?.getTrimmedCanvas();
    if (trimmedCanvas) {
      // const dataUrl = trimmedCanvas.toDataURL("image/png");
      // if (dataUrl) {
      //   await customerSignatureAction(auth?.token, {
      //     image: dataUrl,
      //   })(dispatch);
      //     const res = await updateShopStockCartDetailsAction(
      //       auth?.token,
      //       cartId,
      //       {
      //         receiptSignature: pos?.customerSignature?.data?.secure_url,
      //         acceptTermsAndConditions: false,
      //       }
      //     )(dispatch);
      //     if (res) {
      //       handleNextStepAction(5)(dispatch);
      //       props?.handleClose();
      //     }
      // }
      const dataUrl = trimmedCanvas.toDataURL("image/png");
      if (dataUrl) {
        const res = await customerDocumentsAction(
          auth?.token,
          {
            image: dataUrl,
          },
          "signature"
        )(dispatch);
        if (res?.type) {
          const resp = await updateShopStockCartDetailsAction(
            auth?.token,
            cartId,
            {
              receiptSignature: res?.docLink,
              acceptTermsAndConditions: false,
            }
          )(dispatch);
          if (resp) {
            handleNextStepAction(5)(dispatch);
            props?.handleClose();
          }
        }
      }
    }
  };
  useEffect(() => {}, [pos?.customerSignature?.data?.secure_url]);
  const openNotification = () => {
    notification.error({
      message: "Customer signature is required",
    });
  };
  const checkSignature = () => {
    if (sign?.isEmpty()) {
      openNotification();
    } else {
      handleSignature();
      // props?.handleClose();
    }
  };
  return (
    <div className="xmin-h-52 xw-[100%] relative">
      <p className="text-[#5F5AFE] text-lg text-center font-bold pb-6">
        Sign Consent
      </p>
      <div className=" absolute left-0 top-0 ">
        <Button
          className="bg-[#5F5AFE] text-white"
          onClick={() => {
            props?.handleClose();
          }}
        >
          <FaBackward />
        </Button>
      </div>
      <SignatureCanvas
        canvasProps={{
          height: 200,
          width: 250,
          className:
            "h-[53pdx] w-[50pdx] sm:w-[352pxd] border-dotted border-2 border-indigo-300 py-2 cursor-pointer",
        }}
        ref={(data: any) => setSign(data)}
        penColor="black"
      />
      <div className="flex justify-center mt-4 space-x-4">
        <LoadingButton
          variant="outlined"
          style={{
            width: "100px",
          }}
          className="bg-[#5F5AFE] text-white"
          onClick={handleClear}
        >
          Clear
        </LoadingButton>
        <LoadingButton
          loading={customer?.isFetching || wareHouse?.isUpdateFetching}
          variant="contained"
          style={{
            width: "120px",
          }}
          className="bg-[#5F5AFE] text-white"
          onClick={() => {
            checkSignature();
            // handlePrint();
          }}
        >
          Confirm
        </LoadingButton>
      </div>
    </div>
  );
};

export default Signature;

import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const DraftEditor = ({
  setEditorState,
  editorState,
  placeholder,
}: any) => {
  return (
    <Editor
      editorClassName="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2.5 min-h-[300px]"
      editorState={editorState}
      placeholder={placeholder}
      onEditorStateChange={setEditorState}
      stripPastedStyles={true}
    />
  );
};

import React from "react";
import { createShopStockCartAction } from "../../../store/wareHouse/actions";
import { getActiveShop } from "../../../utils/converter";
import { useDispatch, useSelector } from "react-redux";
import { myCustomerActions } from "../../../store/customer";
import { myLayoutActions } from "../../../store/layout";
import { useNavigate } from "react-router-dom";
import { getAllCartsAction } from "../../../store/pos/actions";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { handleOpenSellingModeAction } from "../../../store/layout/actions";
import { handleNextStepAction } from "../../../apps/device_financing/Subscription/redux/layout/actions";
import { myWareHouseActions } from "../../../store/wareHouse";

const CreateSalesBtn = (props: any) => {
  const { auth, appSettings, wareHouse } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  const handleResetCartState = async () => {
    dispatch(myWareHouseActions.setCreatedCart(null));
    dispatch(myWareHouseActions.setCreatedCart(null));
    dispatch(myWareHouseActions.setSearchResult(null));
    handleNextStepAction(0)(dispatch);
  };

  const handleCreateSale = async () => {
    await handleResetCartState();
    await handleOpenSellingModeAction(true)(dispatch);
    auth?.token &&
      (await createShopStockCartAction(auth?.token, {
        shop:shopId
      })(dispatch));
    auth?.token &&
      getAllCartsAction(
        auth?.token,
        `?shop=${shopId}&status[]=pending&status[]=rejected&status[]=accepted&status[]=requested&status[]=inquiry`
      )(dispatch);
    dispatch(myCustomerActions.setSelected(null));
    dispatch(myLayoutActions.setIsNext(0));
    props?.setShowCreateSales(false);
  };
  React.useEffect(() => {
    if (wareHouse?.createdCart?.data?._id) {
      if (wareHouse?.isCartCreated) {
        navigate(`sale/${wareHouse?.createdCart?.data?._id}`);
      }
    }
  }, [navigate, wareHouse?.createdCart?.data?._id, wareHouse?.isCartCreated]);

  return (
    <div
      className={`bg-[#7782FF] cursor-pointer flex h-[80px] xl:h-[90px] w-[80px] xl:w-[90px] m-auto rounded-full items-center justify-between text-center  px-2 fade-in`}
      onClick={() => handleCreateSale()}
    >
      {wareHouse?.isFetching || wareHouse?.createCartIsFetching ? (
        <Spin
          className="ml-5 text-white"
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
      ) : (
        <h1 className="text-white text-sm xl:text-base font-medium">
          Create Sales
        </h1>
      )}
    </div>
  );
};

export default CreateSalesBtn;

import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { payWithMoMoAction } from "../../store/pos/actions";
import { getMomoPayments } from "../../utils/converter";
import momoLogo from "../../assets/images/MTNMomo-Logo.png";
import { useMediaQuery } from "@mui/material";
import { getDefaultCurrencyCode } from "../../utils/CurrencyHelper";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const MomoPaymentForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { auth, pos ,company} = useSelector((state: any) => state);
  const isTablet = useMediaQuery("(max-width: 1500px)");

  const onFinish = async (values: any) => {
    const phone = values?.phone?.replace(/\s/g, "");
    console.log(values,"value")
    if (auth.token) {
      await payWithMoMoAction(props?.auth.token, {
        phone,
        cartId: props?.warehouse?.createdCart?.data?.cartId,
      })(dispatch);
    }
    props?.setIsPaymentMomoModalOpen(true)
  };

  const totalPayment = getMomoPayments(props?.warehouse)
    ?.map((payment: { amount: number }) => payment?.amount)
    ?.reduce((a: number, b: number) => a + b, 0);
  return (
    <Form
      {...formItemLayout}
      form={form}
      name="momopay"
      onFinish={onFinish}
      initialValues={{}}
      style={{ maxWidth: "100%" }}
      scrollToFirstError
      className="flex justify-center flex-col antd-custom-style"
    >
      <div className="flex items-center justify-center mx-auto">
        <img src={momoLogo} alt="mtnLogo" className="w-80 " />
      </div>
      <div className="flex flex-col justify-start gap-x-5 h-full space-y-4">
        <div className={`${isTablet ? "ml-6" : "ml-28"}`}>
          <p className="lg:text-xl md:text-sm font-bold">Payment Details</p>
          {props?.warehouse.createdCart?.data?.payment?.map(
            (payment: { mode: string; amount: number }) => {
              return (
                <p className="text-lg">
                 {payment.mode?.split("_")?.join(" ")} : {payment?.amount?.toLocaleString()}
                </p>
              );
            }
          )}
          <p className="text-lg">
            You will pay{" "}
            <span className="font-bold text-lg">
              {" "}
              {`${totalPayment?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}
            </span>{" "}
            with MoMo Pay
          </p>
        </div>
        <div
          className={`${
            isTablet ? "ml-4" : "ml-24"
          } flex-1 xbg-gray-300 p-2 pb-5 rounded-lg space-y-1`}
        >
          <p className="lg:text-lg md:text-sm font-semibold mb-5">
            Momo number to pay from
          </p>
          <Form.Item
            name="phone"
            initialValue={props?.warehouse.createdCart?.data?.customer?.phone}
            rules={[
              {
                required: true,
                message: "Phone Number is required!",
                max: 18,
              },
            ]}
            hasFeedback
          >
            <PhoneInput
              country={"rw"}
              inputStyle={{
                width: isTablet ? "100%" : "100%",
                height: "36px",
              }}
              containerClass="phone-container"
              placeholder="+250 780 000 000"
            />
          </Form.Item>
        </div>
      </div>
      <div
        className={`${
          isTablet ? "ml-2" : "ml-20"
        } flex justify-start gap-x-4 pb-8 mt-1 !h-4 box-border`}
      >
        <Form.Item {...tailFormItemLayout}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={pos?.isFetching}
            className="w-32"
          >
            Send
          </LoadingButton>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <LoadingButton
            // type="submit"
            variant="outlined"
            onClick={props?.onCancel}
            className="w-32"
          >
            Back
          </LoadingButton>
        </Form.Item>
      </div>
    </Form>
  );
};

export default MomoPaymentForm;

import React, { useEffect } from "react";
import { Modal, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import type { TableColumnsType } from "antd";
import { getAllWarehouseOutItemsAction } from "../../../store/wareHouseOut/actions";
// import LoadingButton from "@mui/lab/LoadingButton";
// import { updateReceivedProductAction } from "../../../store/pos/actions";
import { Button } from "@mui/material";
import ConfirmStock from "../../forms/confirmStock";
// import NewStockIist from "../../forms/NewStockIist";
// import { myProductActions } from "../../../store/product";
// import ConfirmStock from "../../forms/ConfirmStock";
interface ExpandedDataType {
  _id: any;
  key: any;
  no: number;
  shop: any;
  address: any;
  model: any;
  specification: any;
  snumber: any;
  imei1: any;
  imei2: any;
  price: any;
  extendedWarranty: any;
  color: any;
  isSelected: any;
  data: any;
}

const ConfirmStockTable = (props: any) => {
  const { auth, wareHouseOut, product } = useSelector((state: any) => state);
  const [expandedRowKey, setExpandedRowKey] = React.useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState<any>("");
  const [prodId, setProdId] = useState();
  const dispatch = useDispatch();
  const deliverer = wareHouseOut?.warehouseOut?.data[0]?.deliverer;

  useEffect(() => {
    auth?.token &&
      prodId &&
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${prodId}`
      )(dispatch);
  }, [auth?.token, prodId, dispatch]);

  const expandedRowRender = () => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: "No",
        dataIndex: "no",
        key: "no",
      },
      {
        title: "Shop",
        dataIndex: "shop",
        key: "shop",
        render: (text: string, record: any) => (
          <>
            <p>{text}</p>
            <p>{record?.data?.shop?.channel?.name}</p>
          </>
        ),
      },
      {
        title: "SN/BarCode",
        dataIndex: "snumber",
        key: "snumber",
      },
      {
        title: "Scanned",
        dataIndex: "scanned",
        key: "scanned",
      },
    ];

    const productData = wareHouseOut?.warehouseOutItem?.data?.map(
      (el: any, index: any) => {
        return {
          key: el._id,
          _id: el?._id,
          no: index + 1,
          shop: el?.shop?.name,
          address: el?.shop?.address,
          model: el?.requestedItem?.product?.product?.model,
          specification: el?.requestedItem?.product?.specification,
          price: el?.requestedItem?.product?.prices,
          extendedWarranty: el?.requestedItem?.product?.extendedWarranty,
          snumber: el?.wareHouseItem?.serialNumber,
          imei1: el?.wareHouseItem?.imei1,
          imei2: el?.wareHouseItem?.imei2,
          color: "",
          isSelected: el?.isSelected,
          data: el,
          scanned: el?.isRecieved ? "Yes" : "No",
        };
      }
    );

    return (
      <Table
        columns={columns}
        dataSource={productData}
        pagination={false}
        loading={wareHouseOut?.isFetching}
      />
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "data",
      key: "data",
      render: (text: string, record: any) => (
        <p>{record?.data?.updatedAt?.split("T")[0]}</p>
      ),
    },
    {
      title: "Models",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Descriptions",
      dataIndex: "specification",
      key: "specification",
      render: (text: any, record: any) => (
        <p>
          {record?.specification
            ?.slice(2)
            ?.map((d: any) => d[1] && `${d[0]} : ${d[1]}`)
            ?.join(", ")}
        </p>
      ),
    },
    {
      title: "Price(Frw)",
      dataIndex: "price",
      key: "price",
      render: (text: string, record: any) => (
        <p>
          {record?.data?.product?.prices
            ?.find((obj: any) => obj?.isActive === true)
            ?.value.toLocaleString() || "N/A"}
        </p>
      ),
    },
    {
      title: "Extended Warranty",
      dataIndex: "data",
      key: "data",
      render: (text: string, record: any) => (
        <p>{record?.data?.product?.extendedWarranty ?? "0"} %</p>
      ),
    },
    {
      title: "Requested Quantity",
      dataIndex: "data",
      key: "data",
      render: (text: string, record: any) => <p>{record?.data?.qtyApproved}</p>,
    },
    {
      title: "Received Quantity",
      dataIndex: "qtyRecieved",
      key: "qtyRecieved",
    },
    // {
    //   title: " ",
    //   dataIndex: "data",
    //   key: "data",
    //   render: (text: string, record: any) => (
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       onClick={() => handleModalOpen(record)}
    //     >
    //       Scan
    //     </Button>
    //   ),
    // },
    {
      title: " ",
      dataIndex: "data",
      key: "data",
      render: (text: string, record: any) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleModalOpen(record)}
            disabled={
              record?.data?.qtyApproved <= record?.qtyRecieved ||
              record?.data?.qtyApproved === 0
            }
          >
            Confirm Stock
          </Button>
        );
      },
    },
  ];

  const data = wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.map(
    (el: any, index: number) => {
      return {
        key: el._id,
        _id: el?._id,
        no: index + 1,
        model: el?.product?.product?.model,
        specification: el?.product?.specification,
        qtyRecieved: el?.qtyRecieved ?? 0,
        qtyApproved: el?.qtyApproved,
        data: el,
        shopReqId: wareHouseOut?.selectedWarehouseOut?.stockRequest?._id,
      };
    }
  );

  const handleRowExpand = (expanded: any, record: any) => {
    if (expanded) {
      setProdId(record.key);
      setExpandedRowKey(record.key);
    } else {
      setExpandedRowKey(null);
    }
  };

  // const handleValidate = async () => {
  //   if (auth.token) {
  //     await updateReceivedProductAction(
  //       auth.token,
  //       wareHouseOut?.selectedWarehouseOut?._id,
  //       {
  //         status: "received",
  //       }
  //     )(dispatch);
  //   }
  // };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (el: any) => {
    setIsModalOpen(true);
    setValue(el);
  };

  // useEffect(() => {
  //   const handleReceiveProduct = async () => {
  //     if (
  //       auth.token &&
  //       product?.storedProduct &&
  //       product?.storedProduct?.quantityApproved ===
  //         product?.storedProduct?.verifiedDevices
  //     ) {
  //       await updateReceivedProductAction(
  //         auth.token,
  //         wareHouseOut?.selectedWarehouseOut?._id,
  //         {
  //           status: "received",
  //         }
  //       )(dispatch);
  //       await dispatch(myProductActions.setStoredProduct(null));
  //     }
  //   };
  //   handleReceiveProduct();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [auth.token, dispatch, product?.storedProduct]);

  return (
    <>
      <div className="flex px-6 justify-between gap-2 mb-6 text-gray-900">
        <div>
          <div>
            <p className="font-semibold">
              Channel:
              <span className="text-gray-900 font-medium capitalize">
                {" "}
                {
                  wareHouseOut?.warehouseOut?.data[0]?.stockRequest?.shopId
                    ?.channel?.name
                }
              </span>
            </p>
            <p className="font-semibold">
              Shop:
              <span className="text-gray-900 font-medium capitalize">
                {" "}
                {
                  wareHouseOut?.warehouseOut?.data[0]?.stockRequest?.shopId
                    ?.name
                }
              </span>
            </p>
            <p className="font-semibold">
              Approved By:
              <span className="text-gray-900 font-medium capitalize">
                {" "}
                {
                  wareHouseOut?.selectedWarehouseOut?.stockRequest?.approvedBy
                    ?.names
                }
              </span>
            </p>
          </div>
        </div>
        <div>
          <div>
            <h1 className="font-bold mb-1">Deliverer</h1>
            <p className="font-semibold">
              Name:
              <span className="text-gray-900 font-medium capitalize">
                {" "}
                {deliverer?.name}
              </span>
            </p>
            <p className="font-semibold">
              Company:
              <span className="text-gray-900 font-medium">
                {" "}
                {deliverer?.company}
              </span>
            </p>
            <p className="font-semibold">
              {" "}
              Telephone:
              <span className="text-gray-900 font-medium">
                {" "}
                {deliverer?.phone}
              </span>
            </p>
            <p className="font-semibold">
              Email:
              <span className="text-gray-900 font-medium">
                {" "}
                {deliverer?.email}
              </span>
            </p>
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
          onExpand: handleRowExpand,
          expandedRowKeys: [expandedRowKey],
        }}
        style={{ width: "100%" }}
        loading={wareHouseOut?.isFetching}
      />

      {/* {wareHouseOut?.selectedWarehouseOut?.status?.toLowerCase() ===
        "in-transit" && (
        <LoadingButton
          variant="contained"
          sx={{ minWidth: 100 }}
          onClick={() => handleValidate()}
          loading={pos.isFetching}
          // disabled={true}
        >
          Confirm
        </LoadingButton>
      )} */}

      {/* <Modal
        title=""
        open={isModalOpen}
        maskClosable={false}
        footer={null}
        onCancel={handleCancel}
        width={700}
      >
        <NewStockIist onCancel={handleCancel} data={value} />
      </Modal> */}
      <Modal
        title=""
        open={isModalOpen}
        maskClosable={false}
        footer={null}
        onCancel={handleCancel}
        width={400}
      >
        <ConfirmStock onCancel={handleCancel} data={value} prodId={prodId} />
      </Modal>
    </>
  );
};

export default ConfirmStockTable;

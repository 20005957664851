import React from "react";
import { PieChart, Pie, Sector, Tooltip } from "recharts";
import { useSelector } from "react-redux";

const RenderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    payload,
    value,
    name,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  // Adjust the outerRadius calculation based on percent
  const increasedOuterRadius = outerRadius * (1 + 0.2 * percent);

  // Define an array of colors based on conditions
  const colors = ["#9182FE", "#20DD8E", "#FFC700"];
  // Determine fill color based on percent and conditions
  let fillColor;
  if (percent >= 0.5) {
    fillColor = colors[0];
  } else if (percent >= 0.3) {
    fillColor = colors[1];
  } else {
    fillColor = colors[2];
  }

  return (
    <g>
      <Sector
        style={{ backgroundColor: "red" }}
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={increasedOuterRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fillColor}
      />
      <Sector
        cx={cx}
        cy={cx}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={increasedOuterRadius}
        fill={fillColor}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fillColor}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fillColor} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${name}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

const CustomPieChart = () => {
  const { customer } = useSelector((state: any) => state);

  const customerData = customer?.customerStatus?.data || []; // Use default empty array if data is missing

  const data = [
    { name: "DeviceFinancing", value: customerData?.DeviceFinancing || 0 },
    { name: "Others", value: customerData?.Others || 0 },
  ];
  const activeIndices = data.map((_, index) => index);

  return (
    <div style={{ position: "relative", display: "flex", width: "20px" }}>
      <PieChart width={410} height={400} className="mt-[-10rem] px-6">
        <Pie
          activeIndex={activeIndices}
          activeShape={RenderActiveShape}
          data={data}
          cx={200}
          cy={200}
          innerRadius={60}
          outerRadius={90}
          fill="#20DD8E"
          dataKey="value"
        >
          <Tooltip />
        </Pie>
      </PieChart>
      <div
        className="content"
        style={{
          position: "absolute",
          top: 0,
          left: "60%",
          transform: "translateX(-50%)",
        }}
      >
        {/* {data.map((entry, index) => (
          <div key={index}>
            {entry.name} percent is{" "}
            {(
              (entry.value /
                data.reduce((sum, entry) => sum + entry.value, 0)) *
              100
            ).toFixed(2)}
            %
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default CustomPieChart;

import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateReceivedProductAction,
  updateStockReceivedAction,
} from "../../store/pos/actions";
import { getActiveShop } from "../../utils/converter";

const ConfirmStock = (props: any) => {
  const { auth, shop, appSettings, pos, wareHouseOut } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const shopName =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?.name || shop?.myShop?.name;

  const qntySent =
    wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.reduce(
      (curr: any, item: any) => curr + item?.qtyApproved,
      0
    );

  const qntyReceived =
    wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.reduce(
      (curr: any, item: any) => curr + (item?.qtyRecieved ?? 0),
      0
    );

  const onFinish = async (values: any) => {
    if (auth.token) {
      await updateStockReceivedAction(auth.token, props?.data?.key, {
        qtyRecieved:
          parseInt(props?.data?.qtyRecieved) + parseInt(values?.qtyRecieved),
        isRecieved: true,
        isNotify: true,
        notification: {
          action: "Received Stock",
          role: [
            "sales-manager",
            "sales-supervisor",
            "inventory",
            "dev",
            "admin",
            "finance",
            "finance-manager",
          ],
          message: `${auth?.user?.names} from ${shopName} shop has received ${values?.qtyRecieved} of ${qntySent} ${props?.data?.model} devices`,
          title: "Received Stock",
        },
      })(dispatch);
      if (qntySent === qntyReceived + parseInt(values?.qtyRecieved)) {
        updateReceivedProductAction(
          auth.token,
          wareHouseOut?.selectedWarehouseOut?._id,
          {
            status: "completed",
          }
        )(dispatch);
      } else {
        updateReceivedProductAction(
          auth.token,
          wareHouseOut?.selectedWarehouseOut?._id,
          {
            status: "incompleted",
          }
        )(dispatch);
      }
      navigate("/inventory");
      props?.onCancel();
    }
    form.resetFields();
  };

  return (
    <>
      <div className="mb-4">
        <h1 className="font-bold text-sm">
          {`Could you please confirm the total number of ${props?.data?.model}  received?`}
        </h1>
      </div>

      <Form
        form={form}
        name="Stock"
        onFinish={onFinish}
        layout="vertical"
        scrollToFirstError
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={true}
        style={{
          maxWidth: 500,
        }}
      >
        <div className="flex items-center flex-col">
          <Form.Item
            name="qtyRecieved"
            label="Quantity Received"
            rules={[
              {
                required: true,
                message: "Field is required!",
                whitespace: true,
              },
            ]}
          >
            <Input style={{ width: 365, height: 35 }} type="number" />
          </Form.Item>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ width: 200 }}
            disabled={pos?.isFetching}
            loading={pos?.isFetching}
          >
            Confirm
          </LoadingButton>
        </div>
      </Form>
    </>
  );
};

export default ConfirmStock;

import { notification } from "antd";
import { subCustomerActions } from ".";
import {
  agentFeedbackService,
  createServiceCustomer,
  customerDocumentsService,
  customerValidationService,
  getCustomFormService,
} from "./services";
import { updateServiceCustomer } from "../../../../../store/customer/services";

export const createCustomerAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(subCustomerActions.setIsFetching(true));

      const res = await createServiceCustomer(data, token);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(subCustomerActions.setNew(res));
        dispatch(subCustomerActions.setIsFetching(false));
        notification.success({
          message: "Created Successfully!",
        });
        return { type: true, data: res?.data };
      } else if (res?.response?.status === 409) {
        dispatch(subCustomerActions.setIsFetching(false));
        notification.warning({
          message: "Customer with this phone number already exists!",
        });
        return { type: false, data: {} };
      }
      dispatch(subCustomerActions.setIsFetching(false));
      return { type: false, data: {} };
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateCustomerAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(subCustomerActions.setIsFetching(true));
      const res = await updateServiceCustomer(itemId, data, token);
      if (res?.status === 200) {
        dispatch(subCustomerActions.setUpdated(res));
        dispatch(subCustomerActions.setNew(res));
        dispatch(subCustomerActions.setIsFetching(false));
        return true;
      } else {
        dispatch(subCustomerActions.setIsFetching(false));
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const customerDocumentsAction = (
  token: string,
  data: any,
  storeTo: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(subCustomerActions.setIsFetching(true));
      const res = await customerDocumentsService(data, token);
      if (res?.status === 200) {
        if (storeTo === "signature") {
          dispatch(subCustomerActions.setIsFetching(false));
          dispatch(subCustomerActions.setCustomerSignature(res));
          return { type: true, docLink: res?.data?.secure_url };
        } else if (storeTo === "image") {
          dispatch(subCustomerActions.setIsFetching(false));
          dispatch(subCustomerActions.setCustomerImage(res));
          return { type: true, docLink: res?.data?.secure_url };
        } else if (storeTo === "nid") {
          dispatch(subCustomerActions.setIsFetching(false));
          dispatch(subCustomerActions.setCustomerNationaID(res));
          return { type: true, docLink: res?.data?.secure_url };
        }

        dispatch(subCustomerActions.setIsFetching(false));
      }
      dispatch(subCustomerActions.setIsFetching(false));
      return { type: false, docLink: {} };
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCustomFormAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(subCustomerActions.setCustomFormIsFetching(true));
      const res = await getCustomFormService(query, token);
      if (res?.status === 200) {
        dispatch(subCustomerActions.setCustomForm(res?.data));
        dispatch(subCustomerActions.setCustomFormIsFetching(false));
      }
      dispatch(subCustomerActions.setCustomFormIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const agentFeedbacksAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(subCustomerActions.setIsSavingFeedback(true));
      const res = await agentFeedbackService(data, token);
      if (res?.status === 200) {
        dispatch(subCustomerActions.setIsSavingFeedback(false));
        notification.success({
          message: res?.message,
        });
        return { type: true, data: res?.data };
      }
      dispatch(subCustomerActions.setIsSavingFeedback(false));
      return { type: false, data: {} };
    } catch (err) {
      console.log(err);
    }
  };
};

export const customerValidationAction = (id: any, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(subCustomerActions.setIsFetching(true));
      const res = await customerValidationService(id, data, token);
      if (res?.status === 200) {
        dispatch(subCustomerActions.setUserValidation(res));
        dispatch(subCustomerActions.setIsFetching(false));
        return { type: true, data: res?.data };
      } else if (res?.status === 400) {
        dispatch(subCustomerActions.setInvalidUserData(res?.response));
        dispatch(subCustomerActions.setIsFetching(false));
        return { type: false, data: res?.response };
      }
      dispatch(subCustomerActions.setIsFetching(false));
    } catch (err) {
      dispatch(subCustomerActions.setIsFetching(false));
      console.log(err);
    }
  };
};

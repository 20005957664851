import { addDays } from "date-fns";
import { useState } from "react";
import { DateRangePicker, Range, RangeKeyDict } from "react-date-range";
import { enUS } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./style.css";
import { getAnalyticAction } from "../../../store/customer/actions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
const DateRangePickers: React.FC = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: any) => state);

  const [state, setState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const startDate = dayjs(state[0]?.startDate).format("YYYY-MM-DD");
  const endDate = dayjs(state[0]?.endDate).format("YYYY-MM-DD");

  React.useEffect(() => {
    auth?.token &&
      getAnalyticAction(
        auth?.token,
        `?date1=${startDate}&date2=${endDate}`
      )(dispatch);
  }, [auth?.token, startDate, endDate, dispatch]);

  return (
    <DateRangePicker
      className="w-full"
      onChange={(ranges: RangeKeyDict) => {
        const selection = ranges["selection"];
        if (selection) {
          setState([selection]);
        }
      }}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={state}
      direction="horizontal"
      locale={enUS}
    />
  );
};

export default DateRangePickers;

import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/Layer.png";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
// import "../buttons/button.css";
import { GoPlus } from "react-icons/go";
import { MdClose } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { myWareHouseActions } from "../../store/wareHouse";
import { ReactComponent as Expandicons } from "../../../icons/expand.svg";
import "./navBar.css";
import { Drawer, Radio } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { pageList } from "../assets/pages";
import {
  getActiveMenu,
  getActiveShop,
  storeActiveMenu,
  storeSelectedCaption,
} from "../../../utils/converter";
import { myLayoutActions } from "../../../store/layout";
import MobileNavBar from "./MobileNavBar";
import { myWareHouseActions } from "../../../store/wareHouse";
import { handleNextStepAction } from "../../device_financing/Subscription/redux/layout/actions";
import {
  createShopStockCartAction,
  getShopStockCartAction,
  updateShopStockCartDetailsAction,
} from "../../../store/wareHouse/actions";
import { getAllCartsAction } from "../../../store/pos/actions";
import { myCustomerActions } from "../../../store/customer";
import { subLayoutActions } from "../../device_financing/Subscription/redux/layout";
import { RxHamburgerMenu } from "react-icons/rx";
const NavBar = (props: any) => {
  const { auth, wareHouse, appSettings } = useSelector((state: any) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;
  type SubMenuOpenState = {
    [key: number]: boolean;
  };

  const [subMenuOpen, setSubMenuOpen] = useState<SubMenuOpenState>({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(getActiveMenu());
  const [sideBar, SetSideBar] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const showSideBar = () => {
    SetSideBar(!sideBar);
  };

  let indexes: number[] = [];

  const toggleSubMenu = (
    indexes: number[],
    providedIndex: number,
    status = false
  ) => {
    setSubMenuOpen((prevState) => {
      const newState = { ...prevState };
      indexes.forEach((index) => {
        if (index !== providedIndex) {
          newState[index] = false;
        } else {
          newState[index] = status === false ? !prevState[index] : true;
        }
      });
      return newState;
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attemptToOpen, setAttemptToOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [subMenuData, seSubMenuData] = useState<any>();
  const showDrawer = (data: any) => {
    setOpen(true);
    seSubMenuData(data);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let currentMenu = localStorage.getItem("currentMenu") || "";
    toggleSubMenu(indexes, parseInt(currentMenu), true);
  }, [attemptToOpen]);
  const styles = {
    shadow: {
      boxShadow: "0px -8px 60px 0px #312D570F",
    },
  };
  const handleResetCartState = async () => {
    dispatch(myWareHouseActions.setCreatedCart(null));
    dispatch(myWareHouseActions.setSearchResult(null));
    dispatch(subLayoutActions.setNewCustomer(false));
    handleNextStepAction(0)(dispatch);
  };
  
  const handleCreateSale = async () => {
    dispatch(subLayoutActions.setOpenNewCart(true));
    setIsLoading(true);
    await handleResetCartState();
    auth?.token &&
      (await createShopStockCartAction(auth?.token, {
        shop: shopId,
        isSubscription: "subscription",
      })(dispatch));
    auth?.token &&
      getAllCartsAction(
        auth?.token,
        `?&status[]=pending&status[]=rejected&status[]=accepted&status[]=requested&status[]=inquiry`
      )(dispatch);
    dispatch(myCustomerActions.setSelected(null));
    if (wareHouse?.isCartCreated) {
      // await wareHouse?.createdCart?.data?._id && getShopStockCartAction(auth?.token, wareHouse?.createdCart?.data?._id)(dispatch);
      setIsLoading(false);
      navigate(`apply/${wareHouse?.createdCart?.data?._id}`);
    }
  };
  useEffect(() => {
    auth?.token && getShopStockCartAction(auth?.token, cartId)(dispatch);
  }, [auth?.token, cartId, dispatch]);
  React.useEffect(() => {
    if (wareHouse?.isCartCreated) {
      navigate(`apply/${wareHouse?.createdCart?.data?._id}`);
      setIsLoading(false);
    }
  }, [wareHouse?.isCartCreated]);
  return (
    <div className="text-[#030229]  relative">
      {/* <div className="absolute right-4 m-4" onClick={showDrawer}><RxHamburgerMenu size={32}/></div> */}
      {open && (
        <div className="h-screen w-full">
          <Drawer
            placement="left"
            width={250}
            closable={false}
            onClose={onClose}
            open={open}
            getContainer={false}
          >
            {/* <ul onClick={() => navigate(subMenuData?.path)}>
              {subMenuData &&
                subMenuData?.map((subMenu: any, index: any) => {
                  return (
                    <li
                      key={subMenu.caption}
                      onClick={() => {
                        localStorage.setItem("currentMenu", index.toString());
                        const selectedCaption = subMenu.caption;
                        storeSelectedCaption(selectedCaption);
                        navigate(subMenu.path);
                        setOpen(false);
                      }}
                      className={`py-1 cursor-pointer flex gap-2 text-sm my-2 px-2 hover:bg-light-white rounded-md duration-500 ${
                        localStorage.getItem("selectedCaption") ===
                        subMenu.caption
                          ? "text-[#605BFF] bg-[#ebf2ff46]"
                          : "text-[#030229CC]"
                      }`}
                    >
                      {subMenu?.caption}
                    </li>
                  );
                })}
            </ul> */}
          </Drawer>
        </div>
      )}
      <div
        className={`hidden md:block bg-[#FAFAFB] shadow-lg duration-300 z-10 h-screen pt-4 ${
          props?.open ? "w-52 xl:w-56" : "w-20"
        } fixed`}
      >
         <div className="absolute bottom-[4rem] z-10 w-full flex items-center justify-center content-center">
            <div
              className={`-bottom-5 -ml-6  xl:bottom-2 cursor-pointer absolute bg-[#605BFF] flex m-auto rounded-full items-center justify-between border border-white`}
              onClick={() => {
                handleCreateSale();
              }}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      spin
                      className={`h-[35px] w-[35px] flex items-center justify-center text-white`}
                      style={{ width: "35px", height: "35px", color: "white" }}
                    />
                  }
                />
              ) : (
                <GoPlus
                  className={`h-[35px]  w-[35px] flex items-center justify-center`}
                  color="white"
                />
              )}
            </div>
          </div>
        <IoIosArrowDroprightCircle
          className={`absolute top-[4.3rem] z-[999] text-[#605BFF] opacity-80 -right-3 cursor-pointer ${
            props?.open && "rotate-180"
          }`}
          size={30}
          onClick={() => props?.setOpen(!props?.open)}
        />
        <div className="flex justify-center items-center gap-2.5 duration-500 font-medium py-3 border-slate-300">
          {props?.open ? (
            <img
              src={Logo}
              alt="Logo"
              className={`transition-all w-[8rem] mst-3`}
            />
          ) : (
            <h1 className="text-2xl  font-bold text-black duration-500 text-center">
              S
            </h1>
          )}
        </div>
        <ul className="borders-y py-1 xl:py-5 borders-slate-300 p-2 overflow-y-auto hide-scrollbar h-[83vh]">
          {pageList?.filter((el:any)=> el?.name !=="Apply")?.map((mainMenu: any, index: any) => {
            indexes.push(index);
            return (
              <>
                <li
                  key={mainMenu?.id}
                  className={`flex gap-3 mt-2 p-2 py-2 rounded-sm cbg-[#FAFAFB] hover:bg-[#ebf2ff46] ${
                    selectedTabIndex === mainMenu.id ? "  bg-[#FAFAFB]" : " "
                  }`}
                  onClick={() => {
                    setSelectedTabIndex(mainMenu.id);
                    storeActiveMenu(mainMenu.id);
                    if (!mainMenu.menus) {
                      navigate(mainMenu.path);
                      // showSideBar();
                    } else {
                      props?.setOpen(true);
                    }
                  }}
                >
                  <span
                    className={`text-2xl ml-4  ${
                      selectedTabIndex === mainMenu.id
                        ? "activeIcon shadow-[-53px_0px_25px_6px_rgba(96,91,255,1)]"
                        : "#030229CC"
                    }`}
                  >
                    <mainMenu.icon />
                  </span>
                  <span
                    className={` text-base flex items-center cursor-pointer rounded-md ${
                      !props?.open && "hidden"
                    } ${
                      selectedTabIndex === mainMenu.id
                        ? "text-[#605BFF]"
                        : "text-[#030229CC]"
                    }`}
                    onClick={(e: any) => {
                      toggleSubMenu(indexes, index);
                    }}
                  >
                    {" "}
                    {mainMenu.name}
                  </span>
                  <span>
                    {" "}
                    {mainMenu?.menus && props?.open && (
                      <IoIosArrowDown
                        onClick={(e: any) => {
                          e.stopPropagation();
                          toggleSubMenu(indexes, index);
                        }}
                        className={`mt-1 ${
                          subMenuOpen[index] ? "rotate-180" : ""
                        } ${
                          selectedTabIndex === mainMenu.id
                            ? "text-[#605BFF]"
                            : "text-[#030229CC]"
                        }`}
                      />
                    )}
                  </span>
                </li>
                <ul>
                  {mainMenu?.menus &&
                    subMenuOpen[index] &&
                    props?.open &&
                    mainMenu?.menus.map((subMenu: any, subIndex: any) => {
                      return (
                        <div className="flex" key={subIndex}>
                          <div className="ml-8">
                            <Expandicons />
                          </div>
                          <li
                            key={subMenu.caption}
                            onClick={() => {
                              localStorage.setItem(
                                "currentMenu",
                                index.toString()
                              );
                              const selectedCaption = subMenu.caption;
                              storeSelectedCaption(selectedCaption);
                              navigate(subMenu.path);
                              showSideBar();
                              setAttemptToOpen(!attemptToOpen);
                            }}
                            className={`py-1 cursor-pointer flex gap-2 text-sm my-[1px] px-2 hover:bg-light-white rounded-md duration-500 ${
                              localStorage.getItem("selectedCaption") ===
                              subMenu.caption
                                ? "text-[#605BFF] bg-[#ebf2ff46]"
                                : "text-[#030229CC]"
                            }`}
                          >
                            {subMenu.caption}
                          </li>
                        </div>
                      );
                    })}
                </ul>
              </>
            );
          })}
        </ul>
      </div>
      <div className="relative">
        <div
          className=" block md:hidden fixed  bottom-0 z-10 bg-[#FFFFFF] py-1 w-full h-[90px] items-center content-center justify-center"
          style={styles.shadow}
        >
          <MobileNavBar openDrawer={showDrawer} />
          <div className="absolute bottom-[2rem]d z-10 w-full flex items-center justify-center content-center">
            <div
              className={`bottom-14 -ml-6  xl:bottom-2 cursor-pointer absolute bg-[#605BFF] flex m-auto rounded-full items-center justify-between border border-white`}
              onClick={() => {
                handleCreateSale();
              }}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      spin
                      className={`h-[35px] w-[35px] flex items-center justify-center text-white`}
                      style={{ width: "35px", height: "35px", color: "white" }}
                    />
                  }
                />
              ) : (
                <GoPlus
                  className={`h-[35px] w-[35px] flex items-center justify-center`}
                  color="white"
                />
              )}
            </div>
          </div>
        </div>
        {/* <div className="h-64 w-64 bg-red-400">
          hello
        </div> */}
      </div>
    </div>
  );
};

export default NavBar;

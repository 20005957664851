import { ReactComponent as EarningsIcons } from "../../assets/dashboard/chart-square.svg";
import { ReactComponent as RepairIcons } from "../../assets/dashboard/ph_toolbox-light.svg";
import { ReactComponent as customerIcons } from "../../assets/dashboard/heroicons_users.svg";
import { ReactComponent as PriceListIcons } from "../../assets/dashboard/note-favorite.svg";
import { ReactComponent as InventoryIcons } from "../../assets/dashboard/mingcute_inventory-line.svg";
import { ReactComponent as ReportsIcons } from "../../assets/dashboard/icon-park-outline_sales-report.svg";
import { ReactComponent as DashboardIcons } from "../../assets/dashboard/ri_dashboard-3-line.svg";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { RiDashboard3Line } from "react-icons/ri";
import { repairRoutes } from "../../routes/RepairRoutes";
import { inventoryRoutes } from "../../routes/InventoryRoutes";
import { reportRoutes } from "../../routes/ReportRoutes";
import { posCustomers } from "../../routes/RoutesCustomer";

export const subMenusList = [
  {
    id: 1,
    name: "Dashboard",
    icon: DashboardIcons,
    path: "/dashboard",
    roles: [
      "sales-agent",
      "sales-manager",
      "sales-supervisor",
      "admin",
      "dev",
      "mtn-sales-agent",
    ],
  },
  {
    id: 2,
    name: "Earnings",
    icon: EarningsIcons,
    path: "/earning",
    roles: [
      "sales-agent",
      "sales-manager",
      "sales-supervisor",
      "admin",
      "dev",
      "mtn-sales-agent",
    ],
  },
  {
    id: 3,
    name: "Inventory",
    icon: InventoryIcons,
    path: "/inventory",
    menus: inventoryRoutes,
    roles: [
      "sales-agent",
      "sales-manager",
      "sales-supervisor",
      "admin",
      "dev",
      "mtn-sales-agent",
    ],
  },
  {
    id: 4,
    name: "Price Lists",
    icon: PriceListIcons,
    path: "/priceLists",
    roles: [
      "sales-agent",
      "sales-manager",
      "sales-supervisor",
      "admin",
      "dev",
      "mtn-sales-agent",
    ],
  },
  {
    id: 5,
    name: "Customers",
    icon: customerIcons,
    path: "/customers",
    menus: posCustomers,
    roles: [
      "admin",
      "finance",
      "finance-manager",
      "inventory",
      "sales-manager",
      "sales-agent",
      "sales-supervisor",
      "hr",
      "dev",
      "mtn-sales-agent",
    ],
  },
  {
    id: 6,
    name: "Repair",
    icon: RepairIcons,
    path: "/repair",
    menus: repairRoutes,
    roles: [
      "sales-agent",
      "sales-manager",
      "sales-supervisor",
      "admin",
      "dev",
      "mtn-sales-agent",
    ],
  },
  {
    id: 7,
    name: "Reports",
    icon: ReportsIcons,
    path: "/transaction",
    menus: reportRoutes,
    roles: [
      "admin",
      "finance",
      "finance-manager",
      "sales-manager",
      "sales-agent",
      "sales-supervisor",
      "dev",
      "mtn-sales-agent",
    ],
  },
];

export const listManages = [
  {
    title: "Sales",
    path: "/home",
    icon: <RiDashboard3Line size={20} />,
    dropDown: ["Overview", "Current Sales", "Sales Report"],
    roles: [
      "sales-agent",
      "sales-manager",
      "sales-supervisor",
      "admin",
      "dev",
      "mtn-sales-agent",
    ],
  },
  {
    title: "Customers",
    path: "/overview/customers",
    icon: <RiDashboard3Line size={20} />,
    roles: [
      "admin",
      "finance",
      "finance-manager",
      "inventory",
      "sales-manager",
      "sales-agent",
      "sales-supervisor",
      "hr",
      "dev",
      "mtn-sales-agent",
    ],
  },
  {
    title: "Repair",
    path: "/repair/overview",
    icon: <RiDashboard3Line size={20} />,
    roles: [
      "sales-agent",
      "sales-manager",
      "sales-supervisor",
      "admin",
      "dev",
      "mtn-sales-agent",
    ],
  },
];

export const SystemRoutes = [
  {
    title: "Switch Users",
    path: "/login",
    icon: <PersonIcon className="SamIcon" />,
    roles: [
      "sales-agent",
      "sales-manager",
      "sales-supervisor",
      "admin",
      "dev",
      "mtn-sales-agent",
    ],
  },
  {
    title: "Logout",
    path: "/login",
    icon: <LogoutIcon className="SamIcon" />,
    roles: [
      "admin",
      "finance",
      "finance-manager",
      "inventory",
      "sales-manager",
      "sales-agent",
      "sales-supervisor",
      "hr",
      "dev",
      "mtn-sales-agent",
    ],
  },
];

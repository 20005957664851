import { Avatar, Divider, Form, Input, Modal } from "antd";
import { CiLogout } from "react-icons/ci";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { authActions } from "../../store/auth";
import { myShopActions } from "../../store/shop";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getAgentsAssignedToShop,
  loginAction,
} from "../../store/auth/authActions";
import profilePic from "../../assets/images/profile.jpeg";

import { getActiveShop, handleClearAllState } from "../../utils/converter";
import { mySupplierActions } from "../../store/supplier";
import { myWareHouseOutActions } from "../../store/wareHouseOut";
import { myWareHouseActions } from "../../store/wareHouse";
import { myTransferActions } from "../../store/transfer";
import { myTermsAndConditionsActions } from "../../store/TermsAndCondirons";
import { myEmployeeActions } from "../../store/setting";
import { myRepairActions } from "../../store/repair";
import { myProductActions } from "../../store/product";
import { myPosOverViewActions } from "../../store/posOverView";
import { myPosActions } from "../../store/pos";
import { myKPIActions } from "../../store/kpi";
import { myChannelActions } from "../../store/channel";
import { myCustomerActions } from "../../store/customer";
import { useNavigate } from "react-router-dom";
const UserProfile = (props: any) => {
  const { auth, appSettings } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showUsers, setShowUsers] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [selectedAgent, setSelectedAgent] = React.useState<any>(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    (auth?.token && getActiveShop(auth?.user?.shop?.assigned)[
      appSettings?.selectedShopNumber
    ]?.shop?._id) &&
      getAgentsAssignedToShop(
        auth?.token,
        getActiveShop(auth?.user?.shop?.assigned)[
          appSettings?.selectedShopNumber
        ]?.shop?._id
      )(dispatch);
  }, [
    appSettings?.selectedShopNumber,
    auth?.token,
    auth?.user?.shop,
    dispatch,
  ]);
  const [form] = Form.useForm();
  const onFinish = async () => {
    auth?.token && (await loginAction({ email, password })(dispatch));
    setShowPasswordModal(false);
    setShow(false);
    form.resetFields();
  };

  const clearActions = [
    mySupplierActions.setAll,
    mySupplierActions.setAttributes,
    mySupplierActions.setQuery,
    mySupplierActions.setNew,
    mySupplierActions.setUpdated,
    mySupplierActions.setSelected,
    myWareHouseOutActions.setAll,
    myWareHouseOutActions.setAttributes,
    myWareHouseOutActions.setQuery,
    myWareHouseOutActions.setNew,
    myWareHouseOutActions.setSelected,
    myWareHouseOutActions.setSelectedDeliveryNote,
    myWareHouseOutActions.setFilters,
    myWareHouseOutActions.setAllStockOut,
    myWareHouseOutActions.setSelectedRequest,
    myWareHouseOutActions.setOne,
    myWareHouseOutActions.setWarehouseItem,
    myWareHouseOutActions.setWarehouseOut,
    myWareHouseOutActions.setWarehouseOutItem,
    myWareHouseOutActions.setWarehouseOutSelected,
    myWareHouseOutActions.setWarehouseSelected,
    myWareHouseActions.setAll,
    myWareHouseActions.setAttributes,
    myWareHouseActions.setQuery,
    myWareHouseActions.setNew,
    myWareHouseActions.setSelected,
    myWareHouseActions.setSelectedDeliveryNote,
    myWareHouseActions.setFilters,
    myWareHouseActions.setAllStockStats,
    myWareHouseActions.setAllStockProductStats,
    myWareHouseActions.setSearchResult,
    myWareHouseActions.setSearchError,
    myWareHouseActions.setCreatedCart,
    myTransferActions.setAll,
    myTransferActions.setAttributes,
    myTransferActions.setQuery,
    myTransferActions.setNew,
    myTransferActions.setUpdated,
    myTransferActions.setSelected,
    myTransferActions.setShopTo,
    myTransferActions.setShopProducts,
    myTransferActions.setShopStock,
    myTermsAndConditionsActions.setAll,
    myShopActions.setAll,
    myShopActions.setSelected,
    myShopActions.setAgents,
    myShopActions.setAllSales,
    myShopActions.setSelectedRequestedStock,
    myShopActions.setMyShop,
    myEmployeeActions.setAll,
    myEmployeeActions.setAttributes,
    myEmployeeActions.setQuery,
    myEmployeeActions.setNew,
    myEmployeeActions.setUpdated,
    myEmployeeActions.setSelected,
    myEmployeeActions.setAgentTransaction,
    myEmployeeActions.setAgent,
    myRepairActions.setRepairSearchResult,
    myRepairActions.setRepairSearchError,
    myRepairActions.setRepairRequest,
    myRepairActions.setAll,
    myRepairActions.setSelected,
    myRepairActions.setSelectedRepair,
    myProductActions.setAll,
    myProductActions.setAttributes,
    myProductActions.setQuery,
    myProductActions.setNew,
    myProductActions.setSelected,
    myProductActions.setSelectedProduct,
    myProductActions.setProductStore,
    myProductActions.setStoredProduct,
    myPosOverViewActions.setAll,
    myPosOverViewActions.setAttributes,
    myPosOverViewActions.setQuery,
    myPosOverViewActions.setNew,
    myPosOverViewActions.setUpdated,
    myPosOverViewActions.setSelected,
    myPosOverViewActions.setSamphonePaymentStats,
    myPosOverViewActions.setMTNPaymentStats,
    myPosOverViewActions.setOtherPaymentStats,
    myPosOverViewActions.setTodayRevenueStats,
    myPosOverViewActions.setOverviewStats,
    myPosActions.setAll,
    myPosActions.setAttributes,
    myPosActions.setQuery,
    myPosActions.setNew,
    myPosActions.setUpdated,
    myPosActions.setSelected,
    myPosActions.setStock,
    myPosActions.setStockByStatus,
    myPosActions.setTransation,
    myPosActions.setSelectedTransation,
    myPosActions.setCarts,
    myPosActions.setPriceList,
    myPosActions.setRecent,
    myPosActions.setNosale,
    myPosActions.setOnePriceListItem,
    myPosActions.setCheckoutResponseStatus,
    myPosActions.setCustomerSignature,
    myPosActions.setCustomerProfile,
    myPosActions.setDataActivated,
    myPosActions.setPaymentInitiationStatus,
    myPosActions.setPaymentDoneStatus,
    myPosActions.setCheckPaymentAgainHelper,
    myPosActions.setStatusResult,
    myPosActions.setCommisssionBalance,
    myPosActions.setShopCommission,
    myKPIActions.setAll,
    myKPIActions.setAttributes,
    myKPIActions.setQuery,
    myKPIActions.setNew,
    myKPIActions.setUpdated,
    myKPIActions.setSelected,
    myChannelActions.setAll,
    myChannelActions.setAttributes,
    myChannelActions.setQuery,
    myChannelActions.setNew,
    myChannelActions.setUpdated,
    myChannelActions.setSelected,
    myChannelActions.setAgents,
    myChannelActions.setShops,
    myChannelActions.setPriceList,
    myChannelActions.setPriceListItems,
    myChannelActions.setRequestedStock,
    myChannelActions.setRfsSelected,
    myChannelActions.setProducts,
    myChannelActions.setPriceListItemToUpdate,
    myChannelActions.setPriceListSearch,
    myChannelActions.setRfsTransfer,
    myChannelActions.setSelectedRequestedStock,
    myChannelActions.setShopStockOut,
    myChannelActions.setWarehouseOut,
    myChannelActions.setWarehouseOutSelected,
    myChannelActions.setWarehouseOutItem,
    myChannelActions.setPendingShopOut,
    myCustomerActions.setAll,
    myCustomerActions.setAttributes,
    myCustomerActions.setQuery,
    myCustomerActions.setNew,
    myCustomerActions.setUpdated,
    myCustomerActions.setSelected,
    myCustomerActions.setSearchResult,
    myCustomerActions.setPurchased,
    myCustomerActions.setCustomerRepair,
  ];

  return (
    <div className="relative">
      <div className=" md:flex gap-3 text-[#030229]">
        <div
          className="h-[20px] md:h-[45px] w-[20px] md:w-[45px] rounded-full cursor-pointer"
          onClick={() => (setShow(!show), setShowUsers(false))}
        >
          <div className="relative w-full h-full">
            <img
              src={auth?.user?.picture || profilePic}
              alt="profile"
              className="rounded-full w-fit"
            />
            <div className="absolute h-2 md:h-3 w-2 md:w-3 bg-[#0FA958] rounded-full top-3 md:top-7 left-3 md:left-7 z-2s00 "></div>
          </div>
        </div>
        <>
          <h1>
            {auth?.user?.profile === "freelancer" ? (
              <p className={`${props?.openSide ? "text-[13px]" : "text-base"}`}>
                <span className="md:hidden"> Welcome,</span> {auth?.user?.names?.split(" ")?.at(0)}
              </p>
            ) : (
              <p className={`capitalize ${props?.openSide ? "text-[10px] md:text-[13px]" : "text-[10px] md:text-base"}`}>
                {auth?.user?.names?.split(" ")?.at(0)}
              </p>
            )}
            <p
              className={`text-[#605BFF] capitalize ${props?.openSide ? "text-[10px] md:text-[13px]" : "text-[10px] md:text-sm"
                }`}
            >
              {auth?.user?.profile
                ? auth?.user?.profile?.split("-")?.join(" ")
                : auth?.user?.role?.split("-")?.join(" ")}
            </p>
          </h1>
        </>
      </div>
      {show && auth?.user?.profile !== "freelancer" && (
        <div className="w-[14rem] h-fit bg-white py-3 mt-4 rounded-md shadow-md absolute right-0 text-black">
          <div>
            {!showUsers ? (
              auth?.agents?.length > 1 && (
                <div
                  className="px-3 flex gap-2  cursor-pointer"
                  onClick={() => {
                    setShowUsers(true);
                  }}
                >
                  <HiOutlineSwitchHorizontal className="mt-1" />{" "}
                  <p>Switch User</p>{" "}
                </div>
              )
            ) : (
              <>
                {auth?.agents
                  ?.filter((agent: any) => agent?.user?._id !== auth?.user?._id)
                  .map((agent: any) => {
                    return (
                      <div
                        className=" hover:bg-[#605BFF]  text-[#030229] text-base hover:text-white cursor-pointer"
                        onClick={(e: any) => {
                          setEmail(agent?.user?.email);
                          setShowPasswordModal(true);
                          setSelectedAgent(agent);
                        }}
                      >
                        <div className="flex gap-2 px-4">
                          <Avatar
                            icon={
                              <img
                                src={agent?.user?.picture}
                                alt=""
                                className="rounded-full w-full"
                              />
                            }
                            size={26}
                            className="mt-3"
                          />
                          <p className=" pt-3 text-[10px] md:text-base">
                            {agent?.user?.names}
                          </p>
                        </div>
                        <Divider
                          type="horizontal"
                          style={{ margin: "10px 0" }}
                        />
                        {/* <Divider/> */}
                      </div>
                    );
                  })}
              </>
            )}

            <div
              className="px-3 flex gap-2 my-2 cursor-pointer "
              onClick={() => {
                dispatch(authActions.logout());
                dispatch(myShopActions.setMyShop(null));
                // dispatch(myAppSettings.setSelectedShopNumber(0));
                localStorage.removeItem("selectedShopIndex");
                handleClearAllState(dispatch, clearActions);
                // navigate("/login/scan");
              }}
            >
              <CiLogout className="mt-1" /> <p>Logout</p>{" "}
            </div>
          </div>
        </div>
      )}
      {show && auth?.user?.profile === "freelancer" && (
        <div className="w-[14rem] z-10 h-fit bg-white py-3 mt-4 rounded-md shadow-md absolute left-0 text-black">
          <div
            className="px-3 flex gap-2 my-2 cursor-pointer "
            onClick={() => {
              dispatch(authActions.logout());
              dispatch(myShopActions.setMyShop(null));
              // dispatch(myAppSettings.setSelectedShopNumber(0));
              localStorage.removeItem("selectedShopIndex");
              handleClearAllState(dispatch, clearActions);
              navigate("/login/scan");
            }}
          >
            <CiLogout className="mt-1" /> <p>Logout</p>{" "}
          </div>
        </div>
      )}
      <Modal
        title="Enter Password "
        open={showPasswordModal}
        onCancel={() => {
          setShowPasswordModal(false);
        }}
        onOk={onFinish}
        okButtonProps={{ loading: auth?.isFetching, className: "bg-blue-500" }}
        okText="Switch"
        cancelButtonProps={{ loading: auth?.isFetching }}
      >
        <div className="flex flex-col items-center w-full mb-5">
          <img
            src={selectedAgent?.user?.picture}
            className="w-36 rounded-full"
            alt=""
          />
          <p className="text-xl font-semibold">{selectedAgent?.user?.names}</p>
        </div>
        <Form
          form={form}
          name="register"
          initialValues={auth.user || {}}
          scrollToFirstError
          layout="vertical"
          className="text-center "
        >
          <Form.Item
            name="password"
            tooltip="Please enter  correct password"
            rules={[
              {
                required: true,
                message: "Channel is required!",
                whitespace: true,
              },
            ]}
          >
            <Input
              onChange={(e: any) => setPassword(e?.target?.value)}
              placeholder="Input password here"
              className="max-w-[300px]"
              type="password"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default UserProfile;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleMissingRRAcodeAction } from "../../store/layout/actions";

const MissingRRACodeNotification = () => {
  const navigate = useNavigate();
  const { notifications } = useSelector((state: any) => state);
  const dispatch = useDispatch()

  const handleNavigateToTransaction = async (cartdId: any)=>{
   await handleMissingRRAcodeAction(true)(dispatch)
   navigate(`/checkout/${cartdId}`)
  }

  return (
    <div
      className={` w-full divide-y-2 xp-5 border-border-dark dark:border-white border-b-[0.5px] min-w-[392px]`}
    >
      {notifications?.missingRRACode?.data?.slice(0, 3)?.map((item: any) => (
        <div
          className="flex flex-row justify-between align-center gap-x-[20px] py-2"
          onClick={()=>handleNavigateToTransaction(item.cart?._id)}
        >
          <img
            src={
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIBYlfEVJIGJd6CnA6CbE6MTg02pEPkv55pJYpTdthKQ&s"
            }
            alt="oldMan"
            className="rounded-[1000px] w-[40px] h-[40px] object-cover cursor-pointer"
          />

          <div className="flex flex-col w-full xgap-[5px] cursor-pointer">
            <p className="font-bold">
              Missing RRA Code For this Transaction{" "}
              <span>{item.cart?.cartId}</span>
            </p>
            <p className="text-[#111827] text-[14px]">
              Customer: <span>{item.cart?.customer?.name}</span>
            </p>
            <p className="text-[14px]">
              Date: <span>{item.createdAt?.slice(0, 10)}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MissingRRACodeNotification;

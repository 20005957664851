import { LoadingButton } from "@mui/lab";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updatePriceListItemAction } from "../../store/channel/actions";

const UpdatePriceForm: React.FC<{
  priceListItemId: string;
  dataToUpdate?: any;
  handleOpenUpdatePopover?: any;
}> = ({ priceListItemId, dataToUpdate, handleOpenUpdatePopover }) => {
  const { auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const onFinish = async (values: any) => {
    auth?.token &&
      (await updatePriceListItemAction(
        auth?.token,
        priceListItemId,
        values,
        `?channel=${channel?.selected?._id}`
      )(dispatch));
    handleOpenUpdatePopover(false);
    console.log(values);
  };
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={dataToUpdate}
      style={{ maxWidth: "100%" }}
      scrollToFirstError
    >
      <Form.Item
        name="value"
        label="Product Price"
        // initialValue={dataToUpdate?.model}
        tooltip="Please enter Product price?"
        rules={[
          {
            required: true,
            message: "Price is required!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="extendedWarranty"
        label="Extended Warranty"
        // initialValue={dataToUpdate?.model}
        tooltip="Please enter Extended Warranty?"
        // rules={[
        //   {
        //     required: true,
        //     message: "Price is required!",
        //     whitespace: true,
        //   },
        // ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ minWidth: "100%" }}
          loading={channel?.isPriceListUpdating}
        >
          {"update"}
        </LoadingButton>
      </Form.Item>
    </Form>
  );
};

export default UpdatePriceForm;

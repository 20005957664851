import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/product`, data, token);
};
export const getAllService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/product${query}`, token);
};

export const getAllAttributesService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/product/brands${query}`, token);
};

export const getOneService = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/product/one/${id}`, token);
};

export const updateService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/product/one/${itemId}`,
    data,
    token
  );
};

export const getOneProductOrder = async (itemId: string) => {
  return HttpRequest.get(`${SERVER_URL}/purchase/sent/${itemId}`);
};

export const createPoDeliveryNote = async (data: any, token: string) => {
  console.log(">>>>>>", data);
  return await HttpRequest.post(`${SERVER_URL}/delivNote`, data, token);
};

export const updatePurchaseOrderStatus = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/purchase/sent/${itemId}`,
    data,
    ""
  );
};

export const deleteService = async (itemId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/product/one/${itemId}`, token);
};

// Delete selected products using its ids
export const updateIsDeleteService = async (data: any, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/product`, data, token);
};
export const getPriceListItemByProduct = async (token: string, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/pricelistitem${query}`, token);
};

export const getOneStockProdService = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/product/wr/${id}`, token);
};

export const stopStockInService = async (
  id: string,
  token: string,
  data: any
) => {
  return HttpRequest.post(
    `${SERVER_URL}/stockreqitem/one/verify/${id}`,
    token,
    data
  );
};

import { SmileOutlined } from "@ant-design/icons";
import React from "react";
import { FiEdit3 } from "react-icons/fi";

const ContactInfoCard = (props: any) => {
  return (
    <div className="border border-[#03022926] bg-white p-4 rounded-md">
      {props?.data?.subscriptionInfo?.witnessInfo?.length > 0 ? (
        <>
          {props?.data?.subscriptionInfo?.witnessInfo
            ?.slice(0, 2)
            ?.map((el: any) => {
              return (
                <div>
                  <div className="md:flex justify-between">
                    <h1 className="text-[#030229] text-base font-medium">
                      {el?.name}
                    </h1>
                    <button className="border border-[#D9D9D9] rounded-md px-2 py-1">
                      <FiEdit3 color="#D9D9D9" />
                    </button>
                  </div>

                  <div className="flex w-full gap-10 mt-3">
                    <div>
                      <p className="text-[#03022980]  text-sm pt-2">
                        Phone Number
                      </p>
                      <p className="text-[#03022980]  text-sm pt-2">
                        Relationship
                      </p>
                      <p className="text-[#03022980]  text-sm pt-2">Email</p>
                    </div>
                    <div>
                      <p className="text-[#030229] text-sm pt-2">{el?.phone}</p>
                      <p className="text-[#030229] text-sm pt-2">
                        {el?.relationship}
                      </p>
                      <p className="text-[#030229] text-sm pt-2">{el?.email}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <SmileOutlined style={{ fontSize: 20 }} />
          <p className="text-[#03022980]  text-sm pt-2">Contact Not Found</p>
        </div>
      )}
    </div>
  );
};

export default ContactInfoCard;

import React, { useEffect, useState } from "react";
import RegisterCustomer from "./RegisterCustomer";
import SearchBarV2 from "../../../components/elements/SearchBarV2";
import { myLayoutActions } from "../../../store/layout";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { ReactComponent as AddCustomerPlaceholderImage } from "../../../assets/images/EnterCustomerInfoOption.svg";
import { getOneCustomerByQueryAction } from "../../../store/customer/actions";
import {
  getShopStockCartAction,
  updateShopStockCartDetailsAction,
} from "../../../store/wareHouse/actions";
import { useNavigate, useParams } from "react-router-dom";
import { subLayoutActions } from "../../device_financing/Subscription/redux/layout";
import { Modal, Radio, RadioChangeEvent, Result, Spin } from "antd";
import { handleNextStepAction } from "../../device_financing/Subscription/redux/layout/actions";
import CustomModal from "../../../components/Modals/CustomModal";
import CustomButton from "../../../components/buttons/CustomButton";
import { LoadingButton } from "@mui/lab";

const ApplyDeviceFinancing = () => {
  const { auth, customer, wareHouse, subLayout, layout } = useSelector(
    (state: any) => state
  );
  const [newCustomer, setNewCustomer] = useState(false);
  const [isActiveProduct, setIsActiveProduct] = useState<any>(null);
  const dispatch = useDispatch();
  const [customerQuery, setCustomerQuery] = useState("");
  const { cartId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    dispatch(myLayoutActions.setIsCustomerHasSubscription(false)); 
    setOpenModal(false);
    auth?.user?.profile==='freelancer'? navigate("/cart"): navigate("/sale");
  };
  const onSearch = async (e: any) => {
    e.preventDefault();
    auth?.token &&
      (await getOneCustomerByQueryAction(
        auth?.token,
        `?phone=${customerQuery}`
      )(dispatch));
  };
  useEffect(() => {
    if (auth?.token && cartId) {
      getShopStockCartAction(auth?.token, cartId)(dispatch);
    }
  }, [auth?.token, cartId, dispatch]);
  const handleOnSearchChange = (e: any) => {
    setCustomerQuery(e.target.value?.replace("+", "")?.trim());
  };
  const businessProducts =
    wareHouse?.createdCart?.data?.shop?.channel?.services;

  const handleOpenAddCustomer = () => {
    dispatch(subLayoutActions.setNewCustomer(true));
  };

  useEffect(() => {
    if (customer?.searchResult?.length > 0 && auth?.token) {
      updateShopStockCartDetailsAction(auth?.token, cartId, {
        customer: customer?.searchResult[0]?._id,
      })(dispatch);
    }
  }, [customer?.searchResult, cartId, dispatch, auth?.token]);

  const hasCustomer = wareHouse?.createdCart?.data?.customer;

  useEffect(() => {
    if (hasCustomer) {
      dispatch(subLayoutActions.setNewCustomer(true));
      setNewCustomer(true);
    } else {
      dispatch(subLayoutActions.setNewCustomer(false));
      setNewCustomer(false);
    }
  }, [hasCustomer, dispatch]);

  const handleSelecteBusinessProduct = async (e: RadioChangeEvent) => {
    setIsActiveProduct(e.target.value);
    const res = await updateShopStockCartDetailsAction(auth?.token, cartId, {
      isSubscription: "subscription",
      businessProduct: e.target.value,
    })(dispatch);
    if (res) {
      setIsActiveProduct(null);
      dispatch(subLayoutActions.setOpenNewCart(false));
    }
  };
  return (
    <div className="text-black">
      <Modal
        open={
          !wareHouse?.createdCart?.data?.businessProduct || subLayout?.isNewCart
        }
        closable={false}
        footer={null}
      >
        <div className="mt-4 w-full">
          <Radio.Group
            onChange={handleSelecteBusinessProduct}
            value={isActiveProduct}
            className="md:flex flex-wrap pb-2"
            disabled={
              wareHouse?.isUpdateFetching || wareHouse?.createCartIsFetching
            }
          >
            {businessProducts
              ?.filter((el: any) => el?.businessProduct?.isPosEnabled)
              ?.map((d: any) => (
                <Radio
                  key={d?.businessProduct?._id}
                  value={d?.businessProduct?._id}
                  className={`w-full h-14 border pl-4 mb-2 rounded-md border-gray-400 flex items-center xjustify-center`}
                >
                  <span className="text-sm font-semibold">
                    {d?.businessProduct?.name}
                  </span>
                </Radio>
              ))}
          </Radio.Group>
        </div>
        {(wareHouse?.isFetching ||
          wareHouse?.isUpdateFetching ||
          wareHouse?.createCartIsFetching) && (
          <div>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
            />
          </div>
        )}
      </Modal>
      {!subLayout?.newCustomer ? (
        <div>
          <div className="flex items-center justify-center py-4 space-x-2 pt-10">
            <div className="rounded-lg">
              <form method="get" onSubmit={onSearch}>
                <SearchBarV2
                  placeholder="Input customer number"
                  onChange={handleOnSearchChange}
                  name="customer"
                  isLoading={customer.isFetching || wareHouse?.isUpdateFetching}
                />
              </form>
            </div>
            <button
              onClick={() => handleOpenAddCustomer()}
              className="shadow py-2 px-4 border bg-slate-300 rounded-md"
            >
              <PlusOutlined className="m-0 p-0" />
            </button>
          </div>
          <div className="flex justify-center">
            <AddCustomerPlaceholderImage className="h-44" />
          </div>
        </div>
      ) : (
        <RegisterCustomer setNewCustomer={setNewCustomer} />
      )}
      <CustomModal
        openModal={layout?.isCustomerHasSubscription}
        closeModal={handleCloseModal}
        closable={false}
        component={
          <div>
            <Result
              status="warning"
              title={
                <div>
                  {" "}
                  <p className="text-center text-[#030229B2] text-base">
                   This Customer has Active Subscription you can't procceed{" "}
                  </p>
                  <h1 className="text-center text-[#030229] text-base py-2">
                    Thank you{" "}
                  </h1>
                </div>
              }
              extra={
                <LoadingButton
                  variant="contained"
                  style={{ width: 100, height: 30, fontSize: "12px" }}
                  onClick={handleCloseModal}
                >
                  Close
                </LoadingButton>
              }
            />
            <div className="w-full flex justify-center mt-5"></div>
          </div>
        }
      />
    </div>
  );
};

export default ApplyDeviceFinancing;

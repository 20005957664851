import React, { useEffect } from "react";
import { Table, Tag } from "antd";
import { getAllStockActionByStatus } from "../../store/pos/actions";
import { getActiveShop } from "../../utils/converter";
import { useDispatch, useSelector } from "react-redux";
import { searchTextValue, searchValue } from "../../utils/setColor";

import ScrollableFrame from "../layout/ScrollableFrame";
import { getDefaultCurrencyCode } from "../../utils/CurrencyHelper";

const CurrentStockDetailsTable = (props: any) => {
  const { pos, auth, appSettings,company } = useSelector((state: any) => state);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const dispatch = useDispatch();
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;
  useEffect(() => {
    auth?.token &&
      props?.data?.data?._id &&
      props?.value &&
      getAllStockActionByStatus(
        auth?.token,
        `?shop=${shopId}&productPriceList=${props?.data?.data?._id}&status=${props?.value}`
      )(dispatch);
  }, [
    appSettings?.selectedShopNumber,
    auth,
    props?.value,
    dispatch,
    props?.data?.data?._id,
    shopId,
  ]);

  const columns = [
    {
      title: "Model",
      dataIndex: "model",
    },
    {
      title: "Descriptions",
      dataIndex: "data",
      render: (text: any, record: any) => {
        return (
          <>
            {record?.data?.requestedItem?.product?.specification?.map(
              (d: any, index: number) => (
                <span key={index} className="text-xs">
                  {d[0]}: {d[1]}
                </span>
              )
            )}
          </>
        );
      },
    },
    {
      title: "Shop",
      dataIndex: "shop",
    },
    {
      title: "Serial Number",
      dataIndex: "serialNumber",
    },
    {
      title: `Price(${getDefaultCurrencyCode(company)})`,
      dataIndex: "price",
    },
    {
      title: "Extended Warranty",
      dataIndex: "data",
      render: (text: any, record: any) => (
        <>
          <p>{record?.data?.requestedItem.product?.extendedWarranty || "0"}%</p>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any) => (
        <>
          <Tag color={searchValue(text)}>
            <p
              className={`text-blacks text-[${searchTextValue(
                text
              )}] capitalize px-2`}
            >
              {text}
            </p>
          </Tag>
        </>
      ),
    },
  ];
  const stockData = pos.stockByStatus?.data?.map((el: any) => {
    return {
      key: el?._id,
      model: el?.requestedItem?.product?.product?.model,
      shop: el?.shop?.name,
      serialNumber: el?.wareHouseItem?.serialNumber,
      price: el?.requestedItem?.product?.prices
        ?.filter((d: any) => d.isActive === true)
        ?.map((d: any) => d.value)
        ?.toLocaleString(),
      status: el?.status,
      data: el,
    };
  });
  return (
    <>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={pos.stockByStatus?.total}
        count={Math.ceil(pos.stockByStatus?.total / limit)}
        // isTable
      >
        <Table
          columns={columns}
          dataSource={stockData}
          size="middle"
          loading={pos?.isFetching}
          pagination={false}
        />
      </ScrollableFrame>
    </>
  );
};

export default CurrentStockDetailsTable;

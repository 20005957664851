import React, { useEffect, useState } from "react";
import { Form, Divider, Button, Input, Select } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import AddressDetailsForm from "./AddressDetailsForm";
import WitnessForm from "./WitnessForm";
import PersonalDataForm from "./PersonalDataForm";
import { handleNextStepAction } from "../redux/layout/actions";
import {
  createCustomerAction,
  getCustomFormAction,
  updateCustomerAction,
} from "../redux/customer/actions";
import { Progressbar } from "../components/Progressbar";
import OtherPhoneNumberForm from "./OtherPhoneNumberForm";
import { useParams } from "react-router-dom";
import { updateShopStockCartDetailsAction } from "../../../../store/wareHouse/actions";
import FinancialDetails from "./FinancialDetails";
import { subCustomerActions } from "../redux/customer";

const PersonnelDetailsForm = (props: any) => {
  const { wareHouse, auth, subCustomer } = useSelector((state: any) => state);

  const [selectedDate, setSelectedDate] = useState<any>();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { cartId } = useParams();
  const businessProductId = wareHouse?.createdCart?.data?.businessProduct?._id;
  const customForm =
    subCustomer?.customForm?.length > 0
      ? subCustomer?.customForm?.at(0)?.fields
      : [{}];

  const userImage =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
    const dateofbirth=wareHouse?.createdCart?.data?.customer?.dateOfBirth;
  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer) {
      form.setFieldsValue(wareHouse?.createdCart?.data?.customer);
    } else {
      form.resetFields();
    }
  }, [form, wareHouse?.createdCart?.data?.customer]);

  const onFinish = async (values: any) => {
    const name = `${values?.firstName}  ${values?.lastName}`;
    const payload = {
      ...values,
      name,
      dateOfBirth:selectedDate ?? dateofbirth,
      subscriptionInfo: {
        ...values,
        address: {
          country: values?.country,
          province: values?.province,
          district: values?.district,
          sector: values?.sector,
          cell: values?.cell,
          village: values?.village,
        },
        mainIncomeSource: values?.mainIncomeSource,
        occupationOrBusinessType: values?.occupationOrBusinessType,
        monthlyIncome: values?.monthlyIncome,
        incomeFrequency: values?.incomeFrequency,
        ownsSmartphone: values?.ownsSmartphone,
        nationalIDDoc: nationalId,
        photoCustomerHoldingID: userImage,
      },
    };
    if (wareHouse?.createdCart?.data?.customer?._id && auth?.token) {
      const res = await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        payload
      )(dispatch);
      if (res) {
        await handleNextStepAction(1)(dispatch);
      }
    } else {
      const response = await createCustomerAction(
        auth?.token,
        payload
      )(dispatch);
      if (response?.type) {
        const res = await updateShopStockCartDetailsAction(
          auth?.token,
          cartId,
          {
            customer: response?.data._id,
          }
        )(dispatch);
        if (res) {
          await handleNextStepAction(1)(dispatch);
          form.resetFields();
        }
      }
    }
  };

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer) {
      form.setFieldsValue(wareHouse?.createdCart?.data?.customer);
    } else if (wareHouse?.createdCart?.data?.customer?.subscriptionInfo) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      );
    } else if (
      wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
    ) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
      );
    } else {
      form.resetFields();
    }
  }, [
    form,
    wareHouse?.createdCart?.data?.customer,
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo,
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address,
  ]);

  const initialData = {
    ...wareHouse?.createdCart?.data?.customer,
    ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address,
    ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo,
    secondaryPhone:
      wareHouse?.createdCart?.data?.customer?.otherPhone?.at(0)?.tel,
    firstName: wareHouse?.createdCart?.data?.customer?.name?.split(" ")[0],
    lastName: wareHouse?.createdCart?.data?.customer?.name?.slice(
      wareHouse?.createdCart?.data?.customer?.name?.split(" ")[0]?.length
    ),
  };

  useEffect(() => {
    if (auth?.token && businessProductId)
      getCustomFormAction(
        `?businessProduct=${businessProductId}`,
        auth?.token
      )(dispatch);
  }, [auth?.token, dispatch, businessProductId]);

  useEffect(() => {
    dispatch(subCustomerActions.setIsFetching(false));
  }, [dispatch]);

  return (
    <>
      <div className="h-[82vh] bg-white p-2 rounded-lg overflow-y-auto">
        <Progressbar name={"Customer Details"} percentage={10} />
        <Form
          form={form}
          name="register"
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialData || {}}
          scrollToFirstError
          className="xantd-custom-style space-y-4 capitalize xbg-green-200"
        >
          <PersonalDataForm dataToUpdate={props?.dataToUpdate?.phone} setSelectedDate={setSelectedDate}/>
          <OtherPhoneNumberForm />
          <Divider />
          <FinancialDetails />
          <Divider />
          <AddressDetailsForm />
          <Divider />
          <WitnessForm />

          <div className="pb-4 flex justify-end space-x-4">
            <Button
              danger
              onClick={() => props?.setIsModalOpen(false)}
              className="pb-8"
            >
              Discard
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              style={{
                padding: "10px",
              }}
              loading={subCustomer?.isFetching || wareHouse?.isUpdateFetching}
              disabled={subCustomer?.isFetching || wareHouse?.isUpdateFetching}
            >
              {"Save and Continue"}
            </LoadingButton>
          </div>
        </Form>

        {/* <Form
          form={form}
          name="register"
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialData || {}}
          scrollToFirstError
          className="xantd-custom-style space-y-4 capitalize h-[68vh]"
        >
          <div className="flex-1 flex flex-col h-full overflow-y-auto">
            {customForm?.map((field: any, index: number) => (
              <React.Fragment key={field?._id}>
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold">{field?.category}</h3>
                  <div className="grid grid-cols-3 gap-4">
                    {field?.subFields?.map((subField: any) => (
                      <Form.Item
                        key={subField.fieldName}
                        label={
                          subField.fieldName.charAt(0).toUpperCase() +
                          subField.fieldName.slice(1)
                        }
                        name={subField.fieldName}
                        rules={[
                          {
                            required: subField.required,
                            message: `Please input your ${subField.fieldName}!`,
                          },
                        ]}
                      >
                        {subField.fieldType === "text" && (
                          <Input
                            placeholder={`Enter your ${subField.fieldName}`}
                            className="h-10"
                          />
                        )}
                        {subField.fieldType === "number" && (
                          <Input
                            placeholder={`Enter your ${subField.fieldName}`}
                            type="number"
                            className="h-10"
                          />
                        )}
                        {subField.fieldType === "email" && (
                          <Input
                            type="email"
                            placeholder={`Enter your ${subField.fieldName}`}
                            className="h-10"
                          />
                        )}
                        {subField.fieldType === "select" && (
                          <Select
                            className="h-10"
                            options={subField?.options?.map((el: any) => ({
                              value: el,
                              label: el,
                            }))}
                          />
                        )}
                      </Form.Item>
                    ))}
                  </div>
                </div>
                {index < customForm.length - 1 && <Divider />}{" "}
              </React.Fragment>
            ))}
          </div>

          <div className="pb-4 flex justify-end space-x-4">
            <Button
              danger
              onClick={() => props?.setIsModalOpen(false)}
              className="pb-8"
            >
              Discard
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              style={{
                padding: "10px",
              }}
              loading={subCustomer?.isFetching || wareHouse?.isUpdateFetching}
              disabled={subCustomer?.isFetching || wareHouse?.isUpdateFetching}
            >
              {"Save and Continue"}
            </LoadingButton>
          </div>
        </Form> */}
      </div>
    </>
  );
};

export default PersonnelDetailsForm;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DatePicker,
  Divider,
  Form,
  Modal,
  Select,
  notification,
  Spin,
  Drawer,
} from "antd";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "../../../styles/model.css";
import {
  getActivePlan,
  getActiveShop,
  getTotalPrice,
} from "../../../utils/converter";
import {
  getAllPaymentMethodeAction,
  getShopStockCartAction,
  updateShopStockCartDetailsAction,
  updateShopStockCartItemDetailsAction,
} from "../../../store/wareHouse/actions";
import { uploadContactction } from "../../../store/pos/actions";
import UploadAny from "../../../components/forms/UploadAny";
import DataPlan from "../POS/steps/dataPlan";
import Switches from "../../../components/buttons/Switches";
import TermsAndConditionForm from "../../../components/forms/TermsAndConditionForm";
import Checkout from "./components/Checkout";
import CustomModal from "../../../components/Modals/CustomModal";
import Subscription from "./components/Subscription";
import PaymentSection from "./components/PaymentSection";
import FinalAmountSection from "./components/FinalAmountSection";
import SubscriptionInfo from "./components/SubscriptionInfo";
import { hasMacyemacyeOption } from "../../../assets/data/comment";
import CofirmPayment from "../../../apps/device_financing/Subscription/components/models/CofirmPayment";
import LoadingPaymentProcess from "../../../apps/device_financing/Subscription/components/models/LoadingPaymentProcess";
import {
  handlConfirmPaymentModelAction,
  handlecheckStateInfoAction,
  handleConfirmFinalStageAction,
  handleOpenPaymentModelAction,
  handleOpenTourAction,
  handlePaymentStatusAction,
} from "../../../apps/device_financing/Subscription/redux/layout/actions";
import LockingTour from "../../../apps/device_financing/Subscription/components/card/LockingTour";
import { useMediaQuery, useTheme } from "@mui/material";
import { getAllDiscountAction } from "../../../store/account/actions";
import FinalConfirmation from "../../../apps/device_financing/Subscription/components/FinalConfirmation";

const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;
const PaymentProcess: React.FC<{
  addToCart?: any;
  setIsNewItemAdded?: any;
  isNewItemAdded?: any;
  onChangeDate?: any;
  confirmationDate?: any;
  handleCheckout?: any;
  disableCheckout?: boolean;
}> = ({ onChangeDate, confirmationDate, handleCheckout, disableCheckout }) => {
  const { auth, wareHouse, pos, appSettings, subLayout, account } = useSelector(
    (state: any) => state
  );
  const [isMacyeMacye, setIsMacyeMacye] = useState(() => {
    const hasMacyeMacye = wareHouse?.createdCart?.data?.payment?.some(
      (payment: any) => hasMacyemacyeOption?.includes(payment?.mode)
    );
    return hasMacyeMacye;
  });

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  const channelId = auth?.user?.shop?.channel || auth?.userShop?.channelId;

  const [paymentType, setPaymentType] = useState("");
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [loadingSpiner, setLoadingSpiner] = useState(false);
  const [planType, setPlanType] = useState(
    wareHouse?.createdCart?.data?.initialPaymentType
  );
  const [uploadContract, setUploadContract] = useState<any | null>(null);
  const [customerPay, setCustomerPay] = useState(0);

  const [termsAndConditionModalOpen, setTermsAndConditionModalOpen] =
    useState(false);
  const [active, setActive] = useState(
    wareHouse?.createdCart?.data?.subscriptionPlan
  );

  const [activeInstalment, setActiveInstalment] = useState(
    wareHouse?.createdCart?.data?.instalmentPlan
  );

  const { cartId } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const subsPlans = wareHouse?.createdCart?.data?.list?.map(
    (plan: any, index: number) => plan?.priceProduct?.subscriptionPlan
  );

  const ActivePlan = getActivePlan(
    subsPlans,
    wareHouse?.createdCart?.data?.subscriptionPlan
  );

  const itemsList = wareHouse?.createdCart?.data?.list;
  const discount = wareHouse?.createdCart?.data?.discount;
  const isCartEmpty = wareHouse?.createdCart?.data?.list?.length < 1;

  const handleOpenTermAndContions = () => {
    if (isSmallScreen) {
      setOpen(true);
    } else {
      setTermsAndConditionModalOpen(true);
    }
  };
  const onCloseTermAndContions = () => {
    setTermsAndConditionModalOpen(false);
  };
  const handleDiscountChange = async (e: any) => {
    const discount = {
      discounType: e,
      discountPerc: e?.split("-")[1],
    };
    auth?.token &&
      (await updateShopStockCartDetailsAction(auth?.token, cartId, {
        discount,
      })(dispatch));
    auth?.token &&
      (await updateShopStockCartDetailsAction(auth?.token, cartId, {
        discount,
      })(dispatch));
  };

  useEffect(() => {
    if (["Macye_Macye", "Intelligra_Insurance"].includes(paymentType)) {
      const discount = {
        discounType: "default-0",
        discountPerc: "0",
      };
      auth?.token &&
        updateShopStockCartDetailsAction(auth?.token, cartId, {
          discount,
        })(dispatch);
    }
  }, [paymentType]);

  const fileUpload = async (newFiles: any) => {
    const discount = {
      discounType: wareHouse?.createdCart?.data?.discount?.discounType,
      discountPerc: wareHouse?.createdCart?.data?.discount?.discountPerc,
      proof: [...newFiles.map((pic: any) => pic?.response?.data?.secure_url)],
    };
    auth?.token &&
      (await updateShopStockCartDetailsAction(auth?.token, cartId, {
        discount,
      })(dispatch));
  };

  useEffect(() => {
    if (paymentType !== "") {
      setIsMacyeMacye(
        hasMacyemacyeOption?.includes(paymentType) ? true : false
      );
    }
  }, [paymentType]);

  useEffect(() => {
    if (active && activeInstalment === "weekly") {
      const payload = {
        payment: {
          ...itemsList[0]?.payment,
          amount: ActivePlan && ActivePlan?.at(0)?.initialPayment,
          installementPayment:
            ActivePlan && ActivePlan?.at(0)?.weeklyInstalment,
        },
      };

      auth?.token &&
        updateShopStockCartItemDetailsAction(
          auth?.token,
          itemsList[0]?._id,
          payload
        )(dispatch);
    }
  }, [active, auth?.token, dispatch, activeInstalment]);

  //here to update based on instalment and initial payment type
  const calculatePaymentAmount = () => {
    if (wareHouse?.createdCart?.data?.instalmentPlan === "weekly") {
      return ActivePlan?.at(0)?.weeklyInstalment || 0;
    } else if (wareHouse?.createdCart?.data?.instalmentPlan === "daily") {
      return ActivePlan?.at(0)?.dailyInstalment || 0;
    } else if (wareHouse?.createdCart?.data?.instalmentPlan === "monthly") {
      return ActivePlan?.at(0)?.monthlyInstalment || 0;
    }
    return 0;
  };
  const amountToPay = calculatePaymentAmount();

  useEffect(() => {
    if (!wareHouse?.createdCart?.data?.initialPaymentType) {
      return;
    }
    if (activeInstalment && itemsList) {
      const payload = {
        payment: {
          ...itemsList[0]?.payment,
          amount:
            planType === "b2c_postpaid"
              ? amountToPay
              : ActivePlan && ActivePlan.at(0)?.initialPayment,
          installementPayment:
            ActivePlan &&
            (activeInstalment === "daily"
              ? ActivePlan.at(0)?.dailyInstalment
              : activeInstalment === "weekly"
              ? ActivePlan.at(0)?.weeklyInstalment
              : activeInstalment === "monthly"
              ? ActivePlan.at(0)?.monthlyInstalment
              : 0.1),
        },
      };
      auth?.token &&
        updateShopStockCartItemDetailsAction(
          auth?.token,
          itemsList[0]?._id,
          payload
        )(dispatch);
    }
  }, [planType, auth?.token, dispatch, amountToPay]);

  useEffect(() => {
    form.setFieldsValue({ customerAmount: customerPay });
  }, [customerPay]);

  const handleSubmitContact = async () => {
    const payload = [
      ...(wareHouse?.createdCart?.data?.account?.documents ?? []),
    ];
    const newData = {
      name: "contract",
      tag: "contract",
      docLink: uploadContract && uploadContract[0]?.response?.data?.secure_url,
    };
    payload.push(newData);
    if (wareHouse?.createdCart?.data?.account?._id) {
      await uploadContactction(
        auth.token,
        wareHouse?.createdCart?.data?.account?._id,
        {
          isContractSubmitted: true,
          documents: payload,
        }
      )(dispatch);
      await getShopStockCartAction(auth?.token, cartId)(dispatch);
    } else {
      notification.error({ message: "Account not found" });
    }
  };

  const hasNullValues = discount?.proof
    .map((pic: any) => pic)
    .some((item: any) => item === null || item === undefined);

  const handleClosePaymentModel = async (model: string) => {
    if (model === "payment") {
      await handleOpenPaymentModelAction(false)(dispatch);
    } else {
      await handlConfirmPaymentModelAction(false)(dispatch);
    }
  };

  const handleConfirmPaymant = () => {
    handleOpenPaymentModelAction(true)(dispatch);
  };
  const handleActivateVault = async () => {
    const res = await updateShopStockCartDetailsAction(auth?.token, cartId, {
      subscriptionStatus: "PAYMENT_DONE",
    })(dispatch);
    if (res) {
      await handlePaymentStatusAction("invoice")(dispatch);
      await handlConfirmPaymentModelAction(true)(dispatch);
    }
  };
  const handleEnableVault = () => {
    handleConfirmFinalStageAction(true)(dispatch);
  };
  useEffect(() => {
    if (auth?.token && channelId) {
      getAllPaymentMethodeAction(
        auth?.token,
        `?channel=${channelId}`
      )(dispatch);
      getAllDiscountAction(auth?.token, `?channel=${channelId}`)(dispatch);
    }
  }, [auth?.token, channelId, dispatch]);
  const isCustomerPaid = wareHouse?.createdCart?.data?.autoPayments?.some(
    (status: any) => status?.status === "SUCCESSFUL"
  );

  return (
    <>
      <div className="flex h-sfull leading-none gap-x-[0.7vw] ">
        <div className="flex-1 p-5 rounded-lg overflow-dauto relative">
          {!isCartEmpty && (
            <div>
              {wareHouse?.createdCart?.data?.isSubscription === "pos" ||
              ["accepted", "rejected", "paid"]?.includes(
                wareHouse?.createdCart?.data?.status
              ) ? (
                <div>
                  {/* Discount section */}
                  <div className="space-y-2 mb-4 gap-2 ">
                    <div className="flex flex-wrap items-center gap-2">
                      <div className="space-y-2 w-full">
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          // className="border border-[#03022940] rounded-md"
                          placeholder="Select Discount Type"
                          optionFilterProp="children"
                          disabled={
                            ["Macye_Macye", "Intelligra_Insurance"]?.includes(
                              paymentType
                            ) ||
                            ["accepted", "rejected"]?.includes(
                              wareHouse?.createdCart?.data?.status
                            )
                          }
                          defaultValue={
                            wareHouse?.createdCart?.data?.discount
                              ?.discounType === "private"
                              ? "Select discount type"
                              : wareHouse?.createdCart?.data?.discount
                                  ?.discounType
                          }
                          filterOption={(input, option) =>
                            (option?.label ?? "").includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          onChange={handleDiscountChange}
                          options={[
                            {
                              value: "default-0",
                              label: "None",
                            },
                            {
                              value: "prestige-5",
                              label: "Prestige",
                            },
                            {
                              value: "mtn_staff-10",
                              label: "MTN Staff",
                            },
                            {
                              value: "momo_staff-10",
                              label: "MoMo Staff",
                            },
                          ]}
                        />
                      </div>
                    </div>
                    {wareHouse?.createdCart?.data?.discount?.discounType !==
                      "private" &&
                      wareHouse?.createdCart?.data?.discount?.discounType !==
                        "default-0" && (
                        <div className="flex items-center w-full gap-2">
                          <p className="min-w-max">Proof Document</p>
                          <UploadAny
                            setPictures={fileUpload}
                            isCard="picture-card"
                            limit={5}
                          />
                        </div>
                      )}
                  </div>

                  {/* subscription info */}
                  {/* {wareHouse?.createdCart?.data?.isSubscription ===
                    "subscription" && (
                    <SubscriptionInfo
                      planType={planType}
                      active={active}
                      setActiveInstalment={setActiveInstalment}
                      activeInstalment={activeInstalment}
                    />
                  )} */}
                  {/* Upload terms and conditions */}
                  {wareHouse?.createdCart?.data?.isSubscriptionSubmitted &&
                    wareHouse?.createdCart?.data?.other && <DataPlan />}

                  {wareHouse?.createdCart?.data?.isSubscriptionSubmitted &&
                    wareHouse?.createdCart?.data?.status !== "rejected" &&
                    !wareHouse?.createdCart?.data?.acceptTermsAndConditions && (
                      <div className=" justify-center sm:justify-end flex items-end mt-3">
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          onClick={handleOpenTermAndContions}
                          sx={{ width: 200, height: 40 }}
                        >
                          Terms & Conditions
                        </LoadingButton>
                      </div>
                    )}

                  {wareHouse?.createdCart?.data?.isSubscriptionSubmitted &&
                    wareHouse?.createdCart?.data?.acceptTermsAndConditions &&
                    !wareHouse?.createdCart?.data?.account
                      ?.isContractSubmitted &&
                    ![
                      "PAYMENT_DONE",
                      "VAULT_UPLOADED",
                      "VAULT_ACTIVATED",
                      "COMPLETED",
                    ]?.includes(
                      wareHouse?.createdCart?.data?.subscriptionStatus
                    ) &&
                    !isCustomerPaid && (
                      // <div className="mt-2 pt-2">
                      //   <h1 className="pb-2 font-bold ">Upload Contract</h1>
                      //   <UploadComponent
                      //     setPictures={setUploadContract}
                      //     isCard={true}
                      //     s
                      //     limit={1}
                      //   />
                      //   <LoadingButton
                      //     variant="contained"
                      //     onClick={handleSubmitContact}
                      //     disabled={
                      //       uploadContract === null ||
                      //       (uploadContract &&
                      //         !uploadContract[0]?.response?.data?.secure_url)
                      //     }
                      //     loading={pos?.isFetching}
                      //   >
                      //     Next
                      //   </LoadingButton>
                      // </div>
                      <div className="justify-end flex items-end mt-3">
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          onClick={handleConfirmPaymant}
                          sx={{ width: 200, height: 40 }}
                        >
                          Confirm Payment
                        </LoadingButton>
                      </div>
                    )}
                  {wareHouse?.createdCart?.data?.isSubscriptionSubmitted &&
                    wareHouse?.createdCart?.data?.acceptTermsAndConditions &&
                    !wareHouse?.createdCart?.data?.account
                      ?.isContractSubmitted &&
                    ![
                      "ACCOUNT_REQUEST",
                      "VAULT_UPLOADED",
                      "VAULT_ACTIVATED",
                      "COMPLETED",
                    ]?.includes(
                      wareHouse?.createdCart?.data?.subscriptionStatus
                    ) &&
                    (["PAYMENT_DONE"]?.includes(
                      wareHouse?.createdCart?.data?.subscriptionStatus
                    ) ||
                      isCustomerPaid) && (
                      <div className="justify-end flex items-end mt-3">
                        <LoadingButton
                          type="submit"
                          loading={wareHouse?.isUpdateFetching}
                          variant="contained"
                          onClick={handleActivateVault}
                          sx={{ width: 200, height: 40 }}
                        >
                          ReActivate Vault
                        </LoadingButton>
                      </div>
                    )}
                  {wareHouse?.createdCart?.data?.isSubscriptionSubmitted &&
                    wareHouse?.createdCart?.data?.acceptTermsAndConditions &&
                    !wareHouse?.createdCart?.data?.account
                      ?.isContractSubmitted &&
                    wareHouse?.createdCart?.data?.subscriptionStatus ===
                      "VAULT_ACTIVATED" && (
                      <div className="justify-end flex items-end mt-3">
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          onClick={handleEnableVault}
                          sx={{ width: 200, height: 40 }}
                        >
                          Done/Ok
                        </LoadingButton>
                      </div>
                    )}

                  {wareHouse?.createdCart?.data?.isSubscriptionSubmitted &&
                    wareHouse?.createdCart?.data?.acceptTermsAndConditions &&
                    !wareHouse?.createdCart?.data?.account
                      ?.isContractSubmitted &&
                    ["VAULT_UPLOADED", "COMPLETED"]?.includes(
                      wareHouse?.createdCart?.data?.subscriptionStatus
                    ) && (
                      <div className="justify-end flex items-end mt-3">
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          onClick={() => {
                            handleOpenTourAction(true)(dispatch);
                            handlecheckStateInfoAction("Ready For Use")(
                              dispatch
                            );
                          }}
                          sx={{ width: 200, height: 40 }}
                        >
                          Continue
                        </LoadingButton>
                      </div>
                    )}

                  {wareHouse?.createdCart?.data?.status !== "rejected" &&
                    (wareHouse?.createdCart?.data?.isSubscription === "pos" ||
                      wareHouse?.createdCart?.data?.account
                        ?.isContractSubmitted) && (
                      <>
                        {/* Payment section */}
                        {wareHouse?.createdCart?.status === 200 && (
                          <PaymentSection
                            paymentType={paymentType}
                            customerPay={customerPay}
                            isMacyeMacye={isMacyeMacye}
                            setCustomerPay={setCustomerPay}
                            setPaymentType={setPaymentType}
                          />
                        )}
                        {/* Selling Date */}
                        <div className="flex  items-center p-2 justify-between ">
                          <p className="font-semibold">Selling date</p>
                          <DatePicker
                            defaultValue={dayjs(
                              confirmationDate?.toString(),
                              "DD/MM/YYYY"
                            )}
                            format="DD/MM/YYYY"
                            onChange={onChangeDate}
                            disabled={
                              wareHouse?.createdCart?.data?.status?.toLowerCase() ===
                              "paid"
                            }
                          />
                        </div>
                        {process.env.REACT_APP_ShopId_with_Momo_Implementation?.includes(
                          shopId
                        ) &&
                          ["admin", "dev"].includes(
                            auth?.user?.role?.toLowerCase()
                          ) && (
                            <div className="flex items-center p-2 justify-between">
                              <p className="font-semibold">
                                Disable MoMo Integration
                              </p>
                              <Switches />
                            </div>
                          )}

                        {/* Final amount to pay */}
                        <FinalAmountSection
                          planType={planType}
                          getTotalPrice={getTotalPrice}
                        />

                        {/* Actions */}
                        <div className="flex mt-10 mb-5d">
                          {/* handleCheckout */}
                          <LoadingButton
                            type="submit"
                            onClick={() => setIsCheckoutModalOpen(true)}
                            // onClick={handleCheckout}
                            variant="contained"
                            // startIcon={<AddIcon />}
                            //   sx={{ minWidth: "100%" }}
                            loading={pos?.isFetching}
                            className="w-full"
                            disabled={
                              (isMacyeMacye ? false : disableCheckout) ||
                              (discount?.discountPerc > 1 &&
                                discount?.proof.length < 1) ||
                              (discount?.discountPerc > 1 && hasNullValues) ||
                              !wareHouse?.createdCart?.data?.customer
                            }
                            // disabled={isMacyeMacye ? false : disableCheckout}
                          >
                            Checkout
                          </LoadingButton>
                        </div>
                      </>
                    )}
                  {wareHouse?.createdCart?.data?.status === "rejected" && (
                    <div className="!mt-5">
                      <p className="font-bold">Rejection Reason</p>
                      <hr className="mt-1 mb-3" />
                      <p className="text-red-400">
                        {wareHouse?.createdCart?.data?.rejectSubReason}
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <Subscription
                  planType={planType}
                  setLoadingSpiner={setLoadingSpiner}
                  active={active}
                  activeInstalment={activeInstalment}
                  setActiveInstalment={setActiveInstalment}
                  setPlanType={setPlanType}
                  setActive={setActive}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {/*  model */}
      <Modal
        open={isCheckoutModalOpen}
        onCancel={() => setIsCheckoutModalOpen(false)}
        title={
          <div className="flex gap-5">
            <ExclamationCircleOutlined
              style={{ color: "orange", fontSize: "23px" }}
            />
            <h1 className="text-[#030229] font-medium text-2xl">
              Confirmation
            </h1>
          </div>
        }
        okText={<h1 className="text-white text-base">Confirm</h1>}
        onOk={() => {
          handleCheckout();
          setIsCheckoutModalOpen(false);
        }}
        okButtonProps={{
          style: { border: "1px solid blue", color: "black" },
        }}
        confirmLoading={pos?.isFetching}
      >
        <Divider className="!p-0 !m-0" />
        <Checkout planType={planType} getTotalPrice={getTotalPrice} />
      </Modal>
      {isSmallScreen ? (
        <Drawer placement="bottom" onClose={onClose} open={open} height={450}>
          <div className="text-black">
            <TermsAndConditionForm onCancel={onClose} />
          </div>
        </Drawer>
      ) : (
        <CustomModal
          openModal={termsAndConditionModalOpen}
          cancelModal={onCloseTermAndContions}
          width={1000}
          component={
            <TermsAndConditionForm onCancel={onCloseTermAndContions} />
          }
        />
      )}

      {/*  enable payment */}
      <CustomModal
        openModal={subLayout?.openPaymentModel}
        cancelModal={() => handleClosePaymentModel("payment")}
        width={1000}
        component={
          <CofirmPayment onCancel={() => handleClosePaymentModel("payment")} />
        }
      />

      {/*  confirm payment */}
      <CustomModal
        openModal={subLayout?.confirmPaymentModel}
        // closable={false}
        // cancelModal={() => handleClosePaymentModel("cofirmProcess")}
        width={800}
        component={
          <LoadingPaymentProcess
            onCancel={() => handleClosePaymentModel("cofirmProcess")}
          />
        }
      />

      {/* Tour */}
      <CustomModal
        openModal={subLayout?.enableTour}
        // cancelModal={() => handleClosePaymentModel("cofirmProcess")}
        // width={300}
        component={
          <div className="w-full">
            <LockingTour />
          </div>
        }
      />
      {/* final form */}
      <CustomModal
        openModal={subLayout?.isFinalStage}
        // cancelModal={() => handleClosePaymentModel("cofirmProcess")}
        // width={300}
        component={
          <div className="w-full">
            <FinalConfirmation />
          </div>
        }
      />

      <Modal
        centered
        open={loadingSpiner}
        footer={null}
        width={1000}
        className="custom-modal"
      >
        <div className="flex items-center justify-center rounded-lg mx-auto bg-transparent">
          <Spin indicator={antIcon} />
        </div>
      </Modal>
    </>
  );
};

export default PaymentProcess;

import { Table, Tag } from "antd";
import React from "react";
import { GrFormView } from "react-icons/gr";
import { useSelector } from "react-redux";
import { searchTextValue, searchValue } from "../../utils/setColor";
import ScrollableFrame from "../layout/ScrollableFrame";

const RepairTable = (props: any) => {
  const { repair } = useSelector((state: any) => state);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const columns = [
    {
      title: "Device",
      dataIndex: "model",
      width: "40"
    },
    {
      title: "IMEI",
      dataIndex: "serialNumber"
    },
    {
      title: "Shop",
      dataIndex: "shop"
    },
    {
      title: "Agent",
      dataIndex: "agent"
    },
    {
      title: "Customer",
      dataIndex: "customer"
    },
    {
      title: "Date",
      dataIndex: "date"
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text:any) => {
        return(<Tag
      color={searchValue(text)}
      className='p-1 px-3 w-[5.5rem] capitalize'
      style={{  textAlign: "center",borderRadius:"20px" }}
    >
     <p className={`text-[${searchTextValue(text)}]`}>{text}</p> 
    </Tag>)}
    }
    // {
    //   title: "View",
    //   dataIndex: "view",
    //   render: (text: any, record: any) => {
    //     return (
    //       <GrFormView
    //         size={25}
    //         color="#5F5AFE"
    //         className="cursor-pointer"
    //       />
    //     );
    //   }
    // }
  ];

  const repairData = props?.data
    ?.map((el: any) => {
      return {
        key: el?._id,
        model: el?.cartItemId?.priceProduct?.product?.model,
        serialNumber: el?.cartItemId?.warehouseProduct?.serialNumber,
        status: el?.status,
        shop: el?.cartItemId?.cart?.shop?.name,
        agent: el?.createdBy?.names,
        customer: el?.cartItemId?.cart?.customer?.name,
        date: el?.createdAt?.slice(0, 10),
        data: el
      };
    })
    .slice(0, 8);

  return (
    <div>
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={props?.data?.result}
        count={Math.ceil(props?.data?.result / props?.limit)}
        // isTable
      >
        <Table
          columns={columns}
          dataSource={repairData}
          size="middle"
          loading={repair?.isFetching}
          pagination={false}
        />
      </ScrollableFrame>
    </div>
  );
};

export default RepairTable;

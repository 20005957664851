import { Box, Stack, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Tag } from "antd";
import { searchValue } from "../../utils/setColor";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "./../../components/Modals/DeleteModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRequestStockAction } from "../../store/channel/actions";
import { getActiveShop } from "../../utils/converter";

const Component = (props: any) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth, channel, shop, appSettings } = useSelector(
    (state: any) => state
  );
  const data = {
    name: props?.data?.shopId?.name || "New RFS",
    status: props.data?.status,
    id: props.data?.requestId,
    product:
      props.data?.list?.map((item: any) => item?.product?.product?.model) || "",
    quantity: props.data?.list?.map((item: any) => item?.qtyRequested) || "0",
  };

  const shopId =
    shop?.selected?._id ||
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id;

  const deleteModal = () => {
    setVisible(true);
  };
  const deleteRequest = async () => {
    auth?.token &&
      (await deleteRequestStockAction(
        auth?.token,
        props?.data?._id,
        `?shopId=${shopId}&limit=${props?.limit}&page=${props?.page - 1}`
      )(dispatch));
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="relative custom-backface-visibilty bg-white border-gray-100  p-2 min-w-[300px] border text-black min-h-[126px] rounded-md !duration-200 hover:border-gray-300">
        {/* <Checkbox {...label} color="success" /> */}
        <Stack
          spacing={2}
          onClick={() => {
            navigate(data.id);
          }}
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Stack spacing={0}>
              <h1>{data?.name}</h1>
              <p className="text-xs" style={{ color: "blue" }}>
                {data?.id}
              </p>
            </Stack>
            <Tag
              color={searchValue(data.status)}
              style={{ minWidth: "4rem", textAlign: "center" }}
              className="capitalize"
            >
              {data.status}
            </Tag>
          </Stack>
          <Box sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="flex-end"
              sx={{ width: "100%" }}
            >
              <Stack spacing={0} style={{ marginTop: "15px" }}>
                <p className="text-xs">
                  Requested By:<b>{props?.data?.createdBy?.names}</b>
                </p>

                {props?.data?.status === "done" && (
                  <>
                    <p className="text-xs">
                      Approved By: <b>{props?.data?.approvedBy?.names}</b>
                    </p>
                    <p className="text-xs">
                      Approved On:{" "}
                      <b>{props?.data?.updatedAt?.split("T")[0]}</b>
                    </p>
                  </>
                )}
              </Stack>
            </Stack>
          </Box>
        </Stack>
        {["request", "review"].includes(props?.data?.status) && (
          <Stack
            direction="row"
            alignItems="flex-end"
            spacing={0}
            className="absolute right-2 bottom-0"
          >
            <IconButton aria-label="delete" size="small">
              <DeleteIcon fontSize="inherit" onClick={deleteModal} />
            </IconButton>
          </Stack>
        )}
      </div>
      <DeleteModal
        visible={visible}
        onOk={deleteRequest}
        isLoading={channel.isFetching}
        onCancel={handleCancel}
        itemName="Stock Request"
      />
    </>
  );
};

export default Component;

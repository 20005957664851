import { planManagementActions } from ".";
import { getAllPlansService } from "./services";

export const getAllPlansAction = (token: string, query: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(planManagementActions.setIsFetching(true));
      const res = await getAllPlansService(token, query);
      if (res?.status === 200) {
        dispatch(planManagementActions.setAll(res));
        dispatch(planManagementActions.setIsFetching(false));
        return { type: true, data: res?.data };
      }
      dispatch(planManagementActions.setIsFetching(false));
      return { type: false, data: {} };
    } catch (err) {
      console.log(err);
    }
  };
};

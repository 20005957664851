import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import productReducer from "./product";
import SupplierReducer from "./supplier";
import CustomerReducer from "./customer";
import QuotationReducer from "./quotation";
import ProformaReducer from "./proforma";
import ChannelReducer from "./channel";
import ShopReducer from "./shop";
import purchase from "./purchase";
import WareHouseReducer from "./wareHouse";
import WareHouseOutReducer from "./wareHouseOut";
import PosReducer from "./pos";
import settingReducer from "./setting";
import dashboardReducer from "./dashboard";
import transferReducer from "./transfer";
import inventoryReducer from "./inventory";
import layoutReducer from "./layout";
import ebmReducer from "./ebm";
import companyReducer from "./company";
import posOverViewReducer from "./posOverView";
import repairReducer from "./repair";
import reportReducer from "./report";
import notificationReducer from "./notification";
import kpiReducer from "./kpi";
import socketReducer from "./socket";
import chatReducer from "../apps/chat/redux";
import appSettingsReducer from "./appSettings";
import { Knowledge } from "../apps/knowledge";
import termsAndConditionsReducer from "./TermsAndCondirons";
import AccountReducer from "./account";

//subscription
import subCustomerReducer from "../apps/device_financing/Subscription/redux/customer";
import subLayoutReducer from "../apps/device_financing/Subscription/redux/layout";
import otpManagementReducer from "../apps/device_financing/Subscription/redux/otpManagement";
import planManagementReducer from "../apps/device_financing/Subscription/redux/planManagement";

//subscription freelancer
import applicantsReducer from "../apps/freelancerModule/redux/businessPlans"
const store = configureStore({
  reducer: {
    auth: authReducer,
    product: productReducer,
    supplier: SupplierReducer,
    quotation: QuotationReducer,
    customer: CustomerReducer,
    proforma: ProformaReducer,
    channel: ChannelReducer,
    shop: ShopReducer,
    purchase: purchase,
    wareHouse: WareHouseReducer,
    wareHouseOut: WareHouseOutReducer,
    pos: PosReducer,
    setting: settingReducer,
    dashboard: dashboardReducer,
    transfer: transferReducer,
    inventory: inventoryReducer,
    layout: layoutReducer,
    ebm: ebmReducer,
    company: companyReducer,
    posOverView: posOverViewReducer,
    repair: repairReducer,
    report: reportReducer,
    notifications: notificationReducer,
    kpi: kpiReducer,
    socket: socketReducer,
    chat: chatReducer,
    appSettings: appSettingsReducer,
    termsAndCondions: termsAndConditionsReducer,
    account: AccountReducer,
    knowledge: Knowledge?.Redux,
    subCustomer: subCustomerReducer,
    subLayout: subLayoutReducer,
    otpManagement: otpManagementReducer,
    planManagement: planManagementReducer,
    apllicants: applicantsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

import { LoadingButton } from "@mui/lab";
import { notification, Radio, Select, Spin } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActivePlan } from "../../../../utils/converter";
import {
  getShopStockCartAction,
  updateShopStockCartDetailsAction,
} from "../../../../store/wareHouse/actions";
import { useNavigate, useParams } from "react-router-dom";
import { createAccountAction } from "../../../../store/pos/actions";
import type { RadioChangeEvent } from "antd";
import { getDefaultCurrencyCode } from "../../../../utils/CurrencyHelper";
const { Option } = Select;

const Subscription: React.FC<{
  planType: string;
  setLoadingSpiner: any;
  active: any;
  activeInstalment: any;
  setActiveInstalment: any;
  setPlanType: any;
  setActive: any;
}> = ({
  planType,
  setLoadingSpiner,
  active,
  activeInstalment,
  setActiveInstalment,
  setPlanType,
  setActive,
}) => {
  const { auth, wareHouse, pos,company } = useSelector((state: any) => state);
  const { cartId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeSpinner, setActiveSpinner] = useState("");
  const plan = wareHouse?.createdCart?.data?.subscriptionPlan;

  const subsPlans = wareHouse?.createdCart?.data?.list?.map(
    (plan: any, index: number) => plan?.priceProduct?.subscriptionPlan
  );
  const ActivePlan = getActivePlan(
    subsPlans,
    wareHouse?.createdCart?.data?.subscriptionPlan
  );

  const availablePlan =
    wareHouse?.createdCart?.data?.list &&
    wareHouse?.createdCart?.data?.list?.flatMap((item: any) =>
      item?.priceProduct?.subscriptionPlan?.filter(
        (d: any) => d?.duration === plan
      )
    );

  const isValid =
    !wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc ||
    !wareHouse?.createdCart?.data?.subscriptionPlan ||
    wareHouse?.createdCart?.data?.isSubscriptionSubmitted ||
    !wareHouse?.createdCart?.data?.other;

  const onChangeCallSelected = async (value: any) => {
    setActiveSpinner("call");
    const payload = { ...wareHouse?.createdCart?.data?.other, call: value };
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      other: payload,
    })(dispatch);
    getShopStockCartAction(auth?.token, cartId)(dispatch);
  };

  const onChangeBundleSelected = async (value: any) => {
    setActiveSpinner("bundle");
    const payload = { ...wareHouse?.createdCart?.data?.other, bundle: value };
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      other: payload,
    })(dispatch);
    getShopStockCartAction(auth?.token, cartId)(dispatch);
  };

  const onChangeSMSSelected = async (value: any) => {
    setActiveSpinner("sms");
    const payload = { ...wareHouse?.createdCart?.data?.other, sms: value };
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      other: payload,
    })(dispatch);
    getShopStockCartAction(auth?.token, cartId)(dispatch);
  };

  const onChangePeriodSelected = async (value: any) => {
    setActiveSpinner("period");
    const payload = { ...wareHouse?.createdCart?.data?.other, period: value };

    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      other: payload,
    })(dispatch);
    getShopStockCartAction(auth?.token, cartId)(dispatch);
  };

  const onInstalmentChange = async (e: RadioChangeEvent) => {
    setActiveSpinner("plan");
    setActiveInstalment(e.target.value);
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      instalmentPlan: e.target.value,
    })(dispatch);
  };

  const onChangeInitialPayType = async (value: any) => {
    setActiveSpinner("subType");
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      initialPaymentType: value,
    })(dispatch);
    getShopStockCartAction(auth?.token, cartId)(dispatch);
    setPlanType(value);
  };

  const onChange = async (e: RadioChangeEvent) => {
    setActiveSpinner("duration");
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      subscriptionPlan: e.target.value,
    })(dispatch);
    setActive(e.target.value);
  };

  const handleSendRequest = async () => {
    setActiveSpinner("sendReq");
    // if (planType === "b2c_postpaid") {
    //   const resPost = await updateShopStockCartDetailsAction(
    //     auth?.token,
    //     cartId,
    //     {
    //       isSubscriptionSubmitted: true,
    //       status: "accepted",
    //       payment: [
    //         {
    //           mode: "MoMo_Pay",
    //           amount: ActivePlan?.at(0)?.initialPayment,
    //         },
    //       ],
    //     }
    //   )(dispatch);
    //   if (resPost) {
    //     await createAccountAction(auth?.token, cartId as string)(dispatch);
    //     await getShopStockCartAction(auth?.token, cartId)(dispatch);
    //     setLoadingSpiner(false);
    //   }
    // } else
    if (wareHouse?.createdCart?.data?.isSubscriptionSubmitted) {
      auth?.user?.profile==='freelancer'? navigate("/cart"): navigate("/sale");
    } else {
      const resPre = await updateShopStockCartDetailsAction(
        auth?.token,
        cartId,
        {
          isSubscriptionSubmitted: true,
          status: "requested",
          subscriptionStatus: "ACCOUNT_REQUEST",
          // status: "accepted",
          payment: [
            {
              mode: "MoMo_Pay",
              amount: ActivePlan?.at(0)?.initialPayment,
            },
          ],
        }
      )(dispatch);
      if (resPre) {
        await createAccountAction(auth?.token, cartId as string)(dispatch);
        auth?.user?.profile==='freelancer'? navigate("/cart"): navigate("/sale");
        notification.success({ message: "Request Sent Successfully" });
        setLoadingSpiner(false);
      }
    }
  };

  const isDisabled =
    wareHouse?.createdCart?.data?.selectedPlan ||
    wareHouse?.createdCart?.data?.isSubscriptionSubmitted;

  return (
    <div className="items-center">
      <h1 className="font-semibold pb-1 text-neutral-800 border-b mb-2">
        Plan duration
      </h1>
      <div>
        <Spin
          tip="Loading..."
          spinning={
            (wareHouse?.isUpdateFetching || wareHouse?.isReFetchingCart) &&
            activeSpinner === "duration"
          }
        >
          <Radio.Group
            onChange={onChange}
            value={active}
            className="flex flex-wrap pb-2"
            disabled={isDisabled}
          >
            {wareHouse?.createdCart?.data?.list &&
              wareHouse?.createdCart?.data?.list[0]?.priceProduct?.subscriptionPlan
                ?.filter((el: any) => el?.duration)
                ?.map((d: any) => (
                  <Radio
                    value={d?.duration}
                    className={`w-44 h-14 border pl-4 mb-2 rounded-md border-gray-400 flex items-center xjustify-center ${
                      active === d?.duration && "bg-[#7461e0] text-white"
                    }`}
                  >
                    {`${d?.duration ?? "N/A"} Months`}
                  </Radio>
                ))}
          </Radio.Group>
        </Spin>
      </div>

      <div>
        <p className="font-semibold pb-1 text-neutral-800">Installment plan</p>
        {subsPlans &&
          subsPlans[0]
            ?.filter(
              (plan: any) =>
                plan?.duration ===
                wareHouse?.createdCart?.data?.subscriptionPlan
            )
            ?.map((plan: any, index: number) => (
              <div className="flex justify-start border-t pt-2">
                <Spin
                  tip="Loading..."
                  spinning={
                    (wareHouse?.isUpdateFetching ||
                      wareHouse?.isReFetchingCart) &&
                    activeSpinner === "plan"
                  }
                >
                  <Radio.Group
                    onChange={onInstalmentChange}
                    value={activeInstalment}
                    className="flex flex-wrap pb-2"
                  >
                    <Radio
                      value={"daily"}
                      className={`w-full h-9 border pl-4 mb-2 rounded-tl-md rounded-br-md flex items-center ${
                        activeInstalment === "daily" && "border-yellow-400"
                      }`}
                      disabled={
                        plan?.dailyInstalment === undefined ||
                        wareHouse?.isUpdateFetching ||
                        wareHouse?.isReFetchingCart ||
                        isDisabled
                      }
                    >
                      <div className="w-full xbg-red-400 flex justify-between">
                        <p>Daily </p>
                      </div>
                    </Radio>

                    <Radio
                      value={"weekly"}
                      className={`w-full h-9 border pl-4 mb-2 rounded-tl-md rounded-br-md flex items-center ${
                        activeInstalment === "weekly" && "border-yellow-400"
                      }`}
                      disabled={
                        plan?.weeklyInstalment === undefined ||
                        wareHouse?.isUpdateFetching ||
                        wareHouse?.isReFetchingCart ||
                        isDisabled
                      }
                    >
                      <div className="w-full xbg-red-400 flex justify-between">
                        <p>Weekly </p>
                      </div>
                    </Radio>

                    <Radio
                      value={"monthly"}
                      className={`w-full h-9 border pl-4 mb-2 rounded-tl-md rounded-br-md flex items-center ${
                        activeInstalment === "monthly" && "border-yellow-400"
                      }`}
                      disabled={
                        plan?.monthlyInstalment === undefined ||
                        wareHouse?.isUpdateFetching ||
                        wareHouse?.isReFetchingCart ||
                        isDisabled
                      }
                    >
                      <div className="w-full xbg-red-400 flex justify-between">
                        <p>Monthly</p>
                      </div>
                    </Radio>

                    {/* instalment payments details */}
                    <div className="w-full mt-5 flex justify-between">
                      {wareHouse?.createdCart?.data?.instalmentPlan ===
                        "weekly" && (
                        <>
                          <span className="font-bold text-neutral-800">
                            Weekly Payment Amount:
                          </span>
                          <span
                            className={`text-right font-bold text-slate-800`}
                          >{`${
                            ActivePlan &&
                            ActivePlan.at(0)?.weeklyInstalment?.toLocaleString()
                          } ${getDefaultCurrencyCode(company)}`}</span>
                        </>
                      )}
                      {wareHouse?.createdCart?.data?.instalmentPlan ===
                        "daily" && (
                        <>
                          <span className="font-bold text-neutral-800">
                            Daily Payment Amount:
                          </span>
                          <span
                            className={`text-right font-bold text-slate-800`}
                          >{`${
                            ActivePlan &&
                            ActivePlan.at(0)?.dailyInstalment?.toLocaleString()
                          } ${getDefaultCurrencyCode(company)}`}</span>
                        </>
                      )}
                      {wareHouse?.createdCart?.data?.instalmentPlan ===
                        "monthly" && (
                        <>
                          <span className="font-bold text-neutral-800">
                            Monthly Payment Amount:
                          </span>
                          <span
                            className={`text-right font-bold text-slate-800`}
                          >{`${
                            (ActivePlan &&
                              ActivePlan.at(
                                0
                              )?.monthlyInstalment?.toLocaleString()) ||
                            0
                          } ${getDefaultCurrencyCode(company)}`}</span>
                        </>
                      )}
                    </div>

                    <div className="w-full mt-5 flex justify-between">
                      <span className="font-bold text-neutral-800">
                        Initial Payment Amount:
                      </span>
                      <span
                        className={`text-right font-bold text-slate-800`}
                      >{`${
                        ActivePlan &&
                        ActivePlan.at(0)?.initialPayment?.toLocaleString()
                      } ${getDefaultCurrencyCode(company)}`}</span>
                    </div>
                  </Radio.Group>
                </Spin>
                {/* </div> */}
              </div>
            ))}
        {availablePlan?.map((data: any) => (
          <div className="flex flex-col pb-3 space-y-4 mt-4">
            <p className="border-b pb-1 font-semibold">Data Plan</p>
            <div className="flex items-center flex-wrap space-x-3">
              <div className="flex flex-col pb-3 space-y-1">
                <span>Bundles</span>
                <Select
                  dropdownMatchSelectWidth={false}
                  showSearch
                  placeholder="Select  Role of the Employee!"
                  className="capitalize"
                  style={{ width: 100 }}
                  onChange={onChangeBundleSelected}
                  disabled={
                    isDisabled ||
                    wareHouse?.isUpdateFetching ||
                    wareHouse?.isReFetchingCart
                  }
                  defaultValue={wareHouse?.createdCart?.data?.other?.bundle}
                  loading={
                    (wareHouse?.isUpdateFetching ||
                      wareHouse?.isReFetchingCart) &&
                    activeSpinner === "bundle"
                  }
                >
                  {data?.bundle?.map((bundle: any, index: any) => (
                    <Option value={bundle} key={index} className="capitalize">
                      {`${bundle} GB`}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="flex flex-col pb-3 space-y-1">
                <span>SMS</span>
                <Select
                  dropdownMatchSelectWidth={false}
                  showSearch
                  placeholder="Select  Role of the Employee!"
                  className="capitalize"
                  style={{ width: 100 }}
                  onChange={onChangeSMSSelected}
                  disabled={
                    isDisabled ||
                    wareHouse?.isUpdateFetching ||
                    wareHouse?.isReFetchingCart
                  }
                  defaultValue={wareHouse?.createdCart?.data?.other?.sms}
                  loading={
                    (wareHouse?.isUpdateFetching ||
                      wareHouse?.isReFetchingCart) &&
                    activeSpinner === "sms"
                  }
                >
                  {data?.sms?.map((sms: any) => (
                    <Option value={sms} className="capitalize">
                      {sms}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="flex flex-col pb-3 space-y-1">
                <span>Call</span>
                <Select
                  dropdownMatchSelectWidth={false}
                  showSearch
                  placeholder="Select  Role of the Employee!"
                  className="capitalize"
                  style={{ width: 100 }}
                  onChange={onChangeCallSelected}
                  disabled={
                    isDisabled ||
                    wareHouse?.isUpdateFetching ||
                    wareHouse?.isReFetchingCart
                  }
                  defaultValue={wareHouse?.createdCart?.data?.other?.call}
                  loading={
                    (wareHouse?.isUpdateFetching ||
                      wareHouse?.isReFetchingCart) &&
                    activeSpinner === "call"
                  }
                >
                  {data?.call?.map((call: any) => (
                    <Option value={call} className="capitalize">
                      {call}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="flex flex-col pb-3 space-y-1">
                <span>Period</span>
                <Select
                  dropdownMatchSelectWidth={false}
                  showSearch
                  placeholder="Select  Role of the Employee!"
                  className="capitalize"
                  style={{ width: 100 }}
                  onChange={onChangePeriodSelected}
                  disabled={
                    isDisabled ||
                    wareHouse?.isUpdateFetching ||
                    wareHouse?.isReFetchingCart
                  }
                  defaultValue={wareHouse?.createdCart?.data?.other?.period}
                  loading={
                    (wareHouse?.isUpdateFetching ||
                      wareHouse?.isReFetchingCart) &&
                    activeSpinner === "period"
                  }
                >
                  {["3", "6", "9", "12", "14", "18", "20", "22", "24"]?.map(
                    (call: any) => (
                      <Option value={call} className="capitalize">
                        {call}
                      </Option>
                    )
                  )}
                </Select>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="justify-end flex items-end">
        <LoadingButton
          variant="contained"
          loading={
            (wareHouse?.isUpdateFetching ||
              wareHouse?.isReFetchingCart ||
              pos?.isFetching) &&
            activeSpinner === "sendReq"
          }
          onClick={handleSendRequest}
          // disabled={isValid}
          sx={{ width: 200, height: 40 }}
        >
          {wareHouse?.createdCart?.data?.isSubscriptionSubmitted
            ? "Waiting for approval"
            : "Request"}
        </LoadingButton>
      </div>
    </div>
  );
};

export default Subscription;

import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from "react-redux";

export default function FilterTabsButton(props: any) {
  const { channel } = useSelector((state: any) => state);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    props?.setIsTransferred(newValue === 0 ? "request" : "pending");
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="disabled tabs example"
    >
      <Tab
        label={`Requested Stock Transfer (${
          channel?.rfsTransfer?.total ?? ""
        })`}
      />
      {/* <Tab
        label={`Pending Stock Transfer (${
          channel?.pendingShopOut?.total ?? ""
        })`}
      /> */}
    </Tabs>
  );
}

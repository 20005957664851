import React from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { GrFormView } from "react-icons/gr";
import ScrollableFrame from "../layout/ScrollableFrame";

const CurrentStockTable = (props: any) => {
  const { pos } = useSelector((state: any) => state);

  const columns = [
    {
      title: "Model",
      dataIndex: "data",
      width: "40",
      render: (text: any, record: any) => (
        <p>
          {record?.data?.productSpecs.product?.model +
            "~" +
            record?.data?.productSpecs.product?.type}
        </p>
      ),
    },
    {
      title: "Descriptions",
      dataIndex: "data",
      render: (text: any, record: any) => {
        return (
          <>
            {record?.data?.productSpecs.specification?.map((d: any) => (
              <>
                {d[0]}: {d[1]}
              </>
            ))}
          </>
        );
      },
    },
    {
      title: "Stock",
      dataIndex: "data",
      render: (text: any) => {
        return (
          <p>
            {text?.countsByStatus?.map(
              (d: any) => d?.status?.toLowerCase() === "pending" && d?.count
            )}
          </p>
        );
      },
    },
    {
      title: "Sold",
      dataIndex: "data",
      render: (text: any) => {
        return (
          <p>
            {text?.countsByStatus?.map(
              (d: any) => d?.status?.toLowerCase() === "sold" && d?.count
            )}
          </p>
        );
      },
    },
    {
      title: "Transfer",
      dataIndex: "data",
      render: (text: any) => {
        return (
          <p>
            {text?.countsByStatus?.map(
              (d: any) => d?.status?.toLowerCase() === "transfer" && d?.count
            )}
          </p>
        );
      },
    },
    {
      title: "View",
      dataIndex: "view",
      render: (text: any, record: any) => {
        return (
          <GrFormView
            size={25}
            color="#5F5AFE"
            className="cursor-pointer"
            onClick={() => {
              props?.getData();
              props?.setData(record);
            }}
          />
        );
      },
    },
  ];

  const stockData = pos?.stock?.data?.map((el: any) => {
    return {
      key: el?._id,
      model: el?.productSpecs?.product?.model,
      stockIn: el?.countsByStatus,
      sold: el?.countByStatus,
      transfer: el?.countByStatus,
      data: el,
    };
  });




  return (
    <ScrollableFrame
      setPage={props?.setPage}
      setLimit={props?.setLimit}
      limit={props?.limit}
      total={pos?.stock?.total}
      count={Math.ceil(pos?.stock?.total / props?.limit)}
      // isTable
    >
      <Table
        columns={columns}
        dataSource={stockData}
        size="middle"
        loading={pos?.isFetching}
        pagination={false}
      />
    </ScrollableFrame>
  );
};

export default CurrentStockTable;

import { Radio } from "antd";
import React from "react";

const ConfirmationQuestion: React.FC<{
  question: string;
  handleChange: any;
  questionNumber: number;
}> = ({ question, questionNumber, handleChange }) => {
  return (
    <div className="flex items-center justify-between border px-4 rounded-md py-2.5">
      <label className="text-gray-800 font-medium text-sm">{question}</label>
      <div className="flex space-x-4">
        <Radio.Group
          name={`radiogroup-${questionNumber}`}
          onChange={(e) => handleChange(e, questionNumber)} // Pass the question number along with the event
        >
          <Radio value="true">Yes</Radio>
          <Radio value="false">No</Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default ConfirmationQuestion;

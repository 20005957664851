import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import { getActiveMenu, storeActiveMenu } from "../../../utils/converter";
import { pageList } from "../assets/pages";

const MobileNavBar = (props:any) => {
  const navigate = useNavigate();
  const [selectedTabIndex, setSelectedTabIndex] = useState(getActiveMenu());
  return (
    <div className="relative">
      <div className="px-3 h-full flex  items-center content-center justify-center">
        <ul className="flex gap-5 overflow-x-auto">
          {pageList?.map((mainMenu: any, index:any) => {
            return (
              <li key={mainMenu.id}
                onClick={() => {
                  setSelectedTabIndex(mainMenu.id);
                  storeActiveMenu(mainMenu.id);
                  if(mainMenu?.menus){
                    props?.openDrawer(mainMenu?.menus)
                  }else{

                    navigate(mainMenu.path);
                  }
                }}
              >
                <div
                  className={` flex items-center justify-center content-center text-2xl  ${
                    selectedTabIndex === mainMenu.id
                      ? "activeIcon"
                      : "#030229CC"
                  }`}
                >
                  <mainMenu.icon />
                </div>
                <p
                  className={`text-[#03022980] text-[13px] pt-1.5 flex items-center cursor-pointer rounded-md ${
                    selectedTabIndex === mainMenu.id
                      ? "text-[#605BFF]"
                      : "text-[#030229CC]"
                  }`}
                >
                  {mainMenu.name}
                </p>
                
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MobileNavBar;
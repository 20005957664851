import React from "react";
import EmptyCartImage from "../assets/images/EmptyCart.png";
import CartInformation from "../pages/dashboard/Sales/CartInformation";
import { useSelector } from "react-redux";

const AddToCart = () => {
  const { wareHouse } = useSelector((state: any) => state);
  return (
    <>
      {wareHouse?.createdCart?.data?.list?.length < 1 ? (
        <div className="flex flex-col justify-center items-center text-center text-[#6157f4] h-full space-y-10">
          <img
            src={EmptyCartImage}
            alt=""
            className="flex-grow w-32 max-h-28"
          />
          <div>
            <p className="font-bold text-[2vw]">Oops..</p>
            <p className="font-bold text-[2vw]">The cart is empty</p>
          </div>
          <p className="text-xs">
            Go find the product by IMEI in my left section then click on Add to
            cart to make me happy
          </p>
        </div>
      ) : (
        <div className="px-5 py-7 h-full">
          <CartInformation />
        </div>
      )}
    </>
  );
};

export default AddToCart;

import { notification } from "antd";
import { myRepairActions } from ".";
import {
  getAllRepairsOverViewService,
  getAllRepairsService,
  getOneRepairService,
  getRecentActivitiesService,
  getRepairService,
  requestRepair,
  searchCartItemBySerialNumber,
  updateOneRepairService,
} from "./services";

export const searchCartItemBySerialNumberAction = (
  token: string,
  serialNumber: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myRepairActions.setIsFetching(true));
      const res = await searchCartItemBySerialNumber(token, serialNumber);
      // console.log(">>>>>>>:: W/Out", res?.data);
      if (res?.status === 200) {
        dispatch(myRepairActions.setRepairSearchResult(res?.data));
        dispatch(myRepairActions.setIsFetching(false));
        dispatch(myRepairActions.setRepairSearchError({}));
      } else {
        dispatch(myRepairActions.setRepairSearchError(res?.response));
        dispatch(myRepairActions.setRepairSearchResult(null));
        notification.error({
          message: res?.response?.data?.error || "Something went wrong.",
        });
      }
      dispatch(myRepairActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const requestRepairAction = (token: string, data: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myRepairActions.setIsFetching(true));
      const res = await requestRepair(token, data);
      // console.log(">>>>>>>:: W/Out", res?.data);
      if (res?.status === 201) {
        dispatch(myRepairActions.setRepairRequest(res?.data));
        dispatch(myRepairActions.setIsFetching(false));
        notification.success({
          message: "Request for repair sent successfully!",
        });
      } else {
        notification.error({
          message: res?.response?.data?.error || "Something went wrong.",
        });
      }
      dispatch(myRepairActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllRepairsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myRepairActions.setIsFetching(true));
      const res = await getAllRepairsService(token, query);
      if (res?.status === 200) {
        dispatch(myRepairActions.setAll(res));
        dispatch(myRepairActions.setIsFetching(false));
      }
      dispatch(myRepairActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllRepairsOverviewAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myRepairActions.setIsFetching(true));
      const res = await getAllRepairsOverViewService(token, query);
      if (res?.status === 200) {
        dispatch(myRepairActions.setRepairOverView(res));
        dispatch(myRepairActions.setIsFetching(false));
      }
      dispatch(myRepairActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getRecentActivitiesAction= (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myRepairActions.setIsFetching(true));
      const res = await getRecentActivitiesService(token, query);
      if (res?.status === 200) {
        dispatch(myRepairActions.setRecentActivities(res));
        dispatch(myRepairActions.setIsFetching(false));
      }
      dispatch(myRepairActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneRepairAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myRepairActions.setIsFetching(true));
      const res = await getOneRepairService(id, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myRepairActions.setRepairSearchResult(res?.data));
        // dispatch(myRepairActions.setSelected(res?.data));
        dispatch(myRepairActions.setIsFetching(false));
      }
      dispatch(myRepairActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateOneRepairAction = (id: string, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myRepairActions.setIsFetching(true));
      const res = await updateOneRepairService(id, data, token);
      const resSelected = await getOneRepairService(id, token);
      // console.log(">>>>!!!>>>::)) ..............", res);
      if (resSelected?.status === 200) {
        dispatch(myRepairActions.setRepairSearchResult(resSelected?.data));
        // dispatch(myRepairActions.setSelected(res?.data));
        dispatch(myRepairActions.setIsFetching(false));
      }
      dispatch(myRepairActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getRepairsAction = (itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myRepairActions.setIsFetching(true));
      const res = await getRepairService(itemId);
      if (res?.status === 200) {
        dispatch(myRepairActions.setSelectedRepair(res));
        dispatch(myRepairActions.setIsFetching(false));
      }
      dispatch(myRepairActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

import axios from "axios";
import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";
import { notification } from "antd";
import { authActions } from ".";

export const loginService = async (data: any, dispatch: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/user/login`,
      data,
    })
      .catch((errorType) => {
        if (errorType?.response) {
          notification.error({ message: errorType?.response?.data?.error });
          return;
        }
        notification.error({ message: "Unable to access server" });
      })
      .finally(() => {
        dispatch(authActions.setIsFetching(false));
      });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};

export const loginByScanService = async (data: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/user/loginwithnid`,
      data,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};

export const profileService = async (token: any) => {
  return HttpRequest.get(`${SERVER_URL}/user/profile`, token);
};

export const userProfileBasicService = async (email: String) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${SERVER_URL}/user/userInfo/${email}`,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};

export const getAgentsAssignedToShopService = async (
  token: string,
  id: string
) => {
  return HttpRequest.get(`${SERVER_URL}/assign/agentToshop/${id}`, token);
};

export const updatePINService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/user/pin/${itemId}`,
    data,
    token
  );
};

import React from 'react'

interface cardProps{
    total?:any;
    text?:string;
    icon?:any
}
const TransferCard = ({total,text,icon}:cardProps) => {
  return (
    <div className='bg-white  rounded-md shadow-md h-[112px] w-full'>
        <div className='flex gap-10'>
            <div className='w-[42px] h-[42px] rounded-full border flex justify-center items-center'>{icon}</div>
            <div>
            <p className='text-[14px] text-[#030229d0] pt-2'>{text}</p>
                <p className='text-[16px] font-semibold text-[#030229cc]'>{total || 0}</p>
                
            </div>

        </div>
    </div>
  )
}

export default TransferCard
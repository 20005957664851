import { Stack, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import PosProductGrid from "../../../components/grids/priceList/PosProductGrid";
import {
  getPricelistAction,
  getSubscriptionPriceListAction,
} from "../../../store/pos/actions";
// import ProductFilter from "../../../components/filters/ProductFiltering";
import PriceListFilter from "../../../components/filters/PriceListFiltering";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import SearchInput from "../../../components/buttons/SearchButton";
import HeaderShops from "../../../components/headerShops";
import { GoListUnordered } from "react-icons/go";
import { TbGridDots } from "react-icons/tb";
import { Radio } from "antd";
import DatePick from "../channels/DatePicker";
import SubProductCardGrid from "./plans/SubProductCardGrid";
import { AnyARecord } from "dns";

type BusinessTypeOption = {
  label: string;
  value: string;
};

//

const PageView = (props: any) => {
  const { auth, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = React.useState("");
  const [selectedTab, setSelectedTab] = React.useState("1");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);

  const [viewDetails, setViewDetails] = useState(false);

  const [statusFilter, setStatusFilter] = useState<string>("");

  const channelId = auth?.user?.shop?.channel || auth?.userShop?.channelId;

  useEffect(() => {
    if (auth?.token && channelId) {
      getPricelistAction(
        auth?.token,
        `?channel=${channelId}&page=${page - 1}&limit=${limit}`
      )(dispatch);
    }
  }, [auth?.token, channelId, dispatch, limit, page]);

  const goBack = () => {
    navigate(-1);
  };

  const handleSearch = () => {
    if (auth?.token && channelId) {
      getPricelistAction(
        auth?.token,
        `/searchPriceList?channel=${channelId}&sk=${searchKey}&page=${
          page - 1
        }&limit=${limit}`
      )(dispatch);
    }
  };

  const fetchTransfers = (type: any) => {
    if (auth?.token && channelId) {
      getSubscriptionPriceListAction(
        auth?.token,
        `?channel=${channelId}&type=${type}&page=${page - 1}&limit=${limit}`
      )(dispatch);
    }
  };

  useEffect(() => {
    fetchTransfers(statusFilter);
  }, [auth?.token, dispatch, channelId, statusFilter]);



  const handleStatusChange = (e: any) => {
    const { value } = e.target;
    setStatusFilter(value);
    fetchTransfers(value);
  };

  const businessType = pos?.subscriptionPriceList?.data?.map((item: any) => {
    // Check if subscriptionType exists in item; if not, fallback to businessType
    const type = item?.subscriptionType || item?.businessProductType;
  
    // If it's only available locally, return it directly
    if (type) {
      return type;
    }
  
  });
  

const uniqueTypes: BusinessTypeOption[] = Array.from(new Set(businessType)).map((type: any) => ({
  label: type,
  value: type,
}));

// Add the "All" option at the beginning
uniqueTypes.unshift({
  label: "All",
  value: "", // Empty value to represent "All"
});



  return (
    <div className="flex flex-col h-full">
      <div className="mt-s4 flex flex-wrap justify-between w-full overflow-x-auto mb-5 md:mb-0">
        {!viewDetails ? (
          <>
            <div className="flex gap-5 flex-wrap">
              <PriceListFilter
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
              />
            </div>
            <div className="flex  items-center gap-5">
              <SearchInput
                onSearch={handleSearch}
                onChange={(e: any) => {
                  e.preventDefault();
                  setSearchKey(e.target.value);
                }}
              />
              {/* <div className="bg-[#605BFF] flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                <GoListUnordered size={23} color="#ffff" />
              </div> */}
              {/* <div className="bg-white flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                <TbGridDots size={23} color="#C0C0C9" />
              </div> */}
              <hr className="h-7 w-0.5 bg-gray-300 mt-1.5" />
            </div>
          </>
        ) : (
          <div className="flex justify-between w-full">
            <Radio.Group
              onChange={handleSearch}
              optionType="button"
              buttonStyle="solid"
            />
            <div className="flex gap-5">
              <DatePick />
              <hr className="h-7 w-0.5 bg-gray-300 mt-1.5" />
              <div className="bg-[#605BFF] flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                <GoListUnordered size={23} color="#ffff" />
              </div>
              <div className="bg-white flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                <TbGridDots size={23} color="#C0C0C9" />
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedTab === "1" && (
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={pos?.priceList?.total}
          count={Math.ceil(pos?.priceList?.total / limit)}
        >
          {pos?.isFetching && (
            <div className="flex flex-wrap gap-4 justify-start items-start">
              {Array.from({ length: 14 }).map((d: any) => (
                <div className="w-80">
                  <Skeleton animation="wave" />
                  <Skeleton variant="rectangular" height={150} />
                </div>
              ))}
            </div>
          )}
          {!pos.isFetching && (
            <div
              className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-start items-start mt-5 `}
            >
              {pos?.priceList?.status === 200 && (
                <PosProductGrid data={pos?.priceList?.data} />
              )}
            </div>
          )}
        </ScrollableFrame>
      )}
      {selectedTab === "2" && (
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={pos?.subscriptionPriceList?.total}
          count={Math.ceil(pos?.subscriptionPriceList?.total / limit)}
        >
          <div className="w-full overflow-x-auto p-2">
            <Radio.Group
              options={uniqueTypes}
              onChange={handleStatusChange}
              value={statusFilter}
              optionType="button"
              buttonStyle="solid"
              className="flex flex-row text-xs md:text-base whitespace-nowrap gap-1 md:gap-2"
            />
          </div>
          {pos.isFetching && (
            <div className="flex flex-wrap gap-4 justify-start items-start">
              {Array.from({ length: 14 }).map((d: any) => (
                <div className="w-80">
                  <Skeleton animation="wave" />
                  <Skeleton variant="rectangular" height={150} />
                </div>
              ))}
            </div>
          )}
          {!pos.isFetching && (
            <SubProductCardGrid data={pos?.subscriptionPriceList?.data} />
          )}
        </ScrollableFrame>
      )}
    </div>
  );
};
export default PageView;

import { myLayoutActions } from ".";

export const handlePaymentMode = (selected: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setPaymentMode(selected));
  };
};

export const handleNextStep = (current: number) => {
  return async (dispatch: any) => {
    const next = current + 1;
    dispatch(myLayoutActions.setIsNext(next));
  };
};

export const handlePreviousStep = (current: number) => {
  return async (dispatch: any) => {
    const previous = current - 1;

    dispatch(myLayoutActions.setIsNext(previous));
  };
};

export const handleDisableMoMoIntegration = (isDisabled: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setDisabledMomo(isDisabled));
  };
};

export const handleMissingRRAcodeAction = (isMissing: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setIsMissingRRAcode(isMissing));
  };
};

export const handleSubMenuAction = (index: number, subIndex: number) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setActiveSubMenu([index, subIndex]));
  };
};
export const handleOpenSubMenuAction = (index: number, subIndex: number) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setOpenSubMenu([index, subIndex]));
  };
};
export const handleSelectedDateAction = (data: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setActiveSubMenu(data));
  };
};

export const handleOpenSellingModeAction = (isOpen: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setOpenSellingMode(isOpen));
  };
};
export const toggleSideNavOpenClose = (isOpen: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setIsSideNavOpen(!isOpen));
  };
};
import React from "react";
interface cardProps {
    name?: any;
    secondName?: any;
    amount?: any;
  }
const InstallmentCard = ({name,secondName,amount}:cardProps) => {
  return (
    <div className="border border-[#EBEFF2] bg-white rounded-md p-3 flex justify-between mt-3">
        <div>
      <h1 className="text-[#030229] text-sm font-medium">{name}</h1>
      <h1 className="text-[#030229] text-sm capitalize">{secondName}</h1>
        </div>
        <div>
            <p className="text-[#030229] font-medium capitalize">{amount}</p>
        </div>
            
    </div>
  );
};

export default InstallmentCard;

import React, { useState } from 'react'
import DetailsHeaderActionBar from '../DetailsPage/DetailsHeaderActionBar'
import { useNavigate } from 'react-router-dom';
import CommissionDevicesTable from '../../tables/CommissionDevicesTable';

const PosCommissionDevices = () => {
    const formattedDate = new Date().toISOString().slice(0, 10);
    const [selectedDate, setSelectedDate] = useState<any>(formattedDate);
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <div>
            <DetailsHeaderActionBar
                pageName="Pos"
                title="Commission"
                goBack={goBack}
                item="Commission"
            />
            <div>
                <CommissionDevicesTable  selectedDate={selectedDate}/>
            </div>
        </div>
    )
}

export default PosCommissionDevices
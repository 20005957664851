import { notification } from "antd";
import { otpManagementActions } from ".";
import { sendOtpToCustomer, VerifyCustomerOtpService } from "./services";

export const sendOTPtoCustomerAction = (
  token: string,
  data: any,
  itemId: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(otpManagementActions.setIsSendingFetching(true));
      const res = await sendOtpToCustomer(data, token, itemId);
      if (res?.status === 200) {
        dispatch(otpManagementActions.setNew(res));
        dispatch(otpManagementActions.setIsSendingFetching(false));
        notification.success({
          message: "OTP sent Successfully!, check your email",
        });
        return { type: true, data: res?.data };
      }
      dispatch(otpManagementActions.setIsSendingFetching(false));
      return { type: false, data: {} };
    } catch (err) {
      console.log(err);
    }
  };
};

export const verifyCustomerOtpAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(otpManagementActions.setIsFetching(true));
      const res = await VerifyCustomerOtpService(data, token);
      if (res?.status === 200) {
        dispatch(otpManagementActions.setIsFetching(false));
        notification.success({
          message: res?.message,
        });
        return { type: true, data: res?.data };
      }
      dispatch(otpManagementActions.setIsFetching(false));
      return { type: false, data: {} };
    } catch (err) {
      console.log(err);
    }
  };
};

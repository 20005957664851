import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "App Setttings",
  initialState: {
    selectedShopNumber: 0,
  },
  reducers: {
    setSelectedShopNumber(state, action) {
      state.selectedShopNumber = action.payload;
    },
  },
});

export const myAppSettings = mySlice.actions;

export default mySlice.reducer;

import React, { useState } from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";

const CustomerNoSaleTable = (props: any) => {
  const { customer } = useSelector((state: any) => state);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };
  const columns = [
    { title: "Type", dataIndex: "type" },
    { title: "Brand", dataIndex: "brand" },
    { title: "Model", dataIndex: "model" },
    { title: "Descriptions", dataIndex: "specs" },
  ];
  const data = props?.data?.map((el: any) => {
    return {
      key: el._id,
      type: el?.priceListItemId?.product?.type,
      brand: el?.priceListItemId?.product?.brand,
      model: el?.priceListItemId?.product?.model,
      specs: el?.priceListItemId?.product?.specs
        ?.map((spec: any) => {
          return `${spec.label}: ${spec?.value}`;
        })
        .join(", "),
      data: el,
    };
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <div className="w-full overflow-x-auto bg-white">
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        loading={customer?.isFetching}
      /></div>
    </>
  );
};

export default CustomerNoSaleTable;

import React, { useState } from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import ScrollableFrame from "../layout/ScrollableFrame";
import { GrFormView } from "react-icons/gr";
import StockForOneFreelancerTable from "./StockForOneFreelancerTable";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";

const StockForFreelancersTable = () => {
  const { pos, inventory } = useSelector((state: any) => state);
  const [viewDetails, setViewDetails] = useState(false);
  const [userId, setUserId] = useState<string>("");
  const [productId, setProductId] = useState<string>("");

  const handleViewDetails = (assigneeId: any, productId: any) => {
    setUserId(assigneeId);
    setProductId(productId);
    setViewDetails(true);
  };

  const handleBack = () => {
    setViewDetails(false);
  };

  const columns = [
    {
      title: "Model",
      key: "model",
      width: 150,
      render: (record: any) => (
        <p>{`${record?.item[0]?.item.product?.model}`}</p>
      ),
    },
    {
      title: "Descriptions",
      key: "descriptions",
      width: 200,
      render: (record: any) => (
        <div>
          {record?.item[0].item.product?.specs?.map((spec: any, index: any) => (
            <div key={index}>
              {spec?.label}: {spec?.value?.join(", ")}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Freelancer",
      key: "freelancer",
      width: 150,
      render: (record: any) => (
        <div>
          <div>{record?.assignee}</div>
        </div>
      ),
    },
    {
      title: "Available",
      key: "available",
      width: 100,
      render: (record: any) =>
        (record?.status?.toLowerCase() === "available" && record?.count) || 0,
    },
    {
      title: "Pending",
      key: "pending",
      width: 100,
      render: (record: any) =>
        (record?.status?.toLowerCase() === "pending" && record?.count) || 0,
    },
    {
      title: "Sold",
      key: "sold",
      width: 100,
      render: (record: any) =>
        (record?.status?.toLowerCase() === "sold" && record?.count) || 0,
    },
    {
      title: "Transfer",
      key: "transfer",
      width: 100,
      render: (record: any) =>
        (record?.status?.toLowerCase() === "transfer" && record?.count) || 0,
    },
    {
      title: "View",
      dataIndex: "view",
      width: 100,
      render: (text: any, record: any) => (
        <GrFormView
          size={25}
          color="#5F5AFE"
          className="cursor-pointer"
          onClick={() =>
            handleViewDetails(
              record?.assigneeId,
              record?.item[0]?.item?.requestedItem?.product?._id
            )
          }
        />
      ),
    },
  ];

  const stockData = inventory?.stockStatusCount?.data?.map((item: any) => ({
    ...item,
    key: item._id,
  }));



  return (
    <ScrollableFrame>
      {!viewDetails ? (
        <Table
          columns={columns}
          dataSource={stockData}
          size="middle"
          loading={pos?.isFetching}
          pagination={false}
        />
      ) : (
        <StockForOneFreelancerTable
          userId={userId}
          productId={productId}
          onBack={handleBack}
        />
      )}
    </ScrollableFrame>
  );
};

export default StockForFreelancersTable;

import React from "react";
import { Divider } from "@mui/material";

const ViewSubscriptionForm = (props: any) => {
  return (
    <div className="py-10">
      <figure className="flex justify-center h-fit">
        <img
          className="h-60 rounded-lg"
          src={props?.data?.product?.images[0]}
          alt={props?.data?.product?.model}
        />
      </figure>
      <div className="grid grid-cols-2 gap-y-4">
        <div className="font-semibold">Type</div>
        <div>{props?.data?.product?.type}</div>
        <div className="font-semibold">Brand</div>
        <div>{props?.data?.product?.brand}</div>
        <div className="font-semibold">Model</div>
        <div>{props?.data?.product?.model}</div>
      </div>
      <h1 className="py-2 font-bold text-lg">Subscription Plans</h1>
      {props?.data?.subscriptionPlan?.map((d: any) => (
        <div className="grid grid-cols-2 gap-y-2">
          <div className="font-semibold pt-2">Duration</div>
          <div className="pt-2">{d?.duration}</div>
          {d?.subscriptionType !== "B2B" && (
            <>
              <div className="font-semibold">Initial Payment</div>
              <div>{d?.initialPayment}</div>
            </>
          )}
          <div className="font-semibold">Monthly Instalment</div>
          <div>{d?.monthlyInstalment}</div>
          {d?.subscriptionType !== "B2B" && (
            <>
              <div className="font-semibold">Weekly Instalment</div>
              <div>{d?.weeklyInstalment}</div>
              <div className="font-semibold">Daily Instalment</div>
              <div>{d?.dailyInstalment}</div>
            </>
          )}
          <Divider />
        </div>
      ))}
    </div>
  );
};

export default ViewSubscriptionForm;

import { Divider, notification, Radio } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import {
  handlConfirmPaymentModelAction,
  handlePaymentStatusAction,
} from "../../redux/layout/actions";
import { useDispatch, useSelector } from "react-redux";
import { payWithMoMoAction } from "../../../../../store/pos/actions";
import { LoadingButton } from "@mui/lab";
import { subLayoutActions } from "../../redux/layout";
import { getMomoPayments } from "../../../../../utils/converter";

const CofirmPayment = (_props: any) => {
  const { wareHouse, auth, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [selectedNumber, setSelectedNumber] = useState("");
  const customerData = wareHouse?.createdCart?.data?.customer
  const customerPhoneNumbers = (phoneNumbers: any) => {
    if (
      !phoneNumbers ||
      !phoneNumbers.phone ||
      !Array.isArray(phoneNumbers.otherPhone)
    ) {
      return [];
    }
    return [
      {
        tel: phoneNumbers.phone,
        // isVerified: true,
        // isActive: true,
      },
      ...phoneNumbers.otherPhone,
    ];
  };

  const handlePaymentMethodChange = (e: any) => {
    setPaymentMethod(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const onChange = async (e: any) => {
    setSelectedNumber(e.target.value);
  };

  const confirmPayment = async (values: any) => {
    handlePaymentStatusAction("pending")(dispatch);
    dispatch(subLayoutActions.setIsCustomerPaid(false));
    const phone = selectedNumber?.replace(/\s/g, "");
    if (auth.token && phone) {
      const res = await payWithMoMoAction(
        auth.token,
        {
          phone: +phone,
          cartId: wareHouse?.createdCart?.data?.cartId,
        },
        "subscription"
      )(dispatch);
      if (res) {
        handlConfirmPaymentModelAction(true)(dispatch);
      } else {
        notification.error({
          message: "Something went wrong, Please try again.",
        });
      }
    }
    // handlConfirmPaymentModelAction(true)(dispatch);
  };

  useEffect(() => {
    handlePaymentStatusAction("pending")(dispatch);
  }, [dispatch]);
  const totalPayment = getMomoPayments(wareHouse)
    ?.map((payment: { amount: number }) => payment?.amount)
    ?.reduce((a: number, b: number) => a + b, 0);
  return (
    <div className="flex flex-col items-center justify-center bg-[#ffffff] overflow-y-auto py-6">
      <div className="md:h-96 2xl:h-full">
        <h2 className="text-center text-sm md:text-xl font-semibold">
          CONFIRM INITIAL PAYMENT <span className="font-semibold">{totalPayment?.toLocaleString() ?? null}</span>
        </h2>
        <Divider />
        <form onSubmit={handleSubmit}>
          <div className="mb-8">
            <p className="text-gray-700 mb-2 text-sm font-semibold">
              Choose Phone Number You're Going To Pay With
            </p>
            <Radio.Group
              onChange={onChange}
              value={selectedNumber}
              className="flex-col md:flex fflex-row 2xl:flex-col gap-x-2"
            >
              {customerPhoneNumbers(customerData).map((phone, idx) => (<>
                 {phone?.tel && <div className="flex flex-col border-2 border-gray-100 rounded-lg px-4 py-3 my-3">
                  <div className="flex flex-col gap-x-3">
                  <Radio value={phone?.tel}>{`+${phone?.tel}`}</Radio>
                  </div>
                </div>}</>
              ))}
            </Radio.Group>
          </div>
          <div className="grid md:grid-cols-2 mb-6 gap-x-6">
            <div>
              <p className="text-left text-gray-800 text-xs 2xl:text-sm pb-1">
                Or Add Another Phone Number
              </p>

              <div className="md:flex flex-col mt-5 md:mt-0">
                <label className="text-sm font-semibold pb-2">
                  Phone Number
                </label>
                <div className="flex gap-x-2">
                  <div className="flex  items-center space-x-2 w-full">
                    <PhoneInput
                      value={selectedNumber}
                      onChange={(value) => {
                        setSelectedNumber(value);
                      }}
                      country={"rw"}
                      buttonStyle={{
                        height: "45px",
                        width: "45px",
                        border: "1px solid #E5E7EB",
                      }}
                      inputStyle={{
                        height: "45px",
                        width: "100%",
                        border: "1px solid #E5E7EB",
                      }}
                      containerClass="phone-container"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p className="text-left text-gray-800 text-xs 2xl:text-sm pb-1 mt-5 md:mt-0">
                Choose Payment Method
              </p>
              <div className="">
                <label className="text-sm font-semibold">
                  Select payment method
                </label>
                <select
                  value={paymentMethod}
                  onChange={handlePaymentMethodChange}
                  className="w-full rounded-lg py-2 px-4 bg-white h-[2.8rem] outline-none mt-1.5 border-2"
                >
                  <option defaultValue={"momo"} value="momo">
                    MoMo
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="mx-auto flex items-center justify-center">
            <LoadingButton
              type="submit"
              variant="contained"
              className="w-[250px] h-[45px] text-lg uppercase"
              onClick={confirmPayment}
              disabled={selectedNumber === ""}
              loading={pos?.isFetching}
            >
              CONFIRM PAYMENT
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CofirmPayment;

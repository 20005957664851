import React, { useState } from 'react';
import { Button, Drawer } from 'antd';

interface drawerProps{
    component?:any;
    title?:any;
    openDrawer?:any;
    closeDrawer?:any;
    width?:any;

}
const CustomDrawer = ({component,title,closeDrawer,openDrawer,width}:drawerProps) => {

  return (
    <>
      <Drawer title={title} onClose={closeDrawer} open={openDrawer} width={width}>
        {component}
      </Drawer>
    </>
  );
};

export default CustomDrawer;
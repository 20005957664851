import React from "react";

const CustomSwitch: React.FC<{
  handleChange: (event: any) => void;
  activeTab: number;
  handleTabClick: (tabNumber: number) => void;
}> = ({ handleChange, activeTab, handleTabClick }) => {
  return (
    <div>
      <div className="flex border-b border-gray-300">
        <button
          className={`px-4 py-2 ${
            activeTab === 1 ? "bg-gray-200" : "bg-white"
          }`}
          onClick={() => handleTabClick(1)}
        >
          Individual
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === 2 ? "bg-gray-200" : "bg-white"
          }`}
          onClick={() => handleTabClick(2)}
        >
          Business
        </button>
      </div>
    </div>
  );
};

export default CustomSwitch;

import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "WareHouse States",
  initialState: {
    isFetching: false,
    repairSearchResult: null,
    repairSearchError: null,
    repairRequest: null,
    all: { data: [] },
    selected: null,
    selectedRepair: null,
    repairOverView:null,
    recentActivities:null
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },

    setRepairSearchResult(state, action) {
      state.repairSearchResult = action.payload;
    },

    setRepairSearchError(state, action) {
      state.repairSearchError = action.payload;
    },
    setRepairRequest(state, action) {
      state.repairRequest = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setAll(state, action) {
      state.all = action.payload;
    },
    setSelectedRepair(state, action) {
      state.selectedRepair = action.payload;
    },
    setRepairOverView(state, action) {
      state.repairOverView = action.payload;
    },
    setRecentActivities(state, action) {
      state.recentActivities = action.payload;
    },
  },
});

export const myRepairActions = mySlice.actions;

export default mySlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "dashboard",
  initialState: {
    isFetching: false,
    all: { data: [] },
    salesStats: {},
    revenueStats: {},
    selected: null,
    hourRevenueStats: null,
    paymentMethodStats: null,
    revenueByShopStats: null,
    revenueByAgentStats: null,
    statsByBrand: null,
    regionStats: null,
    quantitySoldByBrandStats: null,
    quantitySoldByModelStats: null,
    productsByBrand: null,
    piecesSoldByProductType: null,
    dataActivetion: null,
    pendingDevices: null,
    nosale: null,
    overviewStats:null
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSalesStats(state, action) {
      state.salesStats = action.payload;
    },
    setAllRevenueStats(state, action) {
      state.revenueStats = action.payload;
    },
    setDaySalesStats(state, action) {
      state.hourRevenueStats = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setPaymentMethodStats(state, action) {
      state.paymentMethodStats = action.payload;
    },
    setRevenueStats(state, action) {
      state.revenueByShopStats = action.payload;
    },
    setRevenueAgentStats(state, action) {
      state.revenueByAgentStats = action.payload;
    },
    setStatsByBrand(state, action) {
      state.statsByBrand = action.payload;
    },
    setRegionStats(state, action) {
      state.regionStats = action.payload;
    },
    setQntyByBrandStats(state, action) {
      state.quantitySoldByBrandStats = action.payload;
    },
    setQntyByModelStats(state, action) {
      state.quantitySoldByModelStats = action.payload;
    },
    setProductsByBrand(state, action) {
      state.productsByBrand = action.payload;
    },
    setSalesByProductType(state, action) {
      state.piecesSoldByProductType = action.payload;
    },
    setDataActivetion(state, action) {
      state.dataActivetion = action.payload;
    },
    setPendingDevices(state, action) {
      state.pendingDevices = action.payload;
    },
    setNosale(state, action) {
      state.nosale = action.payload;
    },
    setOverviewStats(state, action) {
      state.overviewStats = action.payload;
    }
  },
});

export const myDashboardActions = mySlice.actions;

export default mySlice.reducer;

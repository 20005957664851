import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";


export const getAllSalesReportService = async (token: string, shop:string ) => {
  return HttpRequest.get(`${SERVER_URL}/cartItem/shopReport${shop}`, token);
};

export const SalesReportTable = async (token: any, itemId: string) => {
  return HttpRequest.get(`${SERVER_URL}/cartItem/transactionRange${itemId}`, token);
};

export const updatewarehouseDataActivationService = async (
  token: any,
  id: any,
  data: any
) => {
  return HttpRequest.update(`${SERVER_URL}/warehouse/one/${id}`, data, token);
};
import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "applicants",
  initialState: {
    isFetching: false,
    customFormIsFetching: false,
    new: null,
    updated: null,
    customerSignature: null,
    customerImage: null,
    customerNationaID: null,
    customForm: null,
    allApplicants: null,
    applicantDetails:null,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setCustomerSignature(state, action) {
      state.customerSignature = action.payload;
    },

    setCustomerImage(state, action) {
      state.customerImage = action.payload;
    },

    setCustomerNationaID(state, action) {
      state.customerNationaID = action.payload;
    },
    setCustomForm(state, action) {
      state.customForm = action.payload;
    },
    setCustomFormIsFetching(state, action) {
      state.customFormIsFetching = action.payload;
    },
    setAllApplicants(state, action) {
      state.allApplicants = action.payload;
    },
    setApplicantsDetails(state, action) {
      state.applicantDetails = action.payload;
    },
  },
});

export const applicantsActions = mySlice.actions;

export default mySlice.reducer;

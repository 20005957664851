export const comments = [
  "No Inventory",
  "Unkown",
  "Eligible for Macye Macye",
  "Not Eligible - New Sim Card User (Under 6 Months)",
  "Not Eligible - Low Traffic on Momo",
  "Not Eligible - Less Initial Payment (7100)",
  "Not Eligible - Macye Macye System Failure",
  "Not Eligible - CRB Blacklisted",
  "Not Eligible - Over Aged (65 and Above)",
  "Not Eligible - Other",
  "CRB issue",
  "Pending on BK side",
  "Pending on intelligra side",
  "Customer doesn't have enough money",
  "Customer will come back another day",
  "Customer was just looking for information",
  "The device is too expensive for the customer",
];

export const paymentOptions = [
  {
    value: "MoMo_Pay",
    label: "MoMo Pay",
  },
  {
    value: "Macye_Macye",
    label: "Macye Macye",
  },
  {
    value: "Intelligra_Insurance",
    label: "Intelligra Insurance",
  },
  {
    value: "Vuba_Vuba",
    label: "Vuba Vuba",
  },
  {
    value: "Samphone_POS",
    label: "Samphone POS",
  },
  {
    value: "MTN_POS",
    label: "MTN POS",
  },
  {
    value: "Samphone_Bank_Deposit",
    label: "Samphone Bank Deposit",
  },
  {
    value: "MTN_Bank_Deposit",
    label: "MTN Bank Deposit",
  },
  {
    value: "Corporate_Sales",
    label: "Corporate Sales",
  },
  {
    value: "MTN_Staff_Device_Requisition",
    label: "MTN Staff Device Requisition",
  },
  {
    value: "Yello_Payment",
    label: "Yello Payment",
  },
  {
    value: "Samphone_Staff_Handset_Requisition",
    label: "Samphone Staff handset Requisition",
  },
];

export const hasMacyemacyeOption = [
  "Macye_Macye",
  "Vuba_Vuba",
  "Intelligra_Insurance",
  "Corporate_Sales",
  "Yello_Payment",
  "Samphone_Staff_Handset_Requisition",
];

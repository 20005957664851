import axios from "axios";

class HttpRequest {
  static async get(url: string, token?: string) {
    try {
      const res = await axios({
        method: "GET",
        url,
        headers: {
          authToken: `${token}`,
        },
      });
      return res.data;
    } catch (err: any) {
      return err;
    }
  }

  static async post(url: string, data: any, token?: string) {
    try {
      const res = await axios({
        method: "POST",
        url,
        headers: {
          authToken: `${token}`,
        },
        data,
      });

      return res.data;
    } catch (err: any) {
      console.log(err);
      return err;
    }
  }

  static async delete(url: string, token: string, data?: any) {
    try {
      const res = await axios({
        method: "DELETE",
        url,
        headers: {
          authToken: `${token}`,
        },
        data,
      });

      return res.data;
    } catch (err: any) {
      console.log(err.response);
      return err;
    }
  }

  static async update(url: string, data: any, token: string) {
    try {
      const res = await axios({
        method: "PATCH",
        url,
        headers: {
          authToken: `${token}`,
        },
        data,
      });

      return res.data;
    } catch (err: any) {
      return err;
    }
  }
}

export default HttpRequest;

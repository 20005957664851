import React from "react";
import { Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { MinusCircleOutlined } from "@ant-design/icons";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const OtherPhoneNumberForm = (props: any) => {
  const { wareHouse } = useSelector((state: any) => state);

  return (
    <>
      <h1 className="text-base font-medium text-blue-400">
        Other Phone Number
      </h1>
      <Form.List
        name="otherPhone"
        {...formItemLayout}
        initialValue={
          wareHouse?.createdCart?.data?.customer &&
          wareHouse?.createdCart?.data?.customer?.otherPhone?.length !== 0
            ? [...wareHouse?.createdCart?.data?.customer?.otherPhone]
            : [{}]
        }
      >
        {(fields, { add, remove }) => {
          const addButtonDisabled = fields.length >= 3;
          return (
            <div className="grid grid-cols-2 2xl:grid-cols-3 gap-4">
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} className="flex">
                  <Form.Item
                    className="xmr-6 w-full"
                    {...restField}
                    name={[name, "tel"]}
                    label={
                      <span className="text-[#0F0F47] text-base font-medium">
                        Phone Number
                      </span>
                    }
                    initialValue={props?.dataToUpdate?.phone}
                  >
                    <PhoneInput
                      country={"rw"}
                      buttonStyle={{
                        height: "40px",
                        border: "1px solid #E5E7EB",
                      }}
                      inputStyle={{
                        height: "40px",
                        width: "100%",
                        border: "1px solid #E5E7EB",
                      }}
                      containerClass="phone-container"
                    />
                  </Form.Item>

                  {key >= 1 && (
                    <div className="flex flex-row items-start mt-9 ml-1 gap-2">
                      <Button
                        onClick={() => remove(name)}
                        className="shadow !m-0 justify-between !items-center h-8"
                      >
                        <MinusCircleOutlined className="my-auto text-red-500" />
                      </Button>
                    </div>
                  )}
                </div>
              ))}
              {fields.length !== 3 && (
                <div className="2xl:mt-9">
                  <Form.Item>
                    <div className="flex items-center gap-x-3">
                      <Button
                        onClick={() => !addButtonDisabled && add()}
                        className="shadow !m-0 justify-between !items-center text-[#2943D6] h-8"
                        disabled={
                          wareHouse?.createdCart?.data?.isSubscriptionSubmitted
                        }
                      >
                        <PlusOutlined />
                      </Button>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
          );
        }}
      </Form.List>
    </>
  );
};
export default OtherPhoneNumberForm;

import React from "react";
import { useSelector } from "react-redux";

const TermsAndConditionKinyarwanda = () => {
  const { wareHouse, auth, subCustomer, company } = useSelector(
    (state: any) => state
  );
  const customerData = wareHouse?.createdCart?.data?.customer;
  const today = new Date().toISOString().split("T")[0];
  return (
    <div>
      <h1 className="text-[#0F0F47] texte-base sm:text-lg font-bold capitalize text-center underline">
        Amasezerano yo kugurisha mu byiciro hamwe n'amategeko n'amabwiriza
        abigenga
      </h1>
      <p className="text-[#030229] text-sm sm:text-lg pt-5">
        Iyi nyandiko ikubiyemo amasezerano yo kugurisha akozwe hagati ya{" "}
        {company?.selected?.name} Digital Retailers Ltd. ifite{" "}
        {wareHouse?.createdCart?.data?.cartId}, 112620773,( ivugwa muri aya
        masezerano nk'ugurisha ) n' umuguzi {company?.selected?.name} ( uvugwa
        muri aya masezerano nk'umukiriya cg umuguzi ) :
      </p>
      {/* <Table
                columns={customerColumns}
                dataSource={customerToDisplay}
                pagination={false}
                bordered
              /> */}
      <div className="grid px-4 md:grid-cols-3 gap-5 border-solid border-2 md:border-4 text-[#030229] text-sm md:text-base border-black mt-5">
        <div className="grid grid-cols-2 gap-5 px-2">
          <p>Izina ryuzuye ryumuguzi</p>
          <p className=" md:border-x-2 border-black">{customerData?.name}</p>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <p>Inomero y'irangamuntu </p>
          <p className="md:border-x-2 border-black">
            {customerData?.nationalId}
          </p>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <p>Nimero yibicuruzwa</p>{" "}
          <p className="md:border-x-2 border-black">
            {
              wareHouse?.createdCart?.data?.list[0]?.warehouseProduct
                ?.serialNumber
            }
          </p>
        </div>
      </div>
      <div className="">
        <div className="divides-y dividde-black ">
          <h1 className="text-base sm:text-[22px] text-center  font-bold uppercase p-s10"></h1>
          <section className="text-sm sm:text-base ">
            <div className="mt-5">
              <h1 className="text-[#030229]] text-sm  my-2">
                Ibyumvikanyweho:
              </h1>
              <ol
                className="list-decimal"
                type="A"
                style={{ listStyle: "display" }}
              >
                <li className="mt-3">
                  <b>Igurishwa ryibicuruzwa.</b>
                  <span className="text-sm">
                    Bijyendeye ku bw’umvikane n’Ugurisha, Umuguzi yemeye
                    "Igicuruzwa" gitangwa n’ugurisha kandi akemera ko yakuye
                    igicuruzwa ku ugurisha maze bagirana amasezerano y’ubugure.
                  </span>
                </li>
                <li className="mt-3">
                  <b>Kwiyemeza kwishyura Igiciro.</b>
                  <span className="text-sm">
                    Umuguzi yahisemo kuriha igicuruzwa mu buryo bw’ibyiciro
                    bityo yiyemeza kwishyura Igiciro cy’ajyenwe na Samphone
                    Rwanda aho kwishyura igiciro gisanzwe . Umuguzi yiyemeje
                    kandi kwishyura:
                  </span>
                  <ol
                    type="A"
                    style={{ listStyle: "display", marginLeft: "20px" }}
                  >
                    <li className="mt-3">
                      Kwishura mbere mbere yo kwemererwa Igicuruzwa ( “Kubitsa”)
                      ; na
                    </li>
                    <li className="mt-3">
                      Igiciro gito ntarengwa nkuko byajyenwe (mu "Igihe cyo
                      Kwishyuriraho"), kugeza Igiciro cyubuguzi cyishyuwe
                      byuzuye.
                    </li>
                  </ol>
                </li>
                <li className="mt-3">
                  <b>Kwishyura.</b>
                  <span className="text-sm">
                    Umuguzi yemera ko kwishyura kose kuzaba hakoreshejwe
                    kohereza kuri Mobile money. Nta bundi buryo bwo kwishyura
                    buzemerwa. Ugurisha ntazaryozwa igihombo cyangwa
                    ibyangiritse byatewe n’umuguzi yishyura umuntu cyangwa
                    isosiyete binyuze muburyo butandukanye nubwavuzwe haruguru.
                  </span>
                </li>
                <li className="mt-3">
                  <b>Kunanirwa kwishyura.</b>
                  <span className="text-sm">
                    Niba umuguzi yananiwe kubahiriza ibyo asabwa cyangwa
                    yananiwe kwishyura byibuza ubwishyu buto bwajyenwe muri aya
                    masezerano hazabaho:
                  </span>
                  <ol
                    type="A"
                    style={{ listStyle: "display" }}
                    className="ml-10"
                  >
                    <li className="mt-3">
                      <b>Gufungirwa igikoresho:</b>
                      <span className="text-sm">
                        buri munsi washyuye ukererewe bizavamo gukoresha
                        terefone iminsi micye, eg. niba umuguzi abuze ubwishyu
                        muminsi 2, agatanga ubwishyu bw’iminsi 7, azahabwa
                        iminsi 5 gusa iyo yishyuye;
                      </span>
                    </li>
                    <li className="mt-3">
                      <b>Kwishyura ukererewe:</b>
                      <span className="text-sm">
                        each day missed will result in a phone use days lost,
                        eg. if the Buyer misses a payment for 2 days, and makes
                        a payment worth 7 days, they will only be awarded 5 days
                        when they pay;
                      </span>
                    </li>
                    <li className="mt-3">
                      <b>Kwamburwa igikoresho:</b>
                      <span className="text-sm">
                        niba umuguzi arengeje iminsi 14 ku gihe cye cyo
                        kwishyura (“niba yishyura mu byiciro”), Umuguzi yiyemeje
                        kuzasubiza igicuruzwa nkuko yagihawe byumwimerere
                        muminsi itarenze 3 . Umuguzi ntazasubizwa amafaranga
                        yishyuye mbere.,bityo umuguzi akemera kwishyura Igiciro
                        gisanzwe cy’icyo gikoresho;
                      </span>
                    </li>
                    <li className="mt-3">
                      <b>Uruhare rwa Polisi / Umuyobozi w'Umudugudu:</b>
                      <span className="text-sm">
                        niba Umuguzi atinze kwishyura kandi akaba atarasubije
                        igikoresho mu buryo bwemewe kandi no mu gihe cyagenwe,
                        Ugurisha azitabaza inzego z'ubuyobozi bw’ibanze na
                        polisi kugira ngo hubahirizwe ayamasezerano.
                      </span>
                    </li>
                    <li className="mt-3">
                      <b>CRB:</b>
                      <span className="text-sm">
                        mugihe umuguzi ananiwe kwishura no gusubiza igikoresho
                        yaguze, ugurisha afite uburenganzira busesuye bwo ku
                        mwandikisha igihe icyo aricyo cyose muri credit
                        reference bureau (CRB) akamenyekanisha amafaranga
                        umuguzi amusigayemo.
                      </span>
                    </li>
                  </ol>
                </li>
                <li className="mt-3">
                  <b>Uburenganzira ku gikoresho.</b>
                  <span className="text-sm">
                    gikoresho. Uburenganzira kugicuruzwa buhabwa umuguzi kumunsi
                    yashyikirijweho igikoresho. Nyamara, Samphone Rwanda
                    isigarana uburenganzira bwo kwegukana igicuruzwa bivuye ku
                    ruhare rw’umuguzi mugihe umuguzi atubahirije amasezerano.
                  </span>
                </li>
                <li className="mt-3">
                  <b>Ubujura / Ibyangiritse / Gutakaza. Mugihe</b>
                  <span className="text-sm">
                    Gutakaza. Mugihe Igikoresho cyibwe, cyangiritse cyangwa
                    cyatakaye, Umuguzi akomeza kwishyura Igiciro cyuzuye hamwe
                    nuburyo bwose bwo kwishyura mugihe atari yarangiza kwishyura
                    igiciro cyose cyajyenwe. Mugihe habaye ubujura, umuguzi
                    yiyemeje kubimenyesha abapolisi muminsi itarenze ibiri
                    kugirango igikoresho gishakishwe.
                  </span>
                </li>
                <li className="mt-3">
                  <b>
                    Kujyira icyo uhindura cyangwa gufungura igikoresho
                    utabyemerewe.
                  </b>
                  <span className="text-sm">
                    Umuguzi yiyemeje kudashyira ingufu mu gufungura igikoresho
                    akoresheje: “ flashing, tamping”, gusimbuza igice cyangwa
                    ibindi. Niba umuguzi agerageje gufungura cyangwa guhindura
                    igicuruzwa muburyo ubwo aribwo bwose usibye kwishyura,{" "}
                    <u>Umuguzi azaregwa mu mategeko.</u> Umuguzi yiyemeje
                    kurinda igicuruzwa mu bryo ubwo aribwo bwose.
                  </span>
                </li>
                <li className="mt-3">
                  <b>Kugurisha igikoresho ntibyemewe.</b>
                  <span className="text-sm">
                    Umuguzi ntashobora kugurisha igikoresho kugeza igiciro cyose
                    cyubuguzi cyishyuwe. Niba Umuguzi agerageje kugurisha
                    igikoresho mbere yuko igiciro cyubuguzi cyishyurwa,azajyanwa
                    mu nkiko.
                  </span>
                </li>
                <li className="mt-3">
                  <b>Urutonde rw’abahemu.</b>
                  <span className="text-sm">
                    Ugurisha azakurikirana ubwishyu bw’umuguzi. Mugihe umuguzi
                    ahora arenga ku mategeko, kandi / cyangwa ntasubize
                    igicuruzwa nkuko bisabwa k’umucuruzi, Umuguzi azashyirwa
                    kurutonde rwumukara cg rw’abahemu, kandi azabuzwa kugera
                    kubindi bicuruzwa bitangwa n’umucuruzi.
                  </span>
                </li>
                <li className="mt-3">
                  <b>Garanti.</b>
                  <span className="text-sm">
                    Mu gihe cya 12 amezi (“Igihe cya garanti”) gikurikira
                    umukono w'aya masezerano, Ugurisha azasana cyangwa asimbuze
                    igicuruzwa binyuze muri repair and maintenance Service
                    Centre hubahirijwe ibi bikurikira:
                  </span>
                  <ol
                    type="A"
                    style={{ listStyle: "display" }}
                    className="ml-10"
                  >
                    <li className="pt-3">
                      Umuguzi amenyesha Ugurisha mugihe cya garanti inenge
                      y’igicuruzwa;
                    </li>
                    <li className="pt-3">
                      Mugihe cyo kumenyeshwa, Umuguzi agomba kuba yishyura neza
                      amafaranga mu byiciro byo kwishyura nkuko aya masezerano
                      abiteganya;
                    </li>
                    <li className="pt-3">
                      Inenge y’igicuruzwa ni ikosa ry’uruganda kandi ntabwo
                      byemewe ko wakoresha nabi igikoresho witwaje ko gifite
                      inenge iyo ariyo yose.
                    </li>
                  </ol>
                </li>
                <li className="mt-3">
                  <b>Umusoro ku nyongeragaciro.</b>
                  <span className="text-sm">
                    Ugurisha atanga ibicuruzwa bye ku babakiriya muburyo
                    "bw’inguzanyo yishyurwa mu byiciro ", ibicuruzwa cyangwa
                    serivisi bifatwa nkibikurikiranwa mugihe cyamasezerano. [
                    Kubera iyo mpamvu, igihe cyo gutanga ibicuruzwa cyangwa
                    serivisi bisuzumwa hakurikijwe ingingo y’umusoro ku
                    nyongeragaciro u Rwanda].
                  </span>
                </li>
                <li className="mt-3">
                  <b>Amategeko agenga aya masezerano.</b>
                  <span className="text-sm">
                    Aya masezerano agengwa n'amategeko ya Repubulika y'u Rwanda.
                  </span>
                </li>
              </ol>
              <div className="grid md:grid-cols-2">
                <span className="py-2">Itariki y’umunsi: {today}</span>
                <span className="py-2">Umukono w’umuguzi: </span>
              </div>
              <div className="grid md:grid-cols-3">
                <span>Izina ry'umucuruzi: {company?.selected?.name} </span>
                <span className="py-3">Umukono w’umucuruzi: </span>
                <span>Numero y’umucuruzi: {company?.selected?.phone}</span>
              </div>
            </div>

            {/* <TermsAndConditionContent /> */}
          </section>
        </div>

        <div>
          <h1 className="text-base sm:text-lg font-bold capitalize py-5 ">
            Copy of National Id
          </h1>
          <div className="rounded-md">
            <img
              src={customerData?.subscriptionInfo?.nationalIDDoc}
              alt="National Id"
              className="object-cover w-[320px] h-32 rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionKinyarwanda;

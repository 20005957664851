import React from "react";
import DetailsHeaderActionBar from "../DetailsPage/DetailsHeaderActionBar";
import TransactionCartItem from "./TransactionCartItem";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllByTransactionIdAction } from "../../../store/pos/actions";

const ViewTransaction = () => {
  const { pos, auth } = useSelector((state: any) => state);
  const { cartId } = useParams();
  console.log("iddd", cartId);
  const dispatch = useDispatch();
  React.useEffect(() => {
    auth?.token &&
      getAllByTransactionIdAction(auth?.token, `${cartId}`)(dispatch);
  }, [auth]);

  return (
    <div className="text-black">
      <DetailsHeaderActionBar
        pageName="POS"
        title={pos?.selectedTransaction?.data?.cartId}
      />
      <div className="flex space-x-2">
        <div className="flex-1 grid grid-cols-2 bg-white p-6 mt-2">
          <div className="space-y-5">
            <div>
              <p>Customer Info</p>
              <div className="w-16">
                <img
                  src={
                    pos?.selectedTransaction?.data?.customer?.picture
                      ? pos?.selectedTransaction?.data?.customer?.picture
                      : "https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236__340.png"
                  }
                  width={"100%"}
                  alt=""
                  className="rounded-lg"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 w-fit">
              <p className="font-semibold">Name:</p>
              <span>{pos?.selectedTransaction?.data?.customer?.name}</span>
              <p className="font-semibold">Phone:</p>
              <span>{pos?.selectedTransaction?.data?.customer?.phone}</span>
              <p className="font-semibold">Email:</p>
              <span>{pos?.selectedTransaction?.data?.customer?.email}</span>
            </div>
            <div>
              <p className="font-bold">Payment Methods</p>
              <div className="grid grid-cols-2 w-fit gap-x-5">
                {pos?.selectedTransaction?.data?.payment?.map(
                  (payment: any, _index: number) => (
                    <>
                      <p className="font-semibold">{payment?.mode}:</p>
                      <p>{payment?.amount}</p>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
          <div>
            <p>
              <span className="font-semibold">Cart ID: </span>{" "}
              {pos?.selectedTransaction?.data?.cartId}
            </p>
            <TransactionCartItem
              itemsList={pos?.selectedTransaction?.data?.list}
              discount={pos?.selectedTransaction?.data?.discount}
            />
          </div>
        </div>
        {/* <div className="w-96 p-2 mt-2 bg-white rounded-lg">
          <div></div>
        </div> */}
      </div>
    </div>
  );
};

export default ViewTransaction;

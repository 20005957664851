import React, { useEffect, useState } from "react";
import { Form, Divider, Button, Input, Select } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getShopStockCartAction, updateShopStockCartDetailsAction } from "../../../../store/wareHouse/actions";
import { handleNextStepAction } from "../../../device_financing/Subscription/redux/layout/actions";
import OtherPhoneNumberForm from "./OtherPhoneNumberForm";
import PersonalDataForm from "./PersonalDataForm";
import { Progressbar } from "../../../device_financing/Subscription/components/Progressbar";
import FinancialDetails from "./FinancialDetails";
import AddressDetailsForm from "./AddressDetailsForm";
import WitnessForm from "./WitnessForm";
import { ProgressInfobar } from "../ProgresssInfoBar";
import { createCustomerAction, updateCustomerAction } from "../../redux/businessPlans/action";
import { subLayoutActions } from "../../../device_financing/Subscription/redux/layout";

const PersonnelDetailsForm = (props: any) => {
  const { wareHouse, auth, subCustomer, applicant } = useSelector(
    (state: any) => state
  );

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { cartId } = useParams();
  const [loading,setIsLoading]=useState(false);
  const [selectedDate, setSelectedDate] = useState<any>();
  const businessProductId = wareHouse?.createdCart?.data?.businessProduct?._id;
  const customForm =
    subCustomer?.customForm?.length > 0
      ? subCustomer?.customForm?.at(0)?.fields
      : [{}];
  const userImage =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
    const addressData =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;
    const incomeFrequency =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.incomeFrequency;
  const mainIncomeSource =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.mainIncomeSource;
  const monthlyIncome =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.monthlyIncome;
  const ownsSmartphone =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.ownsSmartphone;
  const occupationOrBusinessType =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.occupationOrBusinessType;
    const witnessInfoData =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo;
    const dateofbirth=wareHouse?.createdCart?.data?.customer?.dateOfBirth;
  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer) {
      form.setFieldsValue(wareHouse?.createdCart?.data?.customer);
    } else {
      form.resetFields();
    }
  }, [form, wareHouse?.createdCart?.data?.customer]);
  
const handleDiscard=()=>{
  dispatch(subLayoutActions.setNewCustomer(false));
}
  const onFinish = async (values: any) => {
    setIsLoading(true)
    const name = `${values?.firstName}  ${values?.lastName}`;
    const payload = {
      ...values,
      name,
      dateOfBirth:selectedDate,
      subscriptionInfo: {
        ...values,
        dateOfBirth:selectedDate ?? dateofbirth,
        incomeFrequency:incomeFrequency,
        mainIncomeSource:mainIncomeSource,
        monthlyIncome:monthlyIncome,
        ownsSmartphone:ownsSmartphone,
        occupationOrBusinessType:occupationOrBusinessType,
        witnessInfo: witnessInfoData,
        nationalIDDoc: nationalId,
        photoCustomerHoldingID: userImage,
      },
    };
    if (wareHouse?.createdCart?.data?.customer?._id && auth?.token) {
      const res = await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        payload
      )(dispatch);
      if (res) {
        await getShopStockCartAction(auth?.token, cartId)(dispatch);
        await handleNextStepAction(1)(dispatch);
      }
    } else {
      const response = await createCustomerAction(
        auth?.token,
        payload
      )(dispatch);
      if (response?.type) {
        const res = await updateShopStockCartDetailsAction(
          auth?.token,
          cartId,
          {
            customer: response?.data?._id,
          }
        )(dispatch);
        if (res) {
          await handleNextStepAction(1)(dispatch);
          form.resetFields();
          setIsLoading(false)
        }else{
          setIsLoading(false)
        }
      }else{
        setIsLoading(false)
      }
    }
  };

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer) {
      form.setFieldsValue(wareHouse?.createdCart?.data?.customer);
    } else if (wareHouse?.createdCart?.data?.customer?.subscriptionInfo) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      );
    } else if (
      wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
    ) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
      );
    } else {
      form.resetFields();
    }
  }, [
    form,
    wareHouse?.createdCart?.data?.customer,
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo,
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address,
  ]);

  const initialData = {
    ...wareHouse?.createdCart?.data?.customer,
    ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address,
    ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo,
    secondaryPhone:
      wareHouse?.createdCart?.data?.customer?.otherPhone?.at(0)?.tel,
    firstName: wareHouse?.createdCart?.data?.customer?.name?.split(" ")[0],
    lastName: wareHouse?.createdCart?.data?.customer?.name?.slice(
      wareHouse?.createdCart?.data?.customer?.name?.split(" ")[0]?.length
    ),
  };

  // useEffect(() => {
  //   if (auth?.token && businessProductId)
  //     getCustomFormAction(
  //       `?businessProduct=${businessProductId}`,
  //       auth?.token
  //     )(dispatch);
  // }, [auth?.token, dispatch, businessProductId]);

  return (
    <>
      <div className="h-[82vh] bg-white p-2 rounded-lg overflow-y-auto">
        <ProgressInfobar name={"Personal Details"} percentage={10} />
        <h1 className="text-base font-medium">Customer Details</h1>
        <Form
          form={form}
          name="register"
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialData || {}}
          scrollToFirstError
          className="xantd-custom-style space-y-4 capitalize xbg-green-200"
        >
          <PersonalDataForm dataToUpdate={props?.dataToUpdate?.phone} setSelectedDate={setSelectedDate}/>
          <OtherPhoneNumberForm />
          <Divider />
          <div className="pb-4 flex justify-end space-x-4">
            <Button
              danger
              onClick={() => handleDiscard()}
              className="pb-8"
            >
              Discard
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              style={{
                padding: "10px",
              }}
              loading={loading|| wareHouse?.isUpdateFetching}
              disabled={loading || wareHouse?.isUpdateFetching}
            >
              {"Save and Continue"}
            </LoadingButton>
          </div>
        </Form>

      </div>
    </>
  );
};

export default PersonnelDetailsForm;

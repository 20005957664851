import { Checkbox, Divider, Form, notification } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReloadOutlined } from "@ant-design/icons";
import "./customStyle.css";
import { LoadingButton } from "@mui/lab";
import { handleNextStepAction } from "../redux/layout/actions";
import { InputOTP } from "antd-input-otp";
import { verifyCustomerOtpAction } from "../redux/otpManagement/actions";
import { useParams } from "react-router-dom";
import { getShopStockCartAction } from "../../../../store/wareHouse/actions";

const ConfirmOTP = (props: any) => {
  const { wareHouse, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [otpValue, setOtpValue] = useState(null);
  const customerData = wareHouse?.createdCart?.data?.customer;

  const isOtherPhoneVerified = (customerData: any) => {
    if (!customerData || !Array?.isArray(customerData?.otherPhone)) {
      return false;
    }
    return customerData?.otherPhone?.every(
      (phone: any) => phone?.isVerified === true
    );
  };

  const handleConfirmOtp = async () => {
    if (isOtherPhoneVerified(customerData)) {
      handleNextStepAction(2)(dispatch);
    } else {
      if (otpValue === null || selectedNumber === null) {
        return notification.error({
          message:
            "Please select a phone number to verify and ensure the OTP field is filled in!",
        });
      }
      const res = await verifyCustomerOtpAction(auth?.token, {
        code: otpValue,
        phoneNumber: selectedNumber,
      })(dispatch);
      if (res?.type) {
        setSelectedNumber(null);
        setOtpValue(null);
        getShopStockCartAction(auth?.token, cartId)(dispatch);
      }
    }
  };

  const customerPhoneNumbers = (phoneNumbers: any) => {
    if (
      !phoneNumbers ||
      !phoneNumbers.phone ||
      !Array.isArray(phoneNumbers.otherPhone)
    ) {
      return [];
    }
    return [
      {
        tel: phoneNumbers.phone,
        isVerified: true,
        isActive: true,
      },
      ...phoneNumbers.otherPhone,
    ];
  };

  const onChange = async (e: any) => {
    setSelectedNumber(e.target.value);
  };
  const [form] = Form.useForm();

  const handleOTPChange = (otpArray: any) => {
    const otpString = otpArray.join("");
    setOtpValue(otpString);
  };

  const handleBack = () => {
    setSelectedNumber(null);
    setOtpValue(null);
    props?.onCancel();
  };

  return (
    <>
      <div className="flex justify-center items-center h-full rounded-md capitalize w-full">
        <div className="bg-white px-4 py-4 space-y-2 w-full h-auto text-black">
          <div className="flex justify-center font-poppins text-base font-semibold uppercase">
            Confirm with OTP
          </div>
          <Divider />
          <div className="w-[400px] mx-auto">
            <div className="font-poppins text-base">Select phone number</div>

            {customerPhoneNumbers(customerData)?.map((number: any) => (
              <div className="flex justify-between border border-gray-100 rounded-md px-4 py-2 my-3">
                <div className="flex items-center gap-x-3">
                  <Checkbox
                    onChange={onChange}
                    disabled={number?.isVerified}
                    className="custom-checkbox"
                    value={number?.tel}
                  />

                  <span className="text-gray-500">{number?.tel}</span>
                </div>
                <span
                  className={`${
                    number?.isVerified ? "text-green-500" : "text-gray-500"
                  }`}
                >
                  {number?.isVerified ? "Confirmed" : "Not Confirmed Yet"}
                </span>
              </div>
            ))}
            <div className="mb-2 text-start flex flex-col justify-start items-start">
              <h1 className="text-base py-2">Confirmation code</h1>
              <Form form={form}>
                <Form.Item name="otp">
                  <InputOTP
                    autoSubmit={form}
                    inputType="numeric"
                    length={5}
                    onChange={handleOTPChange}
                  />
                </Form.Item>
              </Form>
            </div>

            <button
              className="text-[#5F5AFE] cursor-pointer items-center gap-x-2 flex flex-row"
              // onClick={handleResend}
            >
              <ReloadOutlined />
              <span>Sendagain</span>
            </button>
          </div>

          <div className="flex justify-center pt-[50px] gap-10">
            <LoadingButton
              variant="outlined"
              onClick={handleBack}
              className="w-[150px] h-[45px] text-lg uppercase"
            >
              Back
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={handleConfirmOtp}
              className="w-[150px] h-[45px] text-lg uppercase"
            >
              {isOtherPhoneVerified(customerData) ? "Continue" : "Verify"}
            </LoadingButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmOTP;

import React from "react";
import { Popover } from "antd";
import { Button } from "@mui/material";

const App: React.FC<{
  title?: any;
  open?: any;
  handleOpen?: any;
  content?: any;
  icon?: any;
  disable: boolean;
}> = ({ title, open, handleOpen, content, icon, disable }) => {
  return (
    <Popover
      content={content}
      title={title}
      trigger="click"
      open={open}
      onOpenChange={handleOpen}
    >
      <Button disabled={disable} color="primary">
        {icon ?? "Button"}
      </Button>
    </Popover>
  );
};

export default App;

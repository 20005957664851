import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const makeTransferService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/transfer`, data, token);
};

export const getAllChannel = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/channel?${query || ""}`, token);
};

export const getAllShop = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop?${query || ""}`, token);
};

export const getAllProductService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopstockitem${query}`, token);
};

export const getAllServiceStock = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopstockitem/stock${query}`, token);
};

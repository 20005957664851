import React from "react";
import { useState, useEffect } from "react";
import { DraftEditor } from "../components/DraftEditor";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Cascader } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../redux/actions";
import UploadComponent from "../components/Upload/Upload";
import { createNewPost } from "../redux/actions";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";

const AddKnowledgePost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth, knowledge } = useSelector((state: any) => state);

  const [propTextState, setPropTextState] = useState(EditorState.createEmpty());
  const [categories, setCategories] = useState<Array<Object>>([]);
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [pictures, setPictures] = useState<Array<Object>>([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [bodyPictures, setBodyPictures] = useState<Array<Object>>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSubmit = async () => {
    if (!title || !description) {
      return;
    }
    if (auth?.token) {
      let categoryId = knowledge.categories.find(
        (category: any) => category.categoryName == selectedCategories[0]
      )?._id;

      await createNewPost(auth.token, {
        title,
        description,
        category: categoryId,
        subCategory: selectedCategories[1],
        picture: pictures.map((pic: any) => pic?.response?.data?.secure_url)[0],
        content: draftToHtml(convertToRaw(propTextState?.getCurrentContent())),
      })(dispatch);

      navigate(-1);
    }
  };

  useEffect(() => {
    if (knowledge?.categories) {
      let organizedCategories: Array<Object> = [];
      knowledge.categories.map((category: any) => {
        organizedCategories.push({
          value: category.categoryName,
          label: category.categoryName,
          children: category.subCategory.map((subCategory: any) => {
            return {
              value: subCategory.name,
              label: subCategory.name,
            };
          }),
        });
      });
      setCategories(organizedCategories);
    }
  }, [knowledge]);

  useEffect(() => {
    if (auth?.token) {
      getCategories(auth.token)(dispatch);
    }
  }, [auth]);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center w-[90%]">
        <div className="flex flex-col justify-between items-center w-[100%]">
          <h1 className="block text-[17px] md:text-2xl font-semibold text-gray-800 py-6 md:text-3x">
            Add a new knowledge post
          </h1>
          <div className="flex flex-col justify-center items-center w-[100%] mt-6">
            <div className="flex flex-row w-[100%] justify-around">
              <div className="mb-6 w-[40%]">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Title
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2.5"
                  placeholder="Title of the post"
                  required
                />
              </div>
              <div className="mb-6 w-[40%]">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Hero image
                </label>
                <UploadComponent setPictures={setPictures} limit={1} />
              </div>
            </div>
            <div className="flex flex-row w-[100%] justify-around">
              <div className="mb-6 w-[40%]">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Description
                </label>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2"
                  placeholder="Short description of the post"
                  required
                />
              </div>
              <div className="mb-6 w-[40%]">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Categories
                </label>
                <Cascader
                  id="category"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2.5"
                  placeholder="Category"
                  options={categories}
                  onChange={(e) => setSelectedCategories(e)}
                  value={selectedCategories}
                />
              </div>
            </div>
            {isModalVisible && (
              <Modal
                title="Upload images"
                visible={isModalVisible}
                onOk={() => {
                  setIsModalVisible(false);
                  setBodyPictures([]);
                }}
                onCancel={() => {
                  setIsModalVisible(false);
                  setBodyPictures([]);
                }}
              >
                <UploadComponent setPictures={setBodyPictures} limit={1} />
                <Button
                  className="mt-4"
                  disabled={bodyPictures.length == 0}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      bodyPictures.map(
                        (pic: any) => pic?.response?.data?.secure_url
                      )[0]
                    );
                  }}
                >
                  Copy to clipboard
                </Button>
              </Modal>
            )}
            <div className="mb-6 w-[90%]">
              <div className="w-[100%] flex flex-row justify-between items-center">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Body
                </label>
                <button onClick={() => setIsModalVisible(true)}>
                  Click to upload images
                </button>
              </div>
              <DraftEditor
                placeholder="Body"
                editorState={propTextState}
                setEditorState={setPropTextState}
              />
            </div>
            <Button
              onClick={handleSubmit}
              loading={knowledge?.isFetching}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 my-5 text-center"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddKnowledgePost;

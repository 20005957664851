import { Modal } from "antd";
import React from "react";

const CustomModel: React.FC<{
  isOpen: boolean;
  contents: any;
  width?: number;
  onClose?: any;
}> = ({ isOpen, onClose, contents, width }) => {
  return (
    <Modal open={isOpen} onCancel={onClose} width={width} footer={null}>
      {contents}
    </Modal>
  );
};

export default CustomModel;

import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { SERVER_URL } from "../../../../utils/constants";

const App = (props: any) => {
  const [fileList, setFileList] = useState<UploadFile[]>(props?.default || []);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    console.log(">>>>>>", newFileList);
    props.setPictures(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadButton = (
    <div className="flex flex-row justify-center items-center">
      <PlusOutlined />
      <div className="ml-2">Click to upload an image</div>
    </div>
  );
  return (
    <>
      <Upload
        name="images"
        className="flex flex-col items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none w-full p-2.5"
        action={`${SERVER_URL}/product/upload`}
        fileList={fileList}
        listType="picture"
        onPreview={onPreview}
        onChange={handleChange}
      >
        {fileList.length >= props.limit ? null : uploadButton}
      </Upload>
    </>
  );
};

export default App;

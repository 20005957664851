import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { getAnalyticAction } from "../../../store/customer/actions";

const CustomChart = (props: any) => {
  const dispatch = useDispatch();
  const { customer, auth } = useSelector((state: any) => state);

;

  const customerActive = customer?.customerAnalytics?.data?.activeCustomer?.results.map((item: any) => ({
    date: item.date,
    totalActive: item.totalActive,
    newCustomers: customer?.customerAnalytics?.data?.activeCustomer?.newCustomers
  }));

  const getDayFromDate = (dateString: any) => {
    const date = new Date(dateString);
    const options: any = { weekday: 'long' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  // Map through the array and add a new property "day" to each object
  const newData = customerActive?.map((item: any) => ({
    ...item,
    day: getDayFromDate(item.date)
  }));

  return (
    <>
      <LineChart width={620} height={250} data={newData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="totalActive" stroke="#8884d8" />
        <Line type="monotone" dataKey="newCustomers" stroke="#82ca9d" />
      </LineChart>
    </>
  );
}

export default CustomChart;



import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function Variants() {
  return (
    <Stack spacing={1}>
      {/* <Skeleton variant="circular" width={40} height={40} /> */}
      <Skeleton variant="rectangular" width={310} height={60} />
      <Skeleton variant="rounded" width={310} height={100} />
    </Stack>
  );
}
export const CurstomeCardSkeleton = () => {
  return (
    <div
      role="status"
      className="max-w-lg mr-4 mb-6 p-4 border border-white bg-white rounded shadow animate-pulse md:p-6"
    >
      <div className="flex gap-x-3">
        <div className="h-10 w-10 border rounded-full bg-gray-200"></div>
        <div className="w-full">
          <div className="h-2.5 bg-gray-200 rounded-full mb-4"></div>
          <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full"></div>
        </div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const FormSkeleton = () => {
  return (
    <div role="status" className="xmax-w-sm animate-pulse mt-8">
      <div className="h-5 bg-gray-200 rounded w-48 mb-4"></div>
      <div className="grid grid-cols-2 gap-x-6 gap-y-4">
        <div className="h-8 bg-gray-100 rounded"></div>
        <div className="h-8 bg-gray-100 rounded"></div>
        <div className="h-8 bg-gray-100 rounded"></div>
        <div className="h-8 bg-gray-100 rounded"></div>
        <div className="h-8 bg-gray-100 rounded"></div>
        <div className="h-8 bg-gray-100 rounded"></div>
        <div className="h-8 bg-gray-100 rounded"></div>
        <div className="h-8 bg-gray-100 rounded"></div>
      </div>
    </div>
  );
};

export const FoundItemSkeleton = () => {
  return (
    <div
      role="status"
      className="xmax-w-lg mr-4 mb-6 p-4 pb-10 border border-white bg-white rounded shadow animate-pulse relative md:pb-36"
    >
      <div className="flex gap-x-3">
        <div className="h-20 w-16 border rounded bg-gray-200"></div>
        <div>
          <div className="h-2.5 bg-gray-200 rounded-full w-80 mb-4"></div>
          <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
          <div className="h-2 bg-gray-200 rounded-full"></div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-4 absolute right-4 bottom-2">
        <div className="h-6 w-44 bg-gray-200 rounded"></div>
        <div className="h-6 w-44 bg-gray-200 rounded"></div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

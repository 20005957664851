import React from "react";
import { Table, Tag } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllCustomerRepairsAction } from "../../store/customer/actions";

interface DataType {
  [x: string]: any;
  key: any;
  name: string;
  age: number;
  address: string;
}

const onChange: TableProps<DataType>["onChange"] = (
) => {
  // console.log("params", pagination, filters, sorter, extra);
};

const CustomerRepairTable = (props: any) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(15);
  const { auth, customer } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const dispatch = useDispatch();
  const { customerId } = useParams();

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text, _record) => (
        <>
          <p className="font-bold">
            {`${text?.name} - ${text?.type}`}
          </p>
          {/* <p>{record?.data?.list[0]?.shopStockItem?.shop?.name} </p>
          <p>{record?.data?.list[0]?.shopStockItem?.shop?.channel?.name} </p> */}
        </>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      render: (text, _record) => (
        <>
          <p>{text?.names}</p>
        </>
      ),
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (text, _record) => <>{text}</>,
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      render: (text, record) => (
        <>
          {text?.map((d: any) => {
            return (
              <>
                <p className="font-medium capitalize">
                  <b>{d[0]}</b>: {d[1]}
                </p>
              </>
            );
          })}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <>
          <p className="capitalize">
            <Tag className="ml-1">{text}</Tag>
          </p>
        </>
      ),
    },
  ];

  React.useEffect(() => {
    auth?.token &&
      getAllCustomerRepairsAction(auth?.token, customerId as string)(dispatch);
  }, [auth?.token, customerId, dispatch]);

  const value = customer?.customerRepairs;

  React.useEffect(() => {
    value &&
      value.forEach((el: any) => {
        console.log(el?.product?.specs);
        dataMapper.push({
          key: el._id,
          date_time: el?.createdAt?.slice(0, 10),
          location: el?.shop,
          agent: el?.createdBy,
          amount: el?.payment?.reduce(
            (total: number, current: any) => parseInt(total + current?.amount),
            0
          ),
          model: el?.product?.model,
          specification: el?.priceProduct?.specification,
          status: el?.status,
          barCode: el?.warehouseProduct?.serialNumber,
          transactionId: el?.cartId,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [customer?.customerRepairs]);
  return (
    <div className="w-full overflow-x-auto bg-white">
    <Table
      columns={columns}
      dataSource={formData}
      onChange={onChange}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        onChange: (page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        },
      }}
      loading={customer?.isFetching}
    /></div>
  );
};

export default CustomerRepairTable;

import React from "react";

const SwitchButton: React.FC<{
  activeTab: number;
  handleTabClick: (tabNumber: number) => void;
  label1: string;
  label2: string;
}> = ({ activeTab, handleTabClick, label1, label2 }) => {
  return (
    <div>
      <div className="flex border-b border-gray-300">
        <button
          className={`px-4 py-2 ${
            activeTab === 1 ? "bg-gray-200" : "bg-white"
          }`}
          onClick={() => handleTabClick(1)}
        >
          {label1}
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === 2 ? "bg-gray-200" : "bg-white"
          }`}
          onClick={() => handleTabClick(2)}
        >
          {label2}
        </button>
      </div>
    </div>
  );
};

export default SwitchButton;

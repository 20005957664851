import { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { enUS } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../Inventory/style.css";
import { useDispatch, useSelector } from "react-redux";
import { getAgentEarningsAction } from "../../../store/kpi/actions";
import { getActiveShop } from "../../../utils/converter";

const EarningsDateRange: React.FC = () => {
  const { auth, appSettings } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleSelect = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
    console.log("data range", selectedDateRange);
  };
  console.log("data range out", selectedDateRange?.startDate?.toISOString());
  const userId = auth?.user?._id;
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;
  const date = new Date(selectedDateRange?.startDate);
  const dateTwo = new Date(selectedDateRange?.endDate);

  const formattedDate =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2) +
    "T" +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2) +
    ".000Z";
  const formattedDateTwo =
    dateTwo.getFullYear() +
    "-" +
    ("0" + (dateTwo.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + dateTwo.getDate()).slice(-2) +
    "T" +
    ("0" + dateTwo.getHours()).slice(-2) +
    ":" +
    ("0" + dateTwo.getMinutes()).slice(-2) +
    ":" +
    ("0" + dateTwo.getSeconds()).slice(-2) +
    ".000Z";

  useEffect(() => {
    if (formattedDate || formattedDateTwo) {
      auth?.token &&
        getAgentEarningsAction(
          auth?.token,
          `?date1=${formattedDate.split("T")[0]}&date2=${formattedDateTwo.split("T")[0]
          }&shop=${shopId}&agentId=${userId}`
        )(dispatch);
    } else {
      auth?.token &&
        getAgentEarningsAction(
          auth?.token,
          `?shop=${shopId}&agentId=${userId}`
        )(dispatch);
    }
  }, [
    auth?.token,
    dispatch,
    selectedDateRange,
    formattedDate.split("T")[0],
    formattedDateTwo.split("T")[0],
    shopId,
    userId,
  ]);

  return (
      <DateRangePicker
        onChange={handleSelect}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={[selectedDateRange]}
        direction="horizontal"
        locale={enUS}
      />

  );
};

export default EarningsDateRange;

import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createPurchaseOrderService = async (
  token: string,
  poId: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/purchase/one/${poId}`,
    "",
    token
  );
};
export const getAllPurchaseOrdersService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/purchase`, token);
};

export const getOnePurchaseOrderService = async (
  poId: string,
  token: string
) => {
  return HttpRequest.get(`${SERVER_URL}/purchase/one/${poId}`, token);
};

export const updatePurchaseOrderItemService = async (
  token: string,
  poId: string,
  data: any
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/purchaseItem/one/${poId}`,
    data,
    token
  );
};

export const updatePurchaseOrderService = async (
  token: string,
  poId: string,
  data: any
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/purchase/one/${poId}`,
    data,
    token
  );
};

export const sendPurchaseOrderEmailService = async (
  token: string,
  data: any
) => {
  return await HttpRequest.post(`${SERVER_URL}/send/po`, data, token);
};

export const getRecentPurchaseOrdersService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/purchase/ready/po`, token);
};
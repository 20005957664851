import React, { useState, useEffect } from "react";
import { ReactComponent as MTNLogo } from "../../src/assets/MTNNLogo.svg";
import { getActiveShop } from "../utils/converter";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, MenuItem, Select, useMediaQuery } from "@mui/material";
import { myAppSettings } from "../store/appSettings";
import SelectShopPosModal from "./Modals/SelectShopPosModal";
import { Button, Dropdown } from "antd";
import { authActions } from "../store/auth";
import type { MenuProps } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
interface HeaderShopsProps {
  selectedShop: any;
}

interface ShopInfo {
  shop?: {
    name?: string;
    region?: string;
    _id: string;
  };
  name?: string;
  region?: string;
  _id: string;
}

const HeaderShops: React.FC<HeaderShopsProps> = () => {
  const { auth, shop, appSettings } = useSelector((state: any) => state);
  const [activeShops, setActiveShops] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [openShopSelectModal, setOpenShopSelectModal] =
    useState<boolean>(false);

  const handleClose = () => setOpenShopSelectModal(false);

  useEffect(() => {
    if (auth.token) {
      const shops = getActiveShop(auth?.user?.shop?.assigned);
      setActiveShops(shops);

      // Retrieve selectedIndex from local storage if exists
      const savedIndex = localStorage.getItem("selectedShopIndex");
      const savedShopId =
        getActiveShop(auth?.user?.shop?.assigned)[
          appSettings?.selectedShopNumber
        ]?.shop?._id || auth?.userShop?.shopId;

      if (savedIndex && savedShopId) {
        const index = shops.findIndex(
          ({ shop }: any) => shop?._id === savedShopId
        );
        if (index !== -1) {
          setSelectedIndex(index);
        } else {
          setSelectedIndex(parseInt(savedIndex, 10));
        }
      }
    }
  }, [auth?.user?.shop?.assigned]);

  const shopInfo: ShopInfo = activeShops[selectedIndex]?.shop || shop?.myShop;

  const dispatch = useDispatch();

  const handleShopSelect = (index: number, shopId: string) => {
    setSelectedIndex(index);
    dispatch(myAppSettings.setSelectedShopNumber(index));
    localStorage.setItem("selectedShopIndex", index.toString());
    localStorage.setItem(
      "selectedShopId",
      activeShops[selectedIndex]?.shop?._id
    );
  };
  const smallScreen = useMediaQuery("(max-width:1023px)");
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <p>{activeShops[selectedIndex]?.shop?.name || shopInfo?.name}</p>,
    },
    {
      key: "2",
      label: (
        <p className="font-poppins text-[12px] sm:text-base font-normal leading-6">
          {shopInfo?.shop?.region || shopInfo?.region}
        </p>
      ),
    },
  ];
  return (
    <>
      <div className="flex flex-row items-center justify-between px-4 h-[56px] bg-white w-full md:w-[99%] bg-opacity-50 rounded-md shadow text-semibold text-[#0F0F47]">
        <SelectShopPosModal
          handleClose={handleClose}
          showModal={openShopSelectModal}
        />

        {shopInfo && (
          <>
            <div className="flex flex-row items-center">
              {auth?.user?.profile ? (
                ""
              ) : (
                <div>
                  <MTNLogo className="h-4" />
                </div>
              )}
              <div className="flex flex-row items-center gap-3 font-poppins text-[12px] sm:text-base font-normal leading-6 text-left">
                {!smallScreen && (
                  <p>
                    {activeShops[selectedIndex]?.shop?.name || shopInfo?.name}
                  </p>
                )}
              </div>
            </div>
            <div style={{ backgroundColor: "white" }}>
              {auth?.user?.role?.toLowerCase() !== "sales-agent" ? (
                <Button onClick={() => setOpenShopSelectModal(true)} className="flex items-center"> 
                  Change Shop
                </Button>
              ) : (
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: smallScreen ? 100 : 200 }}
                >
                  <Select
                    disableUnderline={true}
                    value={
                      activeShops[selectedIndex]?.shop?._id || shopInfo?._id
                    }
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="Select Shop"
                    inputProps={{
                      shrink: false,
                    }}
                    variant="standard"
                    sx={{ "& .MuiSelect-select": { underline: "none" } }}
                  >
                    {activeShops?.map(
                      ({ shop, channel }: any, index: number) => (
                        <MenuItem
                          key={index}
                          value={shop?._id}
                          onClick={() => handleShopSelect(index, shop?._id)}
                        >
                          {shop?.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
            </div>
            {!smallScreen && (
              <p className="font-poppins text-[12px] sm:text-base font-normal leading-6">
                {shopInfo?.shop?.region || shopInfo?.region}
              </p>
            )}
            {smallScreen && (
              <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                <BsThreeDotsVertical />
              </Dropdown>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default HeaderShops;

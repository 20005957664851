import React, { useEffect } from "react";
import OverViewCard from "../../../components/cards/inventory/OverViewCard";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import InventoryChart from "./InventoryChart";
import InventoryTable from "../../../components/tables/inventoryTable";
import DateRangePickers from "./DateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import { getActiveShop } from "../../../utils/converter";
import { overViewStatActions } from "../../../store/posOverView/actions";
import { getRecentPosOrdersAction } from "../../../store/pos/actions";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";

const InventoryOverView = () => {
  const { auth, posOverView, appSettings } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  useEffect(() => {
    auth?.token && overViewStatActions(auth?.token, shopId)(dispatch);
  }, [auth?.token, dispatch, shopId]);

  useEffect(() => {
    auth?.token &&
      getRecentPosOrdersAction(
        auth?.token,
        `?shop=${shopId}&status=In-Transit&status=incompleted`
      )(dispatch);
  }, [appSettings?.selectedShopNumber, auth?.token, shopId, dispatch]);

  return (
    <>
      <div className="text-black">
        <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
          <OverViewCard
            total={
              posOverView.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                posOverView?.overViewStats?.data?.totalCurrentDevices || 0
              )
            }
            text="Total Stock"
            icon={<BoxIcons />}
          />
          <OverViewCard
            total={
              posOverView.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                posOverView?.overViewStats?.data?.stockRequests || 0
              )
            }
            text="Total Stock Requested"
            icon={<BoxIcons />}
          />
          <OverViewCard
            total={
              posOverView.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                posOverView?.overViewStats?.data?.totalProductInTransit || 0
              )
            }
            text="Total Stock Transfered"
            icon={<BoxIcons />}
          />
          <OverViewCard
            total={
              posOverView.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                posOverView?.overViewStats?.data?.totalDevicesSold || 0
              )
            }
            text="Total Quantity Sold"
            icon={<BoxIcons />}
          />
        </div>
        <div className="py-5 xl:flex flex-row-reverse gap-5 flex-swrap">
          <div className="bg-white w-full xl:w-2/6 p-2 rounded-md overflow-x-auto">
            <DateRangePickers />
          </div>
          <div className="bg-white w-full xl:w-[65%] p-5 rounded-md mt-5 xl:mt-0">
            <div className="flex justify-between">
              {" "}
              <h1 className="text-[#030229CC] text-base font-semibold">
                Analytics
              </h1>
              <div className="flex flex-col sm:flex-row gap-5 pb-5">
                <div className="flex flex-row items-center gap-1">
                  <div className="h-3 w-3 bg-[#0FA91E80] mr-1 rounded-full"></div>
                  <div className="text-[#030229] text-xs">Current Stock</div>
                </div>
                <div className="flex flex-row items-center gap-1">
                  <div className="h-3 w-3 bg-[#FA365952] mr-1 rounded-full"></div>
                  <div className="text-[#030229] text-xs">Stock Request</div>
                </div>
                <div className="flex flex-row items-center gap-1">
                  <div className="h-3 w-3 bg-[#605BFF80] mr-1 rounded-full"></div>
                  <div className="text-[#030229] text-xs">Stock Transfer</div>
                </div>
              </div>
            </div>
            <InventoryChart />
          </div>
        </div>
        <div className="py-5 xl:flex  gap-5 flsex-wrap">
          <div className="bg-white w-full xl:w-2/3 p-5 rounded-md h-[384px] overflow-y-auto pr-3">
            <h1 className="py-2">Recent Activities</h1>
            <InventoryTable />
          </div>
          <div className="bg-white w-full mt-5 xl:mt-0 xl:w-1/3 p-5 rounded-xl h-[384px] overflow-y-auto pr-3">
            <div className="flex justify-between">
              <h1 className="text-[#030229CC] text-lg font-medium">
                Stock Alerts
              </h1>
              <button
                className="text-[#605BFF] text-xs cursor-pointer"
                onClick={() => navigate("/inventory/current-stock")}
              >
                View All
              </button>
            </div>
            {/* <h1 className="text-[#03022980] text-base py-2">
              Low Quantity Stock
            </h1>
            <AlertCard
              title={"Product Name"}
              content={"Remaining Quantity : 10 Packet"}
              action={"Request Stock"}
            />
            <AlertCard
              title={"Product Name"}
              content={"Remaining Quantity : 10 Packet"}
              action={"Request Stock"}
            />
            <AlertCard
              title={"Product Name"}
              content={"Remaining Quantity : 10 Packet"}
              action={"Request Stock"}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryOverView;

import React from "react";
interface cardProps {
    name?: any;
    secondName?: any;
  }
const BillingCard = ({name,secondName}:cardProps) => {
  return (
    <div className="border border-[#EBEFF2] bg-white rounded-md p-3">
      <h1 className="text-[#030229] text-[12px]">{name}</h1>
      <h1 className="text-[#030229] text-sm font-medium">{secondName}</h1>
    </div>
  );
};

export default BillingCard;

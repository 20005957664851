import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomerWithSubscription from "./CustomerWithSubscription";
import RegisterCustomer from "../registrationsteps/RegisterCustomer";

const Index = (props: any) => {
  const { wareHouse } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isNewCustomer, setIsNewCustomer] = useState(false);

  const hasCustomer = wareHouse?.createdCart?.data?.customer;

  useEffect(() => {
    if (hasCustomer) {
      setIsNewCustomer(true);
    }
  }, [hasCustomer, dispatch]);

  return (
    <>
      {isNewCustomer ? (
        <RegisterCustomer newCustomer={setIsNewCustomer} />
      ) : (
        <CustomerWithSubscription
          newCustomer={setIsNewCustomer}
          isGettingCustomer={props?.isGettingCustomer}
        />
      )}
    </>
  );
};

export default Index;

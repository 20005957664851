import { Result } from 'antd';
import React from 'react'
import { useSelector } from 'react-redux';

const ApplicantsDocument = (props:any) => {
  const { auth, apllicants } = useSelector((state: any) => state);
  return (
    <div>
         <div className="border border-[#0302291A] p-4 mt-5 rounded-lg">
        <h1 className="text-[#030229] text-lg font-medium py-3 pb-6 text-center">
          Documents
        </h1>
        <div className="flex gap-2 w-full">
          <div className=" w-1/2 border">
            <p className="text-[#03022980]  text-sm text-center py-1">Passport image</p>
            <div className="pb-10 pt-5 w-full flex items-center justify-center rounded-md">
              {/* <img
                src={apllicants?.applicantDetails?.data?.customer?.subscriptionInfo?.photoCustomerHoldingID}
                alt=""
                className="h-32 object-contain w-full mt-2 rounded-md"
              /> */}
              {apllicants?.applicantDetails?.data?.customer?.subscriptionInfo?.photoCustomerHoldingID && (
                <Result
                  status="success"
                />
              )}
            </div>
          </div>
          <div className="w-1/2 border">
            <p className="text-[#03022980]  text-sm text-center py-1">National Id</p>
            <div className="pb-10  pt-5 w-full flex items-center justify-center rounded-md">
              {/* <img
                src={apllicants?.applicantDetails?.data?.customer?.subscriptionInfo?.nationalIDDoc}
                alt=""
                className="h-32 object-contain w-full mt-2 rounded-md"
              /> */}
               {apllicants?.applicantDetails?.data?.customer?.subscriptionInfo?.nationalIDDoc && (
                <Result
                  status="success"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplicantsDocument
import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import TopNav from "../components/navitems/TopNavItems";
import { subMenusList } from "../assets/data/pages";
import React from "react";
import store from "store";
import { useSelector } from "react-redux";

export const posCustomers = [
  {
    caption: "Overview",
    path: "/overview/customers"
  },
  {
    caption: "Customers",
    path: "/customers"
  }
];

const PosCustomer = () => {
  const { auth } = useSelector((state: any) => state);
  const token = store.get("authToken");
  let authent = { token: true };
  const pagesArray = [
    {
      caption: "Overview",
      path: "/overview/customers"
    },
    {
      caption: "Customers",
      path: "/customers"
    }
  ];

  return auth?.token || token ? (
    <DashLayout
      allowSearch={true}
      nav={<TopNav nav={subMenusList[4]} pages={pagesArray} />}
      selectedNav={4}
    >
      <Outlet />
    </DashLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default PosCustomer;

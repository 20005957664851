import React from "react";
import ProductCardGrid from "./ProductCardGrid";
import { useSelector } from "react-redux";

const App = (props: any) => {
  const { pos } = useSelector((state: any) => state);

  return pos?.priceList?.data?.map((d: any) => <ProductCardGrid data={d} />);
};

export default App;

import React, { useEffect, useState } from "react";
import OverViewCard from "../../../components/cards/inventory/OverViewCard";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import { ReactComponent as PdfIcons } from "../../../assets/icons/dashboard/codicon_file-pdf.svg";
import { ReactComponent as CsvIcons } from "../../../assets/icons/dashboard/ph_file-csv.svg";
import { DatePicker, Select, DatePickerProps } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { GoListUnordered } from "react-icons/go";
import { TbGridDots } from "react-icons/tb";
import CustomButton from "../../../components/buttons/CustomButton";
import { IoFilterOutline } from "react-icons/io5";
import TransactionsTable from "../../../components/tables/TransactionTable";
import { getActiveShop } from "../../../utils/converter";
import { useDispatch, useSelector } from "react-redux";
import { getAllSalesReportAction } from "../../../store/report/actions";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getAllTransactionAction } from "../../../store/pos/actions";
import { CurstomeCardSkeleton } from "../../../components/skeleton/Card";
import moment from "moment";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";
// Extend the jsPDF instance with the autoTable plugin
(jsPDF as any).autoTable = require("jspdf-autotable");

const Transaction = () => {
  const { auth, appSettings, report, pos, company } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  dayjs.extend(customParseFormat);

  const [showFilter, setShowFilter] = useState(false);
  const formattedDate = new Date().toISOString().slice(0, 10);
  let today = moment();
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState<any>(formattedPreviousDate);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setSelectedDate(dateString);
  };
  const onChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    setSelectedSecondDate(dateString);
  };
  const [SelectedSecondDate, setSelectedSecondDate] =
    useState<any>(formattedDate);

  // Function to generate PDF
  const generatePDF = () => {
    const doc: any = new jsPDF(); // Cast jsPDF instance to 'any'

    doc.text("Sales Report", 10, 10);

    // Prepare table data
    const tableData = pos?.transaction?.data?.map((row: any) => {
      return [
        row?.paidOn?.split("T")[0],
        row?.data?.cart?.customer?.phone,
        row?.createdBy?.names,
        row?.product?.model,
        row?.payment?.amount,
        row?.cart?.cartId,
        row?.status,
      ];
    });

    // Set column headers
    const headers = [
      "Date",
      "customer",
      "CreatedBy",
      "Model",
      "Amount",
      "transactionId",
      "status",
    ];

    // Add table to PDF
    doc.autoTable({
      head: [headers],
      body: tableData,
    });

    // Save PDF
    doc.save("transactions.pdf");
  };

  // Function to generate CSV
  const generateCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      report?.tableData?.data
        ?.map((row: any) => Object.values(row).join(","))
        .join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "transactions.csv");
    document.body.appendChild(link);
    link.click();
  };

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  useEffect(() => {
    if (auth?.token && shopId)
      getAllSalesReportAction(
        auth?.token,
        `?shops=${shopId}&date1=${selectedDate}&date2=${SelectedSecondDate}`
      )(dispatch);
  }, [selectedDate, SelectedSecondDate, dispatch, shopId, auth?.token]);

  useEffect(() => {
    if (auth?.token && shopId) {
      getAllTransactionAction(
        auth?.token,
        `?shop=${shopId}&status=paid&startDate=${selectedDate}&endDate=${SelectedSecondDate}&page=${page - 1
        }&limit=${limit}`
      )(dispatch);
    }
  }, [
    appSettings?.selectedShopNumber,
    auth,
    dispatch,
    limit,
    page,
    selectedDate,
    SelectedSecondDate,
    shopId,
  ]);

  return (
    <div className="text-[#030229] relative">
      <div className="flex flex-col lg:flex-row justify-between w-[91%] 2xl:w-[94%] pt-1">
        <h1 className="text-[#030229d8] text-xl  mb-5 lg:mb-0">Sales Report</h1>
        <div className="flex gap-2 mr-3">
          <p className="mt-[.3rem]">From</p>
          <DatePicker
            defaultValue={dayjs(formattedPreviousDate)}
            onChange={onChange}
          />
          <p className="mt-[.3rem]">To</p>
          <DatePicker
            defaultValue={dayjs(formattedDate)}
            onChange={onChangeDate}
            allowClear={false}
          /></div>
        <div className="absolute top-[6px] right-4 flex gap-2">
          <PdfIcons className="w-8 h-8 cursor-pointer" onClick={generatePDF} />
          <CsvIcons className="w-8 h-8  cursor-not-allowed" />
        </div>
      </div>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mt-8 px-6 md:px-0">
        {report?.isFetching ? (
          <>
            {[1, 2, 3]?.map((el) => (
              <CurstomeCardSkeleton />
            ))}
          </>
        ) : (
          <>
            <OverViewCard
              text="Total Quantity"
              icon={<BoxIcons />}
              total={report.all?.data?.quantity}
            />
            <OverViewCard
              text="Total Amount"
              icon={<BoxIcons />}
              total={` ${getDefaultCurrencyCode(company)} ${report.all?.data?.revenue?.toLocaleString()}`}
            />
            <OverViewCard
              text="Total Customers"
              icon={<BoxIcons />}
              total={report.all?.data?.customer}
            />
          </>
        )}
      </div>
      <div>
        <div className="flex justify-between my-5">
          <div className="flex gap-3">
            <CustomButton
              // title={showFilter ? "" : "filter"}
              textColor="[#030229B2]"
              icon={<IoFilterOutline size={20} />}
              bgColor={"white"}
              border="[#0302291A]"
            // onClick={() => setShowFilter(!showFilter)}
            />
            {showFilter && (
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="filter by Model"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "1",
                    label: "Pop 8 (BC6)",
                  },
                  {
                    value: "2",
                    label: "Spark 20",
                  },
                  {
                    value: "3",
                    label: "Pop7",
                  },
                ]}
              />
            )}
          </div>
          <div className="flex gap-5">
            {/* <Search
              placeholder="input search text"
              onSearch={(value, _e) => console.log(value)}
              style={{ width: 200, marginTop: "5px" }}
              prefix={
                <SearchOutlined
                  style={{ color: "#1677ff", fontSize: "16px" }}
                />
              }
            /> */}
            <div className="bg-[#605BFF] flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
              <GoListUnordered size={23} color="#ffff" />
            </div>
            <div className="bg-white flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
              <TbGridDots size={23} color="#C0C0C9" />
            </div>
            <hr className="h-7 w-0.5 bg-gray-300 mt-1.5" />
          </div>
        </div>
      </div>
      <div className="overflow-auto">
        <TransactionsTable
          id="transactions-table"
          selectedDate={selectedDate}
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
        />
      </div>
    </div>
  );
};

export default Transaction;

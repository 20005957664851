import { notification } from "antd";
import { myWareHouseOutActions } from ".";
import {
  createServiceStockOut,
  getAllServiceRequestedOut,
  getAllServiceStockOut,
  getAllServiceWarehouse,
  getAllWarehouseOutItem,
  getOneServiceRequestedOut,
  removeProductService,
  updateOneStockOutDetailsService,
  updateStockOutService,
  updateStockRequestStatus,
} from "./services";

export const getAllStockRequestSentAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseOutActions.setIsFetching(true));
      const res = await getAllServiceRequestedOut(token, query);
      // console.log(">>>>>>>:: W/Out", res);
      if (res?.status === 200) {
        dispatch(myWareHouseOutActions.setAllStockOut(res));
        dispatch(myWareHouseOutActions.setIsFetching(false));
      }
      dispatch(myWareHouseOutActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSelectedRequestSentAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseOutActions.setIsFetching(true));
      const res = await getAllServiceRequestedOut(token, query);
      // console.log(">>:: W/Out/Selected", res);
      if (res?.status === 200) {
        dispatch(myWareHouseOutActions.setSelectedRequest(res));
        dispatch(myWareHouseOutActions.setIsFetching(false));
      }
      dispatch(myWareHouseOutActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneStockRequestSentAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseOutActions.setIsFetching(true));
      const res = await getOneServiceRequestedOut(id, token);
      // console.log(">>>>>>>:: W/Out", res);
      if (res?.status === 200) {
        dispatch(myWareHouseOutActions.setOne(res));
        dispatch(myWareHouseOutActions.setIsFetching(false));
      }
      dispatch(myWareHouseOutActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const UpdateOneStockRequestStatusAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseOutActions.setIsFetching(true));
      const res = await updateStockRequestStatus(itemId, data, token);
      // console.log(">>>>>>>:: W/Out", res);
      if (res?.status === 200) {
        dispatch(myWareHouseOutActions.setIsFetching(false));
        return true;
      }
      dispatch(myWareHouseOutActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const createStockOutAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseOutActions.setIsFetching(true));
      const res = await createServiceStockOut(data, token);
      const resAll = await getAllServiceWarehouse(token);

      // console.log(">>>>>>>::res>>> ", res);
      if (res?.status === 201) {
        dispatch(myWareHouseOutActions.setWarehouseItem(resAll));
        dispatch(myWareHouseOutActions.setWarehouseOut(res));
        dispatch(myWareHouseOutActions.setIsFetching(false));
        return res?.data;
      }
      dispatch(myWareHouseOutActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllWarehouseAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseOutActions.setIsFetching(true));
      const res = await getAllServiceWarehouse(token, query);
      // console.log(">>:: W/Out/Selected", res);
      if (res?.status === 200) {
        dispatch(myWareHouseOutActions.setWarehouseItem(res));
        dispatch(myWareHouseOutActions.setIsFetching(false));
      }
      dispatch(myWareHouseOutActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllWarehouseActionBySN = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseOutActions.setIsFetching(true));
      const res = await getAllServiceWarehouse(token, query);
      // console.log(">>:: W/Out/Selected", res);
      if (res?.status === 200) {
        dispatch(myWareHouseOutActions.setWarehouseSelected(res));
        dispatch(myWareHouseOutActions.setIsFetching(false));
        return res?.data;
      }
      dispatch(myWareHouseOutActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllWarehouseOutAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseOutActions.setIsFetching(true));
      const res = await getAllServiceStockOut(token, query);
      if (res?.status === 200) {
        dispatch(myWareHouseOutActions.setWarehouseOut(res));
        dispatch(myWareHouseOutActions.setWarehouseOutSelected(res?.data[0]));
        dispatch(myWareHouseOutActions.setIsFetching(false));
      }
      dispatch(myWareHouseOutActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllWarehouseOutItemsAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseOutActions.setIsFetching(true));
      const res = await getAllWarehouseOutItem(token, query);
      if (res?.status === 200) {
        dispatch(myWareHouseOutActions.setWarehouseOutItem(res));
        dispatch(myWareHouseOutActions.setIsFetching(false));
      }
      dispatch(myWareHouseOutActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateStockOutAction = (id: any, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseOutActions.setIsFetching(true));
      const res = await updateStockOutService(id, data, token);
      // console.log(">>>>>>>:: W/Out", res);
      if (res?.status === 201) {
        const resW = await getAllWarehouseOutItem(
          token,
          `requestedItem=${data?.requestedItem}`
        );

        // dispatch(myWareHouseOutActions.setWarehouseSelected(res));
        dispatch(myWareHouseOutActions.setWarehouseOutItem(resW));
        dispatch(myWareHouseOutActions.setIsFetching(false));
        notification.success({ message: "Successfully Added!" });
        return true;
      } else {
        dispatch(myWareHouseOutActions.setIsFetching(false));
        notification.error({
          message: "Failed,",
          description: `These IMEIs failed to be added: ${res.data.join(
            ", "
          )}. Please try again.`,
        });
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateOneStockOutDetailsAction = (
  token: string,
  id: any,
  requestedItem: any,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseOutActions.setIsFetching(true));
      const res = await updateOneStockOutDetailsService(id, data, token);
      // console.log(">>>>>>>:: W/Out", res);
      if (res?.status === 200) {
        const resW = await getAllWarehouseOutItem(
          token,
          `requestedItem=${requestedItem}`
        );

        // dispatch(myWareHouseOutActions.setWarehouseSelected(res));
        dispatch(myWareHouseOutActions.setWarehouseOutItem(resW));
        dispatch(myWareHouseOutActions.setIsFetching(false));
        return true;
      }
      dispatch(myWareHouseOutActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const removeProductAction = (
  token: string,
  id: string,
  requestedItem?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseOutActions.setIsFetching(true));
      const res = await removeProductService(token, id);
      if (res?.status === 200) {
        const resAll = await getAllWarehouseOutItem(
          token,
          `requestedItem=${requestedItem}`
        );
        dispatch(myWareHouseOutActions.setWarehouseOutItem(resAll));
        dispatch(myWareHouseOutActions.setIsFetching(false));
        notification.success({ message: "deleted SuccesFully" });
      }
      dispatch(myWareHouseOutActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

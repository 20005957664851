import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRepairsAction } from "../store/repair/actions";
import { Checkbox, Tag } from "antd";
import { searchValue } from "../utils/setColor";
import { useParams } from "react-router-dom";

const RepairDetails = () => {
  const { repair } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { repaidId } = useParams();

  useEffect(() => {
    repaidId && getRepairsAction(repaidId)(dispatch);
  }, [repaidId]);

  return (
    <div className="flex items-center justify-between mx-auto md:w-[80%] text-gray-900 ">
      <div className="w-[100%] mx-auto h-screen bg-white px-8 flex flex-col gap-4 relative">
        <h1 className="font-helv text-center uppercase text-2xl pt-10">
          repairing
        </h1>
        {/* <div className="grid lg:grid-cols-2 mx-20 gap-2"> */}
        <div className="grid lg:grid-cols-2 mx-20 gap-2">
          <div className="pl-2 text-gray-900  order-2 lg:order-1">
            <div className="hidden lg:flex flex-col mt-2 space-y-1 ">
              <h1 className="text-left font-helv text-lg xmt-6 mt-2">
                Client Information
              </h1>
              <p>
                Name:
                <span>
                  {" "}
                  {
                    repair?.selectedRepair?.data?.cartItemId?.cart?.customer
                      ?.name
                  }
                </span>
              </p>
              <p>
                National ID:
                <span>
                  {" "}
                  {
                    repair?.selectedRepair?.data?.cartItemId?.cart?.customer
                      ?.nid
                  }
                </span>
              </p>
              <p>
                Telephone:
                <span>
                  {" "}
                  {
                    repair?.selectedRepair?.data?.cartItemId?.cart?.customer
                      ?.phone
                  }
                </span>
              </p>
              <p>
                Email:
                <span>
                  {" "}
                  {
                    repair?.selectedRepair?.data?.cartItemId?.cart?.customer
                      ?.email
                  }
                </span>
              </p>
            </div>
            <div>
              <h1 className="text-left font-helv text-lg xmt-6 mt-2">
                Device Issues
              </h1>
              {repair?.selectedRepair?.data?.deviceIssues?.map((d: any) => (
                <div className="space-x-1">
                  <Checkbox checked={d?.isFixed} />
                  <span>{d?.name}</span>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <p>
                Submitted On:
                <span>
                  {" "}
                  {repair?.selectedRepair?.data?.createdAt?.slice(0, 10)}
                </span>
              </p>
            </div>
          </div>
          <div className="order-1 lg:order-2">
            <div className="mt-2 ml-2">
              <p>
                Company:
                <span> SAMPHONE RWANDA LTD</span>
              </p>
              <p className="capitalize">
                {repair?.selectedRepair?.data?.cartItemId?.shopStockItem?.shop?.type
                  ?.split("-")
                  ?.join(" ")}
                :
                <span>
                  {" "}
                  {
                    repair?.selectedRepair?.data?.cartItemId?.shopStockItem
                      ?.shop?.name
                  }
                </span>
              </p>
              <p>
                Agent:
                <span> {repair?.selectedRepair?.data?.createdBy?.names}</span>
              </p>
              <p>
                Status:{" "}
                <Tag
                  color={searchValue(repair?.selectedRepair?.data?.status)}
                  className="capitalize"
                >
                  {repair?.selectedRepair?.data?.status}
                </Tag>
              </p>
              <p>
                Updated On:
                <span>
                  {" "}
                  {repair?.selectedRepair?.data?.updatedAt?.slice(0, 10)}
                </span>
              </p>
            </div>
            <h1 className="text-left font-helv text-lg mt-6">
              Device Information
            </h1>
            <div className="xmt-2 ml-2">
              <p>
                Model:
                <span>
                  {" "}
                  {
                    repair?.selectedRepair?.data?.cartItemId?.priceProduct
                      ?.product?.model
                  }
                </span>
              </p>
              <p>
                Capacity:
                <span>
                  {" "}
                  {repair?.selectedRepair?.data?.cartItemId?.shopStockItem?.requestedItem?.product?.specification
                    .filter((item: any) => item[0] === "Capacity (ROM)")
                    .map((item: any) => item[1])}
                </span>
              </p>
              <p>
                Memory:
                <span>
                  {" "}
                  {repair?.selectedRepair?.data?.cartItemId?.shopStockItem?.requestedItem?.product?.specification
                    .filter((item: any) => item[0] === "Memory (RAM)")
                    .map((item: any) => item[1])}
                </span>
              </p>
              <p>
                IMEI:
                <span>
                  {" "}
                  {
                    repair?.selectedRepair?.data?.cartItemId?.warehouseProduct
                      ?.serialNumber
                  }
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepairDetails;

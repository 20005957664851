import { createSlice } from "@reduxjs/toolkit";
 

const socketSlice = createSlice({
  name: "socket",
  initialState: {
    isFetching: false,
    onlineUser: [],
    socket: null,
  },
  reducers: {
    setOnlineUser(state, action) {
      state.onlineUser = action.payload;
    },
    storeSocket(state, action) {
      state.socket = action.payload;
    },
  },
});

export const socketActions = socketSlice.actions;

export default socketSlice.reducer;

import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";

import TopNav from "../components/navitems/TopNavItems";
import {subMenusList } from "../assets/data/pages";
import React from "react";
 import store from "store";
import { useSelector } from "react-redux";

export const reportRoutes = [
  {
    caption: "Transactions",
    path: "/transaction",
  },
  {
    caption: "No Sales",
    path: "/nosales",
  },
];
const ReportRoutes = () => {
  const { auth } = useSelector((state: any) => state);
  const token = store.get("authToken");
  let authent = { token: true };
  const pagesArray = [
    {
      caption: "Transactions",
      path: "/transaction",
    },
    {
      caption: "No Sales",
      path: "/nosales",
    },
  ];

  return auth?.token || token ? (
    <DashLayout
      allowSearch={true}
      nav={<TopNav nav={subMenusList[6]} pages={pagesArray} />}
      selectedNav={6}
    >
      <Outlet />
    </DashLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
};
export default ReportRoutes;

import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "chat",
  initialState: {
    isFetching: false,
    conversations: [],
    singleConversation: null,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setConversations(state, action) {
      state.conversations = action.payload;
    },
    setSingleConversation(state, action) {
      state.singleConversation = action.payload;
    },
  },
});

export const chatActions = mySlice.actions;

export default mySlice.reducer;

import ArrowBack from "@mui/icons-material/ArrowBack";

const BackButton = (props: any) => {
  return (
    <button
      {...props}
      type="button"
      className="hover:text-blue-800 focus:ring-0  focus:ring-blue-300 font-medium rounded-full text-sm border border-black hover:border-blue-800 "
    >
      <ArrowBack />
    </button>
  );
};

export default BackButton;

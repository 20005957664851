import React, { useCallback, useEffect, useRef, useState } from "react";
import { BrowserMultiFormatReader, Result } from "@zxing/library";
import { Modal, Alert } from "antd";
import { QrCode } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTeamLeadTransfersActions,
  updateTransferAction,
} from "../store/inventory/actions";
import { getActiveShop } from "../utils/converter";

export interface QRCodeData {
  transferId: any;
  teamLeadId: string | Record<string, unknown>;
  timestamp: string;
  [key: string]: unknown;
}

export interface QRCodeScannerProps {
  transferId: any;
  currentTeamLeadId: any;
  debug?: boolean;
  status: any;
  shopId: any;
  setIsDrawerOpen: any;
}

export const ScanQRCode: React.FC<QRCodeScannerProps> = ({
  transferId,
  currentTeamLeadId,
  debug = false,
  status,
  shopId,
  setIsDrawerOpen,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [hasCamera, setHasCamera] = useState<boolean>(true);
  const [cameraList, setCameraList] = useState<MediaDeviceInfo[]>([]);
  const [selectedCamera, setSelectedCamera] = useState<string>("");
  const [refreshData, setRefreshData] = useState(false);

  const scanner = useRef<BrowserMultiFormatReader>(
    new BrowserMultiFormatReader()
  );
  const videoRef = useRef<HTMLVideoElement>(null);

  const dispatch = useDispatch();
  const { auth, appSettings, inventory } = useSelector((state: any) => state);

  const debugLog = (message: any, data?: any) => {
    if (debug) {
      console.log(`[BarcodeScanner] ${message}`, data || "");
    }
  };

  const verifyAndUpdateTransfer = useCallback(
    (qrData: string) => {
      try {
        const parsedData = JSON.parse(qrData);
        debugLog("Parsed QR Data:", parsedData);
        debugLog("Current Team Lead ID:", currentTeamLeadId);

        const scannedTime = new Date(parsedData.timestamp);
        if (isNaN(scannedTime.getTime())) {
          throw new Error(`Invalid timestamp format: ${parsedData.timestamp}`);
        }

        if (auth.token && transferId) {
          updateTransferAction(
            transferId,
            { teamLeadId: currentTeamLeadId },
            auth.token
          )(dispatch);
          setIsDrawerOpen(false);
        }

        return parsedData;
      } catch (err) {
        debugLog("Verification Error:", err);
        throw err;
      }
    },
    [currentTeamLeadId, transferId, auth.token, dispatch]
  );

  // Check for camera permissions and list available cameras
  const checkCameraPermissions = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop());

      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter((device) => device.kind === "videoinput");
      setCameraList(cameras);

      if (cameras.length > 0) {
        setSelectedCamera(cameras[0].deviceId);
        setHasCamera(true);
      } else {
        setHasCamera(false);
        throw new Error("No cameras found on this device");
      }
    } catch (err) {
      debugLog("Camera permission error:", err);
      setHasCamera(false);
      if (err instanceof Error) {
        if (err.name === "NotAllowedError") {
          setError(
            "Camera access denied. Please enable camera permissions in your browser settings."
          );
        } else if (err.name === "NotFoundError") {
          setError("No camera found on this device.");
        } else {
          setError(`Camera error: ${err.message}`);
        }
      }
    }
  }, [debug]);

  const handleScan = useCallback(
    (result: Result) => {
      try {
        debugLog("Scan result:", result.getText());
        const qrData = result.getText();

        try {
          // Verify and update transfer
          verifyAndUpdateTransfer(qrData);
          setCode(qrData);
          closeModal();
        } catch (err) {
          const errorMessage =
            err instanceof Error ? err.message : "Failed to process QR code";
          setError(errorMessage);

          // Restart scanning after error
          setTimeout(() => {
            if (modalIsOpen) {
              startScanning();
            }
          }, 1000);
        }
      } catch (err) {
        const errorMessage =
          err instanceof Error ? err.message : "Failed to process QR code";
        setError(errorMessage);

        setTimeout(() => {
          if (modalIsOpen) {
            startScanning();
          }
        }, 1000);
      }
    },
    [modalIsOpen, verifyAndUpdateTransfer]
  );

  const startScanning = useCallback(async () => {
    if (!hasCamera) {
      setError("No camera available");
      return;
    }

    try {
      debugLog("Starting scanner with camera:", selectedCamera);
      await scanner.current.stopAsyncDecode();
      await scanner.current.reset();

      const constraints:any = {
        video: {
          deviceId: selectedCamera ? { exact: selectedCamera } : undefined,
          facingMode: "environment", // Use the back camera by default
        },
      };

      scanner.current
      .decodeFromVideoDevice(
        constraints.deviceId, // Updated to use constraints
        "video",
        (result: Result | null, err: Error | undefined) => {
          if (result) {
            handleScan(result);
          }
          if (err && !(err instanceof Error && err.message.includes("NotFoundException"))) {
            debugLog("Scanning error:", err);
          }
        }
      )
      .catch((err: Error) => {
        debugLog("Device error:", err);
        setError(`Failed to start camera: ${err.message}`);
      });
    } catch (err) {
      debugLog("Scanner start error:", err);
      setError("Failed to start the QR code scanner");
    }
  }, [hasCamera, selectedCamera, handleScan]);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    setError("");
    scanner.current.stopAsyncDecode();
    scanner.current.reset();
  }, []);

  useEffect(() => {
    if (modalIsOpen) {
      checkCameraPermissions().then(() => {
        if (hasCamera) {
          startScanning();
        }
      });
    }

    return () => {
      scanner.current.stopAsyncDecode();
      scanner.current.reset();
    };
  }, [modalIsOpen, checkCameraPermissions, hasCamera, startScanning]);

  useEffect(() => {
    return () => {
      scanner.current.stopAsyncDecode();
      scanner.current.reset();
    };
  }, []);

  useEffect(() => {
    if (auth.token && inventory?.isUpdated) {
      getTeamLeadTransfersActions(auth?.token, `${shopId}${status}`)(dispatch);
    }
  }, [auth.token, dispatch, shopId, status, refreshData, inventory?.isUpdated]);

  return (
    <div className="flex flex-col gap-4">
      <button
        onClick={() => setModalIsOpen(true)}
        className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
      >
        <QrCode className="w-5 h-5" />
        Scan QR Code
      </button>

      <Modal
        open={modalIsOpen}
        onCancel={closeModal}
        footer={null}
        className="max-w-md"
        destroyOnClose
      >
        <div className="flex flex-col gap-4">
          {error && (
            <Alert
              message="Error"
              description={error}
              type="error"
              showIcon
              closable
              onClose={() => setError("")}
            />
          )}

          {cameraList.length > 1 && (
            <select
              value={selectedCamera}
              onChange={(e) => {
                setSelectedCamera(e.target.value);
                startScanning();
              }}
              className="mb-4 p-2 border rounded"
            >
              {cameraList.map((camera) => (
                <option key={camera.deviceId} value={camera.deviceId}>
                  {camera.label || `Camera ${camera.deviceId.slice(0, 5)}...`}
                </option>
              ))}
            </select>
          )}

          <div className="relative rounded-lg overflow-hidden">
            <video
              id="video"
              ref={videoRef}
              className="w-full h-64 object-cover"
              playsInline
              autoPlay
            />
          </div>

          {!hasCamera && (
            <Alert
              message="Camera Not Available"
              description="Please ensure you have a camera connected and have granted camera permissions."
              type="warning"
              showIcon
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ScanQRCode;

import { Form, Input, notification } from "antd";
import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
interface dataTypr {
  setDuplicateItems: any;
  itemsArr: any[];
  selectedProduct: any;
  setManualOption: any;
  setScannedItem: any;
  setItemArray: any;
}

const ManuallyStockOut: React.FC<dataTypr> = ({
  setDuplicateItems,
  itemsArr,
  selectedProduct,
  setManualOption,
  setScannedItem,
  setItemArray,
}) => {
  const { auth, channel } = useSelector((state: any) => state);
  const [Manualform] = Form.useForm();

  const onFinisheManually = async (values: any) => {
    if (
      new Set(values?.serialNumber?.trim()?.split(" ")).size !==
      values?.serialNumber?.trim()?.split(" ").length
    ) {
      setDuplicateItems(
        values?.serialNumber
          ?.split(" ")
          .filter(
            (value: any, index: any) =>
              values?.serialNumber?.trim()?.split(" ").indexOf(value) !== index
          )
      );
      return notification.warning({
        message: "IMEI has been duplicated",
        description: `${values?.serialNumber
          ?.split(" ")
          .filter(
            (value: any, index: any) =>
              values?.serialNumber?.trim()?.split(" ").indexOf(value) !== index
          )
          .join(" , ")}`,
      });
    }
    if (values?.serialNumber?.trim()?.split(" ").length > 1000) {
      return notification.warning({
        message: "IMEI limits is 1000",
        description: `Remove ${
          values?.serialNumber?.trim()?.split(" ").length - 1000
        } IMEI `,
      });
    }
    const ites = values?.serialNumber
      ?.trim()
      ?.split(" ")
      .map((imei: String) => imei);

    if (
      itemsArr?.length >
      selectedProduct?.qtyApproved -
        (channel?.warehouseOutItem?.data?.length ?? 0)
    ) {
      notification.error({
        message: `Please Enter Only ${
          selectedProduct?.qtyApproved -
          (channel?.warehouseOutItem?.data?.length ?? 0)
        } Devices`,
      });
    } else {
      setManualOption(true);
      setScannedItem([]);
      setItemArray(ites);
      Manualform.resetFields();
    }
  };
  return (
    <div>
      {["admin", "dev", "sales-agent"]?.includes(
        auth?.user?.role?.toLowerCase()
      ) && (
        <Form
          form={Manualform}
          name="Stock"
          onFinish={onFinisheManually}
          layout="vertical"
          scrollToFirstError
          labelAlign="left"
          labelWrap
          wrapperCol={{
            flex: 1,
          }}
          colon={true}
          style={{
            maxWidth: 600,
          }}
        >
          <div className="flex items-center">
            <Form.Item
              name="serialNumber"
              label={`IMEI`}
              rules={[
                {
                  required: true,
                  message: "IMEI is Required!",
                  whitespace: true,
                },
              ]}
            >
              <div className="flex flex-col">
                <span>{`${channel?.warehouseOutItem?.data?.length || 0}/${
                  selectedProduct?.qtyApproved
                }`}</span>
                <Input style={{ width: 500, height: 35 }} />
              </div>
            </Form.Item>
            <Button type="submit">Verify</Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default ManuallyStockOut;

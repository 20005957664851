import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import TopNav from "../components/navitems/TopNavItems";
import store from "store";
import { useSelector } from "react-redux";
import DashLayout from "../apps/freelancerModule/layout/Layout";
import { pageList } from "../apps/freelancerModule/assets/pages";

const FreelancerRautes = () => {
  const token = store.get("authToken");
  const { auth } = useSelector((state: any) => state);
  let authent = { token: true };
  const pagesArray = [
    {
      caption: "Home",
      path: "/home",
      onClick: () => {
        console.log("sale:00");
      },
    },
  ];
  return auth?.token || token ? (
    <DashLayout
      allowSearch={true}
      nav={<TopNav nav={pageList[1]} pages={pagesArray} />}
      selectedNav={1}
    >
      <Outlet />
    </DashLayout>
  ) : (
    <Navigate to="/home" />
  );
};

export default FreelancerRautes;

import React from "react";
import {Table, Tag } from "antd";
import { searchValue } from "../../utils/setColor";
import { useSelector } from "react-redux";
import CustomButton from "../buttons/CustomButton";
import { getDefaultCurrencyCode } from "../../utils/CurrencyHelper";
import ScrollableFrame from "../layout/ScrollableFrame";

const BellingDataTable = (props: any) => {
  const { customer,company } = useSelector((state: any) => state);
  const columns = [
    {
      title: "Belling Invoice",
      dataIndex: "invoice",
    },
    {
      title:`Installment Amount (${getDefaultCurrencyCode(company)})`,
      dataIndex: "installmentAmount",
    },
    {
      title: `Amount Paid (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amountPaid",
    },
    {
      title: "Starting Date",
      dataIndex: "startingDate",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
    },
    {
      title: "Approved By",
      dataIndex: "approvedBy",
    },
    {
      title: "Paid on",
      dataIndex: "paidOn",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any) => {
        return (
          <>
            <Tag color={searchValue(text)} className="px-3 py-1 capitalize w-16 text-center">{text}</Tag>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "data",
      render:()=>{return(
        <><CustomButton title={'View'} textColor={'[#605BFF]'}/></>
      )}
    },
  ];
  const invoiceData = props?.data?.customerInvoice?.data?.map((el: any) => {
    if (el?.type === "pos") {
      return {
        key: el?._id,
        invoice: el?.transaction,
        installmentAmount: el?.amountToBePaid,
        amountPaid: el?.amountPaid,
        startingDate: el?.date?.substring(0, 10),
        dueDate: el?.dueDate?.substring(0, 10),
        // approvedBy: "Anne",
        paidOn: el?.cart?.paidOn?.substring(0, 10),
        status: el?.status,
      };
    } else
      return {
        key: el?._id,
        invoice: el?.transaction,
        installmentAmount: el?.amountToBePaid,
        amountPaid: el?.amountPaid,
        startingDate: el?.account?.startingDate?.substring(0, 10),
        dueDate: el?.account?.endingDate?.substring(0, 10),
        // approvedBy: "Anne",
        paidOn: el?.payment?.date?.substring(0, 10),
        status: el?.status,
      };
  });
  return (
    <>
     {/* <ScrollableFrame
      setPage={props?.setPage}
      setLimit={props?.setLimit}
      limit={props?.limit}
      total={props?.data?.total}
      count={Math.ceil(props?.data?.total / props?.limit)}
    > */}
      <Table columns={columns} dataSource={invoiceData} size="middle" loading={customer?.isFetching} pagination={false}/>
      {/* </ScrollableFrame> */}
    </>
  );
};

export default BellingDataTable;

import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/system";
import { getActiveShop } from "../../../../utils/converter";
import {
  getAllPendingShopOutAction,
  getAllStockToTransferAction,
} from "../../../../store/channel/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import TransferGridCard from "../../../../components/grids/TransferGridCard";
import FilterTabsButton from "../../../../components/buttons/FilterTabsButton";
import ShopOutTable from "../../../../components/tables/ShopOutTable";
import ButtonGroupComponent from "../../../../components/buttons/ButtonGroupComponent";

const POSTransferStock = (props: any) => {
  const { auth, channel, appSettings } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [isTransferred, setIsTransferred] = useState("request");
  const [selectedValueButton, setSelectedValueButton] = useState<any>({
    value: "pending",
  });

  const groupByOptionsWout: any = [
    { label: "Pending", value: "pending" },
    { label: "In Transit", value: "In-Transit" },
    // { label: "Completed", value: "received" },
  ];

  const shopId = getActiveShop(auth?.user?.shop?.assigned)[
    appSettings?.selectedShopNumber
  ]?.shop?._id;

  useEffect(() => {
    if (isTransferred === "request" && auth?.token) {
      shopId &&
        getAllStockToTransferAction(
          auth?.token,
          `?shopToTransfer=${shopId}&isTransferred=true&transferStatus=${
            selectedValueButton?.value
          }&limit=${limit}&page=${page - 1}`
        )(dispatch);
    } else {
      shopId &&
        getAllPendingShopOutAction(
          auth?.token,
          `?shop=${shopId}&status=${selectedValueButton?.value}`
        )(dispatch);
    }
  }, [
    auth?.token,
    dispatch,
    limit,
    page,
    shopId,
    isTransferred,
    selectedValueButton,
  ]);

  return (
    <Stack spacing={1}>
      <Box>
        <FilterTabsButton setIsTransferred={setIsTransferred} />
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={channel?.rfsTransfer?.total}
          count={Math.ceil(channel?.rfsTransfer?.total / limit)}
        >
          {isTransferred === "request" ? (
            <div className="flex flex-col space-y-4">
              <div>
                <ButtonGroupComponent
                  options={groupByOptionsWout}
                  setSelectedValueButton={setSelectedValueButton}
                />
              </div>
              <div className="flex flex-wrap gap-5 justify-start items-start">
                <TransferGridCard />
              </div>
            </div>
          ) : (
            <div className="flex flex-col space-y-4">
              <div>
                <ButtonGroupComponent
                  options={groupByOptionsWout}
                  setSelectedValueButton={setSelectedValueButton}
                />
              </div>
              <ShopOutTable />
            </div>
          )}
        </ScrollableFrame>
      </Box>
    </Stack>
  );
};

export default POSTransferStock;

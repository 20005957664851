import { authActions } from ".";
import {
  getAgentsAssignedToShopService,
  loginByScanService,
  loginService,
  profileService,
  userProfileBasicService,
  updatePINService,
} from "./authServices";
import { notification } from "antd";
import store from "store";
// Login by email and password
export const loginAction = (data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await loginService(data, dispatch);

      if (res?.status === 200) {
        dispatch(authActions.setIsLoggingIn(true));
        // store.set("authToken", res?.data?.data?.token);
        // store.set("user", res?.data?.data?.user);
        // dispatch(
        //   authActions.login({ ...res?.data, status: res?.status, isAuth: true })
        // );
        dispatch(authActions.setIsFetching(false));
        return { type: true, token: res?.data?.data?.token };
      } else {
        dispatch(authActions.login({ error: res.data, isAuth: false }));
        dispatch(authActions.login({ error: null }));
        dispatch(authActions.setIsFetching(false));
        notification.warning({ message: res?.data?.error });
        return { type: false, token: "" };
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// Login by qr code scan
export const loginByScanAction = (data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await loginByScanService(data);
      if (res?.status === 200) {
        store.set("authToken", res?.data?.data?.token);
        store.set("user", res?.data?.data?.user);
        dispatch(
          authActions.login({ ...res?.data, status: res?.status, isAuth: true })
        );
        dispatch(authActions.setIsFetching(false));
        return { type: true, token: res?.data?.data?.token };
      } else {
        dispatch(authActions.login({ error: res.data, isAuth: false }));
        dispatch(authActions.login({ error: null }));
        dispatch(authActions.setIsFetching(false));
        return { type: false, token: "" };
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getUserprofileAction = (token: String, navigate?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setProfileIsFetching(true));
      const res = await profileService(token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(authActions.setIsLoggingIn(false));
        store.set("authToken", token);
        dispatch(authActions.setUser(res.data?.user));
        dispatch(authActions.setIsUserLoggedIn(true));
        dispatch(authActions.setProfileIsFetching(false));
      } else if ([undefined, 500, 401]?.includes(res?.status)) {
        localStorage.clear();
        dispatch(authActions.setToken(" "));
        navigate("/login");
        dispatch(authActions.setProfileIsFetching(false));
      }
      dispatch(authActions.setProfileIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(authActions.setProfileIsFetching(false));
    }
  };
};

export const userProfileBasicAction = (email: String) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await userProfileBasicService(email);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(authActions.setProfile(res.data));
        dispatch(authActions.setIsFetching(false));
      }
    } catch (err) {
      // dispatch(authActions.login({ isAuth: false }));
      // dispatch(authActions.setIsFetching(false));
      console.log(err);
    }
  };
};

export const getAgentsAssignedToShop = (token: string, shopId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await getAgentsAssignedToShopService(token, shopId);
      // console.log(">>>>>>>:: ", res?.data);
      if (res?.status === 200) {
        dispatch(authActions.setAgents(res.data));
        dispatch(authActions.setIsFetching(false));
      }
    } catch (err) {
      // dispatch(authActions.login({ isAuth: false }));
      // dispatch(authActions.setIsFetching(false));
      console.log(err);
    }
  };
};
export const updatePINCodeAction = (id: string, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await updatePINService(id, data, token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        // const resPr = await profileService(token);
        // dispatch(authActions.setUser(resPr.data));
        dispatch(authActions.setIsFetching(false));
        return true;
      }
      return false;
    } catch (err) {
      // dispatch(authActions.login({ isAuth: false }));
      dispatch(authActions.setIsFetching(false));
      console.log(err);
      return false;
    }
  };
};

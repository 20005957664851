import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import UserProfile from "../../../components/layout/userProfile";
const Header = (props: any) => {
  const navigate = useNavigate();
  const [arrow, setArrow] = useState("Show");

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  return (
    <>
      <div
        className={`bg-white left-10 fixed top-0 md:block hidden w-full z-10 h-[82px] md:h-[72px] ${
          props?.open
            ? " left-10 md:left-[16rem] xl:left-[14rem] pr-2 md:pr-[14rem]"
            : "left-24 md:left-[5rem] pr-2 md:pr-20"
        }`}
      >
        <div className="w-full py-2 px-2 md:px-10">
          <div className="flex justify-between">
            <h1
              className={`text-[#030229cc] text-sm md:text-base xl:text-lg pt-4 xl:pt-3 font-medium`}
            >
              Point Of Sales
            </h1>
            <div className="flex gap-5e gap-x-5">
              <hr className="h-8 w-[1px] mt-3 bg-gray-200" />
              <UserProfile openSide={props?.open} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;

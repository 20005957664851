import * as Auth from "./";
import { notification } from "antd";

export const SessionExpired = async (
  dispatch: any,
  errorTitle: String,
  message: string
) => {
  notification.error({
    message: errorTitle,
    description: message,
  });
  if (errorTitle === "Unauthorized") 
  
  dispatch(Auth.authActions.logout());
};

import { addDays } from "date-fns";
import { useEffect, useState } from "react";
import { DateRangePicker, Range, RangeKeyDict } from "react-date-range";
import { enUS } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { getActiveShop } from "../../../utils/converter";
import { getShopStatsActions } from "../../../store/inventory/actions";

const DateRangePickers: React.FC = () => {
  const { auth, appSettings } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;
  // const shopId =
  // getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
  //   ?.shop?._id ?? auth?.userShop?.shopId;
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleSelect = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
  };
  const date = new Date(selectedDateRange?.startDate);
  const dateTwo = new Date(selectedDateRange?.endDate);
  const formattedDate =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2) +
    "T" +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2) +
    ".000Z";
  const formattedDateTwo =
    dateTwo.getFullYear() +
    "-" +
    ("0" + (dateTwo.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + dateTwo.getDate()).slice(-2) +
    "T" +
    ("0" + dateTwo.getHours()).slice(-2) +
    ":" +
    ("0" + dateTwo.getMinutes()).slice(-2) +
    ":" +
    ("0" + dateTwo.getSeconds()).slice(-2) +
    ".000Z";
  useEffect(() => {
    auth?.token &&
      getShopStatsActions(
        auth?.token,
        `date2=${formattedDateTwo.split("T")[0]}&?shop=${shopId}&date1=${
          formattedDate.split("T")[0]
        }`
      )(dispatch);
  }, [
    formattedDateTwo.split("T")[0],
    formattedDate.split("T")[0],
    auth?.token,
    shopId,
    shopId,
    dispatch,
  ]);
  return (
    <DateRangePicker
      className="w-full"
      onChange={handleSelect}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={[selectedDateRange]}
      direction="horizontal"
      locale={enUS}
    />
  );
};

export default DateRangePickers;

import React from 'react'
import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import TopNav from "../components/navitems/TopNavItems";
import { subMenusList } from "../assets/data/pages";
 import store from 'store';
import { useSelector } from 'react-redux';

const PriceListRoutes = () => {
  const token = store.get("authToken");
  const { auth } = useSelector((state: any) => state)
  let authent = { token: true };
  const pagesArray = [
    {
      caption: "Price Lists",
      path: "/priceLists",
    },
  ];
  return auth?.token || token ? (
    <DashLayout
      allowSearch={true}
      nav={<TopNav nav={subMenusList[3]} pages={pagesArray} />}
      selectedNav={3}
    >
      <Outlet />
    </DashLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
};
export default PriceListRoutes;
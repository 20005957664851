import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import {
  Divider,
  Form,
  Input,
  Space,
  Select,
  InputNumber,
  Tooltip,
  Radio,
  Modal,
} from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";

import { getAllCustomersAction } from "../../store/customer/actions";
import {
  createNosaleAction,
  getAllNosaleAction,
  getOnePriceListItemAction,
} from "../../store/pos/actions";
import { getActiveShop } from "../../utils/converter";
import { getAllProductsAction } from "../../store/transfer/actions";
import { myProductActions } from "../../store/product";
import NoSaleFilter from "../filters/NosaleFilter";
import { MdFilterList } from "react-icons/md";
import { getPricelistItemAction } from "../../store/channel/actions";
import { myPosActions } from "../../store/pos";
import AddNewCustomer from "../../components/forms/NewCustomer";
import { comments } from "../../assets/data/comment";
import "../buttons/button.css";
import CustomButton from "../buttons/CustomButton";
import { GoPlus } from "react-icons/go";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [productId, setProductId] = useState();
  const [selectedRadioButton, setSelectedRadioButton] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const { product, auth, customer, channel, shop, pos, appSettings } =
    useSelector((state: any) => state);
  const [selectedModelId, setSelectedModelId] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const selectedShopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id ?? shop?.selected?._id;

  const onFinish = async (values: any) => {
    const val = {
      ...values,
      shopId: selectedShopId,
      productId: productId,
      // specification: [
      //   ["Type", product?.selected?.type],
      //   ["Brand", product?.selected?.brand],
      //   ...Object.entries(values.specification),
      // ],
    };
    if (auth?.token) {
      await createNosaleAction(auth?.token, val)(dispatch);
      // onCancel();
      await getAllNosaleAction(
        auth?.token,
        `?shopId=${selectedShopId}`
      )(dispatch);
      form.resetFields();
      dispatch(myPosActions.setOnePriceListItem(null));
      props?.onCancel();
    }
  };
  console.log("propssss", props);
  useEffect(() => {
    (auth?.user?.shop?.channel || auth?.userShop?.channelId) &&
      getPricelistItemAction(
        auth?.token,
        `?channel=${
          auth?.user?.shop?.channel ?? auth?.user?.shop?.channelId
        }&limit=100`
      )(dispatch);
  }, [
    auth?.token,
    auth?.user?.shop?.channel,
    auth?.userShop?.channelId,
    dispatch,
  ]);

  useEffect(() => {
    if (searchValue) {
      auth?.token &&
        getAllCustomersAction(
          auth?.token,
          `?sk=${searchValue}&limit=100`
        )(dispatch);
    } else {
      auth?.token && getAllCustomersAction(auth?.token, `?limit=100`)(dispatch);
    }
  }, [auth, dispatch, searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      auth?.token && getAllCustomersAction(auth?.token, `?limit=100`)(dispatch);
    }
  }, [auth, dispatch, searchValue]);

  useEffect(() => {
    auth?.token &&
      productId &&
      getAllProductsAction(
        auth?.token,
        `?shop=${
          getActiveShop(auth?.user?.shop?.assigned)[
            appSettings?.selectedShopNumber
          ]?.shop?._id
        }&product=${productId}`
      )(dispatch);
  }, [
    appSettings?.selectedShopNumber,
    auth?.token,
    auth?.user?.shop?.assigned,
    dispatch,
    productId,
  ]);

  const handleRadioChange = (e: any) => {
    const value = e.target.value;
    setSelectedRadioButton((prevValue) => (prevValue === value ? null : value));
  };
  const handleSearch = (value: any) => {
    const filtered = customer?.all?.data.filter((d: any) =>
      d?.name?.toLowerCase()?.includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setSearchValue(value);
  };

  useEffect(() => {
    dispatch(myProductActions.setSelected(null));
    auth?.token &&
      selectedModelId &&
      getOnePriceListItemAction(auth?.token, selectedModelId)(dispatch);
  }, [auth?.token, dispatch, selectedModelId]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      style={{ maxWidth: "100%" }}
      layout="vertical"
      scrollToFirstError
      className="pt-1 customeForm"
    >
      <div className="flex items-center justify-between">
        {!selectedRadioButton && (
          <NoSaleFilter setProductId={setProductId} productId={productId} />
        )}
        <Radio.Group value={selectedRadioButton}>
          <Radio
            value="option1"
            onClick={handleRadioChange}
            className="text-[#030229] text-base "
          >
            New Product?
          </Radio>
        </Radio.Group>
      </div>

      <Stack
        direction={"row"}
        spacing={2}
        alignItems="flex-start"
        justifyContent={"start"}
        sx={{ width: "100%" }}
      >
        <Stack sx={{ width: "50%" }}>
          {selectedRadioButton ? (
            <Form.Item
              name="newProduct"
              label=<h1 className="text-[#030229] text-base w-full">
                Product Model
              </h1>
              // tooltip="Please select modal of the product!"
              className="w-full"
              rules={[
                {
                  required: true,
                  message: "Please select modal of the product",
                },
              ]}
            >
              <Input
                style={{ fontSize: "14px" }}
                className="h-[52px] w-full rounded-md"
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                name="priceListItemId"
                label=<h1 className="text-[#030229] text-base ">
                  Product Model
                </h1>
                /* tooltip="Please select modal of the product!" */
                rules={[
                  {
                    required: true,
                    message: "Please select modal of the product",
                  },
                ]}
              >
                <Select
                  defaultValue={""}
                  onChange={(value: any) => setSelectedModelId(value)}
                >
                  {channel?.priceListItems?.data?.map(
                    (item: any, index: any) => (
                      <Option key={index} value={item?._id}>
                        <Tooltip
                          title={`${item?.specification
                            ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
                            ?.join(", ")}`}
                        >
                          {item?.product?.model + "~" + item?.product?.type}
                        </Tooltip>
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
              {pos?.onePriceListItem?.data && (
                <div className="mld-[10.5rem] mb-1">
                  <p>
                    {pos?.onePriceListItem?.data?.specification
                      ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
                      ?.join(", ")}
                  </p>
                </div>
              )}
            </>
          )}

          {selectedRadioButton && (
            <Form.Item
              name="specification"
              label=<h1 className="text-[#030229] text-base ">
                Specifications
              </h1>
            >
              <Input
                style={{ fontSize: "14px" }}
                className="h-[52px] w-full rounded-md"
              />
            </Form.Item>
          )}

          <Form.Item
            name="customerId"
            label=<h1 className="text-[#030229] text-base ">Customer</h1>
            rules={[
              {
                required: true,
                message: "Please select a customer",
              },
            ]}
          >
            <Select
              showSearch
              filterOption={false}
              className=" rounded-md h-[52px] w-full"
              placeholder="Select/Add Customer"
              onSearch={handleSearch}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <div style={{ padding: "0 8px 4px", width: "100%" }}>
                    {/* <Link to="/customers"> */}
                    <Button
                      variant="contained"
                      onClick={() => setShowAddCustomer(true)}
                      sx={{ width: "100%", backgroundColor: "#5F5AFE" }}
                    >
                      {" "}
                      <GoPlus color="white" size={20} />{" "}
                      <span className="text-sm font-normal">{" Customer"}</span>
                    </Button>
                    {/* </Link> */}
                  </div>
                </>
              )}
            >
              {filteredOptions.length > 0
                ? filteredOptions.map((option: any) => (
                    <Select.Option key={option.value} value={option._id}>
                      {option.name}
                    </Select.Option>
                  ))
                : customer?.all?.data.map((option: any) => (
                    <Select.Option key={option.value} value={option._id}>
                      {option.name}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Stack>
        <Stack sx={{ width: "50%" }}>
          <Form.Item
            name="comment"
            label=<h1 className="text-[#030229] text-base ">Coments</h1>
            rules={[
              {
                required: true,
                message: "Please add a comment",
              },
            ]}
          >
            <Select defaultValue={""} className="rounded-md h-[52px]">
              {comments?.map((item: any, index: any) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="quantity"
            label=<h1 className="text-[#030229] text-base ">Quantity</h1>
            rules={[
              {
                required: true,
                message: "Please add a quantity",
              },
            ]}
          >
            <InputNumber
              type="number"
              placeholder="quantity"
              // style={{ width: 120 }}
              className="h-[52px] w-full rounded-md"
            />
          </Form.Item>
          {/* <Radio>New</Radio> */}
        </Stack>
      </Stack>
      <Form.Item className="px-40">
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ width: "100%", backgroundColor: "#5F5AFE", height: "45px" }}
          loading={
            product?.isFetching ||
            channel?.isFetching ||
            customer?.isFetching ||
            pos.isFetching
          }
        >
          Save
        </LoadingButton>
      </Form.Item>
      <Modal
        open={showAddCustomer}
        width={900}
        onCancel={() => setShowAddCustomer(false)}
        okButtonProps={{ style: { display: "none" } }}
        title={
          <h1 className="text-[#030229] text-base font-medium">
            Add New Customer
          </h1>
        }
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <AddNewCustomer onCancel={() => setShowAddCustomer(false)} />
      </Modal>
    </Form>
  );
};

export default App;

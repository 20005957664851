import React from 'react'
import AddNoSaleProduct from "../../../components/forms/NosaleProductForm";
const CreateNoSales = (props:any) => {
  return (
    <div>
      <AddNoSaleProduct onCancel={props?.onCancel} />
    </div>
  )
}

export default CreateNoSales
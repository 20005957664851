import React, { useEffect, useState } from "react";
import { getUserprofileAction } from "../../store/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth";
import store from "store";
import { useNavigate } from "react-router-dom";
import { COMPANY_PROFILE_ID } from "../../utils/constants";
import { getOneCompanyAction } from "../../store/company/actions";

const SplashPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [typedText, setTypedText] = useState("");
  const textToType = "SΛMPHONE ";
  const typingDelay = 100; // Delay between typing each character
  useEffect(() => {
    let currentText = "";
    let currentIndex = 0;

    const typingInterval = setInterval(() => {
      if (currentIndex < textToType.length) {
        currentText += textToType[currentIndex];
        setTypedText(currentText);
        currentIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, typingDelay);

    return () => {
      clearInterval(typingInterval);
    };
  }, []);

  const token = store.get("authToken");
  const user = store.get("user");

  useEffect(() => {
    token !== undefined && getUserprofileAction(token, navigate)(dispatch);
    if (token) {
      dispatch(authActions.setToken({ token }));
    }
    if (user) {
      dispatch(authActions.setUserShop(user?.shop));
    }
  }, [dispatch, token]);
  const { auth } = useSelector((state: any) => state);
  const companyId = COMPANY_PROFILE_ID;

  useEffect(() => {
    if (auth?.token) {
      getOneCompanyAction(companyId as string, auth.token)(dispatch);
    }
  }, [auth?.token, dispatch, companyId]);
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white">
      <h1 className="text-5xl md:text-7xl font-light text-black font-arial">{typedText}</h1>
    </div>
  );
};

export default SplashPage;

import React, { useEffect, useState } from "react";
import { Badge, Radio, Tag } from "antd";
import { Link} from "react-router-dom";
import {
  getAllApplicantsAction,
} from "../redux/businessPlans/action";
import { useDispatch, useSelector } from "react-redux";
import { CurstomeCardSkeleton } from "../../../components/skeleton/Card";
import { getActiveShop, getTotalPrice } from "../../../utils/converter";
import { searchValue } from "../../../utils/setColor";
import "./style.css";
import {
  deleteCartAction,
  getAllCartsAction,
} from "../../../store/pos/actions";
import { MdDeleteOutline } from "react-icons/md";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { myCustomerActions } from "../../../store/customer";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";

const Cart = () => {
  const { auth, apllicants, wareHouse, appSettings, pos,company } = useSelector(
    (state: any) => state
  );
  const [status, setStatus] = useState(null);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [dataToDelete, setDataToDelete] = useState<any>(null);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const handleClickDelete = async (value: any) => {
    setDataToDelete(value);
    setVisible(true);
  };
  const handleDeleteModalCancels = () => {
    setVisible(false);
  };
  const onChange = (e: any) => {
    setStatus(e.target.value);
  };
  // const handleNavigate = () => {
  //  auth?.token getApplicantsDetailsAction(auth?.token,)
  // };

  useEffect(() => {
    if (status) {
      auth?.token &&
        getAllApplicantsAction(
          auth?.token,
          `?createdBy=${auth?.user?._id}&status=${status}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllApplicantsAction(
          auth?.token,
          `?createdBy=${auth?.user?._id}&status[]=pending&status[]=accepted&status[]=rejected&status[]=requested&status[]=inquiry`
        )(dispatch);
    }
  }, [auth?.token, dispatch, status, limit, page]);

  const deleteProduct = async () => {
    const activeShop = getActiveShop(auth?.user?.shop?.assigned);
    const shopId = activeShop
      ? activeShop[appSettings?.selectedShopNumber]?.shop?._id
      : auth?.userShop?.shopId;
    auth?.token &&
      (await deleteCartAction(
        auth?.token,
        dataToDelete?._id,
        `?shop=${shopId}&status=paid`
      )(dispatch));
    auth?.token &&
      getAllApplicantsAction(
        auth?.token,
        `?createdBy=${auth?.user?._id}&status[]=pending&status[]=accepted&status[]=rejected&status[]=requested&status[]=inquiry`
      )(dispatch);
    setVisible(false);
  };

  useEffect(() => {
    dispatch(myCustomerActions.setSearchResult(null));
  }, [dispatch]);

  return (
    <div className="radioCustom">
      <div className="w-full overflow-x-auto">
        <Radio.Group
          defaultValue=""
          buttonStyle="solid"
          onChange={onChange}
          className="ml-5"
        >
          <Radio.Button value="">All</Radio.Button>
          <Radio.Button value="pending">Pending</Radio.Button>
          <Radio.Button value="requested">Requested</Radio.Button>
          {/* <Radio.Button value="completed">Completed</Radio.Button> */}
          <Radio.Button value="accepted">Accepted</Radio.Button>
        </Radio.Group>
      </div>
      {apllicants?.isFetching || wareHouse?.isFetching ? (
        <div className=" mt-5 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
          {[1, 2, 3, 1, 2, 3]?.map((el: any) => (
            <CurstomeCardSkeleton />
          ))}
        </div>
      ) : (
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={apllicants?.allApplicants?.total}
          count={Math.ceil(apllicants?.allApplicants?.total / limit)}
        >
          <div className="sm:flex flex-wrap gap-5">
            {apllicants?.allApplicants?.data?.map((el: any) => {
              const isCustomerExist =
                el?.customer?.phone ||
                el?.customer?.names ||
                el?.customer?.email;
              return (
                <>
                  {isCustomerExist && (
                    <div className="mt-5 ">
                      {/* <CartCardGridPOS data={el}/> */}
                      <Badge.Ribbon
                        color="#E1F7DE"
                        text=<h1 className="text-[#3AC722] text-[12px] font-semibold">
                          {" "}
                          {`Total amount: ${getTotalPrice(
                            el?.list
                          )?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}
                        </h1>
                      >
                        <div className="relative w-full sm:w-80 h-fit p-2  bg-white text-black rounded-md">
                          <Link
                            to={`${
                              el.status === "pending"
                                ? `/apply/${el?._id}`
                                : el?._id
                            }`}
                          >
                            <div className="flex w-full pb-10 pt-10">
                              <div className="flex flex-col flex-wrap pl-4">
                                <h1 className="text-[#030229B2] text-sm">
                                  {el?.cartId}
                                </h1>
                                <div className="gap-1">
                                  <p className="text-xs">
                                    <span className="text-[#030229] text-sm">
                                      Customer:{" "}
                                    </span>
                                    <span className="text-[#030229B2] text-sm">
                                      {el?.customer?.name}
                                    </span>
                                  </p>
                                  <p className="text-xs">
                                    <span className="text-[#030229] text-sm">
                                      Created Date:{" "}
                                    </span>
                                    <span className="text-[#030229B2] text-sm">
                                      {" "}
                                      {new Date(
                                        el?.createdAt
                                      ).toLocaleDateString("en-US", {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                      })}
                                    </span>
                                  </p>
                                  <p className="text-xs">
                                    <span className="text-[#030229] text-sm">
                                      Created By:{" "}
                                    </span>
                                    <span className="text-[#030229B2] text-sm">
                                      {el?.createdBy?.names}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                          <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-2 space-x-2">
                            <div className=" capitalize">
                              <Tag
                                color={searchValue(el?.isSubscription)}
                                style={{
                                  minWidth: "4rem",
                                  textAlign: "center",
                                  border: "0.1px solid #faad14d0",
                                }}
                                // className="border border-[#faad14d0]"
                              >
                                <span
                                  className={`${
                                    el?.isSubscription?.toLowerCase() === "pos"
                                      ? "text-[#FAAD14]"
                                      : "text-[#B17F69]"
                                  } text-sm`}
                                >
                                  {el.isSubscription}
                                </span>
                              </Tag>
                              <Tag
                                color={searchValue(el.status)}
                                style={{
                                  minWidth: "4rem",
                                  textAlign: "center",
                                }}
                              >
                                <span className="text-[#030229B2] text-sm">
                                  {" "}
                                  {el.status}
                                </span>
                              </Tag>
                            </div>
                            {el?.status?.toLowerCase() !== "paid" && (
                              <MdDeleteOutline
                                size={22}
                                color="red"
                                onClick={() => handleClickDelete(el)}
                              />
                            )}
                          </div>
                        </div>
                      </Badge.Ribbon>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </ScrollableFrame>
      )}
      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={pos?.isDeleteFetching || apllicants?.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />
    </div>
  );
};

export default Cart;

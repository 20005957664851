import React, { useEffect } from "react";
import OverviewChart from "../components/overviewChart";
import OverviewCard from "../components/OveviewCard";
import { HiOutlineUsers } from "react-icons/hi2";
import { FcApprove } from "react-icons/fc";
import { CiClock2 } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgentEarningsAction,
  getAllAgentRevenueAction,
} from "../../../store/kpi/actions";
import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { getActiveShop } from "../../../utils/converter";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";

const HomePage = (_props: any) => {
  const { auth, kpi, appSettings,company } = useSelector((state: any) => state);
  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [enteredDate, setEnteredDate] = React.useState<any>(formattedDate);
  const [compareDate, setCompareDate] = React.useState<any>(
    formattedPreviousDate
  );
  const onChangeDate: DatePickerProps["onChange"] = async (
    _date: any,
    dateString: any
  ) => {
    setEnteredDate(dateString);
  };

  const onChangePreviousDate: DatePickerProps["onChange"] = async (
    _date: any,
    dateString: any
  ) => {
    setCompareDate(dateString);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (enteredDate || compareDate) {
      auth?.token &&
        getAgentEarningsAction(
          auth?.token,
          `?agentId=${auth?.user?._id}&date1=${enteredDate}&date2=${compareDate}`
        )(dispatch);
    } else {
      auth?.token &&
        getAgentEarningsAction(
          auth?.token,
          `?agentId=${auth?.user?._id}`
        )(dispatch);
    }
  }, [auth?.token, dispatch, compareDate, enteredDate, auth?.user?._id]);

  const userId = auth?.user?._id;
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  useEffect(() => {
    if (auth?.token && enteredDate && compareDate) {
      getAllAgentRevenueAction(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&shop=${shopId}&agentId=${userId}`
      )(dispatch);
    } else {
      auth?.token && getAllAgentRevenueAction(auth?.token, "?")(dispatch);
    }
  }, [auth?.token, compareDate, dispatch, enteredDate, shopId, userId]);

  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };
  const todayCustomer = kpi?.all?.data[0]?.currentDateCustomer;
  const comparedCustomer = kpi?.all?.data[0]?.comparedDateCustomer;
  const todayRevenue = kpi?.all?.data[0]?.currentDateRevenue;
  const comparedRevenue = kpi?.all?.data[0]?.comparedDateRevenue;

  const todayQuantity = kpi?.all?.data[0]?.currentDateQuantity;
  const comparedQuantity = kpi?.all?.data[0]?.comparedDateQuantity;

  return (
    <div className="text-black">
      <div>
        <div className="md:flex justify-between mt-2">
          <div>
            <p>Your Earning</p>
          </div>
          <div className="flex space-x-4 mt-3">
            <DatePicker
              onChange={onChangeDate}
              defaultValue={dayjs()}
              style={{ width: 120 }}
              disabledDate={disabledDate}
            />
            <div className="flex space-x-2">
              <p className="py-1 text-[12px]">Compared To</p>
              <DatePicker
                onChange={onChangePreviousDate}
                defaultValue={dayjs().subtract(1, "day")}
                style={{ width: 120 }}
                disabledDate={disabledDate}
              />
            </div>
          </div>
        </div>
        <div className="my-5 md:grid hidden grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
          {/* <OverviewCard
            icon={<BiDollarCircle size={25} color="#605BFFB2" />}
            text={"Total Comission"}
            amount={"300"}
            yesterdayAmount={"200"}
          /> */}
          <OverviewCard
            icon={<HiOutlineUsers size={25} color="#605BFFB2" />}
            text={"Total Customer"}
            amount={`Frw ${todayCustomer}`}
            yesterdayAmount={`frw ${comparedCustomer ||0}`}
          />
          <OverviewCard
            icon={<FcApprove size={30} color="#23B480" />}
            text={"Total Qunatity"}
            amount={`Frw ${todayQuantity}`}
            yesterdayAmount={`frw ${comparedQuantity ||0}`}
          />
          {/* <OverviewCard
            icon={<CiClock2 size={25} color="#D9D9D9" />}
            text={"Total Commission"}
            amount={`Frw ${todayRevenue}`}
            yesterdayAmount={`Frw ${comparedRevenue ||0}`}
          /> */}
          {/* <OverviewCard
            icon={<MdOutlineCancel size={25} color="#DE2C2F" />}
            text={"Conceled"}
            amount={"$200"}
            yesterdayAmount={""}
          />
          <OverviewCard
            icon={<IoCheckmarkDoneCircleOutline size={25} color="#605BFFB2" />}
            text={"Completed"}
            amount={"$200"}
            yesterdayAmount={""}
          /> */}
        </div>
        {/* <div >
          <h1 className="text-[#030229]  pt-2">Total Revenue</h1>
          <h1 className="text-[#030229] py-1 font-medium">
            ${getDefaultCurrencyCode(company)} {kpi?.allEarning?.data?.totalRevenue?.toLocaleString()}
          </h1>
          <OverviewChart />
        </div> */}
        <div className="xl:flesx flex-row-reverse justify-between mt-7">
          <div className="shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)] w-full md:w-[60%] bg-white rounded-[12px] px-10 py-1 mt-5 xl:mt-0 ">
            <h1 className="text-[#030229]  pt-2">Total Revenue</h1>
            <h1 className="text-[#030229] py-1 font-medium">
              ${getDefaultCurrencyCode(company)} {kpi?.allEarning?.data?.totalRevenue?.toLocaleString()}
            </h1>

            <OverviewChart />
          </div>
        </div>
      </div>
      <div className="my-5 grid md:hidden grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
        {/* <OverviewCard
          icon={<BiDollarCircle size={25} color="#605BFFB2" />}
          text={"Total Comission"}
          amount={"$200"}
          yesterdayAmount={""}
        /> */}
        <OverviewCard
          icon={<HiOutlineUsers size={25} color="#605BFFB2" />}
          text={"Total Customer"}
          amount={`Frw ${todayCustomer}`}
          yesterdayAmount={`frw ${comparedCustomer ||0}`}
        />
        <OverviewCard
          icon={<FcApprove size={30} color="#23B480" />}
          text={"Total Qunatity"}
          amount={`Frw ${todayQuantity}`}
          yesterdayAmount={`frw ${comparedQuantity ||0}`}
        />
        {/* <OverviewCard
          icon={<CiClock2 size={25} color="#D9D9D9" />}
          text={"Total Revenue"}
          amount={`Frw ${todayRevenue}`}
          yesterdayAmount={`Frw ${comparedRevenue ||0}`}
        /> */}
        {/* <OverviewCard
          icon={<MdOutlineCancel size={25} color="#DE2C2F" />}
          text={"Conceled"}
          amount={"$200"}
          yesterdayAmount={""}
        /> */}
        {/* <OverviewCard
          icon={<IoCheckmarkDoneCircleOutline size={25} color="#605BFFB2" />}
          text={"Completed"}
          amount={"$200"}
          yesterdayAmount={""}
        /> */}
      </div>
    </div>
  );
};

export default HomePage;

import React, { useEffect, useState } from "react";
import { notification, Progress } from "antd";
import { LoadingButton } from "@mui/lab";
import {
  handlConfirmPaymentModelAction,
  handlecheckStateInfoAction,
  handleOpenPaymentModelAction,
  handleOpenTourAction,
  handlePaymentStatusAction,
} from "../../redux/layout/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getLastPaymentStatus,
  getMomoPayments,
} from "../../../../../utils/converter";
import { myPosActions } from "../../../../../store/pos";
import {
  checkPaymentStatusAction,
  generateFirstInvoiceAction,
} from "../../../../../store/pos/actions";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../../../../../assets/images/circle-loading-lines.gif";
import SuccessAnimation from "../../../../../assets/images/successfully-done.gif";
import {
  getDeviceInfoAction,
  getDeviceUploadedAction,
  uploadDeviceToVaultAction,
} from "../../redux/vault/actions";
import { subLayoutActions } from "../../redux/layout";
import {
  getShopStockCartAction,
  updateShopStockCartDetailsAction,
} from "../../../../../store/wareHouse/actions";
import { getDefaultCurrencyCode } from "../../../../../utils/CurrencyHelper";

const LoadingPaymentProcess = (props: any) => {
  const { pos, auth, wareHouse, subLayout, company, vault } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const { cartId } = useParams();

  const imei =
    wareHouse?.createdCart?.data?.list?.at(0)?.warehouseProduct?.serialNumber;

  const isUploadedTovault =
    wareHouse?.createdCart?.data?.list?.at(0)?.warehouseProduct
      ?.isUpploadedToVault;

  const lastPaymentStatus = getLastPaymentStatus(
    pos?.statusResult?.data?.autoPayments
  );

  //uploaded device
  useEffect(() => {
    if (subLayout?.paymentStatus === "invoice" && !isUploadedTovault) {
      getShopStockCartAction(auth?.token, cartId)(dispatch);
      const uploadDevice = async () => {
        const res = await uploadDeviceToVaultAction(auth?.token, {
          deviceUid: [imei],
          paymentMethod: "prepaid",
        })(dispatch);
        if (res?.type && res?.data?.uploadStatus === "INPROGRESS") {
          dispatch(
            subLayoutActions.setCheckIsDeviceUploaded({
              type: true,
              uploadID: res?.data?.uploadID,
            })
          );
        }
      };
      uploadDevice();
    } else if (
      isUploadedTovault &&
      wareHouse?.createdCart?.data?.subscriptionStatus === "PAYMENT_DONE"
    ) {
      dispatch(subLayoutActions.setPaymentStatus("done"));
      updateShopStockCartDetailsAction(auth?.token, cartId, {
        subscriptionStatus: "VAULT_UPLOADED",
      })(dispatch);
    }
  }, [
    auth?.token,
    dispatch,
    subLayout?.paymentStatus,
    wareHouse?.createdCart?.data?.subscriptionStatus,
  ]);

  //check if device uploaded
  useEffect(() => {
    if (
      subLayout?.checkIsDeviceUploaded?.type &&
      !["done", "vault_fail"]?.includes(
        subLayout?.paymentStatus && subLayout?.checkIsDeviceUploaded?.uploadID
      )
    ) {
      const intervalId = setInterval(async () => {
        await getDeviceUploadedAction(
          auth?.token,
          `?uploadId=${subLayout?.checkIsDeviceUploaded?.uploadID}`
        )(dispatch);
        if (subLayout?.paymentStatus === "done") {
          await updateShopStockCartDetailsAction(auth?.token, cartId, {
            subscriptionStatus: "VAULT_UPLOADED",
          })(dispatch);
        }
        await getDeviceInfoAction(auth?.token, {
          deviceList: [{ deviceUid: imei }],
        })(dispatch);
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [
    auth?.token,
    dispatch,
    subLayout?.checkIsDeviceUploaded?.type,
    subLayout?.checkIsDeviceUploaded?.uploadID,
    subLayout?.paymentStatus,
  ]);
  const checkPayment = async () => {
    if (auth.token) {
      await updateShopStockCartDetailsAction(auth?.token, cartId, {
        subscriptionStatus: "PAYMENT_DONE",
      })(dispatch);
    } else {
      return notification.error({
        message: "Error",
        description: "You are not logged in",
      });
    }
  };

  const paymentStatus = async () => {
    await checkPaymentStatusAction(auth?.token, cartId as string)(dispatch);
  };

  useEffect(() => {
    if (
      !pos.isPayingInitial ||
      ["done", "vault_fail"]?.includes(subLayout?.paymentStatus)
    ) {
      dispatch(myPosActions.setStatusResult(null));
      return;
    }

    if (lastPaymentStatus?.toLowerCase() === "failed") {
      handlePaymentStatusAction("fail")(dispatch);
      dispatch(myPosActions.setStatusResult(null));
      dispatch(myPosActions.setIsPayingInitial(false));
      notification?.error({ message: "Request Failed, Please try again" });
      return;
    }

    const timerId = setInterval(async () => {
      if (lastPaymentStatus?.toLowerCase() === "failed") {
        handlePaymentStatusAction("fail")(dispatch);
        clearInterval(timerId);
        dispatch(myPosActions.setStatusResult(null));
        dispatch(myPosActions.setIsPayingInitial(false));
        props?.onCancel();
      } else {
        if (
          !["done", "vault_fail", "invoice"]?.includes(subLayout?.paymentStatus)
        ) {
          await paymentStatus();
        }
      }
    }, 10000);

    return () => {
      clearInterval(timerId);
    };
  }, [lastPaymentStatus, pos.isPayingInitial, subLayout?.paymentStatus]);

  useEffect(() => {
    const latestAutoPayment =
      pos?.statusResult?.data?.autoPayments?.[
        pos?.statusResult?.data?.autoPayments?.length - 1
      ];

    if (["done", "vault_fail"]?.includes(subLayout?.paymentStatus)) {
      return;
    }

    if (
      latestAutoPayment?.status?.toLowerCase() === "successful" &&
      !subLayout?.isCustomerPaid
    ) {
      handlePaymentStatusAction("invoice")(dispatch);
      checkPayment();
    }
  }, [
    pos?.statusResult?.data?.autoPayments,
    subLayout?.paymentStatus,
    subLayout?.isCustomerPaid,
  ]);

  const totalPayment = getMomoPayments(wareHouse)
    ?.map((payment: { amount: number }) => payment?.amount)
    ?.reduce((a: number, b: number) => a + b, 0);

  const handlePrevious = () => {
    handlConfirmPaymentModelAction(false)(dispatch);
    handleOpenPaymentModelAction(true)(dispatch);
  };

  const handleConfrimPayment = async () => {
    if (
      ["vault_fail"]?.includes(subLayout?.paymentStatus) ||
      wareHouse?.createdCart?.data?.subscriptionStatus === "PAYMENT_DONE"
    ) {
      handlePaymentStatusAction("invoice")(dispatch);
    } else {
      await updateShopStockCartDetailsAction(auth?.token, cartId, {
        subscriptionStatus: "COMPLETED",
      })(dispatch);
      handleOpenTourAction(true)(dispatch);
      const res = await getDeviceInfoAction(auth?.token, {
        deviceList: [{ deviceUid: imei }],
      })(dispatch);
      if (res?.type) {
        if (res?.data?.deviceResponseList[0]?.stateInfo === "Ready For Use") {
          await handlecheckStateInfoAction("Ready For Use")(dispatch);
          handleOpenTourAction(true)(dispatch);
        }
      }
    }
    // handleOpenTourAction(true)(dispatch);
  };
  const isCustomerPaid = wareHouse?.createdCart?.data?.autoPayments?.some(
    (status: any) => status?.status === "SUCCESSFUL"
  );
  return (
    <>
      {/* {
      (subLayout?.isFinalStage && ["Active"]?.includes(subLayout?.stateInfoStatus))? (
        <FinalConfirmation />
      ) : ( */}
      <div className="flex flex-col items-center justify-center mx-auto">
        <div className="flex items-center mx-auto flex-col space-y-2 py-6">
          {[
            "PAYMENT_DONE",
            "VAULT_UPLOADED",
            "VAULT_ACTIVATED",
            "COMPLETED",
          ]?.includes(wareHouse?.createdCart?.data?.subscriptionStatus) ||
          subLayout?.isCustomerPaid ||
          isCustomerPaid ? (
            <>
              <p className="text-sm sm:text-base text-center mx-12 pt-2">
                The {getDefaultCurrencyCode(company)}{" "}
                <span className="font-semibold">
                  {totalPayment?.toLocaleString()}
                </span>{" "}
                payment has been successfully done.
              </p>
            </>
          ) : (
            <div className="flex items-center flex-col justify-center mb-4">
              <p className="text-base lg:text-lg md:text-sm">
                Complete Payment of {getDefaultCurrencyCode(company)}
                <span className="font-semibold">
                  {totalPayment?.toLocaleString()}
                </span>
              </p>
              <p className="text-sm sm:text-base text-center mx-12 pt-2">
                If no popup appears on your phone, dial 182*7*1# and follow the
                steps to confirm your payment
              </p>
            </div>
          )}
          {["fail", "vault_fail"]?.includes(subLayout?.paymentStatus) ? (
            <svg
              viewBox="0 0 1024 1024"
              fill="currentColor"
              color="red"
              height="6em"
              width="6em"
            >
              <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
              <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
            </svg>
          ) : subLayout?.paymentStatus === "done" ? (
            <img
              width="15%"
              height="15%"
              src={SuccessAnimation}
              alt="Successful"
            />
          ) : (
            <img
              width="15%"
              height="15%"
              src={LoadingAnimation}
              alt="loading"
            />
          )}
        </div>

        <div className="flex flex-col space-y-4 pb-6">
          <div className="flex gap-x-4 items-center">
            <Progress
              type="circle"
              percent={
                ["invoice", "vault", "done", "vault_fail"]?.includes(
                  subLayout?.paymentStatus
                ) ||
                wareHouse?.createdCart?.data?.subscriptionStatus ===
                  "PAYMENT_DONE" ||
                isCustomerPaid
                  ? 100
                  : 25
              }
              size={40}
              status={
                subLayout?.paymentStatus === "fail" ? "exception" : undefined
              }
            />
            {[
              "PAYMENT_DONE",
              "VAULT_UPLOADED",
              "VAULT_ACTIVATED",
              "COMPLETED",
            ]?.includes(wareHouse?.createdCart?.data?.subscriptionStatus) ||
            isCustomerPaid ? (
              <span>First payment verified </span>
            ) : (
              <span>Confirming Initial Payment</span>
            )}
          </div>
          <div className="flex gap-x-4 items-center">
            <Progress
              type="circle"
              percent={
                ["invoice", "vault_fail"]?.includes(subLayout?.paymentStatus)
                  ? 20
                  : ["vault", "done"]?.includes(subLayout?.paymentStatus)
                  ? 50
                  : ["Active"]?.includes(subLayout?.stateInfoStatus)
                  ? 100
                  : 0
              }
              size={40}
              status={
                subLayout?.paymentStatus === "vault_fail"
                  ? "exception"
                  : undefined
              }
            />
            <span>Activate Vaults</span>
          </div>
          <div className="flex gap-x-4 items-center">
            <Progress
              type="circle"
              percent={
                ["vault"]?.includes(subLayout?.paymentStatus)
                  ? 60
                  : ["done"]?.includes(subLayout?.paymentStatus)
                  ? 100
                  : 0
              }
              size={40}
            />
            <span>Generate Bill</span>
          </div>
          {["Idle"]?.includes(subLayout?.stateInfoStatus) && (
            <div className="text-red-400 text-base py-2">
              Vault System Failed Please Contact supervisor for Support
            </div>
          )}
        </div>
        <div className="py-3 flex gap-x-4">
          {wareHouse?.createdCart?.data?.subscriptionStatus !==
            "PAYMENT_DONE" && (
            <LoadingButton
              variant="outlined"
              sx={{
                width: { xs: "120px", sm: "130px", md: "150px" },
                height: 40,
                fontSize: "16px",
              }}
              // style={{ height: 40, fontSize: "16px" }}
              onClick={handlePrevious}
              disabled={
                ["vault_fail", "done"]?.includes(subLayout?.paymentStatus) ||
                wareHouse?.isReFetchingCart ||
                wareHouse?.isUpdateFetching
              }
            >
              Back
            </LoadingButton>
          )}

          <LoadingButton
            variant="contained"
            loading={
              subLayout?.isFetching ||
              wareHouse?.isFetching ||
              wareHouse?.isReFetchingCart ||
              wareHouse?.isUpdateFetching
            }
            sx={{
              width: { xs: "120px", sm: "130px", md: "150px" },
              height: 40,
              fontSize: "12px",
            }}
            disabled={
              ["pending", "fail", "invoice"]?.includes(
                subLayout?.paymentStatus
              ) &&
              wareHouse?.createdCart?.data?.subscriptionStatus !==
                "PAYMENT_DONE"
            }
            onClick={handleConfrimPayment}
          >
            {["vault_fail"]?.includes(subLayout?.paymentStatus) ||
            wareHouse?.createdCart?.data?.subscriptionStatus === "PAYMENT_DONE"
              ? "Reactivate vault"
              : "Confirm"}
            {/* Confirm */}
          </LoadingButton>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default LoadingPaymentProcess;

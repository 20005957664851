// import React, { useEffect, useState, useRef } from "react";
// import { Form, Input, Modal, notification } from "antd";
// import LoadingButton from "@mui/lab/LoadingButton";
// import { useDispatch, useSelector } from "react-redux";
// import UploadComponent from "../../../components/forms/Upload";
// import { updateCustomerAction } from "../../../store/customer/actions";
// import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
// import {
//   handleNextStep,
//   handlePreviousStep,
// } from "../../../store/layout/actions";
// import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import Webcam, { WebcamProps } from "react-webcam";
// import { customerProfileAction } from "../../../store/pos/actions";
// import { url } from "inspector";
// import { myPosActions } from "../../../store/pos";
// import { ProgressInfobar } from "./ProgresssInfoBar";
// import { handleNextStepAction } from "../../device_financing/Subscription/redux/layout/actions";

// const formItemLayout = {
//   labelCol: {
//     xs: { span: 24 },
//     sm: { span: 8 },
//   },
//   wrapperCol: {
//     xs: { span: 24 },
//     sm: { span: 16 },
//   },
// };

// const tailFormItemLayout = {
//   wrapperCol: {
//     xs: {
//       span: 24,
//       offset: 0,
//     },
//     sm: {
//       span: 16,
//       offset: 8,
//     },
//   },
// };

// const UploadDocs = (props: any) => {
//   const { wareHouse, customer, auth, pos } = useSelector((state: any) => state);
//   const dispatch = useDispatch();
//   const [form] = Form.useForm();
//   const [nationalIDDoc, setNationalIDDoc] = useState<any | null>(null);
//   const [passportImage, setPassportImage] = useState<any | null>(null);
//   const [MomoStatementDoc, setMomoStatementDoc] = useState<any>([]);
//   const [moreDocuments, setMoreDocuments] = useState([{ document: "" }]);
//   const [showOtherDocument, setShowOtherDocument] = useState(false);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [isPassportDropdownOpen, setIsPassportDropdownOpen] = useState(false);
//   const [selectedOption, setSelectedOption] = useState("upload");
//   const [selectedPassportOption, setSelectedPassportOption] = useState("upload");
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isPassportModalOpen, setIsPassportModalOpen] = useState(false);
//   const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
//   const webcamRef = useRef<Webcam | null>(null);
//   const [isCameraOpen, setIsCameraOpen] = useState(false);
//   const [isPassportCameraOpen, setIsPassportCameraOpen] = useState(false);
//   const [hideButton, setHideButton] = useState(false);
//   console.log(`passportImage <<<>>>>>>>Id= ${nationalIDDoc} `);

//   const address =
//     wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;
//   const witnessInfo =
//     wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo;
//   const nationalId =
//     wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
//   const momoStatement =
//     wareHouse?.createdCart?.data?.customer?.subscriptionInfo
//       ?.MomoStatementDoc &&
//     wareHouse?.createdCart?.data?.customer?.subscriptionInfo
//       ?.MomoStatementDoc[0];

//   const onFinish = async (values: any) => {
//     if (wareHouse?.createdCart?.data?.customer?._id && address  && witnessInfo) {
//       await updateCustomerAction(
//         auth?.token,
//         wareHouse?.createdCart?.data?.customer?._id,
//         {
//           subscriptionInfo: {
//             address: address,
//             witnessInfo: witnessInfo,
//             nationalIDDoc: nationalIDDoc
//               ? pos?.customerProfile?.data?.secure_url ||
//                 (nationalIDDoc && nationalIDDoc[0]?.response?.data?.secure_url)
//               : nationalId,
//             MomoStatementDoc:
//               MomoStatementDoc.length === 0
//                 ? passportImage
//                 : [MomoStatementDoc[0]?.response?.data?.secure_url],
//           },
//         }
//       )(dispatch);
//      dispatch(myPosActions.setCustomerProfile(null));
//      await handleNextStepAction(6)(dispatch);
//     } else {
//       notification.info({ message: "Missing Witness Details" });
//     }
//     // handleNextStepAction(6)(dispatch);
//   };

//   useEffect(() => {
//     if (wareHouse?.createdCart?.data?.customer?.subscriptionInfo) {
//       form.setFieldsValue(
//         wareHouse?.createdCart?.data?.customer?.subscriptionInfo
//       );
//     } else {
//       form.resetFields();
//     }
//   }, [form, wareHouse?.createdCart?.data?.customer?.subscriptionInfo]);

//   const initialData = {
//     nationalIDDoc: nationalId,
//     MomoStatementDoc: momoStatement,
//   };

//   const handlePrevious = async () => {
//     await handleNextStepAction(4)(dispatch);
//   };
//   const handleAddDocument = () => {
//     setShowOtherDocument(true);
//     if (moreDocuments.length === 0) {
//       setMoreDocuments([...moreDocuments, { document: "" }]);
//     }
//   };
//   const handleRemoveDocument = (index: number) => {
//     const lists = [...moreDocuments];
//     lists.splice(index, 1);
//     setMoreDocuments(lists);
//   };

//   useEffect(() => {
//     moreDocuments?.length === 0 && setShowOtherDocument(false);
//     showOtherDocument === true && setHideButton(true);
//   }, [moreDocuments]);

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };
//   const closeDropdown = () => {
//     setIsDropdownOpen(false);
//   };
//   const handlerDropDown = (value: string) => {
//     setSelectedOption(value);
//     closeDropdown();
//   };
//   const togglePassportDropdown = () => {
//     setIsPassportDropdownOpen(!isPassportDropdownOpen);
//   };
//   const closePassportDropdown = () => {
//     setIsPassportDropdownOpen(false);
//   };
//   const handlerPassportDropDown = (value: string) => {
//     setSelectedPassportOption(value);
//     closePassportDropdown();
//   };

//   const videoConstraints: WebcamProps["videoConstraints"] = {
//     facingMode: "user",
//   };

//   const openCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: videoConstraints,
//       });
//       setMediaStream(stream);
//       setIsCameraOpen(true);
//       setIsModalOpen(true);
//     } catch (error) {
//       console.error("Error accessing camera:", error);
//     }
//   };
//   const openPassportCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: videoConstraints,
//       });
//       setMediaStream(stream);
//       setIsPassportCameraOpen(true);
//       setIsPassportModalOpen(true);
//     } catch (error) {
//       console.error("Error accessing camera:", error);
//     }
//   };

//   const closeCamera = () => {
//     if (mediaStream) {
//       mediaStream.getTracks().forEach((track) => track.stop());
//     }
//     setIsCameraOpen(false);
//   };
//   const closePassportCamera = () => {
//     if (mediaStream) {
//       mediaStream.getTracks().forEach((track) => track.stop());
//     }
//     setIsPassportCameraOpen(false);
//   };

//   const captureImage = async () => {
//     if (webcamRef.current) {
//       const imageSrc = webcamRef.current.getScreenshot();
//       setNationalIDDoc(imageSrc);
//       if (imageSrc) {
//         customerProfileAction(auth?.token, {
//           image: imageSrc,
//         })(dispatch);
//       }
//       setIsCameraOpen(false);
//       closeCamera();
//     }
//   };
//   const capturePassportImage = async () => {
//     if (webcamRef.current) {
//       const imageSrc = webcamRef.current.getScreenshot();
//       setPassportImage(imageSrc);
//       if (imageSrc) {
//         customerProfileAction(auth?.token, {
//           image: imageSrc,
//         })(dispatch);
//       }
//       setIsPassportCameraOpen(false);
//       closePassportCamera();
//     }
//   };

//   useEffect(() => {
//     !isCameraOpen && setIsModalOpen(false);
//     !isPassportCameraOpen && setIsPassportModalOpen(false);
//   }, [isCameraOpen,isPassportCameraOpen]);

//   useEffect(() => {
//     !isModalOpen && setMediaStream(null);
//     !isPassportModalOpen && setMediaStream(null);
//   }, [isModalOpen,isPassportModalOpen]);

//   return (
//     <>
//     <ProgressInfobar name={"Upload documents"} percentage={40} />
//       <Form
//         {...formItemLayout}
//         form={form}
//         name="register"
//         layout="vertical"
//         onFinish={onFinish}
//         initialValues={initialData || {}}
//         style={{ maxWidth: "100%" }}
//         scrollToFirstError
//         className="flex justify-center overflow-y-auto lg:max-2xl:h-56 h-full  flex-col"
//       >
//         <div className=" h-full p-4 ">
//                 <div className="md:flex items-center space-x-4 w-full">
//                   <div className="relative inline-block w-full">
//                     <span
//                       onClick={toggleDropdown}
//                       className="text-gray-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
//                     >
//                       National ID
//                       <svg
//                         className={`w-2.5 h-2.5 ml-2.5 ${
//                           isDropdownOpen ? "transform rotate-180" : ""
//                         }`}
//                         aria-hidden="true"
//                         xmlns="http://www.w3.org/2000/svg"
//                         fill="none"
//                         viewBox="0 0 10 6"
//                       >
//                         <path
//                           stroke="currentColor"
//                           stroke-linecap="round"
//                           stroke-linejoin="round"
//                           stroke-width="2"
//                           d="m1 1 4 4 4-4"
//                         />
//                       </svg>
//                     </span>
//                     {isDropdownOpen && (
//                       <div className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 border border-gray-200">
//                         <ul
//                           className="xpx-2 text-sm text-gray-700"
//                           aria-labelledby="dropdownDefaultButton"
//                         >
//                           <li onClick={() => handlerDropDown("camera")}>
//                             <span
//                               className={`${
//                                 selectedOption === "camera" &&
//                                 "bg-[#352794] text-white"
//                               } block px-4 py-2 hover:bg-[#352794] hover:text-white cursor-pointer`}
//                             >
//                               Camera
//                             </span>
//                           </li>
//                           <li onClick={() => handlerDropDown("upload")}>
//                             <span
//                               className={`${
//                                 selectedOption === "upload" &&
//                                 "bg-[#352794] text-white"
//                               } block px-4 py-2 hover:bg-[#352794] hover:text-white cursor-pointer`}
//                             >
//                               Upload
//                             </span>
//                           </li>
//                         </ul>
//                       </div>
//                     )}
//                     {selectedOption === "camera" ? (
//                       <Form.Item
//                         name="CustomerProfile"
//                         className=" w-full lg:max-2xl:h-32 lg:max-2xl:-mt-1"
//                         rules={[
//                           {
//                             required:
//                               nationalId ||
//                               pos?.customerProfile?.data?.secure_url
//                                 ? false
//                                 : true,
//                             message: "National ID is required",
//                           },
//                         ]}
//                       >
//                         <div className="w-full">
//                           <p
//                             className="ml-5 pb-2 text-sm font-medium text-[#0F0F47] w-32"
//                             onClick={openCamera}
//                           >
//                             Open Camera <CameraAltIcon />
//                           </p>
//                           {(nationalIDDoc || nationalId) && (
//                             <div className=" w-full md:w-32 h-32 lg:max-2xl:w-20 lg:max-2xl:h-20 ml-10">
//                               <img
//                                 src={
//                                   pos?.customerProfile?.data?.secure_url ||
//                                   nationalId
//                                 }
//                                 alt="Captured"
//                               />
//                             </div>
//                           )}
//                         </div>
//                       </Form.Item>
//                     ) : (
//                       <div className="w-full">
//                         <Form.Item
//                           name="nationalIDDoc"
//                           className=" w-full lg:max-2xl:w-[220px]"
//                           rules={[
//                             {
//                               required:
//                                 nationalId ||
//                                 pos?.customerProfile?.data?.secure_url
//                                   ? false
//                                   : true,
//                               message: "National ID is required",
//                             },
//                           ]}
//                           // rules={[
//                           //   {
//                           //     required: true,
//                           //     message: "National ID is required",
//                           //   },
//                           // ]}
//                         >
//                           <div className="w-full">
//                             <div className="w-full">
//                               <p className="px-3 text-sm font-medium text-[#0F0F47]">
//                                 Upload
//                               </p>
//                               <UploadComponent
//                                 setPictures={setNationalIDDoc}
//                                 default={
//                                   nationalId && [
//                                     {
//                                       url: nationalId,
//                                     },
//                                   ]
//                                 }
//                                 isCard={true}
//                                 limit={1}
//                               />
//                             </div>
//                           </div>
//                         </Form.Item>
//                       </div>
//                     )}
//                   </div>
//                   <div className="relative inline-block">
//                     <span
//                       onClick={togglePassportDropdown}
//                       className="text-gray-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
//                     >
//                      Passport Image
//                       <svg
//                         className={`w-2.5 h-2.5 ml-2.5 ${
//                           isPassportDropdownOpen ? "transform rotate-180" : ""
//                         }`}
//                         aria-hidden="true"
//                         xmlns="http://www.w3.org/2000/svg"
//                         fill="none"
//                         viewBox="0 0 10 6"
//                       >
//                         <path
//                           stroke="currentColor"
//                           stroke-linecap="round"
//                           stroke-linejoin="round"
//                           stroke-width="2"
//                           d="m1 1 4 4 4-4"
//                         />
//                       </svg>
//                     </span>
//                     {isPassportDropdownOpen && (
//                       <div className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 border border-gray-200">
//                         <ul
//                           className="xpx-2 text-sm text-gray-700"
//                           aria-labelledby="dropdownDefaultButton"
//                         >
//                           <li onClick={() => handlerPassportDropDown("camera")}>
//                             <span
//                               className={`${
//                                 selectedPassportOption === "camera" &&
//                                 "bg-[#352794] text-white"
//                               } block px-4 py-2 hover:bg-[#352794] hover:text-white cursor-pointer`}
//                             >
//                               Camera
//                             </span>
//                           </li>
//                           <li onClick={() => handlerPassportDropDown("upload")}>
//                             <span
//                               className={`${
//                                 selectedPassportOption === "upload" &&
//                                 "bg-[#352794] text-white"
//                               } block px-4 py-2 hover:bg-[#352794] hover:text-white cursor-pointer`}
//                             >
//                               Upload
//                             </span>
//                           </li>
//                         </ul>
//                       </div>
//                     )}
//                     {selectedPassportOption === "camera" ? (
//                       <Form.Item
//                         name="CustomerPassport"
//                         className=" lg:max-2xl:h-24 lg:max-2xl:-mt-1"
//                         rules={[
//                           {
//                             // required:
//                             //   nationalId ||
//                             //   pos?.customerProfile?.data?.secure_url
//                             //     ? false
//                             //     : true,
//                             message: "Passport is required",
//                           },
//                         ]}
//                       >
//                         <div className="w-full">
//                           <p
//                             className="ml-5 pb-2 text-sm font-medium text-[#0F0F47] w-32"
//                             onClick={openPassportCamera}
//                           >
//                             Open Camera <CameraAltIcon />
//                           </p>
//                           {( passportImage) && (
//                             <div className="w-32 h-32 lg:max-2xl:w-20 lg:max-2xl:h-20 ml-10">
//                               <img
//                                 src={passportImage
//                                 }
//                                 alt="Captured"
//                               />
//                             </div>
//                           )}
//                         </div>
//                       </Form.Item>
//                     ) : (
//                       <div className="w-full">
//                         <Form.Item
//                           name="nationalIDDoc"
//                           className=" w-[260px] lg:max-2xl:w-[220px]"
//                           rules={[
//                             {
//                               required:
//                                 nationalId ||
//                                 pos?.customerProfile?.data?.secure_url
//                                   ? false
//                                   : true,
//                               message: "National ID is required",
//                             },
//                           ]}
//                         >
//                           <div className="w-full">
//                             <div className="">
//                               <p className="px-3 text-sm font-medium text-[#0F0F47]">
//                                 Upload
//                               </p>
//                               <UploadComponent
//                                 setPictures={setNationalIDDoc}
//                                 // default={
//                                 //   nationalId && [
//                                 //     {
//                                 //       url: nationalId,
//                                 //     },
//                                 //   ]
//                                 // }
//                                 isCard={true}
//                                 limit={1}
//                               />
//                             </div>
//                           </div>
//                         </Form.Item>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               <Form.Item {...tailFormItemLayout} className="w-full">
//                 <div className="flex justify-between mb-16 w-full">
//                   <LoadingButton
//                     variant="outlined"
//                     style={{
//                       padding: "6px",
//                       width: "150px",
//                     }}
//                     loading={customer?.isFetching}
//                     onClick={handlePrevious}
//                   >
//                     {"Previous"}
//                   </LoadingButton>
//                   <LoadingButton
//                     type="submit"
//                     variant="contained"
//                     style={{
//                       padding: "6px",
//                       width: "150px",
//                     }}
//                     loading={customer?.isFetching}
//                   >
//                     {"Next"}
//                   </LoadingButton>
//                 </div>
//               </Form.Item>
//         </div>
//       </Form>
//       <Modal title="National ID" open={isModalOpen} footer={null}>
//         {isCameraOpen && (
//           <>
//             <div className="camera-container">
//               <Webcam
//                 audio={false}
//                 ref={webcamRef}
//                 screenshotFormat="image/jpeg"
//                 videoConstraints={videoConstraints}
//               />
//             </div>
//             <div className="  items-center justify-between pt-2">
//               <LoadingButton
//                 variant="contained"
//                 style={{
//                   padding: "4px",
//                   width: "26px",
//                   borderRadius: "2px",
//                 }}
//                 onClick={captureImage}
//               >
//                 Save
//               </LoadingButton>
//               <LoadingButton
//                 variant="outlined"
//                 style={{
//                   padding: "4px",
//                   width: "26px",
//                   borderColor: "red",
//                   backgroundColor: "red",
//                   color: "white",
//                 }}
//                 onClick={closeCamera}
//               >
//                 Close
//               </LoadingButton>
//             </div>
//           </>
//         )}
//       </Modal>
//       <Modal title="Passport" open={isPassportModalOpen} footer={null}>
//         {isPassportCameraOpen && (
//           <>
//             <div className="camera-container">
//               <Webcam
//                 audio={false}
//                 ref={webcamRef}
//                 screenshotFormat="image/jpeg"
//                 videoConstraints={videoConstraints}
//               />
//             </div>
//             <div className="  items-center justify-between pt-2">
//               <LoadingButton
//                 variant="contained"
//                 style={{
//                   padding: "4px",
//                   width: "26px",
//                   borderRadius: "2px",
//                 }}
//                 onClick={capturePassportImage}
//               >
//                 Save
//               </LoadingButton>
//               <LoadingButton
//                 variant="outlined"
//                 style={{
//                   padding: "4px",
//                   width: "26px",
//                   borderColor: "red",
//                   backgroundColor: "red",
//                   color: "white",
//                 }}
//                 onClick={closePassportCamera}
//               >
//                 Close
//               </LoadingButton>
//             </div>
//           </>
//         )}
//       </Modal>
//     </>
//   );
// };

// export default UploadDocs;

import React, { useEffect, useState, useRef } from "react";
import { Form, Modal, notification, Upload, UploadFile } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import PhotoPLaceholder from "../../../assets/images/PhotoPLaceholder.png";
import Webcam, { WebcamProps } from "react-webcam";
import { CiSquarePlus } from "react-icons/ci";
import { useParams } from "react-router-dom";
import { updateCustomerAction } from "../redux/businessPlans/action";
import { getShopStockCartAction } from "../../../store/wareHouse/actions";
import { handleNextStepAction } from "../../device_financing/Subscription/redux/layout/actions";
import { subCustomerActions } from "../../device_financing/Subscription/redux/customer";
import { customerDocumentsAction } from "../../device_financing/Subscription/redux/customer/actions";
import { ProgressInfobar } from "./ProgresssInfoBar";

const { Dragger } = Upload;

const UploadDocs = () => {
  const { wareHouse, auth, subCustomer } = useSelector((state: any) => state);

  const dispatch = useDispatch();
  const { cartId } = useParams();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading,setIsLoading]=useState(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const webcamRef = useRef<Webcam | null>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [choosenDoc, setChoosenDoc] = useState("");
  const [addDocs, setAddDocs] = useState(false);

  const hamdleAddOtherDocs = () => {
    setAddDocs(true);
  };

  const addressData =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;
  const witnessInfoData =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo;
  const userImage =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const incomeFrequency =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.incomeFrequency;
  const mainIncomeSource =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.mainIncomeSource;
  const monthlyIncome =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.monthlyIncome;
  const ownsSmartphone =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.ownsSmartphone;
  const occupationOrBusinessType =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.occupationOrBusinessType;

  const onFinish = async (values: any) => {
    setIsLoading(true);
    const payload = {
      ...values,
      subscriptionInfo: {
        address: addressData,
        incomeFrequency:incomeFrequency,
        mainIncomeSource:mainIncomeSource,
        monthlyIncome:monthlyIncome,
        ownsSmartphone:ownsSmartphone,
        occupationOrBusinessType:occupationOrBusinessType,
        witnessInfo: witnessInfoData,
        nationalIDDoc:
          subCustomer?.customerNationaID !== null
            ? subCustomer?.customerNationaID?.data?.secure_url
            : nationalId,
        photoCustomerHoldingID:
          subCustomer?.customerImage !== null
            ? subCustomer?.customerImage?.data?.secure_url
            : userImage,
      },
    };
    if (
      (subCustomer?.customerNationaID === null && !nationalId) ||
      (subCustomer?.customerImage === null && !userImage)
    ) {
      return notification.error({ message: "Please upload all documents" });
    }
    if (wareHouse?.createdCart?.data?.customer?._id) {
      const res = await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        payload
      )(dispatch);
      if (res) {
        await getShopStockCartAction(auth?.token, cartId)(dispatch);
        await handleNextStepAction(7)(dispatch);
        setIsLoading(false);
        dispatch(subCustomerActions.setCustomerNationaID(null));
        dispatch(subCustomerActions.setCustomerImage(null));
      }
    }
  };

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer?.subscriptionInfo) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      );
    } else {
      form.resetFields();
    }
  }, [form, wareHouse?.createdCart?.data?.customer?.subscriptionInfo]);

  const initialData = {
    nationalIDDoc: nationalId,
  };

  const videoConstraints: WebcamProps["videoConstraints"] = {
    facingMode: "user",
  };

  const openCamera = async (values: any) => {
    setChoosenDoc(values);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: videoConstraints,
      });
      setMediaStream(stream);
      setIsCameraOpen(true);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const closeCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
    setIsCameraOpen(false);
  };

  const captureImage = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        customerDocumentsAction(
          auth?.token,
          {
            image: imageSrc,
          },
          choosenDoc
        )(dispatch);
      }
    }
    setIsCameraOpen(false);
    closeCamera();
  };

  useEffect(() => {
    !isCameraOpen && setIsModalOpen(false);
  }, [isCameraOpen]);

  useEffect(() => {
    !isModalOpen && setMediaStream(null);
  }, [isModalOpen]);

  const beforeUpload = () => {
    return false;
  };

  const handlePrevious = () => {
    handleNextStepAction(5)(dispatch);
  };

  return (
    <div className="">
      <ProgressInfobar name={"Documents"} percentage={100} />
      <Form
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialData || {}}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
        className="flex justify-start  flex-col"
      >
        <div className="p-4 h-[27rem] 2xl:h-[calc(100vh-300px)] overflow-y-auto flex flex-col xbg-red-100">
          <div className="flex-1 flex flex-col space-y-4">
            <div className="rounded-lg space-y-2">
              <Form.Item
                name="CustomerProfile"
                className="2xl:max-2xl:h-24 2xl:max-2xl:-mt-1"
              >
                <div className="grid md:grid-cols-2 2xl:flex gap-4 ">
                  <div onClick={() => openCamera("image")}>
                    <h1 className="text-sm pb-2 font-semibold">Upload passport Image</h1>
                    <Dragger
                      openFileDialogOnClick={false}
                      beforeUpload={beforeUpload}
                      showUploadList={false}
                    >
                      {subCustomer?.customerImage || userImage ? (
                        <img
                          src={
                            subCustomer?.customerImage?.data?.secure_url ||
                            userImage
                          }
                          alt="Captured"
                          className="h-[150px] w-full 2xl:w-[330px] flex object-contain"
                        />
                      ) : (
                        <>
                          <p className="ant-upload-drag-icon flex items-center mx-auto justify-center">
                            <img
                              src={PhotoPLaceholder}
                              alt=""
                              className="w-20"
                            />
                          </p>
                          <p className="ant-upload-text font-medium text-gray-300">
                            Drag and drop your picture here, or{" "}
                            <span className="text-blue-400">
                              click to upload.
                            </span>
                          </p>
                        </>
                      )}
                    </Dragger>
                  </div>
                  <div onClick={() => openCamera("nid")}>
                    <h1 className="text-sm pb-2 font-semibold py-2">
                      Upload National ID
                    </h1>
                    <Dragger
                      openFileDialogOnClick={false}
                      beforeUpload={beforeUpload}
                      showUploadList={false}
                    >
                      {subCustomer?.customerNationaID || nationalId ? (
                        <img
                          src={
                            subCustomer?.customerNationaID?.data?.secure_url ||
                            nationalId
                          }
                          alt="Captured"
                          className="h-[150px] w-full 2xl:w-[330px] flex object-contain"
                        />
                      ) : (
                        <>
                          <p className="ant-upload-drag-icon flex items-center mx-auto justify-center">
                            <img
                              src={PhotoPLaceholder}
                              alt=""
                              className="w-20"
                            />
                          </p>
                          <p className="ant-upload-text font-medium text-gray-300">
                            Drag and drop your ID image here, or{" "}
                            <span className="text-blue-400">
                              click to upload.
                            </span>
                          </p>
                        </>
                      )}
                    </Dragger>
                  </div>
                </div>
              </Form.Item>
            </div>

            <div>
              <div
                className="flex space-x-2 items-center cursor-pointer pb-3"
                onClick={hamdleAddOtherDocs}
              >
                <CiSquarePlus size={"30px"} color="blue" />
                <span className="text-lg font-medium">Other documents</span>
              </div>

              {addDocs && (
                <Upload
                  action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                  listType="picture-card"
                >
                  {fileList.length < 5 && "+ Upload"}
                </Upload>
              )}
            </div>
          </div>

          <div className="flex justify-between space-x-4">
            <LoadingButton
              variant="outlined"
              style={{
                width: "150px",
                height: 40,
              }}
              onClick={handlePrevious}
            >
              {`Previous`}
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              style={{
                padding: "7px",
                height: 40,
              }}
              loading={loading|| wareHouse?.isReFetchingCart}
              disabled={loading|| wareHouse?.isReFetchingCart}
              className="w-[150px] h-[45px] text-lg uppercase"
            >
              {"Save & Continue"}
            </LoadingButton>
          </div>
        </div>
      </Form>

      <Modal title="National ID" open={isModalOpen} footer={null}>
        {isCameraOpen && (
          <>
            <div className="camera-container">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
            <div className="  items-center justify-between pt-2">
              <LoadingButton
                variant="contained"
                style={{
                  padding: "4px",
                  width: "26px",
                  borderRadius: "2px",
                }}
                onClick={captureImage}
              >
                Save
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                style={{
                  padding: "4px",
                  width: "26px",
                  borderColor: "red",
                  backgroundColor: "red",
                  color: "white",
                }}
                onClick={closeCamera}
              >
                Close
              </LoadingButton>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default UploadDocs;

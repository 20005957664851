import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "channel",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    allAgent: null,
    allShop: null,
    attributes: null,
    reqStock: null,
    new: null,
    updated: null,
    query: null,
    priceList: null,
    priceListItems: null,
    isPriceListUpdating: false,
    selectedRfs: null,
    products: null,
    priceListItemToUpdate: null,
    priceListSearch: null,
    rfsTransfer: null,
    selectedRequestedStock: null,
    shopStockOut: null,
    warehouseOut: null,
    warehouseOutSelected: null,
    warehouseOutItem: null,
    pendingShopOut: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setAgents(state, action) {
      state.allAgent = action.payload;
    },
    setShops(state, action) {
      state.allShop = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setPriceList(state, action) {
      state.priceList = action.payload;
    },
    setPriceListItems(state, action) {
      state.priceListItems = action.payload;
    },
    setIsPriceListUpdating(state, action) {
      state.isPriceListUpdating = action.payload;
    },
    setRequestedStock(state, action) {
      state.reqStock = action.payload;
    },
    setRfsSelected(state, action) {
      state.selectedRfs = action.payload;
    },
    setProducts(state, action) {
      state.products = action.payload;
    },
    setPriceListItemToUpdate(state, action) {
      state.priceListItemToUpdate = action.payload;
    },
    setPriceListSearch(state, action) {
      state.priceListSearch = action.payload;
    },
    setRfsTransfer(state, action) {
      state.rfsTransfer = action.payload;
    },
    setSelectedRequestedStock(state, action) {
      state.selectedRequestedStock = action.payload;
    },
    setShopStockOut(state, action) {
      state.shopStockOut = action.payload;
    },
    setWarehouseOut(state, action) {
      state.warehouseOut = action.payload;
    },
    setWarehouseOutSelected(state, action) {
      state.warehouseOutSelected = action.payload;
    },
    setWarehouseOutItem(state, action) {
      state.warehouseOutItem = action.payload;
    },
    setPendingShopOut(state, action) {
      state.pendingShopOut = action.payload;
    },
  },
});

export const myChannelActions = mySlice.actions;

export default mySlice.reducer;

import { Card, Progress } from "antd";
import "./samphonePayments.style.css";
import { HiOutlineArrowUpTray } from "react-icons/hi2";

type Payload = {
  OverAllPercentage: any;
  amount: number;
  yesterdayAmount: number;
  pieces?: number;
  yesterdayPieces?: number;
  title?: string;
  data: {
    name: string;
    value: number;
    yesterday: number;
    today: number;
    percentage: number;
  }[];
};

const SamphonePayments: React.FC<Payload> = (props) => {
  return (
    <>
      <Card className=" p-2 relative w-full sm:!min-w-[400px] h-full shadow-sm">
        <p className="capitalize mb-2 text-base font-Poppins font-medium">
          {props.title}
        </p>
        <div className=" flex flex-col mt-6">
          <div className="border border-sm rounded-[12px] p-4 px-2 text-[#030229] h-[12rem]">
            {props?.data?.map((datas: any, index: number) => {
              return (
                <div className="flex justify-between">
                  <div className="flex gap-3">
                    <div className="mt-2">
                      <Progress
                        type="dashboard"
                        percent={datas?.percentage?.percentage}
                        size={20}
                      />
                    </div>
                    <span>
                      <h1 className="text-sm font-medium opacity-90">
                        {datas?.name}
                      </h1>
                      <span
                        className={`flex gap-1 font-medium ${datas?.percentage?.type === "increase"
                            ? "text-green-500"
                            : datas?.percentage?.type === "decrease"
                              ? "text-red-500"
                              : "text-blue-900"
                          } text-sm`}
                      >
                        {datas?.percentage?.type === "increase" ? (
                          <HiOutlineArrowUpTray size={17} />
                        ) : datas?.percentage?.type === "decrease" ? (
                          <HiOutlineArrowUpTray
                            size={16}
                            className="rotate-180"
                          />
                        ) : (
                          ""
                        )}{" "}
                        {`${datas?.percentage?.percentage}%`}
                      </span>
                    </span>
                  </div>
                  <div className="flex flex-col text-sm my-2">
                    <h1 className="font-semibold opacity-90">
                      {datas?.today?.toLocaleString()}
                    </h1>
                    <h1 className="font-medium opacity-60">
                      {datas?.yesterday?.toLocaleString()}
                    </h1>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Card>
    </>
  );
};

export default SamphonePayments;

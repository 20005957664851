import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "Transaction-Library",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    samphonePaymentStats: null,
    mtnPaymentStats: null,
    otherPaymentStats: null,
    todayRevenueStats: null,
    overViewStats: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSamphonePaymentStats(state, action) {
      state.samphonePaymentStats = action.payload;
    },
    setMTNPaymentStats(state, action) {
      state.mtnPaymentStats = action.payload;
    },
    setOtherPaymentStats(state, action) {
      state.otherPaymentStats = action.payload;
    },
    setTodayRevenueStats(state, action) {
      state.todayRevenueStats = action.payload;
    },
    setOverviewStats(state, action) {
      state.overViewStats = action.payload;
    },
  },
});

export const myPosOverViewActions = mySlice.actions;

export default mySlice.reducer;

import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import TopNav from "../components/navitems/TopNavItems";
import {  subMenusList } from "../assets/data/pages";
import React from "react";
 import store from "store";
import { useSelector } from "react-redux";

export const inventoryRoutes = [
  {
    caption: "Overview",
    path: "/inventory",
  },
  {
    caption: "Current Stock",
    path: "/inventory/current-stock",
  },
  {
    caption: "Stock Requests",
    path: "inventory/stock-request",
  },
  {
    caption: "Transfer",
    path: "/inventory/transfers",
    access:["admin","freelancer","team-leader","dev"]
  },
];
const InventoryRoutes = () => {
  const { auth } = useSelector((state: any) => state);
  const token = store.get("authToken");
  let authent = { token: true };
  const pagesArray = [
    {
      caption: "Overview",
      path: "/inventory",
    },
    {
      caption: "Current Stock",
      path: "/inventory/current-stock",
    },
    {
      caption: "Stock Requests",
      path: "inventory/stock-request",
    },
    {
      caption: "Transfer",
      path: "/transfer",
    }
  ]

  return auth?.token || token ? (
    <DashLayout
      allowSearch={true}
      nav={<TopNav nav={subMenusList[2]} pages={pagesArray} />}
      selectedNav={2}
    >
      <Outlet />
    </DashLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default InventoryRoutes;

export const relationship = [
  "Husband ",
  "Wife",
  "Father",
  "Mother",
  "Legal guardian",
  "Mudugudu",
  "Son",
  "Daughter",
  "Brother",
  "Sister",
  "Uncle",
  "Aunt",
  "Grandfather",
  "Grandmother",
  "Project Manager",
];

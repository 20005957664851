import React, { useEffect } from 'react'
import { getActiveShop } from '../../utils/converter';
import { getAllNosaleAction } from '../../store/pos/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import ScrollableFrame from '../layout/ScrollableFrame';
import { getDefaultCurrencyCode } from '../../utils/CurrencyHelper';

const NoSalesTable = (props:any) => {
    const {company,pos}=useSelector((state:any)=>state)
   
    const dispatch=useDispatch();
    const columns= [
        {
          title: 'Date',
          dataIndex: 'date',
        },
        {
          title: 'Model',
          dataIndex: 'model',
        },
        {
          title: 'Specifications',
          dataIndex: 'data',
          render: (text: any, record: any) => {
            return (
              <>
                {record?.data?.priceListItemId?.specification?.map(
                  (d: any, index: number) => (
                    <span key={index} className="text-xs">
                      {d[0]}: {d[1]}
                    </span>
                  )
                )}
              </>
            );
          }
        },
        {
          title: `Price (${getDefaultCurrencyCode(company)})`,
          dataIndex: 'price',
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
        },
        {
          title: 'Phone',
          dataIndex: 'phone',
        },
        {
          title: 'Agent',
          dataIndex: 'agent',
        },
        {
          title: 'Customer',
          dataIndex: 'name',
        },
        {
          title: 'Reason',
          dataIndex: 'comment',
        },
      ];
      const nosaleData=pos?.nosale?.data?.noSales?.map((el:any)=>{
        return{
            key: el?._id,
            name: el?.customerId?.name,
            comment: el?.comment,
            price: el?.priceListItemId?.prices
            ?.filter((d: any) => d.isActive === true)
            ?.map((d: any) => d.value)
            ?.toLocaleString(),
            model:el?.priceListItemId?.product?.model,
            quantity:el?.quantity,
            phone:el?.customerId?.phone,
            channel:el?.priceListItemId?.channel?.name,
            agent:el?.createdBy?.names,
            date:el?.createdAt?.slice(0,10),
            data:el
        }
      })
      

  return (
    <div>
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={pos?.nosale?.total}
        count={Math.ceil(pos?.nosale?.total / props?.limit)}
        // isTable
      >
        <Table columns={columns} dataSource={nosaleData} size="middle" loading={pos?.isFetching}/>
        </ScrollableFrame>

    </div>
  )
}

export default NoSalesTable
import { notification } from "antd";
import { myChannelActions } from ".";
import {
  createChannel,
  getAllChannel,
  getOneChannel,
  updateChannel,
  createShop,
  getAllShop,
  deleteShop,
  updateShop,
  createAgent,
  getAllAgent,
  deleteAgentService,
  updateServiceAgent,
  getPriceListItem,
  addPriceListItem,
  deletePriceListItem,
  updatePriceListItem,
  getPriceLists,
  createServiceRequest,
  getAllServiceRequest,
  addOneItemServiceRequest,
  sendRequestService,
  updateIsDeleteService,
  assignAgentToShop,
  getProductsByReqItem,
  deleteStockRequestService,
  getPriceListsSearchService,
  getAllStocktoTransferService,
  createTransferService,
  getAllServiceStockOut,
  getAllWarehouseOutItem,
  updateStockOutService,
  updateOneStockOutDetailsService,
  updateStockReqItemService,
} from "./services";

export const createChannelAction = (token: string, data: any) => {
  // console.log(">>",data)
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createChannel(data, token);
      const resAll = await getAllChannel(token);

      // console.log(">>>>>>>:: Channel registered", res);
      if (res?.status === 201) {
        dispatch(myChannelActions.setNew(res));
        dispatch(myChannelActions.setAll(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Channel created" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createShopAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createShop(data, token);

      // console.log(">>>>>>>:: Channel registered", res);
      if (res?.status === 201) {
        const resAll = await getAllShop(token, `channel=${res?.data?.channel}`);
        dispatch(myChannelActions.setNew(res));
        dispatch(myChannelActions.setShops(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Shop created" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createAgentAction = (token: string, data: any) => {
  // console.log(">>",data)
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createAgent(data, token);

      // console.log("Agent registered", res);
      if (res?.status === 201) {
        dispatch(myChannelActions.setNew(res));
        const resAll = await getAllAgent(res?.data?.channel, token);
        dispatch(myChannelActions.setAgents(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Agent Created" });
        return true;
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllChannelAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllChannel(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setAll(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllShopAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllShop(token, query);
      // console.log(">>>>>>>:: shops", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setShops(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllAgentAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllAgent(token, query);
      if (res?.status === 200) {
        const resShop = await getAllShop(token, query);
        dispatch(myChannelActions.setShops(resShop));
        dispatch(myChannelActions.setAgents(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneChanneltAction = (itemId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getOneChannel(itemId, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setSelected(res?.data));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneChanneltByNameAction = (name: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllChannel(token, `name=${name}`);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setSelected(res?.data[0]));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteAgentActions = (itemId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await deleteAgentService(itemId, token);
      // console.log(" delete agent.....?", res)
      if (res?.status === 200) {
        const resAll = await getAllAgent(res?.data?.channel, token);
        dispatch(myChannelActions.setAgents(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "deleted Succesfully" });
        return true;
      }
      dispatch(myChannelActions.setIsFetching(false));
      return true;
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteShopAction = (itemId: string, token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await deleteShop(itemId, data, token);
      // console.log(" delete res.......>", res)
      if (res?.status === 200) {
        const resAll = await getAllShop(
          token,
          `channel=${res?.data?.channel?._id}`
        );
        dispatch(myChannelActions.setShops(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "deleted Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateChannelAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateChannel(itemId, data, token);
      // console.log(">>>>!!!>>>::channel updated)) ", res);
      if (res?.status === 200) {
        const resAll = await getAllChannel(token, "");
        dispatch(myChannelActions.setUpdated(res));
        dispatch(myChannelActions.setAll(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "updated Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateShopAction = (itemId: string, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateShop(itemId, data, token);
      // console.log(">>>>!!!>>>:: )) ", res);
      if (res?.status === 200) {
        const resAll = await getAllShop(
          token,
          `channel=${res?.data?.channel?._id}`
        );
        dispatch(myChannelActions.setUpdated(res));
        dispatch(myChannelActions.setShops(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "updated Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateAgentActions = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateServiceAgent(itemId, data, token);
      // console.log("<<<<updated data>>>>", res)
      if (res?.status === 200) {
        const resAll = await getAllAgent(res?.data?.channel, token);
        dispatch(myChannelActions.setUpdated(res));
        dispatch(myChannelActions.setAgents(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const reAssignAgentToShopAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await assignAgentToShop(itemId, data, token);
      // console.log("<<<<Assigned Agent>>>>", res);
      if (res?.status === 200) {
        const resAll = await getAllAgent(token, "");
        dispatch(myChannelActions.setAgents(resAll));
        dispatch(myChannelActions.setUpdated(res));
        dispatch(myChannelActions.setIsFetching(false));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// Prices

export const getPricelistAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getPriceLists(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setPriceList(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPricelistItemAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getPriceListItem(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setPriceListItems(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSinglePricelistItemAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      // dispatch(myChannelActions.setIsFetching(true));
      const res = await getPriceListItem(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setPriceListItemToUpdate(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addPriceListItemAction = (
  token: string,
  priceListId: string,
  data: any,
  query: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await addPriceListItem(token, priceListId, data);
      // const resAll = await getPriceListItem(token, query);

      // console.log(">>>>>>>:: Channel registered", res);
      if (res?.status === 200) {
        // dispatch(myChannelActions.setNew(res));
        const resP = await getPriceListItem(token, `?channel=${data?.channel}`);
        dispatch(myChannelActions.setPriceListItems(resP));
        // dispatch(myChannelActions.setPriceList(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Price list item added" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({ message: "Adding item to price list failed" });
    }
  };
};

export const deletePriceListItemAction = (
  token: string,
  priceListItemId: string,
  query: string
) => {
  // console.log(">>",data)
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await deletePriceListItem(token, priceListItemId);
      const resAll = await getPriceListItem(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setPriceListItems(resAll));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Price list item Deleted" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({ message: "Deleting item from price list failed" });
    }
  };
};

export const updatePriceListItemAction = (
  token: string,
  priceListItemId: string,
  data: any,
  query: string
) => {
  // console.log(">>",data)
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsPriceListUpdating(true));
      const res = await updatePriceListItem(token, priceListItemId, data);
      const resAll = await getPriceListItem(token, query);

      // console.log(">>>>>>>:: Channel registered", res);
      if (res?.status === 200) {
        // dispatch(myChannelActions.setNew(res));
        dispatch(myChannelActions.setPriceList(resAll));
        dispatch(myChannelActions.setPriceListItems(resAll));
        dispatch(myChannelActions.setIsPriceListUpdating(false));
        notification.success({ message: "Price updated" });
      }
      dispatch(myChannelActions.setIsPriceListUpdating(false));
    } catch (err) {
      console.log(err);
      notification.error({ message: "Failed to update price" });
    }
  };
};

export const createRequestStockAction = (
  token: string,
  data: {},
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createServiceRequest(data, token);
      // const resPrice = await getPriceLists(token, query);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myChannelActions.setNew(res));
        // dispatch(myChannelActions.setPriceList(resPrice));
        dispatch(myChannelActions.setIsFetching(false));
        return res.data;
      }
      dispatch(myChannelActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteRequestStockAction = (
  token: string,
  stockReqId: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await deleteStockRequestService(stockReqId, token);
      const selectedRfs = await getAllServiceRequest(token, query);
      // const resPrice = await getPriceLists(token, query);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setNew(res));
        dispatch(myChannelActions.setRfsSelected(selectedRfs));
        dispatch(myChannelActions.setIsFetching(false));
        return res.data;
      }
      dispatch(myChannelActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllStockReqAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllServiceRequest(token, query);
      const resPrice = await getPriceLists(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setRequestedStock(res));
        dispatch(myChannelActions.setPriceList(resPrice));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllStockSentAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllServiceRequest(token, query);
      const resPrice = await getPriceLists(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setRfsSelected(res));
        dispatch(myChannelActions.setPriceList(resPrice));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSelectedRequestAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllServiceRequest(token, query);
      const resPrice = await getPriceLists(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200 && res?.data.length === 1) {
        dispatch(myChannelActions.setRfsSelected(res?.data[0]));
        dispatch(myChannelActions.setPriceList(resPrice));
        dispatch(myChannelActions.setIsFetching(false));
      } else if (res?.status === 200 && res?.data.length !== 1) {
        console.log(res);
        notification.error({ message: "RFS ID Is Not Exist!" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addItemToSelectedRequestAction = (
  id: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await addOneItemServiceRequest(id, data, token);

      // console.log(">>>>>>>:: >>", res);
      if (res?.status === 200) {
        const resSelected = await getAllServiceRequest(
          token,
          `?requestId=${res?.data.requestId}`
        );
        dispatch(myChannelActions.setRfsSelected(resSelected?.data[0]));
        dispatch(myChannelActions.setIsFetching(false));
      } else if (res?.status === 200 && res?.data.length !== 1) {
        console.log(res);
        notification.error({ message: "RFS ID Is Not Exist!" });
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const sendRequestAction = (id: string, data: {}, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await sendRequestService(id, data, token);

      if (res?.status === 200) {
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Request Sent Successfully!" });
        return true;
      }
      dispatch(myChannelActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteChannelsAction = (
  itemId: string,
  token: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateIsDeleteService(itemId, data, token);
      if (res?.status === 200) {
        dispatch(myChannelActions.setSelected(res?.data));
        const resAll = await getAllChannel(token, "");
        dispatch(myChannelActions.setAll(resAll));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log("an error occured......");
      console.log(err);
    }
  };
};

export const getProductsByReqItemAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getProductsByReqItem(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setProducts(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPricelistSearchAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const resSearch = await getPriceListsSearchService(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (resSearch?.status === 200) {
        dispatch(myChannelActions.setPriceListItems(resSearch));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllStockToTransferAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllStocktoTransferService(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myChannelActions.setRfsTransfer(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSelectedStockReqAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllServiceRequest(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setSelectedRequestedStock(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateStockRequestStatusAction = (
  itemId: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await sendRequestService(itemId, data, token);
      if (res?.status === 200) {
        dispatch(myChannelActions.setIsFetching(false));
        return true;
      }
      dispatch(myChannelActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const createStockOutAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await createTransferService(data, token);
      if (res?.status === 201) {
        dispatch(myChannelActions.setShopStockOut(res));
        dispatch(myChannelActions.setIsFetching(false));
        return res?.data;
      }
      dispatch(myChannelActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllShopOutAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllServiceStockOut(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setWarehouseOut(res));
        dispatch(myChannelActions.setWarehouseOutSelected(res?.data[0]));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllWarehouseOutItemsAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllWarehouseOutItem(token, query);
      if (res?.status === 200) {
        // dispatch(myChannelActions.setWarehouseOutItem(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateStockOutAction = (id: any, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateStockOutService(id, data, token);
      if (res?.status === 201) {
        const resW = await getAllWarehouseOutItem(
          token,
          `requestedItem=${data?.requestedItem}`
        );
        dispatch(myChannelActions.setWarehouseOutItem(resW));
        dispatch(myChannelActions.setIsFetching(false));
        notification.success({ message: "Successfully Added!" });
        return true;
      } else {
        dispatch(myChannelActions.setIsFetching(false));
        notification.error({
          message: "Failed,",
          description: `These IMEIs failed to be added: ${res.data.join(
            ", "
          )}. Please try again.`,
        });
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateOneStockOutDetailsAction = (
  token: string,
  id: any,
  requestedItem: any,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateOneStockOutDetailsService(id, data, token);
      if (res?.status === 200) {
        const resW = await getAllWarehouseOutItem(
          token,
          `requestedItem=${requestedItem}`
        );
        dispatch(myChannelActions.setWarehouseOutItem(resW));
        dispatch(myChannelActions.setIsFetching(false));
        return true;
      }
      dispatch(myChannelActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateStockReqItemAction = (id: any, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await updateStockReqItemService(id, data, token);
      dispatch(myChannelActions.setIsFetching(false));
      return true;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllPendingShopOutAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myChannelActions.setIsFetching(true));
      const res = await getAllServiceStockOut(token, query);
      if (res?.status === 200) {
        dispatch(myChannelActions.setPendingShopOut(res));
        dispatch(myChannelActions.setIsFetching(false));
      }
      dispatch(myChannelActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

import React from "react";
import SamphonePayments from "../../../components/dashboard/Payments/SamphonePayments";
import {
  calculatePaymentTotalRevenue,
  calculatePercentageChange,
} from "../../../utils/converter";
import { useSelector } from "react-redux";


const PaymentMode = (props: any) => {
  const { posOverView } = useSelector((state: any) => state);

  const currentTotalRevenue = calculatePaymentTotalRevenue(
    posOverView?.samphonePaymentStats?.data,
    "currentDate"
  );

  const samphonePayment = posOverView?.samphonePaymentStats?.data?.map(
    (item: any) => {
      return {
        name:
          item?.mode === "Samphone_POS"
            ? "POS"
            : item?.mode === "Samphone_Bank_Deposit"
              ? "Bank Deposit"
              : item?.mode?.split("_")?.join(" "),
        today: parseInt(item?.revenue?.currentDate?.revenueTotal),
        yesterday: parseInt(item?.revenue?.comparedDate?.revenueTotal),
        value:
          currentTotalRevenue !== 0 &&
          (parseFloat(item?.revenue?.currentDate?.revenueTotal) /
            currentTotalRevenue) *
          100,
        percentage: calculatePercentageChange(
          item?.revenue?.currentDate?.revenueTotal,
          item?.revenue?.comparedDate?.revenueTotal
        ),
      };
    }
  );

  const otherPayment = posOverView?.otherPaymentStats?.data?.map(
    (item: any) => {
      return {
        name: item?.mode,
        today: parseInt(item?.revenue?.currentDate?.revenueTotal),
        yesterday: parseInt(item?.revenue?.comparedDate?.revenueTotal),
        value:
          currentTotalRevenue !== 0 &&
          (parseFloat(item?.revenue?.currentDate?.revenueTotal) /
            currentTotalRevenue) *
          100,
        percentage: calculatePercentageChange(
          item?.revenue?.currentDate?.revenueTotal,
          item?.revenue?.comparedDate?.revenueTotal
        ),
      };
    }
  );

  const mtnPayment = posOverView?.mtnPaymentStats?.data?.map((item: any) => {
    return {
      name:
        item?.mode === "MTN_POS"
          ? "POS"
          : item?.mode === "MTN_Bank_Deposit"
            ? "Bank Deposit"
            : "Requisition",
      today: parseInt(item?.revenue?.currentDate?.revenueTotal),
      yesterday: parseInt(item?.revenue?.comparedDate?.revenueTotal),
      value:
        currentTotalRevenue !== 0 &&
        (parseFloat(item?.revenue?.currentDate?.revenueTotal) /
          currentTotalRevenue) *
        100,
      percentage: calculatePercentageChange(
        item?.revenue?.currentDate?.revenueTotal,
        item?.revenue?.comparedDate?.revenueTotal
      ),
    };
  });

  return (
    <div className="w-[100%] h-full px-6 mt-8 bg-white rounded-lg pb-10 pt-2 mb-4">
      <h3 className="font-Poppins text-[16px] font-medium text-[#030229] py-2 opacity-90">Methods Of Payments</h3>
      <div className="flex-wrap gap-4 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 justify-between h-full rounded-md p-42 divide-y lg:divide-y-0 lg:divide-x">
        <SamphonePayments
          title="MTN Payments"
          OverAllPercentage={50}
          amount={599908}
          yesterdayAmount={400530}
          data={mtnPayment}
          pieces={3}
          yesterdayPieces={2}
        />
        <SamphonePayments
          title="Samphone Payments"
          OverAllPercentage={53}
          amount={599908}
          yesterdayAmount={400530}
          data={samphonePayment}
          pieces={3}
          yesterdayPieces={2}
        />
        <SamphonePayments
          title="Other Payments"
          OverAllPercentage={23}
          amount={599908}
          yesterdayAmount={400530}
          data={otherPayment}
          pieces={3}
          yesterdayPieces={2}
        />
      </div>
    </div>
  );
};

export default PaymentMode;

import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import countryList from "country-list";
// @ts-ignore
import { Provinces, Districts, Sectors, Cells, Villages } from "rwanda";
import { LoadingButton } from "@mui/lab";
import { handleNextStepAction } from "../../../device_financing/Subscription/redux/layout/actions";
import { Progressbar } from "../../../device_financing/Subscription/components/Progressbar";
import { ProgressInfobar } from "../ProgresssInfoBar";
import { getShopStockCartAction, updateShopStockCartDetailsAction } from "../../../../store/wareHouse/actions";
import { useParams } from "react-router-dom";
import { createCustomerAction, updateCustomerAction } from "../../redux/businessPlans/action";

const AddressDetailsForm = () => {
  const { auth, wareHouse, applicants } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const [loading,setIsLoading]=useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedCell, setSelectedCell] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");
  const { cartId } = useParams();
  const [listDistrict, setListDistrict] = useState<any[]>([]);
  const [listSector, setListSector] = useState<any[]>([]);
  const [listCell, setListCell] = useState<any[]>([]);
  const [listVillage, setListVillage] = useState<any[]>([]);
  const dispatch = useDispatch();
  const countryOptions = countryList.getData()?.map((country: any) => ({
    label: country.name,
    value: country.name,
  }));

  const provinceOptions: [{ value: string; label: string }] = Provinces()?.map(
    (pro: string) => ({
      label: pro,
      value: pro,
    })
  );

  useEffect(() => {
    try {
      setListDistrict(
        Districts(selectedProvince ?? "")?.map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      setListSector([]);
      setSelectedSector("");

      return;
    }
  }, [selectedProvince]);

  useEffect(() => {
    try {
      setListSector(
        Sectors(selectedProvince ?? "", selectedDistrict ?? "")?.map(
          (val: string) => ({
            label: val,
            value: val,
          })
        )
      );
    } catch (e: any) {
      setSelectedCell("");
      setListCell([]);
      return;
    }
  }, [selectedDistrict, listDistrict]);

  useEffect(() => {
    try {
      setListCell(
        Cells(
          selectedProvince ?? "",
          selectedDistrict ?? "",
          selectedSector ?? ""
        )?.map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      setSelectedVillage("");
      setListVillage([]);
      return;
    }
  }, [listSector, selectedSector]);

  useEffect(() => {
    try {
      setListVillage([]);
      setSelectedVillage("");
      setListVillage(
        Villages(
          selectedProvince ?? "",
          selectedDistrict ?? "",
          selectedSector ?? "",
          selectedCell ?? ""
        )?.map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      console.log(e);
    }
  }, [listCell, selectedCell]);

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
      );
    } else {
      form.resetFields();
    }
  }, [form, wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address]);
  const handlePrevious = async () => {
    await handleNextStepAction(0)(dispatch);
  };
  useEffect(() => {
    form.setFieldsValue({
      country:
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
          ?.country,
      province:
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
          ?.province,
      district:
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
          ?.district,
      cell: wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
        ?.cell,
      village:
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
          ?.village,
    });
  }, [wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address]);
  const initialData = {
    ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address,
  };
  const incomeFrequency =
  wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.incomeFrequency;
const mainIncomeSource =
  wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.mainIncomeSource;
const monthlyIncome =
  wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.monthlyIncome;
const ownsSmartphone =
  wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.ownsSmartphone;
const occupationOrBusinessType =
  wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.occupationOrBusinessType;
  const witnessInfoData =
  wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo;

  const userImage =
  wareHouse?.createdCart?.data?.customer?.subscriptionInfo
    ?.photoCustomerHoldingID;
const nationalId =
  wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const onFinish = async (values: any) => {
    setIsLoading(true)
    const payload = {
      ...values,
      subscriptionInfo: {
        ...values,
        address: {
          country: values?.country,
          province: values?.province,
          district: values?.district,
          sector: values?.sector,
          cell: values?.cell,
          village: values?.village,
        },
        incomeFrequency:incomeFrequency,
        mainIncomeSource:mainIncomeSource,
        monthlyIncome:monthlyIncome,
        ownsSmartphone:ownsSmartphone,
        occupationOrBusinessType:occupationOrBusinessType,
        witnessInfo: witnessInfoData,
        nationalIDDoc: nationalId,
        photoCustomerHoldingID: userImage,
      },
    };
      const res = await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        payload
      )(dispatch);
      if (res?.type) { 
        await getShopStockCartAction(auth?.token, cartId)(dispatch);
             setIsLoading(false)
        await handleNextStepAction(2)(dispatch);
      }
  };
  return (
    <div className="rounded-lg">
      <ProgressInfobar name={"Personal Details"} percentage={20} />
      <div>
        <h1 className="text-black text-lg pb-4 font-semibold">
          Address Details
        </h1>
      </div>
      <Form
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialData || {}}
      >
        <div className="grid md:grid-cols-2 2xl:grid-cols-3 gap-x-4">
          <Form.Item
            name="country"
            initialValue={
              wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
                ?.country
            }
            label={<span className="text-[#0F0F47] text-sm">Country</span>}
            rules={[
              {
                required: true,
                message: "Country is required!",
                whitespace: true,
              },
            ]}
          >
            <Select
              showSearch
              className="h-14 md:h-12"
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: string) => {
                setSelectedCountry(value);
                form.resetFields([
                  "province",
                  "district",
                  "sector",
                  "cell",
                  "village",
                ]);
              }}
              options={countryOptions}
            >
              <Input
                placeholder="Choose Country"
                className="pl-4 h-[50px] md:h-[40px] w-[100%] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] border"
              />
            </Select>
          </Form.Item>
          <Form.Item
            name="province"
            initialValue={
              wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
                ?.province
            }
            label={<span className="text-[#0F0F47] text-sm">Province</span>}
            rules={[
              {
                required: true,
                message: "Province is required!",
                whitespace: true,
              },
            ]}
          >
            {selectedCountry === "Rwanda" ? (
              <Select
                className="h-12"
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => {
                  setSelectedProvince(value);
                  form.resetFields(["district", "sector", "cell", "village"]);
                }}
                options={provinceOptions}
                allowClear
              />
            ) : (
              <Input
                placeholder="Choose Province"
                className="pl-4 h-12 lg:max-2xl:h-12 border"
              />
            )}
          </Form.Item>
          <Form.Item
            name="district"
            initialValue={
              wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
                ?.district
            }
            label={<span className="text-[#0F0F47] text-sm">District</span>}
            rules={[
              {
                required: true,
                message: "District is required!",
                whitespace: true,
              },
            ]}
          >
            {selectedCountry === "Rwanda" ? (
              <Select
                className="h-12"
                showSearch
                placeholder={
                  <div className="flex flex-col align-center">
                    Search to Select
                  </div>
                }
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => {
                  setSelectedDistrict(value);
                  form.resetFields(["sector", "cell", "village"]);
                }}
                options={listDistrict}
                allowClear
              />
            ) : (
              <Input
                placeholder="Choose District"
                className="pl-4 h-12 border"
              />
            )}
          </Form.Item>
          <Form.Item
            name="sector"
            initialValue={
              wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
                ?.sector
            }
            label={<span className="text-[#0F0F47] text-sm">Sector</span>}
            rules={[
              {
                required: true,
                message: "Sector is required!",
                whitespace: true,
              },
            ]}
          >
            {selectedCountry === "Rwanda" ? (
              <Select
                className="h-12"
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => {
                  setSelectedSector(value);
                  form.resetFields(["cell", "village"]);
                }}
                options={listSector}
                value={selectedSector}
                allowClear
              />
            ) : (
              <Input
                placeholder="Choose Sector"
                className="pl-4 h-12 border"
              />
            )}
          </Form.Item>
          <Form.Item
            name="cell"
            initialValue={
              wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
                ?.cell
            }
            label={<span className="text-[#0F0F47] text-sm">Cell</span>}
            rules={[
              {
                required: true,
                message: "Cell is required!",
                whitespace: true,
              },
            ]}
          >
            {selectedCountry === "Rwanda" ? (
              <Select
                className="h-12"
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => {
                  setSelectedCell(value);
                  form.resetFields(["village"]);
                }}
                options={listCell}
                value={selectedCell}
                allowClear
              />
            ) : (
              <Input
                placeholder="Choose Cell"
                className="pl-4 h-12 border"
              />
            )}
          </Form.Item>
          <Form.Item
            name="village"
            initialValue={
              wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
                ?.village
            }
            label={<span className="text-[#0F0F47] text-sm">Village</span>}
            rules={[
              {
                required: true,
                message: "Village is required!",
                whitespace: true,
              },
            ]}
          >
            {selectedCountry === "Rwanda" ? (
              <Select
                className="h-12"
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => setSelectedVillage(value)}
                options={listVillage}
                value={selectedVillage}
                allowClear
              />
            ) : (
              <Input className="pl-4 h-12 border" />
            )}
          </Form.Item>
        </div>
        <div className="py-6 pl-4 flex-row  flex items-end justify-end gap-4">
          {/* <LoadingButton
            variant="outlined"
            style={{
              width: "100px",
              height: 40,
            }}
            onClick={handlePrevious}
          >
            Previous
          </LoadingButton> */}
          <div className=" flex items-center gap-x-1 mr-5 2xl:gap-x-4 ">
          <LoadingButton
              type="submit"
              variant="contained"
              style={{
                padding: "10px",
              }}
              loading={loading || wareHouse?.isUpdateFetching}
              disabled={loading || wareHouse?.isUpdateFetching}
            >
              {"Save and Continue"}
            </LoadingButton>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddressDetailsForm;

import { useSelector} from "react-redux";
import { Carousel, Tag } from "antd";
import React from "react";
import defaultPic from "../../../../assets/nophoto.png";
import { getDefaultCurrencyCode } from "../../../../utils/CurrencyHelper";

const SubProductCardGrid = (props: any) => {
  const { company,  } = useSelector((state: any) => state);
  return (
    <div className="text-black flex flex-wrap gap-4 text-sm font-poppins">
      {props?.data?.map((item: any) => (
        <div
          key={item._id}
          className="group shadow rounded-lg p-4 bg-white relative h-[310px] w-[370px]"
        >
          <div className="flex flex-row items-center gap-2">
            <div className="flex justify-center mt-4 mb-6">
              {item?.pricelistItem?.product?.images?.length === 0 ? (
                <div>
                  {" "}
                  <img
                    src={defaultPic}
                    width={"100%"}
                    className=" h-36 object-cover"
                    alt=""
                  />
                </div>
              ) : (
                <Carousel autoplay style={{ height: "110px", width: "128px" }}>
                  {item?.pricelistItem?.product?.images?.map((im: any) => (
                    <div>
                      <img
                        src={im}
                        width={"100%"}
                        className=" h-36 object-cover"
                        alt=""
                      />
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
            <div>
              <div className="flex items-center justify-between">
                <h2 className="text-sm text-[#030229] mb-2 pl-1">
                  {item?.pricelistItem?.product?.model}
                </h2>
                {/* {[
                "admin",
                "finance",
                "finance-manager",
                "dev",
                "b2b-relation",
              ].includes(auth?.user?.role?.toLowerCase()) && (
                <div>
                  <ThreeDotDropdown
                    onUpdate={handleEditSubPrice}
                    onDelete={handleClickDelete}
                  />
                </div>
              )} */}
              </div>
              <p className="text-sm font-normal text-gray-600 flex flex-wrap leading-4">
                {item?.pricelistItem?.specification?.slice(0, 4)?.map(
                  (data: any, _index: number) =>
                    data[1] && (
                      <div className="flex flex-row">
                        <p className="text-xs px-1">
                          <span className="text-[12px] sm:text-sm  text-[#03022980]">{`${data[0]}: `}</span>
                          <span className="text-[12px] sm:text-sm  text-[#030229]">
                            {` ${data[1]} |`}
                          </span>
                        </p>
                      </div>
                    )
                )}
              </p>
              <div className="pt-3">
                {item?.monthlyInstalment && (
                  <Tag className="py-0.5">
                    <span className="font-semibold text-xs">
                      {`${
                        item?.monthlyInstalment?.toLocaleString() !== 0 &&
                        item?.monthlyInstalment?.toLocaleString()
                      } ${getDefaultCurrencyCode(company)}`}
                    </span>
                    <span className="text-xs text-gray-600">/Month</span>
                  </Tag>
                )}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <p className="text-center text-sm text-gray-600">
              {/* {item?.dailyInstalment && (
                  <Tag className="py-0.5 mb-1">
                    <span className="font-semibold text-xs">
                      {" "}
                      {`${item?.dailyInstalment.toLocaleString() !== 0  && item?.dailyInstalment.toLocaleString()} ${getDefaultCurrencyCode(company)}`}
                    </span>
                    <span className="text-xs text-gray-600">/Day</span>
                  </Tag>
                )}
                {item?.weeklyInstalment && (
                  <Tag className="py-0.5">
                    <span className="font-semibold text-xs">
                      {item?.businessProductType}
                      {`${item?.weeklyInstalment?.toLocaleString() !==0 && item?.weeklyInstalment?.toLocaleString()} ${getDefaultCurrencyCode(company)} `}
                    </span>
                    <span className="text-xs text-gray-600">/Week</span>
                  </Tag>
                )} */}
            </p>
            <hr className="my-2" />
            <div className="flex justify-center">
              <div className="flex gap-3">
                <p className="text-[12px] sm:text-sm md:text-base text-[#030229]">
                  Bundle:
                </p>
                <Tag>{item?.bundle?.[0] || 0}</Tag>
              </div>
              <div className="flex gap-3">
                <p className="text-[12px] sm:text-sm md:text-base  text-[#030229]">
                  SMS:
                </p>
                <Tag>{item?.sms?.[0] || 0}</Tag>
              </div>
              <div className="flex gap-3">
                <p className="text-[12px] sm:text-sm md:text-base text-[#030229]">
                  Calls
                </p>
                <Tag>{item?.call?.[0] || 0}</Tag>
              </div>
            </div>
            <hr className="my-2" />
            <p className="text-center text-sm md:text-base lg:text-lg font-semibold mt-1">{`For ${
              item?.duration || 0
            } Months`}</p>
          </div>

          {/* <div className="absolute left-1/2 transform -translate-x-1/2 top-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <button
              className="px-2.5 py-1.5 rounded-md transition-transform duration-300 ease-in-out hover:scale-110 bg-[#030229] hover:bg-[#605BFF] text-white"
              onClick={handleOpenDetailsModel}
            >
              See More Plans
            </button>
          </div> */}
          <div className="absolute bottom-1 right-0">
            <Tag className="bg-green-200 mx-0 rounded-none border-0">
              {item?.businessProductType}
            </Tag>
          </div>
        </div>
      ))}

      {/* <Modal
        open={isModalOpen}
        onCancel={handleOnCancel}
        key={isModalOpen ? "modal-open" : "modal-closed"}
        footer={null}
        width={1000}
      >
        <ViewSubscriptionForm data={props?.data} />
      </Modal> */}
    </div>
  );
};

export default SubProductCardGrid;

import { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import ForceLogo from "../assets/images/Force_Logo.svg";
import { Menu, Tooltip } from "antd";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MenuFoldOutlined } from "@ant-design/icons";
import profilePic from "../../../assets/images/profile.jpeg";
import {
  getAgents,
  getAllPosts,
  getCategories,
  getPricelist,
} from "../redux/actions";
import { KnowledgeActions } from "../redux";
import { set } from "store";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const KnowledgeOutlet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width: 960px)");
  const path = window.location.pathname;
  const { auth, knowledge } = useSelector((state: any) => state);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [user, setUser] = useState<any>(null);

  const menuItems = knowledge?.categories
    .map((item: any) => {
      return {
        ...item,
        subCategory: item?.subCategory?.filter((subitem: any) =>
          subitem?.accessLevels?.includes(user?.role)
        ),
      };
    })
    ?.map((category: any) => {
      if (category?.subCategory?.length > 0) {
        return {
          key: category?.categoryName,
          label: category?.categoryName,
          icon: null,
          children: category.subCategory.map(
            (subCategory: any, index: number) => {
              return {
                key:
                  category.categoryName +
                  " / " +
                  subCategory.name +
                  " / " +
                  subCategory.pageType,
                label: subCategory.name,
              };
            }
          ),
        };
      }
    });

  useEffect(() => {
    let result = localStorage.getItem("user");
    setUser(JSON.parse(result || "{}"));
  }, []);

  useEffect(() => {
    if (auth?.token) {
      getCategories(auth.token)(dispatch);
    }
  }, [auth]);

  useEffect(() => {
    if (
      auth?.token &&
      knowledge?.activeCategory &&
      knowledge?.activeSubCategory &&
      knowledge?.pageType === "knowledge"
    ) {
      getAllPosts(
        auth.token,
        `?categoryId=${
          knowledge?.categories?.find(
            (item: any) => item?.categoryName === knowledge?.activeCategory
          )?._id
        }&subCategory=${knowledge?.activeSubCategory}`
      )(dispatch);
    }
  }, [
    auth,
    knowledge?.activeCategory,
    knowledge?.activeSubCategory,
    knowledge?.pageType,
    dispatch,
  ]);

  useEffect(() => {
    if (
      auth?.token &&
      knowledge?.activeCategory &&
      knowledge?.activeSubCategory &&
      knowledge?.pageType === "pricelist"
    ) {
      getPricelist(
        auth.token,
        `?brand=${knowledge?.activeSubCategory}`
      )(dispatch);
    }
  }, [
    auth,
    knowledge?.activeCategory,
    knowledge?.activeSubCategory,
    knowledge?.pageType,
    dispatch,
  ]);

  useEffect(() => {
    if (
      auth?.token &&
      knowledge?.activeCategory &&
      knowledge?.activeSubCategory &&
      knowledge?.pageType === "agents"
    ) {
      getAgents(
        auth.token,
        `?region=${knowledge?.activeSubCategory}`
      )(dispatch);
    }
  }, [
    auth,
    knowledge?.activeCategory,
    knowledge?.activeSubCategory,
    knowledge?.pageType,
    dispatch,
  ]);
  return (
    <div
      style={{
        backgroundColor: "#fff",
        color: "#000",
      }}
    >
      <nav className="flex justify-between py-4 px-10 bg-white/80 backdrop-blur-md shadow-md w-full sticky top-0 left-0 right-0 z-10">
        <div className="flex items-center">
          <NavLink to="/dashboard" className="cursor-pointer">
            <h3 className="text-2xl font-medium">
              <img
                className={`${isMobile ? "h-6" : "h-8"} object-cover`}
                src={ForceLogo}
                alt="Store Logo"
              />
            </h3>
          </NavLink>
        </div>

        <div className="flex items-center space-x-5">
          <NavLink to="/dashboard">
            <button>Home</button>
          </NavLink>
          {user?.role?.toLowerCase() === "admin" && (
            <>
              <NavLink to="/knowledge/manage">
                <button>Manage</button>
              </NavLink>
            </>
          )}

          <div className="flex items-center">
            <Tooltip title="Account settings">
              <IconButton size="small">
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  // variant={navigator.onLine ? "dot" : "standard"}
                >
                  <img
                    className="w-12 h-12 object-cover rounded-full mr-1"
                    alt="Remy Sharp"
                    src={user?.picture || profilePic}
                  />
                </StyledBadge>
              </IconButton>
            </Tooltip>

            {!isMobile ? (
              <div className="flex flex-col justify-start items-start">
                <span className="text-sm text-left text-slate-900 font-bold leading-[14px] capitalize">
                  {user?.names}
                </span>

                <span className="text-xs text-left text-blue-800 font-normal capitalize min-w-[5rem] pt-1">
                  {user?.role?.split("-")?.join(" ").toString()}
                </span>
              </div>
            ) : (
              <div className="flex flex-col justify-start items-start">
                <button
                  onClick={() => {
                    setIsSidebarOpen(!isSidebarOpen);
                  }}
                >
                  <MenuFoldOutlined
                    style={{
                      fontSize: "1.5rem",
                      color: "#000",
                    }}
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
      <div className={!path.includes("manage") ? "flex flex-row" : ""}>
        {(!isMobile || isSidebarOpen) && !path.includes("manage") && (
          <aside className="flex flex-col w-64 h-screen px-5 pt-4 pb-32 overflow-y-auto bg-[#f6f8fa] fixed z-20">
            <div className="flex flex-col justify-between flex-1 mt-6">
              <nav className="-mx-3 space-y-6 ">
                <div className="space-y-3 ">
                  <label className="px-3 text-xs text-gray-500 uppercase dark:text-gray-400">
                    Topics
                  </label>
                  <Menu
                    onClick={(e) => {
                      if (
                        e.key.split(" / ")[1] === knowledge.activeSubCategory
                      ) {
                        return;
                      }
                      setIsSidebarOpen(false);
                      navigate("/knowledge");
                      dispatch(
                        KnowledgeActions.setListTitle(
                          e.key.split(" / ")[0] + " / " + e.key.split(" / ")[1]
                        )
                      );
                      dispatch(
                        KnowledgeActions?.setActiveCategory(
                          e.key.split(" / ")[0]
                        )
                      );
                      dispatch(
                        KnowledgeActions.setActiveSubCategory(
                          e.key.split(" / ")[1]
                        )
                      );
                      dispatch(
                        KnowledgeActions.setPageType(e.key.split(" / ")[2])
                      );
                    }}
                    className="bg-[#f6f8fa] text-gray-700"
                    defaultOpenKeys={["sub1"]}
                    mode={isMobile ? "inline" : "vertical"}
                    items={menuItems}
                  />
                </div>
              </nav>
            </div>
          </aside>
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default KnowledgeOutlet;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
// import { getSelectedRequestSentAction } from "../../../../store/wareHouseOut/actions";
import { Stack } from "@mui/material";
import WareHouseConfirm from "./WsConfirm";
import { getSelectedStockReqAction } from "../../../store/channel/actions";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
// import PurchaseOrderDetailsSkeleton from "../../../../components/skeleton/PurchaseOrderDetailsSkeleton";

const WarehouseOutCreate = (props: any) => {
  const { wareHouseOut, auth, wareHouse, channel } = useSelector(
    (state: any) => state
  );
  const { rfsId } = useParams();
  const dispatch = useDispatch();
  const deliveryNote = wareHouseOut?.selectedDeliveryNote?.data;
  React.useEffect(() => {
    auth?.token &&
      rfsId &&
      getSelectedStockReqAction(auth?.token, `?requestId=${rfsId}`)(dispatch);
  }, [auth?.token, dispatch, rfsId]);

  return (
    <>
      {!wareHouse?.isFetching && (
        <div className="text-black p-5 mt-3 space-y-2">
          <div className="p-5 bg-white rounded-lg space-y-6">
            <DetailsHeaderActionBar pageName={"Transfer"} title={rfsId} />
            <div className="flex justify-between items-start text-sm  ">
              <div className="grid grid-cols-1 gap-x-10">
                <p className="text-blue-500 text-lg pb-2">
                  {channel?.selectedRequestedStock?.data[0]?.requestId}
                </p>
                <p className="font-bold capitalize">
                  <span>
                    {
                      channel?.selectedRequestedStock?.data[0]?.shopId?.channel
                        ?.name
                    }
                  </span>
                </p>
                <p className="font-bold capitalize">
                  <span>
                    {`${
                      channel?.selectedRequestedStock?.data[0]?.shopId?.name
                    } ~ ${channel?.selectedRequestedStock?.data[0]?.shopId?.type
                      ?.split("-")
                      ?.join(" ")}`}
                  </span>
                </p>
                <p className="font-bold">
                  {deliveryNote?.pOrderID?.supplier?.name}
                </p>
                <p>{deliveryNote?.pOrderID?.supplier?.address}</p>
                <p>{deliveryNote?.pOrderID?.supplier?.phone}</p>
                <p>{deliveryNote?.pOrderID?.supplier?.email}</p>
              </div>
            </div>
            <WareHouseConfirm />
          </div>
        </div>
      )}
    </>
  );
};

export default WarehouseOutCreate;

import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllChannelAction,
  getAllShopAction,
} from "../../store/channel/actions";
import { authActions } from "../../store/auth";
import { getOneShopAction } from "../../store/shop/actions";
import { searchProduct } from "../../utils/converter";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    sm: { span: 4},
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { channel, auth } = useSelector((state: any) => state);
  const [selectedChannelId, setSelectedChannelId] = useState("");
  const [shopFilter, setShopFilter] = useState([]);

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  React.useEffect(() => {
    auth?.token &&
      selectedChannelId &&
      getAllShopAction(
        auth?.token,
        `channel=${selectedChannelId}&limit=1000`
      )(dispatch);
  }, [auth.token, dispatch, selectedChannelId]);

  const onFinish = async (values: any) => {
    localStorage.setItem("user", JSON.stringify({ shop: values }));
    dispatch(authActions.setUser({ ...auth?.user, shop: values }));
    dispatch(authActions.setUserShop(values));

    dispatch(authActions.setIsFetching(false));
    form.resetFields();
    props?.onCancel();
  };

  const handleSelectChange = (value: any) => {
    setSelectedChannelId(value);
  };

  const onSelecteShop = (value: string) => {
    auth?.token &&
      value &&
      getOneShopAction(value, auth?.token, true)(dispatch);
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={props?.data || {}}
      className="w-full md:w-[600px]"
      scrollToFirstError
    >
      <Form.Item
        name="channelId"
        label="Channel"
        hasFeedback
        rules={[
          {
            required: true,
            message: "Channel is required!",
            whitespace: true,
          },
        ]}
      >
        <Select onChange={handleSelectChange} className="h-10">
          {channel?.all?.data?.map((el: any) => {
            return <Option value={el?._id}>{el?.name}</Option>;
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="shopId"
        label="Shop"
        hasFeedback
        rules={[
          {
            required: true,
            message: "Shop is required!",
            whitespace: true,
          },
        ]}
      >
        <Select
          showSearch
          allowClear
          onSearch={(value: any) =>
            searchProduct(
              value,
              channel?.allShop?.data,
              (option: any, val) =>
                option?.name?.toLowerCase().includes(val.toLowerCase()),
              setShopFilter
            )
          }
          onChange={onSelecteShop}
          className="capitalize h-10"
          filterOption={false}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toString()
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toString().toLowerCase())
          }
        >
          {shopFilter.length > 0
            ? shopFilter.map((el: any) => {
                let isIncluded = auth?.user?.regions?.includes(el?._id);
                if (!isIncluded && auth?.user?.role === "sales-supervisor")
                  return null;
                return (
                  <Option key={el._id} value={el?._id} className="capitalize">
                    {el?.name + " ~ " + el?.type?.replace(/-/g, " ")}
                  </Option>
                );
              })
            : channel?.allShop?.data?.map((el: any) => {
                let isIncluded = auth?.user?.regions?.includes(el?._id);
                if (!isIncluded && auth?.user?.role === "sales-supervisor" )
                  return null;
                return (
                  <Option key={el._id} value={el?._id} className="capitalize">
                    {el?.name + " ~ " + el?.type?.replace(/-/g, " ")}
                  </Option>
                );
              })}
          {/* {channel?.allShop?.data?.map((el: any) => {
            let isIncluded = auth?.user?.regions?.includes(el?._id);

            if (!isIncluded && auth?.user?.role === "sales-supervisor")
              return null;
            return (
              <Option value={el?._id} className="capitalize">
                {el?.name + " ~ " + el?.type?.replace(/-/g, " ")}
              </Option>
            );
          })} */}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ minWidth: "60%" }}
          loading={channel.isFetching}
        >
          Continue
        </LoadingButton>
      </Form.Item>
    </Form>
  );
};

export default App;

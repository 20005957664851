import HttpRequest from "../../utils/HttpRequest";
import { SERVER_URL } from "../../utils/constants";

export const getSalesStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/sales?${query || ""}`, token);
};

export const getOverViewStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/overview?${query || ""}`, token);
};

export const getRevenueStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/revenue?${query || ""}`, token);
};

export const getDaySalesStats = async (token: string, day: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/salesbyday/${day}`, token);
};

export const getPaymentsMethodsStats = async (token: string, day: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/stats/calculateTotalPaymentMethods/${day}`,
    token
  );
};
export const getRevenueByShopStats = async (token: string, day: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/revenueByShop/${day}`, token);
};
export const getRevenueByAgentStats = async (token: string, day: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/createdBy/${day}`, token);
};
export const getByBrandStats = async (token: string, query: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/stats/warehouseBrands?${query || ""}`,
    token
  );
};
export const getRegionStats = async (token: string, id: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/region/${id}`, token);
};

export const getQntySoldByBrandStats = async (token: string, day: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/brand/${day}`, token);
};

export const getQntySoldByModelStats = async (token: string, day: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/model/${day}`, token);
};

export const getAllProductsByBrandservice = async (
  token: string,
  brand: string
) => {
  return HttpRequest.get(`${SERVER_URL}/stats/result/${brand}`, token);
};

export const getSalesByProductTypeStats = async (
  token: string,
  day: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/stats/salesByProductType/${day}`,
    token
  );
};

export const getDataActivationStats = async (token: string, day: string) => {
  return HttpRequest.get(`${SERVER_URL}/warehouse/dataActiveted/${day}`, token);
};



export const getPendingDevices = async (token: string, day: string) => {
  return HttpRequest.get(`${SERVER_URL}/cartItem/pendingDevices${day}`, token);
};


export const getNosale = async (token: string, day: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/noSalebyshop${day}`, token);
};


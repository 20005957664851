import React from "react";
import { Modal } from "antd";
import SelectShopToAccess from "../forms/SelectShopPOS";

const SelectShopPosModal: React.FC<{
  showModal?: boolean;
  handleClose: () => void;
}> = ({ showModal, handleClose }) => {
  return (
    <Modal
      open={showModal}
      onCancel={handleClose}
      closable={false}
      footer={null}
      centered
      className="min-w-min"
    >
      <div>
        <h2 className="text-center text-base py-2">Select Shop</h2>
        <div>
          <SelectShopToAccess onCancel={handleClose} />
        </div>
      </div>
    </Modal>
  );
};

export default SelectShopPosModal;

import React from "react";
import { Tooltip } from "antd";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { updateNotificationAction } from "../../store/notification/actions";
import Menu from "@mui/material/Menu";
import { IconButton, Badge } from "@mui/material";
import Notification from "../../components/notification/Notification";

const notification = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

const GetNotification: React.FC = () => {
  const { auth, notifications } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] =
    React.useState<null | HTMLElement>(null);
  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null);
  const openNotification = Boolean(anchorElNotification);

  const updateNotification = async () => {
    await updateNotificationAction(auth.token, {})(dispatch);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSettings(null);
    setAnchorElNotification(null);
  };

  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(event.currentTarget);
  };
  return (
    <div className="flex justify-between w-full">
      <Tooltip title="Notifications">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          onClick={handleClickNotification}
        >
          <Badge
            badgeContent={
              notifications?.unReadNotification?.data?.length > 0 &&
              notifications?.unReadNotification?.total
            }
            color={
              notifications?.unReadNotification?.data?.length > 0
                ? "error"
                : "default"
            }
            onClick={updateNotification}
          >
            <IoMdNotificationsOutline size={30} color="black" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElNotification}
        id="account-menu"
        open={openNotification}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={notification}
        defaultValue={3}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <h1 className="ml-5 text-lg font-semibold p-2">Notifications</h1>
        <div className="h-[35rem] overflow-y-auto">
          {notifications?.all?.data?.map((notification: any, index: any) => (
            <Notification data={notification} />
          ))}
        </div>
      </Menu>
    </div>
  );
};
export default GetNotification;

import React, { useState } from "react";
import SearchBarV2 from "../../../../../components/elements/SearchBarV2";
import { useDispatch, useSelector } from "react-redux";
import { myLayoutActions } from "../../../../../store/layout";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as AddCustomerPlaceholderImage } from "../../../../../assets/images/EnterCustomerInfoOption.svg";
import { getOneCustomerByQueryAction } from "../../../../../store/customer/actions";

const CustomerWithSubscription = (props: any) => {
  const { auth, customer, wareHouse } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [customerQuery, setCustomerQuery] = useState("");

  const onSearch = async (e: any) => {
    e.preventDefault();
    auth?.token &&
      (await getOneCustomerByQueryAction(
        auth?.token,
        `?phone=${customerQuery}`
      )(dispatch));
  };

  const handleOnSearchChange = (e: any) => {
    setCustomerQuery(e.target.value?.replace("+", "")?.trim());
  };

  return (
    <div>
      <div className="flex items-center justify-center py-4 space-x-2 pt-10">
        <div className="rounded-lg">
          <form method="get" onSubmit={onSearch}>
            <SearchBarV2
              placeholder="Input customer number"
              onChange={handleOnSearchChange}
              name="customer"
              isLoading={
                customer.isFetching ||
                (wareHouse?.isUpdateFetching && props?.isGettingCustomer)
              }
            />
          </form>
        </div>
        <button
          onClick={() => {
            props?.newCustomer(true);
            dispatch(myLayoutActions.setIsNext(0));
          }}
          className="shadow py-2 px-4 border bg-slate-300 rounded-md"
        >
          <PlusOutlined className="m-0 p-0" />
        </button>
      </div>
      <div className="flex justify-center">
        <AddCustomerPlaceholderImage className="h-44" />
      </div>
    </div>
  );
};

export default CustomerWithSubscription;

import React, { useState } from "react";
import ConfirmationQuestion from "./card/ConfirmationQuestion";
import { Divider, notification, RadioChangeEvent } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import {
  handlConfirmPaymentModelAction,
  handleConfirmFinalStageAction,
} from "../redux/layout/actions";
import {
  checkPaymentAction,
  generateFirstInvoiceAction,
} from "../../../../store/pos/actions";
import { agentFeedbacksAction } from "../redux/customer/actions";

const FinalConfirmation = () => {
  const { auth, wareHouse ,pos,subLayout} = useSelector((state: any) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const customerData = wareHouse?.createdCart?.data?.customer;
  const customerFeddback = [
    {
      statement: `Have you registered ${customerData?.name} personal details?`,
      feedbackReason: "YES",
    },
    {
      statement: `Did ${customerData?.name} sign consent agreements?`,
      feedbackReason: "YES",
    },
    {
      statement: `Did ${customerData?.name} agree with terms and condition?`,
      feedbackReason: "YES",
    },
    {
      statement: `Did you receive ${customerData?.name}'s first initial payment?`,
      feedbackReason: "YES",
    },
    {
      statement: "Have you configured the vault?",
      feedbackReason: "YES",
    },
  ];
  const [formData, setFormData] = useState({
    question1: null,
    question2: null,
    question3: null,
    question4: null,
    question5: null,
  });

  const onChange = (e: RadioChangeEvent, questionNumber: number) => {
    const response = e.target.value;
    const questionKey = `question${questionNumber}`;

    setFormData((prevData) => ({
      ...prevData,
      [questionKey]: response,
    }));
  };

  const isFormComplete = () => {
    return Object.values(formData).every(
      (response) => response !== null && response === "true"
    );
  };

  const checkPayment = async () => {
    if (auth.token) {
      const res = await checkPaymentAction(auth?.token, cartId as string, {
        status: "paid",
      })(dispatch);
      if (res) {  await agentFeedbacksAction(auth?.token, {
          cart: cartId,
          agent: auth?.user?._id,
          feedback: customerFeddback,
        })(dispatch);
        const response = await generateFirstInvoiceAction(
          auth?.token,
          cartId as string
        )(dispatch);
      
        if (response) {
          await handlConfirmPaymentModelAction(false)(dispatch);
          await handleConfirmFinalStageAction(false)(dispatch);
          auth?.user?.profile === "freelancer"
            ? navigate("/home")
            : navigate("/sale");
          // const res = await createSalesActions(auth?.token, {
          //   cartId: cartId as string,
          //   salesTypeCode: "N",
          //   receiptTypeCode: "S",
          //   paymentTypeCode: "01",
          //   salesStatusCode: "02",
          //   // "purchaserOrderCode": "315963"
          // })(dispatch);
          // if (res) {
          //   updateShopStockCartDetailsAction(auth?.token, cartId, {
          //     subscriptionStatus: "BILL_ACTIVATED",
          //   })(dispatch);
          // }
        }
      } 
    } else {
      return notification.error({
        message: "Error",
        description: "You are not logged in",
      });
    }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isFormComplete()) {
      checkPayment();
    } else {
      notification.error({
        message: "Kindly confirm once more that everything is complete.",
      });
    }
  };
  return (
    <div className="flex flex-col justify-center items-center ">
      <h2 className="text-center text-lg font-semibold">
        AGENT CHECKLIST FORM
      </h2>
      <Divider />
      <div className="bg-white w-full max-w-[39rem] pb-8">
        <h1 className="text-center mb-1 text-gray-800 text-lg font-semibold">
          {`Hi, ${auth?.user?.names}, Please fill out this checklist form.`}
        </h1>
        <h3 className="text-center mb-5 text-gray-800 text-lg font-normal">
          By answering the following questions.
        </h3>

        <div className="space-y-6">
          <ConfirmationQuestion
            question="I. Have you registered a customer personal details?"
            questionNumber={1}
            handleChange={onChange}
          />
          <ConfirmationQuestion
            question="II. Did customer sign consent agreements?"
            questionNumber={2}
            handleChange={onChange}
          />
          <ConfirmationQuestion
            question="III. Did customer agree with terms and condition?"
            questionNumber={3}
            handleChange={onChange}
          />
          <ConfirmationQuestion
            question="IV. Did you receive the customer first initial payment?"
            questionNumber={4}
            handleChange={onChange}
          />
          <ConfirmationQuestion
            question="V. Have you configured the vault?"
            questionNumber={5}
            handleChange={onChange}
          />

          <div className="text-center mt-8">
            <LoadingButton
              variant="contained"
              loading={pos.isFetching || subLayout?.isSavingFeedback}
              style={{ width: 200, height: 40, fontSize: "16px" }}
              onClick={handleSubmit}
            >
              SUBMIT
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalConfirmation;

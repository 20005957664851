import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import React from "react";
import Header from "./Header";
import { useMediaQuery } from "@mui/material";
import { authActions } from "../../../store/auth";
import NavBar from "./NavBar";
import UserProfile from "../../../components/layout/userProfile";
import GetNotification from "../../../components/layout/Notification";

const DashLayout = (props: any) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: any) => state);
  const location = useLocation();

  useEffect(() => {
    dispatch(authActions.setIsFetching(true));
    // dispatch(authActions.setUser(store.get("user")));
    dispatch(authActions.setIsFetching(false));
  }, [auth.token, dispatch]);

  const isTablet = useMediaQuery("(max-width: 1270px)");
  const [open, setOpen] = useState(isTablet ? false : true);
  return (
    <div className="md:flex text-black">
      <NavBar open={open} setOpen={setOpen} />
      <div className="flex flex-col w-full">
        <Header  open={open}/>
        <div className="md:hidden m-5 flex justify-between">
          <div>
            <UserProfile openSide={props?.open} />
          </div>
          {/* <div>
            <GetNotification />
          </div> */}
        </div>
        <main
          className={`flex-grow px-5  ${
            open ? " pl-2 md:pl-[220px] xl:pl-[250px]" : " pl-2 md:pl-[100px]"
          } pt-f3 md:pt-24 bg-[#FAFAFB] overflow-auto max-h-screen mb-24`}
        >
          {props.children}
        </main>
      </div>
    </div>
  );
};

export default DashLayout;

import React, { useEffect, useState } from "react";
import { Divider, Table, Tag } from "antd";
import type { TableColumnsType } from "antd";
import {
  getColorByValue,
  searchTextValue,
  searchValue,
} from "../../utils/setColor";
import { getActiveShop } from "../../utils/converter";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPendingShopOutAction,
  getAllStockToTransferAction,
} from "../../store/channel/actions";
import ScrollableFrame from "../layout/ScrollableFrame";
import store from "store";
import { GrFormView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
interface DataType {
  key: React.Key;
  model: string;
  brand: string;
  type: string;
  quantity: number;
  specifications: string;
  status: string;
}

const StockTransferTable = (props: any) => {
  const { posOverView, auth, channel, appSettings, shop } = useSelector(
    (state: any) => state
  );
  const [isTransferred, setIsTransfered] = useState("request");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;
  // const shopId = getActiveShop(auth?.user?.shop?.assigned)[
  //   appSettings?.selectedShopNumber
  // ]?.shop?._id ?? auth?.userShop?.shopId

  useEffect(() => {
    if (isTransferred === "request" && auth?.token) {
      shopId &&
        getAllStockToTransferAction(
          auth?.token,
          `?shopToTransfer=${shopId}&isTransferred=true&transferStatus=${
            props?.selectedValueButton
          }&limit=${limit}&page=${page - 1}`
        )(dispatch);
    } else {
      shopId &&
        getAllPendingShopOutAction(
          auth?.token,
          `?shop=${shopId}&status=${props?.selectedValueButton?.value}`
        )(dispatch);
    }
  }, [
    auth?.token,
    dispatch,
    limit,
    page,
    shopId,
    isTransferred,
    props?.selectedValueButton,
  ]);
  const columns: TableColumnsType<DataType> = [
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Brand",
      dataIndex: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
    },
    {
      title: "Specifications",
      width: 300,
      dataIndex: "data",
      render: (text: any, record: any) => {
        return (
          <>
            {record?.data?.product?.specification?.map((d: any) => (
              <>
                <span className="pl-2 text-sm font-medium">{d[0]}: </span>{" "}
                <span className="pl-[1px] text-[12px]">{d[1]}</span>
              </>
            ))}
          </>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      render: (text) => {
        return (
          <Tag
            color={searchValue(text)}
            className="p-1 px-3 w-[5.5rdem] capitalize"
            style={{ textAlign: "center", borderRadius: "20px" }}
          >
            <p className={`text-[${searchTextValue(text)}]`}>{text}</p>
          </Tag>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "data",
      render: (text, record: any) => {
        return (
          <>
            <GrFormView
              color="#605BFF"
              size={23}
              onClick={() =>
                navigate(
                  `/pos/transfer/${record?.data?._id}/${record?.data?.stockRequest?.requestId}`
                )
              }
              className="cursor-pointer"
            />
          </>
        );
      },
    },
  ];
  const transferData = channel?.rfsTransfer?.data?.map((el: any) => {
    return {
      key: el?._id,
      type: el?.product?.product?.type,
      brand: el?.product?.product?.brand,
      quantity: el?.qtyApproved,
      model: el?.product?.product?.model,
      status: el?.transferStatus,
      data: el,
    };
  });
  return (
    <>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={channel?.rfsTransfer?.total}
        count={Math.ceil(channel?.rfsTransfer?.total / limit)}
      >
        <Table
          columns={columns}
          dataSource={transferData}
          size="middle"
          loading={channel?.isFetching}
          pagination={false}
        />
      </ScrollableFrame>
    </>
  );
};

export default StockTransferTable;

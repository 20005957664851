import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { authActions } from "../../store/auth";
import SideNav from "./SideNav";
import { myLayoutActions } from "../../store/layout";
import { useMediaQuery } from "@mui/material";
import { getAllCartsAction } from "../../store/pos/actions";
import { getActiveShop } from "../../utils/converter";
import { getOneShopAction } from "../../store/shop/actions";
import HeaderShops from "../headerShops";
import CustomLayout from "../../apps/freelancerModule/layout/Layout";
const Layout = (props: any) => {
  const { auth, shop, appSettings, pos, layout } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isEbm = location.pathname === "/ebm";
  const isKnowledgePage = location.pathname === "/knowledge";
  const isSetting = location.pathname === "/setting";
  const isVoaults = location.pathname === "/vaults";

  useEffect(() => {
    dispatch(authActions.setIsFetching(true));
    // dispatch(authActions.setUser(store.get("user")));
    dispatch(authActions.setIsFetching(false));
  }, [auth.token, dispatch]);
  const elementRef = useRef<HTMLDivElement>(null);

  const handleFullScreenClick = () => {
    if (elementRef.current) {
      elementRef.current.requestFullscreen();
    }
  };

  const handleSideNav = () => {
    dispatch(myLayoutActions.setIsSideNavOpen(!layout.isSideNavOpen));
  };
  const smallScreen = useMediaQuery("(max-width:767px)");
  const [activeShops, setActiveShops] = React.useState<any[]>([]);
  useEffect(() => {
    dispatch(authActions.setIsFetching(true));
    // dispatch(authActions.setUser(store.get("user")));
    dispatch(authActions.setIsFetching(false));
  }, [auth.token, dispatch]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      auth?.token &&
        getOneShopAction(auth.userShop?.shopId, auth?.token, true)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const isTablet = useMediaQuery("(max-width: 1270px)");

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  useEffect(() => {
    auth?.token &&
      getAllCartsAction(
        auth?.token,
        `?shop=${shopId}&status[]=pending&status[]=rejected&status[]=accepted&status[]=requested&status[]=inquiry`
      )(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    setActiveShops(getActiveShop(auth?.user?.shop?.assigned));
  }, [auth?.user?.shop?.assigned]);
  return (
    <div className="landScreenBack">
      <div
        className={` gap-8  w-full h-screen overflow-y-auto ${
          !props?.dashback && "landScreen"
        } `}
      >
        {["freelancer"]?.includes(auth?.user?.profile) ? (
          <CustomLayout />
        ) : (
          <div className="flex">
            <SideNav
              toggleSideNav={handleSideNav}
              selected={props?.selectedNav || 0}
              isOpen={layout.isSideNavOpen}
            />
            <div className="flex flex-col w-full">
              {/* <Header open={open} data={pos} /> */}
              <Header
                nav={props?.nav}
                title={props?.title || " Samphone"}
                handleFullScreenClick={handleFullScreenClick}
                isOpen={layout?.isSideNavOpen}
                setIsOpen={setIsOpen}
              />
              {/* <main
          className={`flex-grow px-5  ${
            layout?.isSideNavOpen ? "pl-[220px] xl:pl-[250px]" : "pl-[100px]"
          }  pt-24 bg-[#FAFAFB] overflow-auto max-h-screen`}
        >
          <div className="mb-5">
            <HeaderShops selectedShop={activeShops} />
          </div>
          {props.children}
        </main> */}
              <div
                className={` pb-4 h-[calc(100%-64px)] w-full ${
                  smallScreen
                    ? "px-3 pt-[3rem]"
                    : `pr-10 pt-[3rem] pbs-4  ${
                        layout.isSideNavOpen
                          ? `pl-[230px]`
                          : `pl-[6rem]`
                      }`
                }`
          }
              >
                <div className="my-5 mt-10">
                  <HeaderShops selectedShop={activeShops} />
                </div>
                {props.children}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Layout;

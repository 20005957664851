import React from "react";
import { PaginationControlled } from "../filters/Paginate";
const ScrollableFrame: React.FC<{
  children: any;
  loading?: boolean;
  limit?: number;
  count?: number;
  total?: number;
  isTable?: boolean;
  scroll?: boolean;
  setLimit?: (limit: number) => void;
  setPage?: (page: number) => void;
}> = ({
  children,
  loading,
  limit,
  count,
  total,
  isTable,
  scroll = true,
  setLimit,
  setPage,
}) => {
  return (
    <div className="relative space-y-4 mt-2 h-[calc(100vh-190px)] xl:h-[calc(100vh-200px)] bottom-2">
      <div
        className={`h-[calc(100%-40px)] ${isTable ? "" : "p-2"} ${
          scroll ? " overflow-y-auto " : " overflow-y-hidden "
        } border rounded-md xscrollbar-hide`}
      >
        {children}
      </div>
      <div className="absolute bottom-0 right-2">
        <PaginationControlled
          loading={loading}
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={total}
          count={count}
        />
      </div>
    </div>
  );
};

export default ScrollableFrame;

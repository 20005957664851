
import React, { useEffect, useState } from "react";
import ForceLogo from "../../assets/images/Layer.png";
import { ReactComponent as NewLogo } from "../../assets/icons/Newlog_S.svg";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./sideNav.css";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { subMenusList } from "../../assets/data/pages";
import { IoIosArrowDown } from "react-icons/io";
import "../buttons/button.css";
import { GoPlus } from "react-icons/go";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { myLayoutActions } from "../../store/layout";
import CustomModal from "../Modals/CustomModal";
import CreateNoSales from "../../pages/dashboard/Sales/CreateNoSales";
import CreateSalesBtn from "../../pages/dashboard/Sales/CreateSalesBtn";
import { ReactComponent as Expandicons } from "../../icons/expand.svg";
import {
  getActiveMenu,
  storeActiveMenu,
  storeSelectedCaption,
} from "../../utils/converter";
import { useMediaQuery } from "@mui/material";
import * as faIcons from "react-icons/fa";

const SideNav: React.FC<{
  isOpen: boolean;
  selected?: number;
  toggleSideNav: () => void;
}> = ({ isOpen, toggleSideNav, selected }) => {
  const { auth, layout, company } = useSelector((state: any) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [sideBar, SetSideBar] = useState(false);
  const showSideBar = () => {
    SetSideBar(!sideBar);
  };
  const smallScreen = useMediaQuery("(max-width:767px)");
  type SubMenuOpenState = {
    [key: number]: boolean;
  };

  const [subMenuOpen, setSubMenuOpen] = useState<SubMenuOpenState>({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(getActiveMenu());

  let indexes: number[] = [];

  const toggleSubMenu = (
    indexes: number[],
    providedIndex: number,
    status = false
  ) => {
    setSubMenuOpen((prevState) => {
      const newState = { ...prevState };
      indexes.forEach((index) => {
        if (index !== providedIndex) {
          newState[index] = false;
        } else {
          newState[index] = status === false ? !prevState[index] : true;
        }
      });
      return newState;
    });
  };

  const [showCreateSales, setShowCreateSales] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attemptToOpen, setAttemptToOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(true);
    setShowCreateSales(false);
  };
  const handlCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    let currentMenu = localStorage.getItem("currentMenu") || "";
    toggleSubMenu(indexes, parseInt(currentMenu), true);
  }, [attemptToOpen]);
  return (
    <div>
      <div
        className={`nav-container fixed flex w-fit flex-col ${
          sideBar ? "min-h-[32]" : "min-h-screen"
        }   items-center !bg-whites z-50 bg-white`}
      >
        {smallScreen && (
          <faIcons.FaBars
            className="menu-bar mx-5 mt-8 text-black"
            onClick={() => {
              if (!isOpen) {
                toggleSideNav();
              }
              showSideBar();
            }}
            size={20}
          />
        )}

        <div
          className={`  ${
            sideBar && smallScreen ? "nav-menu" : "nav-menu active"
          } ${
            isOpen ? "w-52" : "w-20"
          } bg-[#F4F6FA] h-screen  pt-8 relative duration-300 shadow-lg shadow-gray-500/40`}
        >
          {/* <img
            src={Control}
            className={`absolute cursor-pointer  -right-3 top-[1.06rem] w-5 border-[#0F0F47B2]
           border-2 rounded-full  ${!isOpen && "rotate-180"}`}
            onClick={() => (smallScreen ? showSideBar() : toggleSideNav())}
            alt="nav control"
          /> */}
          <IoIosArrowDropleftCircle 
              className={`absolute top-[4.3rem] z-[999] text-[#605BFF] opacity-80 -right-3 cursor-pointer ${
                !isOpen && "rotate-180"
              }`}
              size={30}
               onClick={() => (smallScreen ? showSideBar() : toggleSideNav())}
          />

          <div className={`flex justify-center items-center -mt-[1.09rem] `}>
            {isOpen ? (
              <img
                src={ForceLogo}
                className="cursor-pointer duration-500 w-32  mt-2"
                alt=""
              />
            ) : (
              <h1 className="text-2xl  font-bold text-black duration-500 text-center">
                  <NewLogo className="h-5" />
             </h1>
            )}
          </div>
          <ul className="borders-y py-1 xl:py-5 borders-slate-300 p-2 overflow-y-auto hide-scrollbar h-[83vh]">
             {subMenusList?.map((mainMenu: any, index) => {
              indexes.push(index);
              return (
                <>
                  <li
                    key={mainMenu?.id}
                    className={`flex gap-3 my-2 p-2 py-2 rounded-sm cbg-[#FAFAFB] hover:bg-[#ebf2ff46] ${
                      selectedTabIndex === mainMenu.id ? "  bg-[#FAFAFB]" : " "
                    }`}
                    onClick={() => {
                      setSelectedTabIndex(mainMenu.id);
                      storeActiveMenu(mainMenu.id);
                      if (!mainMenu.menus) {
                        navigate(mainMenu.path);
                        // showSideBar();
                      } else {
                        dispatch(myLayoutActions.setIsSideNavOpen(true));
                      }
                    }}
                  >
                    <span
                      className={`text-2xl ml-4  ${
                        selectedTabIndex === mainMenu.id
                          ? "activeIcon shadow-[-53px_0px_25px_6px_rgba(96,91,255,1)]"
                          : "#030229CC"
                      }`}
                    >
                      <mainMenu.icon />
                    </span>
                    <span
                      className={` text-base flex items-center cursor-pointer rounded-md ${
                        !isOpen && "hidden"
                      } ${
                        selectedTabIndex === mainMenu.id
                          ? "text-[#605BFF]"
                          : "text-[#030229CC]"
                      }`}
                      onClick={(e: any) => {
                        toggleSubMenu(indexes, index);
                      }}
                    >
                      {" "}
                      {mainMenu.name}
                    </span>
                    <span>
                      {" "}
                      {mainMenu?.menus && isOpen && (
                        <IoIosArrowDown
                          onClick={(e: any) => {
                            e.stopPropagation();
                            toggleSubMenu(indexes, index);
                          }}
                          className={`mt-1 ${
                            subMenuOpen[index] ? "rotate-180" : ""
                          } ${
                            selectedTabIndex === mainMenu.id
                              ? "text-[#605BFF]"
                              : "text-[#030229CC]"
                          }`}
                        />
                      )}
                    </span>
                  </li>
                  <ul>
                    {mainMenu?.menus &&
                      subMenuOpen[index] &&
                      isOpen &&
                      mainMenu?.menus.map((subMenu: any, subIndex: any) => {
                        return (
                          <div className="flex" key={subIndex}>
                            <div className="ml-8">
                              <Expandicons />
                            </div>
                            <li
                              key={subMenu.caption}
                              onClick={() => {
                                localStorage.setItem(
                                  "currentMenu",
                                  index.toString()
                                );
                                const selectedCaption = subMenu.caption;
                                storeSelectedCaption(selectedCaption);
                                navigate(subMenu.path);
                                showSideBar();
                                setAttemptToOpen(!attemptToOpen);
                              }}
                              className={`py-1 cursor-pointer flex gap-2 text-sm my-[1px] px-2 hover:bg-light-white rounded-md duration-500 ${
                                localStorage.getItem("selectedCaption") ===
                                subMenu.caption
                                  ? "text-[#605BFF] bg-[#ebf2ff46]"
                                  : "text-[#030229CC]"
                              }`}
                            >
                              {subMenu.caption}
                            </li>
                          </div>
                        );
                      })}
                  </ul>
                </>
              );
            })}
          </ul>
          {!showCreateSales ? (
            <div
              className={`bg-gradient-to-r ${
                isOpen ? "left-14 xl:left-10" : "left-5 xl:left-3"
              }  bottom-3 xl:bottom-2 cursor-pointer absolute from-[#605BFF] to-[#8294FF] flex ${
                isOpen
                  ? "h-[60px] xl:h-[80px] w-[60px] xl:w-[80px] "
                  : " h-[45px] w-[45px]"
              }m-auto rounded-full items-center justify-between`}
              onClick={() => {
                // dispatch(myWareHouseActions.setCreatedCart(null));
                dispatch(myLayoutActions.setSetConfirmCheckout(false));
                setShowCreateSales(true);
              }}
            >
              <GoPlus
                className={` ${
                  isOpen
                    ? "h-[20px] xl:h-[42px] w-[20px] xl:w-[42px]  ml-5"
                    : "h-[37px] w-[45px] flex items-center justify-center"
                }`}
                color="white"
              />
            </div>
          ) : (
            <div className="absolute bottom-0">
              <div
                className={`bg-gradient-to-r ${
                  isOpen ? "left-16 xl:left-10" : "left-5 xl:left-3"
                }  cursor-pointer absolute bottom-3 xl:bottom-2 from-[#F1595C80] to-[#F1595C40] flex ${
                  isOpen
                    ? " h-[60px] xl:h-[80px] w-[60px] xl:w-[80px] "
                    : " h-[45px] w-[45px] "
                }m-auto rounded-full items-center justify-between`}
                onClick={() => setShowCreateSales(false)}
              >
                <MdClose
                  className={` ${
                    isOpen
                      ? "h-[20px] xl:h-[42px] w-[20px] xl:w-[42px] ml-5"
                      : "h-[34px] w-[34px] ml-1"
                  }`}
                  color="red"
                />{" "}
              </div>
              <div
                className={`absolute bottom-[6rem] xl:bottom-[6rem] rounded-full ${
                  isOpen ? "left-24 xl:left-20" : "left-10 xl:left-2"
                }`}
              >
                <CreateSalesBtn setShowCreateSales={setShowCreateSales} />
              </div>
              <div
                className={`absolute cursor-pointer bottom-2 xl:bottom-5 rounded-full ${
                  isOpen ? "left-36 xl:left-40" : "left-20 xl:left-20"
                }`}
              >
                <div
                  className={`bg-[#AFACFF] flex h-[80px] xl:h-[90px] w-[80px] xl:w-[90px] m-auto rounded-full items-center justify-between text-center  px-2 fade-in`}
                  onClick={handleOk}
                >
                  <h1 className="text-white text-sm xl:text-base font-medium">
                    Create no Sales
                  </h1>
                </div>
              </div>
            </div>
          )}
          <CustomModal
            title={
              <h1 className="text-[#0F0F47] text-lg font-medium">
                Create No sale
              </h1>
            }
            width={900}
            openModal={isModalOpen}
            cancelModal={handlCloseModal}
            closeModal={handleOk}
            component={
              <CreateNoSales onClick={handleOk} onCancel={handlCloseModal} />
            }
          />
        </div>
        
      </div>
    </div>
  );
};

export default SideNav;

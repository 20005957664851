
import React from "react";
import { Drawer, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

interface QRDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  qrData: string | null;
}

const QRDrawer: React.FC<QRDrawerProps> = ({ isOpen, onClose, qrData }) => {
  const qrContent = qrData
    ? JSON.stringify({
        teamLeadId: qrData,
        timestamp: new Date().toISOString(),
      })
    : null;

  return (
    <Drawer
      placement="right"
      open={isOpen}
      onClose={onClose}
      width={600}
      closable={false}
      title={
        <div className="flex justify-between w-full">
          <span>QR Code</span>
          <Button onClick={onClose} icon={<CloseOutlined />} type="text" />
        </div>
      }
    >
      {qrContent && (
        <div>
          <img
            src={`https://api.qrserver.com/v1/create-qr-code/?data=${qrContent}`}
            alt="QR Code"
            className="w-full h-auto pt-12"
          />
        </div>
      )}
      
    </Drawer>
  );
};

export default QRDrawer;

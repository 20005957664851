import React from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Table, Tag } from "antd";
import * as convert from "../../assets/data/productExport";
import { Space, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";

const columns= [
  {
    title: "Customer",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Id",
    dataIndex: "nid",
    key: "nid"
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email"
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone"
  },
  {
    title: "Customer Type",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    render: (_:any, record:any) => {
      return(
      <>
      {record?.data?.subscriptionInfo?.address?.district} {record?.data?.subscriptionInfo?.address?.province} {record?.data?.subscriptionInfo?.address?.country}
      </>
    )},
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  }
];
const App = (props: any) => {

  const firstFiveCustomers = props?.customer?.customers?.slice(0, 5);
  const data = firstFiveCustomers?.map((d: any) => {
    console.log(d,"d")
    const { district, province, country } = d?.subscriptionInfo?.address || {};
    return {
      key: d?._id,
      name: d?.name,
      nid: d?.nid,
      email: d?.email,
      phone: d?.phone,
      type:d?.subscriptionInfo?.customerType,
      address: `${district}, ${province}, ${country}`,
      status: <Tag color="green">{d.status}</Tag>,
      data:d
    }
  });

  return <Table columns={columns} dataSource={data} />;
};

export default App;

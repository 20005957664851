import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Stack } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { Select, Form, InputNumber, Tooltip } from "antd";
import AddIcon from "@mui/icons-material/Add";
import RfsFiltering from "../../../components/filters/RfsFiltering";
import {
  getAllProductsAction,
  getOneProductAction,
} from "../../../store/product/actions";
import { myProductActions } from "../../../store/product";
import {
  addItemToSelectedRequestAction,
  getPricelistAction,
  getPricelistItemAction,
} from "../../../store/channel/actions";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const PageView = (props: any) => {
  const [form] = Form.useForm();
  const { product, auth, quotation, channel, shop } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    const val = {
      ...values,
    };
    auth?.token &&
      (await addItemToSelectedRequestAction(
        channel?.selectedRfs?._id,
        val,
        auth.token
      )(dispatch));
    form.resetFields();
  };

  const [selectedModelId, setSelectedModelId] = useState("");

  const channelId =
    channel?.selected?._id ||
    auth?.user?.shop?.channelId ||
    auth?.user?.shop?.channel;

  useEffect(() => {
    auth?.token && getPricelistAction(auth?.token, `?channel=${channelId}`);
  }, [auth?.token, channelId, dispatch]);

  React.useEffect(() => {
    auth?.token &&
      getPricelistItemAction(
        auth?.token,
        `?channel=${channelId}${
          selectedModelId && `&product=${selectedModelId}`
        } `
      )(dispatch);
  }, [auth?.token, channelId, selectedModelId, dispatch]);

  useEffect(() => {
    auth?.token &&
      getAllProductsAction(auth?.token, product?.query || "?")(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.token, quotation?.query, quotation?.allRfqSupplier?.data]);

  useEffect(() => {
    dispatch(myProductActions.setSelected(null));
    auth?.token &&
      selectedModelId &&
      getOneProductAction(auth?.token, selectedModelId)(dispatch);
  }, [selectedModelId, channel.selectedRfs, dispatch, auth?.token]);

  return (
    <div>
      <RfsFiltering limit={200} />
        <div>
          <div  className="pl-4 pr-4 pt-2 pb-2">
            <Form
              // {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={{}}
              style={{ maxWidth: "100%" }}
              scrollToFirstError
            >
              {/* <Stack
                spacing={0}
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
              > */}
              <div className="lg:flex justify-between">
                  <Form.Item
                    name="product"
                    label="Product Model"
                    tooltip="Please select modal of the product!"
                    rules={[
                      {
                        required: true,
                        message: "Model is required!",
                      },
                    ]}
                      className="w-full lg:w-[500px]"
                  >
                    <Select
                      showSearch
                      allowClear
                      loading={product.isFetching}
                      disabled={product.isFetching}
                      // style={{ width: 300 }}
                      style={{ width: "100%",height:"60px" }}
                      value={selectedModelId === "" ? null : selectedModelId}
                      placeholder="Select By Model"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").toString().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toString().toLowerCase()
                          )
                      }
                      onChange={(value: any) => {
                        !value && setSelectedModelId("");
                        value && setSelectedModelId(value);
                      }}
                    >
                      {channel?.priceListItems?.data?.map((pro: any) => (
                        <Option key={pro._id} value={pro._id}>
                          <Tooltip
                            placement="rightTop"
                            title={pro?.specification
                              .map((spec: any) => spec[1] && spec.join(": "))
                              .join(", ")}
                          >
                            <span className="w-full">
                              <p>
                                {pro?.product?.model} ~ {pro?.product?.type}{" "}
                              </p>
                              <div className="text-xs text-gray-400">
                                {pro?.specification
                                  .map((spec: any) => {
                                    if (spec[1] && !["Brand"].includes(spec[0]))
                                      return spec.join(": ");
                                  })
                                  .join(", ")}
                              </div>
                            </span>
                          </Tooltip>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                <div className="mt-3">
                  <Form.Item
                    name="qtyRequested"
                    label="Quantity"
                    tooltip="Please enter number of quantity of the product you selected!"
                    rules={[
                      {
                        required: true,
                        message: "Quantity is required!",
                      },
                    ]}
                    className="mt-7"
                  >
                    <InputNumber
                      min={1}
                      max={100000}
                      style={{ width: "100%",height:"50px" }}
                      // onChange={onChange}
                    />
                  </Form.Item>

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      props?.onClickAdd();
                      setSelectedModelId("");
                    }}
                    startIcon={<AddIcon />}
                    sx={{ minWidth: "100%" }}
                    loading={channel?.isFetching || shop?.isFetching}
                  >
                    Add Product
                  </LoadingButton>
                </div>
                </div>
              {/* </Stack> */}
            </Form>
          </div>
        </div>
    </div>
  );
};

export default PageView;

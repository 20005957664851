import React, { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { notification } from "antd";
import { Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  checkPaymentStatusAction,
  resetPaymentRelatedStates,
} from "../../store/pos/actions";
import { getLastPaymentStatus, getMomoPayments } from "../../utils/converter";
import { checkPaymentAction } from "../../store/pos/actions";
import LoadingAnimation from "../../assets/images/circle-loading-lines.gif";
import SuccessAnimation from "../../assets/images/successfully-done.gif";
import { useNavigate } from "react-router-dom";
import { myPosActions } from "../../store/pos";
import { getDefaultCurrencyCode } from "../../utils/CurrencyHelper";

const MomoWaitingAndSuccessForm = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pos, auth, company } = useSelector((state: any) => state);

  const checkPayment = async () => {
    if (props?.auth.token) {
      const res = await checkPaymentAction(
        props?.auth?.token,
        props?.warehouse.createdCart?.data?._id as string,
        {
          status: "paid",
        }
      )(dispatch);
      // if (res) {
      //   createSalesActions(auth?.token, {
      //     cartId: props?.warehouse.createdCart?.data?._id as string,
      //     salesTypeCode: "N",
      //     receiptTypeCode: "S",
      //     paymentTypeCode: "01",
      //     salesStatusCode: "02",
      //     // "purchaserOrderCode": "315963"
      //   })(dispatch);
      // }
    } else {
      return notification.error({
        message: "Error",
        description: "You are not logged in",
      });
    }
  };

  const paymentStatus = async () => {
    await checkPaymentStatusAction(
      auth?.token,
      props?.warehouse.createdCart?.data?._id as string
    )(dispatch);
  };

  // useEffect(() => {
  //   paymentStatus();
  // }, [pos.paymentInitiationStatus]);

  const lastPaymentStatus = getLastPaymentStatus(
    pos?.statusResult?.data?.autoPayments
  );

  useEffect(() => {
    if (!pos.paymentInitiationStatus) {
      dispatch(myPosActions.setStatusResult(null));
      return;
    }

    if (lastPaymentStatus?.toLowerCase() === "failed") {
      dispatch(myPosActions.setStatusResult(null));
      dispatch(myPosActions.setPaymentInitiationStatus(false));
      notification?.error({ message: "Request Failed, Please try again" });
      return;
    }

    const timerId = setInterval(async () => {
      if (lastPaymentStatus?.toLowerCase() === "failed") {
        clearInterval(timerId);
        dispatch(myPosActions.setStatusResult(null));
        dispatch(myPosActions.setPaymentInitiationStatus(false));
        props?.onCancel();
      } else {
        await paymentStatus();
      }
    }, 10000);

    return () => {
      clearInterval(timerId);
    };
  }, [lastPaymentStatus, pos.paymentInitiationStatus]);

  // useEffect(() => {
  //   if (
  //     pos?.statusResult?.data?.autoPayments[
  //       pos?.statusResult?.data?.autoPayments?.length - 1
  //     ]?.status?.toLowerCase() === "successful"
  //   ) {
  //     checkPayment();
  //   }
  // }, [
  //   pos?.statusResult?.data?.autoPayments[
  //     pos?.statusResult?.data?.autoPayments?.length - 1
  //   ]?.status,
  // ]);

  useEffect(() => {
    const latestAutoPayment =
      pos?.statusResult?.data?.autoPayments?.[
        pos?.statusResult?.data?.autoPayments?.length - 1
      ];

    if (latestAutoPayment?.status?.toLowerCase() === "successful") {
      checkPayment();
    }
  }, [pos?.statusResult?.data?.autoPayments]);

  const totalPayment = getMomoPayments(props?.warehouse)
    ?.map((payment: { amount: number }) => payment?.amount)
    ?.reduce((a: number, b: number) => a + b, 0);
  // useEffect(()=>{
  //    ( auth?.token && pos.paymentDoneStatus ) && createSalesActions(auth?.token,
  //     {
  //       cartId: props?.warehouse.createdCart?.data?._id as string,
  //       salesTypeCode: "N",
  //       receiptTypeCode: "S",
  //       paymentTypeCode: "01",
  //       salesStatusCode: "02"
  //       // "purchaserOrderCode": "315963"
  //   }
  //    );

  // },[dispatch,pos.paymentDoneStatus, auth?.token])
  return (
    <div
      style={{ maxWidth: "100%" }}
      className="flex justify-center items-center flex-col h-full"
    >
      {!pos.paymentDoneStatus ? (
        <>
          <p className="lg:text-lg md:text-sm font-semibold pt-5">
            Complete Payment of {getDefaultCurrencyCode(company)}{" "}
            {totalPayment.toLocaleString()}
          </p>
          <p className="text-base pt-5">
            If there is no popup on your phone press *182*7*1# and confirm
            payment
          </p>
        </>
      ) : (
        <p className="lg:text-lg md:text-sm font-semibold">
          You have successfuly completed payment of
          {getDefaultCurrencyCode(company)} {totalPayment.toLocaleString()}
        </p>
      )}
      {pos.paymentDoneStatus ? (
        <>
          <img
            width="20%"
            height="20%"
            src={SuccessAnimation}
            alt="Successful"
          />
          <Button
            onClick={() => {
              navigate(
                `/checkout/${props?.warehouse.createdCart?.data?._id as string}`
              );
              resetPaymentRelatedStates()(dispatch);
            }}
            sx={{ minWidth: 250 }}
            className="mt-4 mb-2"
          >
            Finish
          </Button>
        </>
      ) : (
        <img width="20%" height="20%" src={LoadingAnimation} alt="loading" />
      )}
    </div>
  );
};

export default MomoWaitingAndSuccessForm;

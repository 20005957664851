import { myCompanyActions } from ".";
import { getOneCompanyService } from "./services";

export const getOneCompanyAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCompanyActions.setIsFetching(true));
      const res = await getOneCompanyService(id, token);
      if (res?.status === 200) {
        dispatch(myCompanyActions.setSelected(res?.data));
        dispatch(myCompanyActions.setIsFetching(false));
        console.log(res,"response")
      }
      dispatch(myCompanyActions.setIsFetching(false));
    } catch (err) {
      console.log(err,"errr");
    }
  };
};
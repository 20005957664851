import React, { useEffect } from "react";
import { notification, Progress } from "antd";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getLastPaymentStatus, getMomoPayments } from "../../../utils/converter";
import { getDeviceUploadedAction, uploadDeviceToVaultAction } from "../../device_financing/Subscription/redux/vault/actions";
import { subLayoutActions } from "../../device_financing/Subscription/redux/layout";
import { checkPaymentAction, checkPaymentStatusAction, generateFirstInvoiceAction } from "../../../store/pos/actions";
import { myPosActions } from "../../../store/pos";
import { handlConfirmPaymentModelAction, handleOpenPaymentModelAction, handleOpenTourAction, handlePaymentStatusAction } from "../../device_financing/Subscription/redux/layout/actions";
import FinalConfirmation from "../../device_financing/Subscription/components/FinalConfirmation";
import LoadingAnimation from "../../../assets/images/circle-loading-lines.gif";
import SuccessAnimation from "../../../assets/images/successfully-done.gif";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";
const LoadingPaymentProcess = (props: any) => {
  const { pos, auth, wareHouse, subLayout,apllicants,company } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  // const { cartId } = useParams();
  const navigate=useNavigate();
const cartId=apllicants?.applicantDetails?.data?._id;
console.log(cartId,'cartIdcartIdcartId')
  const imei =
    wareHouse?.createdCart?.data?.list?.at(0)?.warehouseProduct?.serialNumber;

  const lastPaymentStatus = getLastPaymentStatus(
    pos?.statusResult?.data?.autoPayments
  );

  // //uploaded device
  // useEffect(() => {
  //   if (subLayout?.paymentStatus === "invoice") {
  //     const uploadDevice = async () => {
  //       const res = await uploadDeviceToVaultAction(auth?.token, {
  //         deviceUid: [imei],
  //         paymentMethod: "postpaid",
  //       })(dispatch);
  //       if (res?.type && res?.data?.uploadStatus === "INPROGRESS") {
  //         dispatch(
  //           subLayoutActions.setCheckIsDeviceUploaded({
  //             type: true,
  //             uploadID: res?.data?.uploadID,
  //           })
  //         );
  //       }
  //     };
  //     uploadDevice();
  //   }
  // }, [auth?.token, dispatch, subLayout?.paymentStatus]);

  // //check if device uploaded
  // useEffect(() => {
  //   if (
  //     subLayout?.checkIsDeviceUploaded?.type &&
  //     !["done", "vault_fail"]?.includes(
  //       subLayout?.paymentStatus && subLayout?.checkIsDeviceUploaded?.uploadID
  //     )
  //   ) {
  //     const intervalId = setInterval(async () => {
  //       await getDeviceUploadedAction(
  //         auth?.token,
  //         `?uploadId=${subLayout?.checkIsDeviceUploaded?.uploadID}`
  //       )(dispatch);
  //     }, 5000);

  //     return () => clearInterval(intervalId);
  //   }
  // }, [
  //   auth?.token,
  //   dispatch,
  //   subLayout?.checkIsDeviceUploaded?.type,
  //   subLayout?.checkIsDeviceUploaded?.uploadID,
  //   subLayout?.paymentStatus,
  // ]);

  const checkPayment = async () => {
    if (auth.token) {
      dispatch(myPosActions.setPaymentDoneStatus(true));
      dispatch(myPosActions.setIsFetching(false));
      dispatch(subLayoutActions.setIsCustomerPaid(true));
  
  }};

  const paymentStatus = async () => {
    await checkPaymentStatusAction(auth?.token, cartId as string)(dispatch);
  };

  useEffect(() => {
    if (
      !pos.isPayingInitial
    ) {
      dispatch(myPosActions.setStatusResult(null));
      return;
    }

    if (lastPaymentStatus?.toLowerCase() === "failed") {
      handlePaymentStatusAction("fail")(dispatch);
      dispatch(myPosActions.setStatusResult(null));
      dispatch(myPosActions.setIsPayingInitial(false));
      notification?.error({ message: "Request Failed, Please try again" });
      return;
    }

    const timerId = setInterval(async () => {
      if (lastPaymentStatus?.toLowerCase() === "failed") {
        handlePaymentStatusAction("fail")(dispatch);
        clearInterval(timerId);
        dispatch(myPosActions.setStatusResult(null));
        dispatch(myPosActions.setIsPayingInitial(false));
        props?.onCancel();
      } else {
        // if (!["done", "vault_fail"]?.includes(subLayout?.paymentStatus)) {
          await paymentStatus();
        // }
      }
    }, 10000);

    return () => {
      clearInterval(timerId);
    };
  }, [lastPaymentStatus, pos.isPayingInitial, subLayout?.paymentStatus]);

  useEffect(() => {
    const latestAutoPayment =
      pos?.statusResult?.data?.autoPayments?.[
        pos?.statusResult?.data?.autoPayments?.length - 1
      ];

    if (
      latestAutoPayment?.status?.toLowerCase() === "successful" &&
      !subLayout?.isCustomerPaid
    ) {
      // handlePaymentStatusAction("invoice")(dispatch);
      checkPayment();
    }
  }, [
    pos?.statusResult?.data?.autoPayments,
    subLayout?.paymentStatus,
    subLayout?.isCustomerPaid,
  ]);

  // const totalPayment = getMomoPayments(apllicants)
  //   ?.map((payment: { amount: number }) => payment?.amount)
  //   ?.reduce((a: number, b: number) => a + b, 0);
const totalPayment=apllicants?.applicantDetails?.data?.selectedPlan?.initialPayment
  const handlePrevious = () => {
    handlConfirmPaymentModelAction(false)(dispatch);
    handleOpenPaymentModelAction(true)(dispatch);
  };

  const handleConfrimPayment = () => {
    // if (["vault_fail"]?.includes(subLayout?.paymentStatus)) {
    //   handlePaymentStatusAction("invoice")(dispatch);
    // } else {
      // handleOpenTourAction(true)(dispatch);
    // }
    dispatch(subLayoutActions.setConfirmPaymentModel(false))
    navigate('/home')
  };

  return (
    <>
      {subLayout?.isFinalStage ? (
        <FinalConfirmation />
      ) : (
        <div className="flex flex-col items-center justify-center mx-auto">
          <div className="flex items-center mx-auto flex-col space-y-2 py-6">
            <div className="flex items-center flex-col justify-center mb-4">
              <p className="text-base lg:text-lg md:text-sm">
                Complete Payment of {getDefaultCurrencyCode(company)} <span className="font-semibold">{totalPayment?.toLocaleString()}</span> 
              </p>
              <p className="text-sm sm:text-base text-center mx-12">
                If no popup appears on your phone, dial *182*7*1# and follow the
                steps to confirm your payment.
              </p>
            </div>
            {["fail", "vault_fail"]?.includes(subLayout?.paymentStatus) ? (
              <svg
                viewBox="0 0 1024 1024"
                fill="currentColor"
                color="red"
                height="6em"
                width="6em"
              >
                <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
                <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
              </svg>
            ) : subLayout?.paymentStatus === "done" || pos?.paymentDoneStatus ? (
              <img
                width="15%"
                height="15%"
                src={SuccessAnimation}
                alt="Successful"
              />
            ) : (
              <img
                width="15%"
                height="15%"
                src={LoadingAnimation}
                alt="loading"
              />
            )}
          </div>

          <div className="flex flex-col space-y-4 pb-6">
            <div className="flex gap-x-4 items-center">
              <Progress
                type="circle"
                percent={
                  (["invoice", "vault", "done", "vault_fail"]?.includes(
                    subLayout?.paymentStatus
                  )|| pos?.paymentDoneStatus)
                    ? 100
                    : 25
                }
                size={40}
                status={
                  subLayout?.paymentStatus === "fail" ? "exception" : undefined
                }
              />
            {pos?.paymentDoneStatus? <span>Initial Payment Confirmed</span>:<span>Confirming Initial Payment</span>}  
            </div>
          </div>
          <div className="py-3 flex gap-x-4">
            <LoadingButton
              variant="outlined"
              style={{ width: 100, height: 40, fontSize: "16px" }}
              onClick={handlePrevious}
              disabled={["vault_fail", "done"]?.includes(
                subLayout?.paymentStatus
              )}
            >
              Back
            </LoadingButton>
            <LoadingButton
              variant="contained"
              style={{ width: 200, height: 40, fontSize: "16px" }}
              // disabled={["pending", "fail", "invoice"]?.includes(
              //   subLayout?.paymentStatus
              // )}
              disabled={!pos?.paymentDoneStatus}
              onClick={handleConfrimPayment}
            >
              {/* {["vault_fail"]?.includes(subLayout?.paymentStatus)
                ? "Reactivate vault"
                : "Confirm"} */}
              {pos?.paymentDoneStatus ?"Close":"Confirm"}  
            </LoadingButton>
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingPaymentProcess;

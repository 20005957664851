import React, { useState, useEffect } from "react";
import { Line, LineChart, ResponsiveContainer } from "recharts";
import { ReactComponent as ReachartFooter } from "../../../assets/rechart.svg";
import { Card, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const DataActivationAndTrafficCard: React.FC<{
  title: any;
  amount: number;
  text:any;
  chartData: any;
  comparedAmount: any;
}> = ({ title, amount, comparedAmount, chartData, text }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { dashboard } = useSelector((state: any) => state);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 4000); // Simulating a loading time of 2 seconds

    return () => clearTimeout(timeout); // Cleanup function to clear timeout on unmount
  }, []);

  return (
    <div className="relative w-full sm:w-[50%] mt-5 ">
      <Card className="relative z-10">
        <p className="capitalize">{title}</p>
        {isLoading  ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : (
          <>
            <span className="font-poppins text-lg">{`${amount} ${text}`}</span>
            <div className="font-poppins text-lg text-gray-400">{`${comparedAmount}  ${text}`}</div>
          </>
        )}
        <div className="h-5">
          <ReachartFooter className="absolute right-0 bottom-0" />
        </div>
      </Card>
    </div>
  );
};

export default DataActivationAndTrafficCard;


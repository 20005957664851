import React, { useEffect, useRef } from "react";
import GrowingSpinner from "../Loaders/GrowingSpinner";

const SearchBarV2: React.FC<{
  value?: any;
  name?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  scannedCode?: string;
}> = ({
  value,
  name,
  placeholder,
  onChange,
  isDisabled,
  isLoading,
  scannedCode,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/\D/g, ""); // Remove all non-digit characters

    if (formattedValue.length > 3) {
      if (!formattedValue.startsWith("250")) {
        e.target.value = "+250" + formattedValue;
      }
    } else {
      e.target.value = "+250";
    }

    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = "+250";
    }
  }, []);

  return (
    <div className="flex justify-center">
      <div className="xmb-3 xl:w-96">
        <div className="relative xmb-4 flex w-full flex-wrap items-stretch rounded-md bg-gray-100">
          <input
            type="text"
            name={name || "q"}
            disabled={isDisabled || false}
            className="relative m-0 block w-[1%] min-w-0 flex-auto rounded xborder bg-transparent bg-clip-padding px-3 py-1.5 text-base font-normal text-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary-600 focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
            placeholder={placeholder || "Search"}
            aria-label="Search"
            aria-describedby="button-addon2"
            ref={inputRef}
            onChange={handleChange}
            // autoFocus
          />
          <button
            type="submit"
            className="flex justify-center items-center w-16"
          >
            <span
              className={`input-group-text flex items-center whitespace-nowrap rounded px-3 py-1.5 text-center text-base font-normal ${
                isLoading ? "" : "text-neutral-200"
              }`}
              id="basic-addon2"
            >
              {!isLoading && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                    clipRule="evenodd"
                    className="text-gray-400"
                  />
                </svg>
              )}
              {isLoading && (
                <div>
                  <GrowingSpinner />
                </div>
              )}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBarV2;

import { myDashboardActions } from ".";
import {
  getDaySalesStats,
  getPaymentsMethodsStats,
  getRevenueByAgentStats,
  getRevenueByShopStats,
  getSalesStats,
  getRevenueStats,
  getByBrandStats,
  getRegionStats,
  getQntySoldByBrandStats,
  getQntySoldByModelStats,
  getAllProductsByBrandservice,
  getSalesByProductTypeStats,
  getDataActivationStats,
  getPendingDevices,
  getNosale,
  getOverViewStats
} from "./services";


export const getOverViewStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getOverViewStats(token, query);
      // const resRevenue = await getRevenueStats(token, query);
      // // console.log(">>>>>>>:: shops", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setOverviewStats(res));
        // dispatch(myDashboardActions.setAllRevenueStats(resRevenue));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSalesStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getSalesStats(token, query);
      // const resRevenue = await getRevenueStats(token, query);
      // // console.log(">>>>>>>:: shops", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setSalesStats(res));
        // dispatch(myDashboardActions.setAllRevenueStats(resRevenue));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPendingDevicesAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getPendingDevices(token, query);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setPendingDevices(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};



export const getNosaleAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getNosale(token, query);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setNosale(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSalesStatsByDayActions = (token: string, day: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getDaySalesStats(token, day);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setDaySalesStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPaymentsMethodsStatsActions = (token: string, day: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getPaymentsMethodsStats(token, day);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setPaymentMethodStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getRevenueByShopStatsActions = (token: string, day: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getRevenueByShopStats(token, day);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setRevenueStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getRevenueByAgentStatsActions = (token: string, day: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getRevenueByAgentStats(token, day);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setRevenueAgentStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getByBrandStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getByBrandStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setStatsByBrand(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getStatsByRegionsActions = (token: string, day: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getRegionStats(token, day);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setRegionStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getQntySoldByBrandStatsActions = (token: string, day: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getQntySoldByBrandStats(token, day);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setQntyByBrandStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getQntySoldByModelStatsActions = (token: string, day: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getQntySoldByModelStats(token, day);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setQntyByModelStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllProductsByBrandActions = (token: string, brand: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getAllProductsByBrandservice(token, brand);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setProductsByBrand(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSalesByProductTypeStatsActions = (
  token: string,
  day: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getSalesByProductTypeStats(token, day);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setSalesByProductType(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDataActivationStatsActions = (token: string, day: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getDataActivationStats(token, day);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setDataActivetion(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

import { SERVER_URL } from "../../../../../utils/constants";
import HttpRequest from "../../../../../utils/HttpRequest";

export const uploadDeviceToVaultService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault`, data, token);
};

export const getDeviceUploadedService = async (token: string, query: any) => {
  return await HttpRequest.get(`${SERVER_URL}/vault${query || `?`}`, token);
};
export const getDeviceInfoService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault/devices`, data, token);
};
import React, { ReactInstance, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import GeneralTermsAndConditions from "./subscriptionCustomerForms/GeneralTermsAndConditions";

import "./subscriptionCustomerForms/address.css";

const TermsAndConditionForm = (props: any) => {
  const templateRef = useRef<ReactInstance>(null);
  const handlePrint = useReactToPrint({
    content: () => templateRef.current,
  });

  return (
    <div className="w-fulls">
      <GeneralTermsAndConditions
        handlePrint={handlePrint}
        templateRef={templateRef}
        cancel={props?.onCancel}
      />
    </div>
  );
};

export default TermsAndConditionForm;

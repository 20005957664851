import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllShopStockService = async (token: string, id: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/warehouseByproduct/${id}`, token);
};

export const getStockItemsStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/warehouse?${query || ""}`, token);
};
export const getStockStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopStats/?${query || ""}`, token);
};
export const getRecentActivities = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopStats/recent?${query || ""}`, token);
};


export const getTeamLeadTransfersServices = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/subscriptionTransfer/alltransfers/${query || ""}`, token);
};

export const getTeamLeadTransfersDetailsServices = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/subscriptionTransfer/${query || ""}`, token);
};




export const getTeamLeadStatusCountServices = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/subscriptionTransfer/statusCount/${query}`, token);
};





export const CreateTeamLeadTransfersServices = async (token: string,data:any) => {
  return HttpRequest.post(`${SERVER_URL}/subscriptionTransfer/transfer`,data,token);
};



export const getAllTeamLeadServices = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/user/users?limit=2000&profile=freelancer`,token);
};


export const updateTransferServices = async (transferId:any,data:any,token:any) => {
  return HttpRequest.update(`${SERVER_URL}/subscriptionTransfer/${transferId}`,token,data);
};

export const updateTeamLeadTransferServices = async (transferId:any,data:any,token:any) => {
  return HttpRequest.update(`${SERVER_URL}/subscriptionTransfer/updateOne/${transferId}`,token,data);
};

export const returnTransferServices = async (transferId:any,data:any,token:any) => {
  return HttpRequest.update(`${SERVER_URL}/subscriptionTransfer/returnstock/${transferId}`,token,data);
};


export const searchAllServices = async (token: string,query:any) => {
  return HttpRequest.get(`${SERVER_URL}/shopstockitem/search${query || ""}`,token);
};

export const getStockForOwnAssigneeServices = async (token: string,query?:any) => {
  return HttpRequest.get(`${SERVER_URL}/subscriptionTransfer/stock?${query}`,token);
};



export const getStockStatusSCountServices = async (token: string,query?:any) => {
  return HttpRequest.get(`${SERVER_URL}/subscriptionTransfer/stockcount?${query}`,token);
};

import React, { useEffect, useRef } from "react";
import { Input } from "antd";

const InputField: React.FC<{ onChange: (event: any) => void }> = ({
  onChange,
}) => {
  const inputRef = useRef<any>(null);

  useEffect(() => {
    inputRef.current?.focus();
  });
  return (
    <Input.Password
      ref={inputRef}
      placeholder="Input password"
      //   onSearch={props?.onSearch}
      onChange={onChange}
      // enterButton
      //   style={{ width: 250 }}
      type="number"
      pattern="[0-9]*"
      inputMode="numeric"
    />
  );
};

export default InputField;

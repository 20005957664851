import React from "react";
import { useSelector } from "react-redux";
import PersonnelDetailsForm from "../../forms/PersonnelDetailsForm";
import ConsentAgreementPage from "../../forms/consentAgreement";
import Creditscoring from "../../components/steps/Creditscoring";
import MatchingPLan from "../../components/steps/MatchingPLan";
import UploadDocs from "../../components/steps/UploadDocs";
// import Checkeligibility from "../../components/steps/Checkeligibility";
import DeviceScanning from "../../components/steps/DeviceScanning";

const RegisterCustomer = (props: any) => {
  const { subLayout } = useSelector((state: any) => state);

  const steps = [
    {
      title: "Personnel Details",
      content: (
        <PersonnelDetailsForm
          removeCustomer={props?.onCancel}
          setIsModalOpen={props?.newCustomer}
        />
      ),
    },
    {
      title: "consent details",
      content: <ConsentAgreementPage removeCustomer={props?.onCancel} />,
    },
    {
      title: "Documents",
      content: <UploadDocs />,
    },
    // {
    //   title: "Credit scoring",
    //   content: <Creditscoring />,
    // },
    {
      title: "Matching plan",
      content: <MatchingPLan />,
    },

    {
      title: "Scanning Devices",
      content: <DeviceScanning />,
    },
  ];

  return <div>{steps[subLayout?.nextStep]?.content}</div>;
};

export default RegisterCustomer;
import React from "react";
import { Table, Tag } from "antd";
import { searchTextValue, searchValue } from "../../utils/setColor";
import { useSelector } from "react-redux";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
const InventoryTable = () => {
  const { inventory, pos } = useSelector((state: any) => state);
  const navigate = useNavigate();

  const columns = [
    {
      title: "Date",
      dataIndex: "date"
    },
    {
      title: "RequestId",
      dataIndex: "RqId"
    },
    {
      title: "Delivered by",
      dataIndex: "name"
    },
    {
      title: "Deliver phone",
      dataIndex: "phone"
    },
    {
      title: "Quantity Requested",
      dataIndex: "quantity",
      render: (text: any) => <p>{text} Products</p>
    },
    {
      title: "Quantity Sent",
      dataIndex: "approved",
      render: (text: any) => <p>{text} Products</p>
    },
    {
      title: "Done By",
      dataIndex: "by"
    },
    {
      title: "Status",
      dataIndex: "status",
      width:100,

      render: (text: any) =>{
        return (
        <Tag
          color={searchValue(text)}
          className="p-1 px-3 w-4g0"
          style={{
            // minWidth: "2rem",
            textAlign: "center",
            borderRadius: "20px"
          }}
        >
          <p className={`text-[${searchTextValue(text)}] text-sm capitalize`}>{text}</p>
        </Tag>
      )}
    },
    {
      title: "Action",
      dataIndex: "data",

      render: (text: any) => {
        return (
          <GrView
            color="blue"
            className="cursor-pointer"
            size={20}
            onClick={() => navigate(`/pos/${text?.warehouseOutId}`)}
          />
        );
      }
    }
  ];
  const Data = pos?.recent?.data?.map((el: any) => {
    const totalQuantityApproved = el?.stockRequest?.list?.reduce(
      (acc: number, item: any) => acc + item.qtyRecieved,
      0
    );
    const totalQuantityRequested = el?.stockRequest?.list?.reduce(
      (acc: number, item: any) => acc + item.qtyRequested,
      0
    );
    return {
      key: el?._id,
      date: el?.createdAt?.slice(0, 10),
      RqId: el?.stockRequest?.requestId,
      quantity: totalQuantityRequested,
      approved: el?.list?.length,
      name: el?.deliverer?.name,
      phone: el?.deliverer?.phone,
      by: el?.createdBy?.names,
      status: el?.status,
      data: el
    };
  });

  return (
    <div>
      <Table
        columns={columns}
        dataSource={Data}
        size="middle"
        loading={pos?.isFetching}
      />
    </div>
  );
};

export default InventoryTable;

import React from 'react'

interface cardProps{
    total?:any;
    text?:string;
    icon?:any
}
const OverViewCard = ({total,text,icon}:cardProps) => {
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    border: {
      border: "0px 1px 0px 0px solid rgba(3, 2, 41, 0.04)",
    },
  };
  return (
    <div className='bg-white p-5 w-[c384px] rounded-md h-[11s2px]' style={styles.customShadow}>
        <div className='flex gap-10'>
            <div className='w-[42px] h-[42px] rounded-full border flex justify-center items-center'>{icon}</div>
            <div>
                <p className='text-[22px] font-semibold text-[#030229cc]'>{total || 0}</p>
                <p className='text-base text-[#030229d0] pt-2'>{text}</p>
            </div>

        </div>
    </div>
  )
}

export default OverViewCard
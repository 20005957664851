import React from "react";
import { Table, Button, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFrame from "../layout/ScrollableFrame";
import { getActiveShop } from "../../utils/converter";
import { getStockForOwnAssigneeActions } from "../../store/inventory/actions";
import { Icon } from "lucide-react";
import { IoMdArrowRoundBack } from "react-icons/io";



const StockForOneFreelancerTable: React.FC<any> = ({
  userId,
  onBack,
  productId
}) => {
  const { inventory, auth, appSettings } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;
  const Id = auth?.user?.profile === "freelancer" ? auth?.user?._id : userId;

  React.useEffect(() => {
    auth?.token &&
      getStockForOwnAssigneeActions(
        auth?.token,
        `shop=${shopId}&assignee=${Id}&product=${productId}`
      )(dispatch);
  }, [auth?.token, dispatch, shopId,productId]);

  const columns = [
    {
      title: "Model",
      key: "model",
      width: 150,
      render: (record: any) => <p>{record?.item?.item.product?.model}</p>,
    },
    {
      title: "Descriptions",
      key: "descriptions",
      width: 200,
      render: (record: any) => (
        <div>
          {record?.item?.item.requestedItem?.product?.specification?.map(
            (spec: any, index: any) => (
              <div key={index}>
                {spec[0]}: {spec[1]}
              </div>
            )
          )}
        </div>
      ),
    },

    {
      title: "Shop",
      key: "shop",
      width: 100,
      render: (record: any) => <p>{record?.shop}</p>,
    },
    {
      title: "Serial Number",
      key: "serialnumber",
      width: 100,
      render: (record: any) => <p>{record?.item?.item?.serialNumber}</p>,
    },
    {
      title: "Price(Rfw)",
      key: "price",
      width: 100,
      render: (record: any) => (
        <p>
          {" "}
          {record?.item?.item.requestedItem?.product?.prices?.[0]?.value ||
            "N/A"}
        </p>
      ),
    },
    {
      title: "Extended warranty",
      key: "extendedwaranty",
      width: 100,
      render: (record: any) => (
        <p>
          {record?.item?.item.requestedItem?.product?.extendedWarranty || 0}{" "}
        </p>
      ),
    },
    {
      title: "Status",
      key: "status",
      width: 100,
      render: (record: any) => {
        const status = record?.item?.status;
        let color = "default";
        if (status === "available") color = "green";
        else if (status === "pending") color = "orange";
        else if (status === "sold") color = "red";
        else if (status === "transfer") color = "blue";
        return (
            <Tag color={color} style={{ width: "60px", padding: "4px 8px" }}>
                {status}
            </Tag>
        );
    },
    },
  ];

  const stockData = inventory?.freelancerStock?.data?.map((item: any) => ({
    ...item,
    key: item._id,
  }));

  return (
    <>
      {auth.user.profile !== "freelancer" && (
        <Button
          type="primary"
          onClick={onBack}
          style={{ marginBottom: "3rem" }}
        >
          <IoMdArrowRoundBack />
        </Button>
      )}

      <Table
        columns={columns}
        dataSource={stockData}
        size="middle"
        loading={inventory?.isFetching}
        pagination={false}
        scroll={{ x: "100%", y: 300 }}
      />
    </>
  );
};

export default StockForOneFreelancerTable;

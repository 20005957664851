import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { getDefaultCurrencyCode } from "../../utils/CurrencyHelper";

const ShopLineChart = () => {
  const { dashboard,company } = useSelector((state: any) => state);
  const [hiddenKeys, setHiddenKeys] = useState<any>([]);
  const [colors, setColors] = useState<any>({
    SmartPhone: "#2943D6",
    FeacturedPhone: "#DE2C2F",
    Accessories: "#0FA958",
  });

  const toggleDataKey = (key: any) => {
    if (hiddenKeys.includes(key)) {
      setHiddenKeys(hiddenKeys.filter((k: any) => k !== key));
    } else {
      setHiddenKeys([...hiddenKeys, key]);
    }
  };
  const getColor = (key: any) => {
    return colors[key] || "#8884d8";
  };
  const chartData = dashboard?.hourRevenueStats?.data?.revenue;
  const data = chartData?.currentDate?.revenueByHour?.map(
    (item: any, index: any) => {
      const todayRevenue = item?.revenue;
      const yesterdayRevenue = chartData?.comparedDate?.revenueByHour[index].revenue;
      const hour = item.hour > 12 ? item.hour - 12 + " PM" : item.hour + " AM";
      const name = hour;

      return { name, Today: todayRevenue, Yesterday: yesterdayRevenue };
    }
  );

  const yAxisFormatter = (value: number) => (`${getDefaultCurrencyCode(company)} ${value}`);

  return (
    <div className="flex items-center justify-center mx-cauto w-full h-[35vh]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={300}
          height={100}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" fontSize={17} />
          <YAxis fontSize={14} tickFormatter={yAxisFormatter} /> 
          <Tooltip />
          <Line type="monotone" dataKey="Today" stroke="#82ca9d" />
          <Line
            type="monotone"
            dataKey="Yesterday"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ShopLineChart;

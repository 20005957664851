import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "sub-layout",
  initialState: {
    isFetching: false,
    isNewCart: false,
    openPaymentModel: false,
    confirmPaymentModel: false,
    isCustomerPaid: false,
    isFinalStage: false,
    enableTour: false,
    stateInfoStatus: null,
    checkIsDeviceUploaded: { type: false, uploadID: null },
    paymentStatus: "pending",
    nextStep: 0,
    newCustomer: false,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setNextStep(state, action) {
      state.nextStep = action.payload;
    },
    setOpenPaymentModel(state, action) {
      state.openPaymentModel = action.payload;
    },
    setConfirmPaymentModel(state, action) {
      state.confirmPaymentModel = action.payload;
    },
    setPaymentStatus(state, action) {
      state.paymentStatus = action.payload;
    },
    setIsFinalStage(state, action) {
      state.isFinalStage = action.payload;
    },
    setCheckIsDeviceUploaded(state, action) {
      state.checkIsDeviceUploaded = action.payload;
    },
    setEnableTour(state, action) {
      state.enableTour = action.payload;
    },
    setStateInfoStatus(state, action) {
      state.stateInfoStatus = action.payload;
    },
    setIsCustomerPaid(state, action) {
      state.isCustomerPaid = action.payload;
    },
    setNewCustomer(state, action) {
      state.newCustomer = action.payload;
    },
    setOpenNewCart(state, action) {
      state.isNewCart = action.payload;
    },
  },
});

export const subLayoutActions = mySlice.actions;

export default mySlice.reducer;

import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const getAllPostsService = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/knowledge${query || ""}`, token);
};

export const getSinglePostService = async (token: string, id: string) => {
  return HttpRequest.get(`${SERVER_URL}/knowledge/one/${id}`, token);
};

export const getCategoriesServices = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/knowledge/category`, token);
};

export const getSingleCategoryService = async (token: string, id: string) => {
  return HttpRequest.get(`${SERVER_URL}/knowledge/category/one/${id}`, token);
};

export const createNewCategoryService = async (token: string, data: any) => {
  return HttpRequest.post(`${SERVER_URL}/knowledge/category`, data, token);
};

export const createNewPostService = async (token: string, data: any) => {
  return HttpRequest.post(`${SERVER_URL}/knowledge`, data, token);
};

export const editPostService = async (token: string, id: string, data: any) => {
  return HttpRequest.update(`${SERVER_URL}/knowledge/one/${id}`, data, token);
};

export const editCategoryService = async (
  token: string,
  id: string,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/knowledge/category/one/${id}`,
    data,
    token
  );
};

export const getAllPricelistService = async (token: string, query: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/pricelistitem/product${query || ""}`,
    token
  );
};

export const getAllAgentsService = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/assign/region${query || ""}`, token);
};

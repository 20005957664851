import { LoadingButton } from "@mui/lab";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { useState } from "react";
import {
  getAllTransactionAction,
  updateRRACodeActions,
} from "../../store/pos/actions";
import { getActiveShop } from "../../utils/converter";
import { myNotificationActions } from "../../store/notification";
import { myLayoutActions } from "../../store/layout";

const RRAForm = (props: any) => {
  const { auth, pos, appSettings, wareHouse } = useSelector(
    (state: any) => state
  );
  console.log("rrraaa", props);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const dispatch = useDispatch();
  const onFinish = async (values: any) => {
    await updateRRACodeActions(auth.token, props?.itemId, {
      receiptCode: values?.receiptCode,
    })(dispatch);
    dispatch(myNotificationActions.setMissingRRACode(null));
    dispatch(myLayoutActions.setIsMissingRRAcode(false));

    if (!props?.formCart) {
      await getAllTransactionAction(
        auth?.token,
        `${
          (auth.user?.shop?._id || auth?.userShop?.shopId) &&
          `?shop=${
            getActiveShop(auth?.user?.shop?.assigned)[
              appSettings?.selectedShopNumber
            ]?.shop?._id ?? auth?.userShop?.shopId
          }&status=paid&date=${props?.date}`
        }`
      )(dispatch);
    }
    form.resetFields();
    props?.onCancel(-1);
  };
  const [form] = Form.useForm();

  const handleCancel = () => {
    props?.onCancel(-1);
  };

  return (
    <div className="relative">
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        layout="vertical"
        style={{ maxWidth: "100%", height: "110px" }}
        scrollToFirstError
      >
        <Form.Item
          name="receiptCode"
          label="RRA Code"
          rules={[
            {
              required: true,
              message: "RRA Code is Required",
              whitespace: true,
            },
          ]}
        >
          <Input disabled={wareHouse.isFetching} />
        </Form.Item>
        <div className="flex justify-between">
          <Form.Item>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "100%" }}
              className="w-32"
              disabled={wareHouse?.isFetching}
              loading={wareHouse?.isFetching || pos?.isFetching}
            >
              {"Save"}
            </LoadingButton>
          </Form.Item>
          {/* <Form.Item>
            <LoadingButton
              onClick={handleCancel}
              style={{
                minWidth: "100%",
                backgroundColor: "red",
                color: "white",
              }}
              disabled={wareHouse.isFetching || pos?.isFetching}
            >
              {"Cancel"}
            </LoadingButton>
          </Form.Item> */}
        </div>
      </Form>
    </div>
  );
};

export default RRAForm;

import { Row } from "antd";
import { useState } from "react";

import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";

import styles from "../styles/Home.module.css";

export default function App() {
  const { auth } = useSelector((state: any) => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className={styles.container}>
      <Row className="w-full font-exo h-full">
        <div className="w-1/2 flex items-center justify-center bg-login">
          <div className="backdrop-blur-xl bg-white/20 w-2/3 h-2/3 flex flex-col justify-center text-7xl space-y-4 rounded-lg font-doublebold">
            <p
              className="ml-24 mb-3 not-italic font-black "
              style={{ fontWeight: "900 !important" }}
            >
              Meet Samphone.
            </p>
            <p></p>
            <p className="ml-24 text-black"> Simplifying</p>
            <p className="ml-24 text-black">the wireless</p>
            <p className="ml-24 text-black">world.</p>
            <p></p>
            <p className="ml-24 text-xl">
              Every stage of a device life cycle made simple.
            </p>
          </div>
        </div>
        <div className="text-black flex justify-center w-1/2 flex-col space-y-7">
          {/* <img src="logo.png" alt="" className="w-56 h-16 ml-24" /> */}
          <h1 className="text-[28px] text-center text-black">Samphone</h1>
          <section>
            <div className="font-doublebold text-4xl ml-24">
              Hello Samphone heroes, 👋🏿
            </div>
            <div className="text-gray-300 text-xl ml-24">
              Reset Your Password
            </div>
          </section>
          <div className="ml-24 w-3/5">
            <label className="block font-extrabold mb-2 text-lg">Email</label>
            <input
              className="w-full border border-gray-200 bg-white rounded-lg h-10 p-2"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="ml-24 w-3/5">
            <label className="block font-extrabold mb-2 text-lg">
              New Password
            </label>
            <input
              className="border w-full border-gray-200 bg-white rounded-lg h-12 p-2"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </div>
          <div className="ml-24 w-3/5 flex items-center justify-between">
            <div>
              <a
                href="/login"
                className="w-full place-content-end text-right font-bold text-sm text-blue-400"
              >
                Remember Password yet??
              </a>
            </div>
            <div className="">
              <LoadingButton
                style={{ width: 150 }}
                className="h-12 text-white indigobgmedium rounded-lg"
                variant="contained"
                loading={auth?.isFetching}
              >
                Reset Password
              </LoadingButton>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
}

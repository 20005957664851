import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "WareHouse States",
  initialState: {
    isFetching: false,
    isReFetchingCart: false,
    isUpdateFetching: false,
    createCartIsFetching: false,
    dataActivatedIsFetching: false,
    paymentMethodeIsFetching: false,
    hasRemovedDevice: false,
    all: { data: [] },
    allPaymentMethod: null,
    selected: null,
    selectedDeliveryNote: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    filters: null,
    allStockStats: null,
    allStockProductStats: null,
    searchResult: null,
    searchError: null,
    createdCart: null,
    isCartCreated: false,
    dataActivation: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },

    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSelectedDeliveryNote(state, action) {
      state.selectedDeliveryNote = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },

    setCreateCartIsFetching(state, action) {
      state.createCartIsFetching = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setIsReFetchingCart(state, action) {
      state.isReFetchingCart = action.payload;
    },
    setAllStockStats(state, action) {
      state.allStockStats = action.payload;
    },
    setAllStockProductStats(state, action) {
      state.allStockProductStats = action.payload;
    },
    setSearchResult(state, action) {
      state.searchResult = action.payload;
    },
    setSearchError(state, action) {
      state.searchError = action.payload;
    },
    setCreatedCart(state, action) {
      state.createdCart = action.payload;
    },
    setIsCartCreated(state, action) {
      state.isCartCreated = action.payload;
    },
    setIsdataActivation(state, action) {
      state.dataActivation = action.payload;
    },
    setIsUpdateFetching(state, action) {
      state.isUpdateFetching = action.payload;
    },
    setDataActivatedIsFetching(state, action) {
      state.dataActivatedIsFetching = action.payload;
    },
    setAllPaymentMethod(state, action) {
      state.allPaymentMethod = action.payload;
    },
    setPaymentMethodeIsFetching(state, action) {
      state.paymentMethodeIsFetching = action.payload;
    },
    setHasRemovedDevice(state, action) {
      state.hasRemovedDevice = action.payload;
    },
  },
});

export const myWareHouseActions = mySlice.actions;

export default mySlice.reducer;

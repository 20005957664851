import React from "react";
import { searchValue } from "../../../utils/setColor";
import { Tag } from "antd";

interface cardProps {
  name?: any;
  text?: string;
  img?: any;
  email?: any;
  phone?: any;
  onClick?: any;
  status?: any;
}
const ApplicantsCard = ({
  name,
  text,
  img,
  email,
  phone,
  onClick,
  status,
}: cardProps) => {
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    border: {
      border: "0px 1px 0px 0px solid rgba(3, 2, 41, 0.04)",
    },
  };
  return (
    <div
      className="bg-white p-2 w-[c384px] rounded-md h-[182px]  mt-5 "
      style={styles.customShadow}
      onClick={onClick}
    >
      <div className="flex gap-5 w-full">
        <div className="w-[42px] h-[42px] rounded-full border flex justify-center items-center ">
          <img src={img} className="rounded-full" />
        </div>
        <div className="w-full">
          <div className="flex justify-between w-full">
            <p className="text-sm font-semibold text-[#030229cc]">
              {name || 0}
            </p>
            <Tag
              color={searchValue(status)}
              style={{ minWidth: "4rem", textAlign: "center" }}
            >
              <span className="text-[#030229B2] text-sm capitalize"> {status}</span>
            </Tag>
          </div>
          <p className="text-[13px] text-[#605BFF] ">{text}</p>
          <div className="flex w-full gap-10 mt-5">
            <p className="text-[#03022980]  text-sm"> Email</p>
            <p className="text-[#030229] text-sm">{email}</p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm"> Phone</p>
            <p className="text-[#030229] text-sm">{phone}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantsCard;

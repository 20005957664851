import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { Badge, Divider, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getTeamLeadTransfersActions,
  getTeamLeadTransfersDetailsActions,
  updateTeamLeadTransferAction,
} from "../../store/inventory/actions";
import { getActiveShop } from "../../utils/converter";

const TeamLeadGridCard = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { auth, appSettings } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  const getStatusColor = (status: any) => {
    switch (status) {
      case "pending":
        return "#BDBDBD";
      case "cancelled":
        return "#ff0000";
      case "active":
        return "#3AC722";
      default:
        return "#BDBDBD";
    }
  };

  const handleCancelClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event from bubbling up to parent
    setIsModalOpen(true);
  };

  const handleConfirmCancel = async () => {
    if (auth.token) {
      const res = await updateTeamLeadTransferAction(
        props?.transfer?._id,
        { status: "cancelled" },
        auth.token
      )(dispatch);

      if (res.status ===200) {
        getTeamLeadTransfersActions(
          auth?.token,
          `${shopId}`
        )(dispatch);
        getTeamLeadTransfersDetailsActions(
          auth.token,
          props?.transfer?._id
        )(dispatch);
      }
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <Badge.Ribbon
        text={<h1 className="capitalize py-1 px-2">{props?.transfer?.status}</h1> }
        color={getStatusColor(props?.transfer?.status)}
      >
        <div
          className="cursor-pointer h-auto bg-white text-black rounded-lg p-2.5 border border-gray-100  hover:bg-white hover:border-gray-300"
          onClick={props?.onClick}
        >
              <div className="flex items-start justify-between">
                <div className="text-sm font-medium">
                  {props?.transfer?.transferId}
                </div>
              </div>
          {/* <Stack direction="row" alignItems="center" sx={{ width: "100%" }}> */}
              <div className="flex flex-row align-center justify-between w-full mt-5">
                <div className="text-sm text-[#605BFF]">
                  {props?.transfer?.shopName}
                </div>

                {props?.transfer?.status === "pending" &&
                  ["admin", "team-leader"].includes(auth?.user?.profile) && (
                    <div
                      onClick={handleCancelClick}
                      className="text-red-500 text-center text-xs p-1 bg-white rounded-md border border-red-500 cursor-pointer w-[78px]"
                    >
                      Cancel 
                    </div>
                  )}
              </div>
          {/* </Stack> */}
          <Divider />
          <div>
            <div className="flex gap-4 text-sm px-2">
              <span className="text-gray-500">Created By:</span>
              <span>{props?.transfer?.createdBy}</span>
            </div>
            <div className="flex gap-4 text-sm py-1 px-2">
              <span className="text-gray-500">Assignee:</span>
              <span>{props?.transfer?.assignee}</span>
            </div>

            <div className="flex justify-between text-sm py-1 px-2">
              <div className="flex gap-2 text-sm">
                <span className="text-gray-500">Created On:</span>
                <span>{props?.transfer.createdOn?.slice(0, 10)}</span>
              </div>
              <div>
                {props?.transfer.devices} Device
                {props?.transfer.devices !== 1 ? "s" : ""}
              </div>
            </div>
          </div>
        </div>
      </Badge.Ribbon>

      <Modal
        title="Cancel Transfer"
        open={isModalOpen}
        onOk={handleConfirmCancel}
        onCancel={() => setIsModalOpen(false)}
        okText="Yes, cancel transfer"
        cancelText="No, keep it"
        okButtonProps={{
          style: { backgroundColor: "#f5222d", borderColor: "#f5222d" },
        }}
      >
        <p>
          Are you sure you want to cancel this transfer? This action cannot be
          undone.
        </p>
      </Modal>
    </>
  );
};

export default TeamLeadGridCard;

import React from "react";
import { useSelector } from "react-redux";
import AddToCart from "../../../components/AddToCart";
import { CurstomeCardSkeleton } from "../../../components/skeleton/Card";
import DeviceScanning from "../../device_financing/Subscription/components/steps/DeviceScanning";
import CartInformation from "../../../pages/dashboard/Sales/CartInformation";
import { Skeleton } from "antd";

const SaleWithSubscription = () => {
  const { auth, appSettings, wareHouse, customer, layout, subLayout } =
    useSelector((state: any) => state);
  return (
    <>
        <div>
          {wareHouse?.createdCart?.data?.list?.length < 1 ? (
            <DeviceScanning />
          ) : (
            <div className="px-5 py-7 h-full">
              <CartInformation />
            </div>
          )}
        </div>
    </>
  );
};

export default SaleWithSubscription;

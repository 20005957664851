import { Stack, Button, Box, Tab } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProductActions } from "../../store/product";
import {
  getAllProductsAction,
  getAllAttributesAction,
} from "../../store/product/actions";
import { getPricelistItemAction } from "../../store/channel/actions";
import { IoFilterOutline } from "react-icons/io5";
import { TabContext, TabList, TabPanel } from "@mui/lab";
const { Option } = Select;

const PriceListFilter = (props: any) => {
  const { product, auth, channel, pos } = useSelector((state: any) => state);
  const channelId = auth?.user?.shop?.channel || auth?.userShop?.channelId;
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [productId, setProductId] = useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(40);

  useEffect(() => {
    dispatch(
      myProductActions.setQuery(
        `?${type && `type=${type}&${brand && `brand=${brand}`}`}`
      )
    );
  }, [brand, dispatch, type]);

  useEffect(() => {
    const fetchingData = async () => {
      auth?.token &&
        getAllProductsAction(
          auth?.token,
          product?.query + `&limit=${limit}&page=${page - 1}` || "?"
        )(dispatch);
      auth?.token &&
        (await getAllAttributesAction(
          auth?.token,
          product?.query || "?"
        )(dispatch));
    };
    fetchingData();
  }, [auth, product?.query, dispatch, limit, page]);

  useEffect(() => {
    if (productId !== "") {
      auth?.token &&
        getPricelistItemAction(
          auth?.token,
          `?channel=${channelId}&product=${productId}`
        )(dispatch);
    }
  }, [auth?.token, channelId, dispatch, productId]);

  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setBrand("");
      setProductId("");
    }
  };
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    props.setSelectedTab(newValue);
  };

  return (
    <div className="px-4 py-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          spacing={1}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Device Prices" value="1" />
                <Tab label="Subscription Plans" value="2" />
              </TabList>
            </Box>
          </TabContext>
          {/* <Button
            startIcon={
              selectedFilters ? <IoFilterOutline /> : <IoFilterOutline />
            }
            onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}
          >
            Filter
          </Button> */}
        </Stack>
      </Stack>
    </div>
  );
};

export default PriceListFilter;

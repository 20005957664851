import { message, notification } from "antd";
import { myInventoryActions } from ".";
import {
  getAllShopStockService,
  getRecentActivities,
  getStockItemsStats,
  getStockStats,
  getTeamLeadTransfersServices,
  CreateTeamLeadTransfersServices,
  getAllTeamLeadServices,
  searchAllServices,
  getTeamLeadTransfersDetailsServices,
  getTeamLeadStatusCountServices,
  updateTransferServices,
  updateTeamLeadTransferServices,
  returnTransferServices,
  getStockForOwnAssigneeServices,
  getStockStatusSCountServices
} from "./services";

export const getAllShopStockAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getAllShopStockService(token, id);
      // console.log(">>>>>>>:: shop stock>>>...", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setAllShopStock(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const getStockInItemsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getStockItemsStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setStockItems(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSearchBySerialNumberActions = (
  token: string,
  query: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getStockItemsStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setSearchBySn(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getShopStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getStockStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setShopStats(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getShopRecentActivitiesActions = (
  token: string,
  query: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getRecentActivities(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setRecentActivities(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getTeamLeadTransfersActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getTeamLeadTransfersServices(token, query);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setTransfer(res));
        dispatch(myInventoryActions.setIsFetching(false));
        dispatch(myInventoryActions.setIsUpdated(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getTeamLeadTransfersDetailsActions = (
  token: string,
  id: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getTeamLeadTransfersDetailsServices(token, id);
    
      if (res?.status === 200) {
        dispatch(myInventoryActions.setTransferDetails(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const CreateTeamLeadTransfersActions = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await CreateTeamLeadTransfersServices(token, data);
   
      if (res?.status === 201) {
        dispatch(myInventoryActions.setNewTransfer(res));
        dispatch(myInventoryActions.setIsFetching(false));
        return res;
      }
      if (res?.response?.data?.status === 400) {
        notification.error({ message: res?.response?.data?.error });
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getTeamLeadstatusCountActions = (token: string, id: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getTeamLeadStatusCountServices(token, id);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setStatusCount(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getTeamLeadActions = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getAllTeamLeadServices(token);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setAllTeamLead(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};


export const getStockForOwnAssigneeActions = (token: any,query?:any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getStockForOwnAssigneeServices(token,query);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setFreelancerStock (res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getStockStatusSCountActions = (token: any,query?:any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getStockStatusSCountServices(token,query);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setStockStatusCount(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};



export const getSearchByEmiesActions = (token: string, query: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await searchAllServices(token, query)
      if (res?.status === 200) {
        dispatch(myInventoryActions.setSearchByEmies(res));
        dispatch(myInventoryActions.setIsFetching(false));   
        if(res?.data?.product?.status === "sold")  {
          message.error(res?.message);
          return {type:false,data:{}}  
        }
        else{
          return {type:true,data:res.data} 
        }
               
      } else {
        message.error(res?.response.data?.error);
      }
      dispatch(myInventoryActions.setIsFetching(false));
      return {type:false,data:{}}
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateTransferAction = (
  transferId: string,
  data: any,
  token: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await updateTransferServices(transferId, token, data);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setUpdateTransfer(res?.data));

        dispatch(myInventoryActions.setIsFetching(false));
        dispatch(myInventoryActions.setIsUpdated(true));
        notification.success({ message: res.message });
        return true;
        
      }
      if (res?.response?.data?.status === 400) {
        notification.error({ message: res?.response?.data?.error });
      }
      dispatch(myInventoryActions.setIsFetching(false));
      return false
    } catch (err) {
      console.log(err);
    }
  };
};


export const updateTeamLeadTransferAction = (
  transferId: string,
  data: any,
  token: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await updateTeamLeadTransferServices(transferId, token, data);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setUpdateTeamTransfer(res?.data));
        dispatch(myInventoryActions.setIsFetching(false));
        dispatch(myInventoryActions.setIsUpdatedOne(true));
        notification.success({ message: res.message });
        return res;
      }
      if (res?.response?.data?.status === 400) {
        notification.error({ message: "Team lead is not verified" });
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log("an error occured......");
      console.log(err);
    }
  };
};



export const returnTransferAction = (
  transferId: string,
  data: any,
  token: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await returnTransferServices(transferId, token, data);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setIsReturn(res?.data));
        dispatch(myInventoryActions.setIsFetching(false));
        notification.success({ message: res.message });
        return res;
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log("an error occured......");
      console.log(err);
    }
  };
};

import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { useSelector } from "react-redux";
import countryList from "country-list";
// @ts-ignore
import { Provinces, Districts, Sectors, Cells, Villages } from "rwanda";

const AddressDetailsForm = () => {
  const { wareHouse } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedCell, setSelectedCell] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");

  const [listDistrict, setListDistrict] = useState<any[]>([]);
  const [listSector, setListSector] = useState<any[]>([]);
  const [listCell, setListCell] = useState<any[]>([]);
  const [listVillage, setListVillage] = useState<any[]>([]);

  const countryOptions = countryList.getData()?.map((country: any) => ({
    label: country.name,
    value: country.name,
  }));

  const provinceOptions: [{ value: string; label: string }] = Provinces()?.map(
    (pro: string) => ({
      label: pro,
      value: pro,
    })
  );

  useEffect(() => {
    try {
      setListDistrict(
        Districts(selectedProvince ?? "")?.map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      setListSector([]);
      setSelectedSector("");

      return;
    }
  }, [selectedProvince]);

  useEffect(() => {
    try {
      setListSector(
        Sectors(selectedProvince ?? "", selectedDistrict ?? "")?.map(
          (val: string) => ({
            label: val,
            value: val,
          })
        )
      );
    } catch (e: any) {
      setSelectedCell("");
      setListCell([]);
      return;
    }
  }, [selectedDistrict, listDistrict]);

  useEffect(() => {
    try {
      setListCell(
        Cells(
          selectedProvince ?? "",
          selectedDistrict ?? "",
          selectedSector ?? ""
        )?.map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      setSelectedVillage("");
      setListVillage([]);
      return;
    }
  }, [listSector, selectedSector]);

  useEffect(() => {
    try {
      setListVillage([]);
      setSelectedVillage("");
      setListVillage(
        Villages(
          selectedProvince ?? "",
          selectedDistrict ?? "",
          selectedSector ?? "",
          selectedCell ?? ""
        )?.map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      console.log(e);
    }
  }, [listCell, selectedCell]);

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
      );
    } else {
      form.resetFields();
    }
  }, [form, wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address]);

  return (
    <div className="rounded-lg">
      <div>
        <h1 className="text-black text-lg pb-4 font-semibold">
          Address Details
        </h1>
      </div>
      <div className="grid sm:grid-cols-2 2xl:grid-cols-3 gap-x-4">
        <Form.Item
          name="country"
          label={<span className="text-[#0F0F47] text-sm">Country</span>}
          rules={[
            {
              required: true,
              message: "Country is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            showSearch
            className="h-12"
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toString()
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toString().toLowerCase())
            }
            onChange={(value: string) => {
              setSelectedCountry(value);
              form.resetFields([
                "province",
                "district",
                "sector",
                "cell",
                "village",
              ]);
            }}
            options={countryOptions}
          >
            <Input
              placeholder="Choose Country"
              className="pl-4 h-[40px] w-[100%] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] border"
            />
          </Select>
        </Form.Item>
        <Form.Item
          name="province"
          label={<span className="text-[#0F0F47] text-sm">Province</span>}
          rules={[
            {
              required: true,
              message: "Province is required!",
              whitespace: true,
            },
          ]}
        >
          {selectedCountry === "Rwanda" ? (
            <Select
            className="h-12"
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => {
                setSelectedProvince(value);
                form.resetFields(["district", "sector", "cell", "village"]);
              }}
              options={provinceOptions}
              allowClear
            />
          ) : (
            <Input
              placeholder="Choose Province"
              className="pl-4 h-[40px] lg:max-2xl:h-[42px] border"
            />
          )}
        </Form.Item>
        <Form.Item
          name="district"
          label={<span className="text-[#0F0F47] text-sm">District</span>}
          rules={[
            {
              required: true,
              message: "District is required!",
              whitespace: true,
            },
          ]}
        >
          {selectedCountry === "Rwanda" ? (
            <Select
            className="h-12"
              showSearch
              placeholder={
                <div className="flex flex-col align-center">
                  Search to Select
                </div>
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => {
                setSelectedDistrict(value);
                form.resetFields(["sector", "cell", "village"]);
              }}
              options={listDistrict}
              allowClear
            />
          ) : (
            <Input
              placeholder="Choose District"
              className="pl-4 h-[40px] lg:max-2xl:h-[42px] border"
            />
          )}
        </Form.Item>
        <Form.Item
          name="sector"
          label={<span className="text-[#0F0F47] text-sm">Sector</span>}
          rules={[
            {
              required: true,
              message: "Sector is required!",
              whitespace: true,
            },
          ]}
        >
          {selectedCountry === "Rwanda" ? (
            <Select
            className="h-12"
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => {
                setSelectedSector(value);
                form.resetFields(["cell", "village"]);
              }}
              options={listSector}
              value={selectedSector}
              allowClear
            />
          ) : (
            <Input
              placeholder="Choose Sector"
              className="pl-4 h-[40px] lg:max-2xl:h-[42px] border"
            />
          )}
        </Form.Item>
        <Form.Item
          name="cell"
          label={<span className="text-[#0F0F47] text-sm">Cell</span>}
          rules={[
            {
              required: true,
              message: "Cell is required!",
              whitespace: true,
            },
          ]}
        >
          {selectedCountry === "Rwanda" ? (
            <Select
            className="h-12"
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => {
                setSelectedCell(value);
                form.resetFields(["village"]);
              }}
              options={listCell}
              value={selectedCell}
              allowClear
            />
          ) : (
            <Input
              placeholder="Choose Cell"
              className="pl-4 h-[40px] lg:max-2xl:h-[42px] border"
            />
          )}
        </Form.Item>
        <Form.Item
          name="village"
          label={<span className="text-[#0F0F47] text-sm">Village</span>}
          rules={[
            {
              required: true,
              message: "Village is required!",
              whitespace: true,
            },
          ]}
        >
          {selectedCountry === "Rwanda" ? (
            <Select
            className="h-12"
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => setSelectedVillage(value)}
              options={listVillage}
              value={selectedVillage}
              allowClear
            />
          ) : (
            <Input className="pl-4 h-[40px] lg:max-2xl:h-[42px] border" />
          )}
        </Form.Item>
      </div>
    </div>
  );
};

export default AddressDetailsForm;

import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import RequestGrid from "../../../../components/grids/RequestGrid";
import { Spin } from "antd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { getActiveShop } from "../../../../utils/converter";
import {
  createRequestStockAction,
  getAllStockSentAction,
} from "../../../../store/channel/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import ButtonGroupComponent from "../../../../components/buttons/ButtonGroupComponent";
const groupByOptionsWout: any = [
  { label: "Pending Stock", value: "request" },
  { label: "Approved Stock", value: "done" },
  { label: "In Transit", value: "In-Transit" },
  { label: "Under Review", value: "review" },
];

const PageView = (props: any) => {
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [selectedValueButton, setSelectedValueButton] = useState<any>({
    value: "request",
  });
  const { auth, channel, appSettings } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shopId = getActiveShop(auth?.user?.shop?.assigned)[
    appSettings?.selectedShopNumber
  ]?.shop?._id;

  useEffect(() => {
    auth?.token &&
      shopId &&
      getAllStockSentAction(
        auth?.token,
        `?shopId=${shopId}&limit=${limit}&page=${page - 1}&status=${
          selectedValueButton?.value
        }`
      )(dispatch);
  }, [auth?.token, dispatch, limit, page, shopId, selectedValueButton]);

  return (
    <Stack spacing={1}>
      <div className="text-black pt-4 pb-2 flex justify-between items-center">
        <div>
          {
            <ButtonGroupComponent
              options={groupByOptionsWout}
              setSelectedValueButton={setSelectedValueButton}
            />
          }
        </div>
        <LoadingButton
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={async () => {
            if (auth?.token) {
              const res: any = await createRequestStockAction(auth?.token, {
                shopId: shopId,
                channel: auth?.user?.shop?.channel,
              })(dispatch);
              if (res) {
                navigate(`/pos/request/${res?.requestId}`);
              }
            }
          }}
          loading={channel?.isFetching}
        >
          Request Stock
        </LoadingButton>
      </div>
      <Box>
        <span className=" text-black font-medium">
          Stock Requested:
          {channel.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            channel?.reqStock?.result
          )}
        </span>
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={channel?.selectedRfs?.total}
          count={Math.ceil(channel?.selectedRfs?.total / limit)}
          // isTable
        >
          <div className="flex flex-wrap gap-5 justify-start items-start">
            <RequestGrid
              data={channel?.selectedRfs?.data}
              limit={limit}
              page={page}
            />
          </div>
        </ScrollableFrame>
      </Box>
    </Stack>
  );
};

export default PageView;

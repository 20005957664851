import React from "react";
import { Badge, Divider, Space } from "antd";
import { useNavigate } from "react-router-dom";
import ProfileAvatal from "../buttons/ProfileAvatal";
const Component = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
          <Badge.Ribbon
        text={
          <span
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontWeight: 400,
              paddingTop: "1.5px",
            }}
          >
            {props.data?.status}
          </span>
        }
        color={props.data?.isActive ? "green" : "red"}
        style={{ width: "5rem", padding: "3px 1px" }}
      >
        <div
          className="w-[384px] h-[200px] text-[#030229] bg-white border-[.5px] border-[#D9DBE930] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] p-6 font-normal"
          onClick={() => navigate(`/customers/${props.data._id}`)}
        >
          <div className="flex gap-6">
            <div style={{ width: "2rem" }}>
              <ProfileAvatal
                picture={props?.data?.picture}
                name={props?.data?.name}
                noActiveBadge={true}
              />
            </div>
            <div className="flex flex-col">
              <h1 className="text-[16px]">{props.data?.name?.toUpperCase()}</h1>
              <p className="text-[14px] text-[#605BFF]">{props?.data?.nationalId}</p>
            </div>
          </div>
          <Divider />
          <div className="flex flex-col text-[14px]">
            <Space>
              <p className="opacity-50 ">Email : </p>
              <p className="text-[#030229] ">{props.data?.email}</p>
            </Space>
            <Space>
              <p className="opacity-50 ">Phone : </p>
              <p className="text-[#030229] ">{props.data?.phone}</p>
            </Space>
            <Space>
              <p className="opacity-50 ">Address : </p>
              <p className="text-[#030229] ">{props.data?.address}</p>
            </Space>
          </div>
        </div>
      </Badge.Ribbon>
    </>
  );
};

export default Component;

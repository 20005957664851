import React, { useEffect, useState } from "react";

const POSSteps: React.FC<{
  steps: any;
  handleFinish: any;
  status?: String;
  handlePrintReceipt?: () => void;
}> = ({ steps, handleFinish, status, handlePrintReceipt }) => {
  const [, setCurrent] = useState(0);

  useEffect(() => {
    if (
      status?.toLocaleLowerCase() === "paid" ||
      status?.toLocaleLowerCase() === "cancelled"
    ) {
      setCurrent(1);
    }
  }, [status]);

  return (
    <div className="relative h-full xbg-white bg-opacity-50 rounded-lg px-1 pb-5 overflow-y-auto">
      {/* <div className="h-full overflow-auto -mt-2"> */}
      {steps.content}
      {/* </div> */}
    </div>
  );
};

export default POSSteps;

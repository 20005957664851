import React from "react";

function Notification({ data }: { data: any }) {
  return (
    <div
      className={`${
        data.read ? "bg-red-300 mx-2" : "bg-white"
      } w-full p-5 border-border-dark dark:border-white border-b-[0.5px] min-w-[392px]`}
      key={data.id}
    >
      <div className="flex flex-row justify-between align-center gap-x-[20px] ">
        <img
          src={
            data.profile ||
            "https://www.pci.uni-heidelberg.de/bpc2/images/faces/dummy.jpg"
          }
          alt="oldMan"
          className="rounded-[1000px] w-[20px] h-[20px] object-cover cursor-pointer mt-1"
        />
        <div className="flex flex-col w-[30rem] gap-[2px] cursor-pointer text-[#030229]">
          <p className="font-medium text-[14px]">{data.message}</p>
          <p className=" text-[11px] opacity-80">{data.action}</p>
          <p className="text-[11px] opacity-80">
            {data.createdAt?.slice(0, 10)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Notification;

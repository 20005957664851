import { useState } from "react";
import UploadComponent from "../../../../components/forms/Upload";
const File = () => {
  const [pictures, setPictures] = useState([]);
  return (
    <>
      <div className="bg-white p-5 py-10 border-dotted border-2 border-[#0302291A] w-full flex justify-center items-center">
        <div className="w-1/s4 m-asuto">
        <div className="ml-20 mb-5">

          <UploadComponent
            className="bg-blue-500"
            setPictures={setPictures}
            limit={1}
          />
        </div>
          <p className='text-[#030229] text-base'>Choose a file or drag & drop here</p>
          <span className="text-[#03022980] text-[12px]">JPEG,PNG,PDG, and MP4 formats up to 50MB</span>
        </div>
      </div>
    </>
  );
};
export default File;

import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React from "react";
import StockTransfer from "./StockTransfer";
import TeamleadTransfer from "./TeamleadTransfer";
import { useSelector } from "react-redux";
import StockForOneFreelancerTable from "../../../components/tables/StockForOneFreelancerTable";

const Transfer = () => {
  const { auth } = useSelector((state: any) => state);
  const handleBack = () => {
    // console.log("ddddd");
  };

  return (
    <div className="text-black">
      <Tabs defaultActiveKey="1">
        {auth.user.profile !== "freelancer" && (
          <TabPane tab="Stock Transfer" key="1" className="text-[16px]">
            <StockTransfer />
          </TabPane>
        )}
        {["freelancer", "team-leader"]?.includes(auth?.user?.profile) && (
          <TabPane tab="Create Transfer" key="2">
            <TeamleadTransfer />
          </TabPane>
        )}
        {["admin"]?.includes(auth?.user?.role) && (
          <TabPane tab="Create Transfer" key="2">
            <TeamleadTransfer />
          </TabPane>
        )}
        {auth?.user?.profile === "freelancer" && (
          <TabPane tab="My Stock" key="3">
            <StockForOneFreelancerTable userId={""} onBack={handleBack} />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default Transfer;


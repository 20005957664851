import { Routes, Route } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import LoginByScan from "../pages/LoginByScan";
import LoginProtection from "./LoginProtection";

// customers
import CustomerPageView from "../pages/dashboard/Customers";
import CustomerOverview from "../pages/dashboard/customer/OverviewPage";
import CustomerDetails from "../pages/dashboard/customer/CustomerDetails";

//POS
import PointOfSales from "../pages/dashboard/PointOfSales";
import PageNotFound from "../pages/PageNotFound";
import POSRoutes from "./POSRoutes";
import SaleWithPOS from "../pages/dashboard/POS/SaleWithPOS";
import POSTransactions from "../pages/dashboard/POS/POSTransactions";
import TransactionsDetails from "../components/cards/transactionDetails/ViewTransaction";
import NewRequestView from "../pages/dashboard/channels/NewRequestView";

import PosPriceList from "../pages/dashboard/POS/PriceList";
import PosStockRequest from "../pages/dashboard/POS/requestStock/POSRequestStock";

//reset password
import ResetPassword from "../pages/ResetPassword";
import ViewSingleRecent from "../pages/dashboard/POS/viewRecent/ViewSingleRecent";

import { authActions } from "../store/auth";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

import RepairRoutes from "./RepairRoutes";
import RepairOverview from "../pages/dashboard/repair/RepairOverview";
import Repair from "../pages/dashboard/repair/Repair";
import RepairDevices from "../pages/dashboard/repair/RepairDevices";
import RepairDetails from "../pages/RepairDetails";
import { Knowledge } from "../apps/knowledge";
import KnowledgeRoute from "../pages/dashboard/knowledge/KnowledgeRoute";
import POSTransferStock from "../pages/dashboard/POS/requestStock/POSTransferStock";
import WarehouseOutCreate from "../pages/dashboard/stockOut/WarehouseOutCreate";
import CreateStockOut from "../pages/dashboard/stockOut/CreateStockOut";
import { getActiveShop } from "../utils/converter";
import { getAllStockToTransferAction } from "../store/channel/actions";
import PosCommissionDevices from "../components/cards/dashboard/PosCommissionDevices";

//Earning Routes
import EarningRoutes from "./EarningRoutes";
import OverView from "../pages/dashboard/Earning/OverView";

import InventoryOverView from "../pages/dashboard/Inventory/InventoryOverView";
import Transaction from "../pages/dashboard/reports/Transaction";
import InventoryRoutes from "./InventoryRoutes";
import CurrentStock from "../pages/dashboard/Inventory/CurrentStock";
import StockRequest from "../pages/dashboard/Inventory/StockRequest";
import TeamLeadTransfer from "../pages/dashboard/Inventory/TeamleadTransfer";
import CreateSale from "../pages/dashboard/Sales/CreateSale";
import SalesOverView from "../pages/dashboard/Sales/salesOverView";
import ReportRoutes from "./ReportRoutes";
import NoSales from "../pages/dashboard/reports/NoSales";
import PosCustomer from "./RoutesCustomer";
import PriceListRoutes from "./RoutePriceList";
import store from "store";
import FreelancerRautes from "./FreelancerRoutes";
import HomePage from "../apps/freelancerModule/pages/HomePage";
import ApplyDeviceFinancing from "../apps/freelancerModule/pages/ApplyDeviceFinancing";
import BusinnessPlans from "../apps/freelancerModule/pages/BussinessPlans";
import Applicants from "../apps/freelancerModule/pages/Applicants";
import ApplicantDetails from "../apps/freelancerModule/pages/ApplicantDetails";
import AllPlans from "../apps/freelancerModule/pages/AllPlans";
import StockTransfer from "../pages/dashboard/Inventory/StockTransfer";
import Transfer  from "../pages/dashboard/Inventory/Transfer";
import SaleWithSubscription from "../apps/freelancerModule/pages/SaleWithSubscription";
import Cart from "../apps/freelancerModule/pages/Cart";
import ConfirmCheckout from "../pages/dashboard/POS/steps/ConfirmCheckout";

const MainRouter = () => {
  const { auth, appSettings, channel } = useSelector((state: any) => state);
  const shopId = getActiveShop(auth?.user?.shop?.assigned)[
    appSettings?.selectedShopNumber
  ]?.shop?._id;

  const dispatch = useDispatch();
  const token = store.get("authToken");
  const user = store.get("user");
  useEffect(() => {
    if (token) {
      dispatch(authActions.setToken({ token }));
    }
    if (user) {
      dispatch(authActions.setUserShop(user?.shop));
    }
  }, [auth?.token, dispatch, token]);

  useEffect(() => {
    auth?.token &&
      shopId &&
      getAllStockToTransferAction(
        auth?.token,
        `?shopToTransfer=${shopId}&isTransferred=true&transferStatus=pending&limit=${1}&page=${0}`
      )(dispatch);
  }, [auth?.token, dispatch, shopId]);

  return (
    <Routes>
      <Route path="/reset/password/" element={<ResetPassword />} />
      <Route element={<LoginProtection />}>
        <Route path="/" element={<LoginByScan />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/scan" element={<LoginByScan />} />
      </Route>
      <Route path="/repair/:repaidId" element={<RepairDetails />} />
      <Route element={<PosCustomer />}>
        <Route path="/knowledge" element={<KnowledgeRoute />} />
        <Route path="/overview/customers" element={<CustomerOverview />} />
        <Route path="/customers" element={<CustomerPageView />} />
        <Route path="/customers/:customerId" element={<CustomerDetails />} />
      </Route>

      <Route path="/knowledge" element={<KnowledgeRoute />} />

      <Route path="/knowledged" element={<Knowledge.Screens.Outlet />}>
        <Route path="" element={<Knowledge.Screens.Home />} />
        <Route path="view/:id" element={<Knowledge.Screens.ViewPost />} />
        <Route path="manage" element={<Knowledge.Screens.Management />} />
        <Route path="manage/addPost" element={<Knowledge.Screens.AddPost />} />
        <Route
          path="manage/editPost/:id"
          element={<Knowledge.Screens.EditPost />}
        />
        <Route
          path="manage/addCategory"
          element={<Knowledge.Screens.AddCategory />}
        />
        <Route
          path="manage/editCategory/:id"
          element={<Knowledge.Screens.EditCategory />}
        />
      </Route>

      {/* POS */}
      <Route element={<POSRoutes totalRequest={channel?.rfsTransfer?.total} />}>
        <Route path="/dashboard" element={<PointOfSales />} />
        <Route path="/sale" element={<SalesOverView />} />
        <Route path="/sale/:cartId" element={<CreateSale />} />
        <Route path="/pos/sale/:cartId" element={<SaleWithPOS />} />
        <Route path="/checkout/:cartId" element={<ConfirmCheckout />} />
        <Route
          path="/pos/transactions"
          element={<POSTransactions route={"pos"} />}
        />
        <Route
          path="/pos/transactions/:cartId"
          element={<TransactionsDetails />}
        />
        <Route path="/pos/commission" element={<PosCommissionDevices />} />
        <Route path="/pos/request" element={<PosStockRequest />} />
        <Route path="/pos/request/:rfsId" element={<NewRequestView />} />
        <Route path="/pos/:rfsId" element={<ViewSingleRecent />} />
        <Route path="/pos/transfer" element={<POSTransferStock />} />
        <Route
          path="/pos/transfer/:stockreqitemId/:rfsId"
          element={<WarehouseOutCreate />}
        />
        <Route
          path="/pos/transfer/stock/:stockreqitemId/:wOutId"
          element={<CreateStockOut />}
        />
      </Route>

      {/*  Inventory */}
      <Route element={<InventoryRoutes />}>
        <Route path="/inventory" element={<InventoryOverView />} />
        <Route path="/inventory/current-stock" element={<CurrentStock />} />
        <Route path="/inventory/stock-request" element={<StockRequest />} />
        <Route path="/inventory/transfers" element={<Transfer />} />
      </Route>
      {/* report */}
      <Route element={<ReportRoutes />}>
        <Route path="/transaction" element={<Transaction />} />
        <Route path="/nosales" element={<NoSales />} />
      </Route>
      {/* Repair*/}
      <Route element={<RepairRoutes />}>
        <Route path="/repair" element={<RepairOverview />} />
        <Route path="/add/repair" element={<Repair />} />
        <Route path="/repair/devices" element={<RepairDevices />} />
      </Route>
      {/* Earning */}
      <Route element={<EarningRoutes />}>
        <Route path="/earning" element={<OverView />} />
      </Route>
      <Route element={<FreelancerRautes />}>
        <Route path="/home" element={<HomePage />} />
        <Route path="/plans" element={<AllPlans />} />
        <Route path="/apply/:cartId" element={<ApplyDeviceFinancing/>} />
        <Route path="/applicants" element={<Applicants/>} />
        <Route path="/applicants/:applicantId" element={<ApplicantDetails/>} />
        <Route path="/cart" element={<Cart/>} />
        <Route path="/cart/:cartId" element={<SaleWithSubscription/>} />
        <Route path="/transfer" element={<  Transfer />} />
      </Route>

      {/* Price list */}
      <Route element={<PriceListRoutes />}>
        <Route path="/priceLists" element={<PosPriceList />} />
      </Route>
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
};

export default MainRouter;

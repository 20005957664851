import React, { useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import { Divider } from "antd";
import { ReactComponent as RRALogo } from "../../../assets/images/RRA_Logo.svg";
import { useSelector } from "react-redux";
import { getActivePrice, getTotalPrice } from "../../../utils/converter";
// import { WhatsappShareButton } from "react-share";

const RRAPaymentReceipt: React.FC<{ templateRef: any; handlePrint: any }> = ({
  templateRef,
  handlePrint,
}) => {
  const { wareHouse,company } = useSelector((state: any) => state);
  const elementRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState([
    {
      name: "John Doe",
      age: 30,
    },
    {
      name: "Jane Doe",
      age: 25,
    },
  ]);

  const generatePDF = () => {
    const element = elementRef.current;

    if (element) {
      setTimeout(() => {
        html2pdf()
          .set({
            margin: 0.5,
            filename: "1my-component.pdf",
            image: { type: "jpeg" },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: [58, 297] },
            autoPaging: true,
          })
          .from(element)
          .save();
      }, 1000); // Delay the PDF generation by 1 second (adjust as needed)
    }
  };

  // console.log("*****", wareHouse?.createdCart?.data?.ebmreceiptNo);

  return (
    <div ref={templateRef} className=" text-xs text-[#0F0F47] p-[50px]">
      {/* Title */}
      {/* <div className="flex gap-2 mb-4 items-center xjustify-center">
        <RRALogo />
        <p className="font-semibold">Rwanda Revenue Authority</p>
      </div> */}

      <div className="space-y-2">
        <div className="flex flex-col gap-1 text-sm">
                <p> {company?.selected?.name}</p>
                <p>{company?.selected?.address?.provence} - {company?.selected?.address?.country}</p>
                <p>{company?.selected?.email}</p>
                <p> {company?.selected?.phone}</p>
                <p>{company?.selected?.tin}</p>
                <p>{company?.selected?.cachier}</p>
              </div>
      </div>

      <Divider dashed className="my-4 border" />

      <div>
        <p>
          <b>CLIENT TIN:</b>{" "}
          {wareHouse?.createdCart?.data?.customer?.tin ??
            wareHouse?.createdCart?.data?.customer?.phone}
        </p>
        <p>
          <b>CLIENT NAME:</b> {wareHouse?.createdCart?.data?.customer?.name}
        </p>
      </div>

      <Divider dashed className="my-4 border"/>

      {wareHouse?.createdCart?.data?.list?.map((d: any) => {
        
        return(
        <div>
          <p>
            {
              d?.warehouseProduct?.warehouseItem?.purchaseItem?.quotationItem
                ?.product?.model
            }
          </p>
          <p className="font-bold">
            {
              d?.warehouseProduct?.serialNumber
            }
          </p>
          <p>{d?.warehouseProduct?.warehouseItem?.ebmItemCode}</p>
          <div className="flex justify-between">
            <p>
              {getActivePrice(
                d?.shopStockItem?.requestedItem?.product?.prices
              )?.toFixed(2) + " x 1"}
            </p>
            <p>
              {(
                d?.payment?.amount +
                (d?.payment?.amount * d?.payment?.extendedWarranty) / 100
              )?.toFixed(2) + " A-EX"}
            </p>
          </div>
        </div>
      )})}

      <Divider dashed className="my-4 border" />

      <div>
        <div className="flex justify-between">
          <p>TOTAL</p>
          <p>
            {getTotalPrice(
              wareHouse?.createdCart?.data?.list
            )?.toLocaleString()}
          </p>
        </div>
        <div className="flex justify-between">
          <p>TOTAL A-EX</p>
          <p>
            {getTotalPrice(
              wareHouse?.createdCart?.data?.list
            )?.toLocaleString()}
          </p>
        </div>
        <div className="flex justify-between">
          <p>TOTAL B-18%</p>
          <p>0.00</p>
        </div>
        <div className="flex justify-between">
          <p>TOTAL TAX-B</p>
          <p>0.00</p>
        </div>
        <div className="flex justify-between">
          <p>TOTAL TAX</p>
          <p>0.00</p>
        </div>
      </div>

      <Divider dashed className="my-2 border" />
      {wareHouse?.createdCart?.data?.payment?.map((d: any) => (
        <div className="flex justify-between">
          <p className="font-semibold">{d?.mode?.split("_")?.join(" ")}</p>
          <p>{d?.amount?.toLocaleString()}</p>
        </div>
      ))}

      <Divider dashed className="my-2 border" />

      <div className="flex justify-between">
        <p>ITEM NUMBER:</p>
        <p>{wareHouse?.createdCart?.data?.list?.length}</p>
      </div>

      <Divider dashed className="my-2 border" />

      <div className="flex flex-col items-center">
        <p>SDC INFORMATION</p>
        <div className="flex flex-col w-full">
          <div className="flex justify-between">
            <p>Date: {wareHouse?.createdCart?.data?.createdAt?.slice(0, 10)}</p>
            <p>
              Time: {wareHouse?.createdCart?.data?.createdAt?.slice(11, 16)}
            </p>
          </div>
          <div className="flex justify-between">
            <p>SDC ID:</p>
            {/* <p>{wareHouse?.createdCart?.data?.ebmsdcId ?? "N/A"}</p> */}
            <p>{"N/A"}</p>
          </div>
          <div className="flex justify-between">
            <p>RECEIPT NUMBER:</p>
            {/* <p>{wareHouse?.createdCart?.data?.ebmreceiptNo ?? "N/A"}/NS</p> */}
            <p>{"N/A"}</p>
          </div>
        </div>
      </div>

      <Divider dashed className="my-3 border" />

      <div className="text-center">
        <p className="font-semibold">INTERNAL DATA</p>
        {/* <p>{wareHouse?.createdCart?.data?.intrlData}</p> */}
        <p className="font-semibold">RECEIPT SIGNATURE</p>
        {/* <p>{wareHouse?.createdCart?.data?.receiptSignature}</p> */}
      </div>

      <Divider dashed className="my-3 border" />

      <div>
        <div className="flex justify-between">
          <p>RECEIPT NUMBER:</p>
          <p>1</p>
        </div>
        <div className="flex justify-between">
          <p>Date: {wareHouse?.createdCart?.data?.createdAt?.slice(0, 10)}</p>
          <p>Time: {wareHouse?.createdCart?.data?.createdAt?.slice(11, 16)}</p>
        </div>
        <div className="flex justify-between">
          <p>MRC:</p>
          <p>WIS00071759</p>
        </div>
      </div>

      <Divider dashed className="my-3 border" />

      <p className="text-md font-semibold">END OF LEGAL RECEIPT</p>
      <Divider dashed className="my-1" />
      <Divider dashed className="my-1" />
      {/* <WhatsappShareButton
        title="Check out this PDF"
        url={"https://force.samphone/stats"}
      >
        <button>Share on WhatsApp</button>
      </WhatsappShareButton> */}

      {/* <button
        className="bg-blue-800 py-1 px-2 rounded-sm text-white"
        onClick={handlePrint}
      >
        Generate PDF
      </button> */}
    </div>
  );
};

export default RRAPaymentReceipt;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { BrowserMultiFormatReader } from "@zxing/library";

const BarcodeScanner: React.FC<{
  isLogin?: boolean;
  getCode: (code: string) => void;
  modalIsOpen: boolean;
  setModalIsOpen: (state: boolean) => void;
}> = ({ isLogin, getCode, modalIsOpen, setModalIsOpen }) => {
  const [cameraOpen, setCameraOpen] = useState(true);
  const [code, setCode] = useState("");

  const scanner = useRef(new BrowserMultiFormatReader());

  const openModal = () => {
    setCameraOpen(false);
    setTimeout(() => {
      setModalIsOpen(!modalIsOpen);
    }, 2000);
  };

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    scanner.current.stopAsyncDecode();
    scanner.current.reset();
  }, [setModalIsOpen]);

  const handleScan = useCallback(
    (result: any) => {
      setCode(result.getText());
      getCode(result.getText());
      closeModal();
    },
    [closeModal, getCode]
  );

  const handleError = useCallback(
    (error: any) => {
      console.error(error);
      closeModal();
    },
    [closeModal]
  );

  const startScanning = useCallback(() => {
    scanner.current
      .decodeOnceFromVideoDevice(undefined, "video")
      .then(handleScan)
      .catch(handleError);
  }, [handleError, handleScan, scanner]);

  useEffect(() => {
    if (cameraOpen) {
      startScanning();
    } else {
      scanner.current.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraOpen]);

  useEffect(() => {
    scanner.current.stopAsyncDecode();
    scanner.current.reset();
  }, [code]);

  return (
    <div className="relative flex items-start">
      {cameraOpen && (
        <>
          <button
            onClick={openModal}
            className="absolute top-2 right-2 flex items-center text-2xl text-white gap-x-2 z-50"
          >
            X
          </button>
          <div className="relative">
            <video
              id="video"
              height="70"
              className="w-80 rounded-lg"
              playsInline
              autoPlay
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BarcodeScanner;

import { notification } from "antd";
import { shopSalesReportAction, shopSalesReportTable } from "./index";
import {
  getAllSalesReportService,
  SalesReportTable,
  updatewarehouseDataActivationService,
} from "./services";



export const getAllSalesReportAction = (token: string, shop: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(shopSalesReportAction.setIsFetching(true));
      const res = await getAllSalesReportService(token, shop);
      console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(shopSalesReportAction.setAll(res));
        dispatch(shopSalesReportAction.setIsFetching(false));
      }
      dispatch(shopSalesReportAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getSalesReportTable = (token: string, itemId: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(shopSalesReportTable.setIsFetching(true));
      const res = await SalesReportTable(token, itemId);
      console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(shopSalesReportTable.setTableData(res));
        dispatch(shopSalesReportTable.setIsFetching(false));
      }
      dispatch(shopSalesReportTable.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
import React from "react";
import { useSelector } from "react-redux";

const DataPlan = () => {
  const { wareHouse } = useSelector((state: any) => state);
  return (
    <div className="flex flex-col border-t space-y-2 pb-4 border-b">
      {/* <span className="pt-2 font-semibold"> Data Plan</span> */}
      <div className="flex flex-wrap space-y-2 items-center space-x-4">
        {/* <p>
          Bundle:{" "}
          <span>{`${wareHouse?.createdCart?.data?.other?.bundle} GB`}</span>
        </p>
        <p>
          SMS: <span>{wareHouse?.createdCart?.data?.other?.sms}</span>
        </p>
        <p>
          Call: <span>{wareHouse?.createdCart?.data?.other?.call}</span>
        </p>
        <p>
          Period:{" "}
          <span>{`${wareHouse?.createdCart?.data?.other?.period} Months`}</span>
        </p> */}
      </div>
    </div>
  );
};

export default DataPlan;
